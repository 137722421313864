import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, FormElementWrapper, Label, ShoppingCart } from '@fjordline/styles-v3'

import { ButtonFull } from '../../../components/globalStyles'
import UseGetBookingDetails from '../../../graphql/customerHooks/UseGetBookingDetails'
import { BasketNavLink } from '../../../routes/navLinkFunctions'

import { ShoppingCartWrapper } from './basketInfo/basketInfo.styles'

type BasketType = {
  sumTotal: number
  bookingCode: string
  backTarget?: string
  backTargetTitle?: string
}

const BasketInfo: React.FC<BasketType> = ({ bookingCode, sumTotal, backTarget, backTargetTitle }: BasketType) => {
  const { t } = useTranslation()

  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode })

  return (
    <>
      <ShoppingCartWrapper>
        <ShoppingCart color="WHITE" width={30} height={30} />
        <Label as="h6">{t('label.booking.basketInfo.yourBasket')}</Label>
      </ShoppingCartWrapper>
      <p>{t('label.booking.basketInfo.info')}</p>
      <Label as="h5" size="medium">
        {t('label.booking.basketInfo.toPay')} {flBooking?.currency} {sumTotal}
      </Label>
      <FormElementWrapper>
        <ButtonFull>
          <Link
            to={BasketNavLink({
              backTarget,
              backTargetTitle,
              bookingCode,
            })}
          >
            <Button theme="clean" size="medium" dataCy="go-to-basket-btn">
              {t('label.button.gotoBasket')}
            </Button>
          </Link>
        </ButtonFull>
      </FormElementWrapper>
    </>
  )
}

export default BasketInfo
