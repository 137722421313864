import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Accordion, AccordionItem, Button, Column, H2, Modal, Row, useAccordionState } from '@fjordline/styles-v3'
import styled from 'styled-components'

import StepperStateWrapper from '../../../../components/StepperStateWrapper'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { AvailabilityExtraItemWithSpec, Specification } from '../../../../graphql/types'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../../../providers/AvailabilityItemsProvider'
import { extractBookingNumber } from '../../../../providers/WebsocketProvider'
import { extractSubCode } from '../addExtrasAshore/ExtrasAshore'
import useFetchExtras from '../../../../sanity/extras/useFetchExtras'
import { StyledPortableText, _blankAnchor } from '../../../../sanity/components/PortableTextComponent'
import { PortableText } from '@portabletext/react'
import useFetchSpecifications from '../../../../sanity/specifications/useFetchSpecifications'

type ExtrasProps = {
  isOutbound: boolean
  data: AvailabilityExtraItemWithSpec[]
}

const ExtrasOnboard: React.FC<ExtrasProps> = ({ data, isOutbound }: ExtrasProps) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { openedItems, onToggle, onToggleAll } = useAccordionState({ multiOpen: true })
  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.extrasOnboard
  const isOnboard = true
  const { t } = useTranslation()

  const checkedItems = itemsInState?.map((item) => {
    return `code: ${item.code}`
  })

  return (
    <Row>
      <Column>
        {' '}
        <H2 style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          {isOutbound ? t('component.extras.common.departure') : t('component.extras.common.arrival')}
        </H2>
      </Column>

      <LessPadding>
        <Accordion
          onToggleAll={onToggleAll}
          variant="panel"
          onToggle={onToggle}
          openedItems={openedItems}
          values={checkedItems}
        >
          {data.map((item) => {
            //cancellation insurance is only selected for outbound, but will be added for inbound aswell.
            if (item.isCancellationInsurance) return null
            if (item.isPetInCar && flBooking?.outbound?.vehicles?.some((e) => !e.code?.includes('FL_'))) return null
            if (item.code.includes('CHARGE') && flBooking?.outbound?.vehicles?.some((e) => !e.code?.includes('FL_')))
              return null
            return (
              <ExtraOnboardAccordionItem key={item.code} item={item} isOutbound={isOutbound} isOnboard={isOnboard} />
            )
          })}
        </Accordion>
      </LessPadding>
    </Row>
  )
}

export default ExtrasOnboard

export const ExtraItemReadMoreContainer = styled.div`
  a {
    font-weight: 500;
    text-decoration: underline;
  }
`

export const LessPadding = styled.div`
  > div > div > div > div {
    padding: 1.5rem;
  }
`
type ExtraAccordionItemProps = {
  item: AvailabilityExtraItemWithSpec
  isOutbound: boolean
  isOnboard: boolean
}
const ExtraOnboardAccordionItem: React.FC<ExtraAccordionItemProps> = ({
  item,
  isOutbound,
  isOnboard,
}: ExtraAccordionItemProps) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { openedItems, onToggle, onToggleAll } = useAccordionState({ multiOpen: true })
  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.extrasOnboard
  const { addExtrasToCart } = useAvailabilityItemsOperations()
  const { t } = useTranslation()
  const [openModal, setOpenModal] = React.useState(false)
  const { findExtrasFullDescription, findExtrasName, findImageUrl } = useFetchExtras(item.code)
  const checkedAccordionItem = itemsInState?.map((item) => {
    return `AccordionItem: code: ${item.code} subCode: ${item.subCode}`
  })

  return (
    <>
      <AccordionItem key={item.code} title={findExtrasName() ?? item.itemName} itemId={`code: ${item.code}`}>
        <Accordion
          data-cy={`accordion-${findImageUrl()}`}
          variant="checkbox"
          values={checkedAccordionItem}
          openedItems={openedItems}
          onToggle={onToggle}
          onToggleAll={onToggleAll}
          onChange={(e) => {
            if (e && typeof e === 'string') {
              const specCode = extractSubCode(e)
              const spec = item.specifications?.find((a) => a.specificationCode === specCode)
              if (spec) {
                if (itemsInState.find((e) => e.code === item.code && e.subCode === specCode)) {
                  addExtrasToCart(item, 0, spec, bookingCode || '', isOutbound, isOnboard)
                } else {
                  addExtrasToCart(item, 1, spec, bookingCode || '', isOutbound, isOnboard)
                }
              }
            }
          }}
        >
          <Row style={{ marginBottom: '1rem' }}>
            <Column large={4} medium={4}>
              {findImageUrl() ? (
                <img
                  width="100%"
                  src={findImageUrl()}
                  alt={findExtrasName() || item.code}
                  style={{ borderRadius: '4px', maxHeight: '300px' }}
                />
              ) : null}
            </Column>
            <Column large={8} medium={8}>
              <StyledPortableText>
                <PortableText value={findExtrasFullDescription()} components={_blankAnchor} />
              </StyledPortableText>
            </Column>
          </Row>
          {item.specifications?.map((spec) => {
            return (
              <ExtraSpec
                key={spec.specificationCode}
                spec={spec}
                item={item}
                isOutbound={isOutbound}
                isOnboard={isOnboard}
              />
            )
          })}
        </Accordion>
        {findExtrasFullDescription() &&
        findExtrasFullDescription().length > 0 &&
        (item.isCancellationInsurance || item.isPetInCar || item.isDogCage) ? (
          <Button
            onClick={() => {
              setOpenModal(true)
            }}
            style={{ marginBottom: '1rem', marginTop: '1rem' }}
            theme="ghost"
          >
            {t('component.extras.common.readMore')}
          </Button>
        ) : null}
      </AccordionItem>
      {openModal ? (
        <Modal
          data-cy="modal-read-more"
          onRequestClose={() => {
            setOpenModal(false)
          }}
          hideHeader
          label=""
        >
          <PortableText value={findExtrasFullDescription()} components={_blankAnchor} />
        </Modal>
      ) : null}
    </>
  )
}
type ExtraSpecProps = {
  spec: Specification
  item: AvailabilityExtraItemWithSpec
  isOutbound: boolean
  isOnboard: boolean
}
const ExtraSpec: React.FC<ExtraSpecProps> = ({ spec, item, isOutbound, isOnboard }: ExtraSpecProps) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const numberOfVehicles = flBooking?.outbound?.vehicles?.filter((e) => e.code?.includes('FL_')).length || 0
  const { i18n } = useTranslation()
  const { findExtrasName } = useFetchExtras(item.code)
  const { specByCode } = useFetchSpecifications(spec.specificationCode)
  const { addExtrasToCart } = useAvailabilityItemsOperations()
  const { addToCartState } = useAvailabilityItems()

  const itemsInState = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.extrasOnboard

  return (
    <Column key={spec.specificationCode}>
      <StepperStateWrapper
        data-cy={`stepper-${spec.specificationCode}`}
        id={spec.specificationCode || ''}
        label={
          spec.specificationCode && spec.specificationCode.length > 0
            ? specByCode?.specificationName?.[i18n.language] || ''
            : findExtrasName() || ''
        }
        max={item.code.includes('CHARGE') ? numberOfVehicles : item.quantityAvailable}
        inputAsText={true}
        min={0}
        allowZero={true}
        value={itemsInState?.find((m) => m.code === item.code && m.subCode === spec.specificationCode)?.quantity || 0}
        setValue={(value) => {
          addExtrasToCart(item, value, spec, bookingCode || '', isOutbound, isOnboard)
        }}
        price={{
          amount: spec.specificationPrice.value || 0,
          currency: spec.specificationPrice.currency || '',
          locale: i18n.language,
        }}
      />
    </Column>
  )
}
