import { useParams } from 'react-router-dom'

import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { AvailabilityMealItemWithSpec, Currency, useGetAvailableMealsQuery } from '../../types'
import UseRouteExpect from '../route/UseRouteExpect'
import { useMemo } from 'react'
import { addCategoryToAttatchedMeals, filterMeals, sortMealsByStartTime } from './helpers'
function UseGetOutboundMeals(addModalExtrasBookingCode?: string) {
  const { bookingCode: bookingCodeFromParams } = useParams()
  const bookingCode = addModalExtrasBookingCode || bookingCodeFromParams
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })

  const attatchedMeals = flBooking?.outbound?.meals?.filter((meal) => meal.isAttachment === true)

  const journeyCode = flBooking?.outbound?.journeyCode as string

  const { expectMeals, routeExpectLoading } = UseRouteExpect({
    originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
  })

  const { data, loading, refetch } = useGetAvailableMealsQuery({
    variables: {
      requestParams: {
        ticketCode: flBooking?.outbound?.ticket?.ticketCode as string,
        journeyCode: journeyCode,
        departureDate: flBooking?.outbound?.departureDate as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.outbound?.departureCode as string,
        destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
        hasVehicles: flBooking?.outbound?.vehicles && flBooking?.outbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.outbound?.passengers?.[0].customerCode &&
          flBooking?.outbound?.passengers[0].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.outbound?.vesselCode as string,
      },
    },
    skip:
      flBooking?.isOngoing ||
      flBooking?.canBuyBookingItems === false ||
      !expectMeals ||
      routeExpectLoading ||
      journeyCode === undefined ||
      flBooking?.outbound?.departureCode === undefined ||
      flBooking?.outbound?.departurePortInfo?.carresPortCode === undefined ||
      flBooking.outbound.ticket?.ticketCode === undefined,
  })

  const mealsDataFromGQL = data?.mealAvailability.availableMeals as AvailabilityMealItemWithSpec[]

  const attatchedMealsWithCategory = addCategoryToAttatchedMeals(attatchedMeals)

  const hasAttatchedMeals = attatchedMealsWithCategory && attatchedMealsWithCategory?.length > 0

  const mealsData = useMemo(
    () => (hasAttatchedMeals ? filterMeals(attatchedMealsWithCategory, mealsDataFromGQL) : mealsDataFromGQL),
    [attatchedMealsWithCategory, hasAttatchedMeals, mealsDataFromGQL],
  )

  return {
    data: mealsData,
    loading,
    refetch,
    attatchedMeals: sortMealsByStartTime(attatchedMealsWithCategory ?? []),
  }
}

export default UseGetOutboundMeals
