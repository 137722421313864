import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { extractBookingNumber } from '../../../providers/WebsocketProvider'
import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { Currency, FareCode, useGetSuggestedItemsQuery } from '../../types'

type Props = {
  passengersToPlace: number
  itemCode: string
}

function UseGetSuggestedCabinsInbound({ passengersToPlace, itemCode }: Props) {
  useTranslation()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })

  const { data, loading } = useGetSuggestedItemsQuery({
    variables: {
      requestParams: {
        journeyCode: flBooking?.inbound?.journeyCode as string,
        departureDate: flBooking?.inbound?.departureDate as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.inbound?.departureCode as string,
        destinationPort: flBooking?.inbound?.arrivalPortInfo?.portCode as string,
        ticketCode: flBooking?.inbound?.ticket?.ticketCode as string,
        hasVehicles: flBooking?.inbound?.vehicles && flBooking?.inbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.inbound?.passengers?.[0].customerCode && flBooking?.inbound?.passengers[0].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.inbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.inbound?.vesselCode,
        itemCode: itemCode,
        passengers: [
          {
            code: FareCode.Adult,
            quantity: passengersToPlace,
          },
        ],
      },
    },
    skip:
      flBooking?.inbound?.journeyCode === undefined ||
      flBooking?.inbound?.departureCode === undefined ||
      itemCode === undefined ||
      itemCode !== 'LOUNGE' ||
      passengersToPlace === 0,
  })

  return {
    inboundSuggestedCabins: data?.suggest.cabins,
    inboundSuggestedLoading: loading,
  }
}

export default UseGetSuggestedCabinsInbound
