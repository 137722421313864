import { Participants } from '../../getBookingObjects/getBookingObjects.types'

export default function getMealSeatingInfo(participants: Participants): string {
  return Object.keys(participants).reduce((acc: string, key) => {
    const seating = participants[key]
    if (seating.quantity === 0) {
      return ''
    }
    const newInfo = `${seating.quantity} ${seating.translatedInfo}`
    acc = acc.length === 0 ? `${newInfo}` : `${acc} - ${newInfo}`
    return acc
  }, '')
}
