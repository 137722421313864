import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom'
import { Button, FormActionsWrapper } from '@fjordline/styles-v3'

import { BookingList } from '../../types/MyPage'
import { Status } from '../../types/myPage/enums'

export default function NavigationButtons({
  bookingList,
  currentBookingStatusToView,
  setParams,
}: {
  bookingList: BookingList | undefined
  currentBookingStatusToView: Status
  setParams: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => void
}): JSX.Element {
  const { t } = useTranslation()
  return (
    <FormActionsWrapper>
      <Button
        disabled={currentBookingStatusToView === Status.ACTIVE}
        onClick={() => setParams(`?view=${Status.ACTIVE}`)}
      >
        {`${t(`label.button.${Status.ACTIVE}`)}(${
          bookingList ? Object.keys(bookingList.ongoing.indexedBookings).length : '0'
        })`}
      </Button>
      <Button
        disabled={currentBookingStatusToView === Status.FUTURE}
        onClick={() => setParams(`?view=${Status.FUTURE}`)}
      >
        {`${t(`label.button.${Status.FUTURE}`)}(${
          bookingList ? Object.keys(bookingList.future.indexedBookings).length : '0'
        })`}
      </Button>
      <Button
        disabled={currentBookingStatusToView === Status.PASSED}
        onClick={() => setParams(`?view=${Status.PASSED}`)}
      >
        {`${t(`label.button.${Status.PASSED}`)}(${
          bookingList ? Object.keys(bookingList.passed.indexedBookings).length : '0'
        })`}
      </Button>
      <Button
        disabled={currentBookingStatusToView === Status.CANCELLED}
        onClick={() => setParams(`?view=${Status.CANCELLED}`)}
      >
        {`${t(`label.button.${Status.CANCELLED}`)}(${
          bookingList ? Object.keys(bookingList.cancelled.indexedBookings).length : '0'
        })`}
      </Button>
    </FormActionsWrapper>
  )
}
