import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
// import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { GlobalStyles, ToastProvider } from '@fjordline/styles-v3'

import { BASE_PATH } from './config'
import MyPageApolloClient from './graphql/MyPageApolloClient'
import MyPageStateProvider from './providers/MyPageStateProvider'

import { gtmParams } from './utils/GTM/tagManagerArgs'
import { getCsp } from './utils/CSP'
import isPropValid from '@emotion/is-prop-valid'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CSP = getCsp(gtmParams.nonce)

import { ErrorBoundary } from 'react-error-boundary'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

import AvailabilityItemsProvider from './providers/AvailabilityItemsProvider'

import { ErrorFallback, logErrors } from './pages/ErrorBoundary'

import './App.css'
import TelemetryProvider from './providers/TelemetryProvider'
import { StyleSheetManager } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import KeycloakProvider from './providers/KeycloakProvider'
import Navbar from './components/Navbar'
import AppRoutes from './routes/AppRoutes'
const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <BrowserRouter basename={BASE_PATH}>
      <StyleSheetManager
        shouldForwardProp={(propName, elementToBeRendered) => {
          return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logErrors}>
          <QueryClientProvider client={queryClient}>
            <TelemetryProvider>
              <HelmetProvider>
                <ToastProvider>
                  <Helmet>{/* <meta httpEquiv="Content-Security-Policy" content={CSP} /> */}</Helmet>
                  <KeycloakProvider>
                    <MyPageApolloClient>
                      <GTMProvider state={gtmParams}>
                        <MyPageStateProvider>
                          <AvailabilityItemsProvider>
                            <GlobalStyles />
                            <Navbar>
                              <AppRoutes />
                            </Navbar>
                          </AvailabilityItemsProvider>
                        </MyPageStateProvider>
                      </GTMProvider>
                    </MyPageApolloClient>
                  </KeycloakProvider>
                </ToastProvider>
              </HelmetProvider>
            </TelemetryProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </StyleSheetManager>
    </BrowserRouter>
  )
}

export default App
