import React from 'react'
import UseGetBookingDetails from '../../../graphql/customerHooks/UseGetBookingDetails'
import { useTranslation } from 'react-i18next'
import useFetchCabins from '../../../sanity/cabins/useFetchCabins'

type Props = {
  code: string
  bookingCode: string
  rowNumber: number
  isOutbound: boolean
}

const RemovedCabins: React.FC<Props> = ({ code, bookingCode, rowNumber, isOutbound }) => {
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { i18n, t } = useTranslation()
  const journey = isOutbound ? flBooking?.outbound : flBooking?.inbound
  const vesselCode = isOutbound ? flBooking?.outbound?.vesselCode : flBooking?.inbound?.vesselCode
  const { cabinsFromSanity } = useFetchCabins(code, vesselCode || undefined)
  return (
    <div style={{ textDecoration: 'line-through', opacity: '60%' }}>
      {' '}
      {cabinsFromSanity?.cabinName?.[i18n.language] || code} •{' '}
      {isOutbound ? t('component.general.departure') : t('component.general.return')} • {flBooking?.currency}{' '}
      {journey?.cabins?.find((e) => e.rowNumber === rowNumber)?.price}
    </div>
  )
}

export default RemovedCabins
