import { useEffect, useMemo } from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { FlBooking } from '../../graphql'
import { useAvailabilityItems } from '../../providers/AvailabilityItemsProvider'

import { compartmentsData, extrasData, mealsData, ticketData } from './productData'
import { GTM_events, GTM_TravelType, GTM_tripType } from './types'

function usePurchaseGTM(session: FlBooking, bookingCode: string, price: number) {
  const { addToCartState } = useAvailabilityItems()
  const sendDataToGTM = useGTMDispatch()

  const timeBetweenNowAndDepartureDate =
    new Date(session?.outbound?.departureDate as string).getTime() - new Date().getTime()
  const daysToDeparture = Math.floor(timeBetweenNowAndDepartureDate / (1000 * 60 * 60 * 24))
  const productName = session?.outbound?.ticket?.ticketCode
  const outboundVessel = session?.outbound?.vesselCode
  const inboundVessel = session?.inbound?.vesselCode
  const availabilityItems = addToCartState[bookingCode]

  let tripType
  let travelType

  // if (session?.accommodations && session?.accommodations.length > 0) {
  //   travelType = GTM_TravelType.package
  //   tripType = GTM_tripType.roundTrip
  // }

  if (
    session?.outbound &&
    session?.inbound === null &&
    session?.outbound.departurePortInfo?.portCode === session?.outbound.arrivalPortInfo?.portCode
  ) {
    travelType = GTM_TravelType.cruise
    tripType = GTM_tripType.oneWay
  }

  if (
    session?.outbound &&
    session?.inbound === null &&
    session?.outbound.departurePortInfo?.portCode !== session?.outbound.arrivalPortInfo?.portCode
  ) {
    travelType = GTM_TravelType.travel
    tripType = GTM_tripType.oneWay
  }

  if (
    session?.outbound &&
    session?.inbound &&
    session?.outbound.departurePortInfo?.portCode !== session?.outbound.arrivalPortInfo?.portCode
  ) {
    travelType = GTM_TravelType.travel
    tripType = GTM_tripType.roundTrip
  }

  const purchaseData = useMemo(
    () => ({
      // base data
      event: GTM_events.PURCHASE,
      // dimensions
      tripType: tripType,
      travelType: travelType,
      route: `${session?.outbound?.departurePortInfo?.portCode} - ${session?.outbound?.arrivalPortInfo?.portCode}`,
      bookingType: 'New',
      daysToDeparture: daysToDeparture,
      // metrics
      transactionRebate: 0,
      ecommerce: {
        currencyCode: session?.currency,
        purchase: {
          actionField: {
            action: GTM_events.PURCHASE,
            revenue: price ? price : 0,
            id: bookingCode ?? '',
            affiliation: session?.language,
          },
          products: ticketData(session, productName as string, outboundVessel as string, inboundVessel as string)
            .concat(compartmentsData(session, availabilityItems, outboundVessel as string, inboundVessel as string))
            .concat(mealsData(session, availabilityItems, outboundVessel as string, inboundVessel as string))
            .concat(extrasData(session, availabilityItems, outboundVessel as string, inboundVessel as string)),
        },
      },
    }),
    [
      availabilityItems,
      bookingCode,
      daysToDeparture,
      inboundVessel,
      outboundVessel,
      price,
      productName,
      session,
      travelType,
      tripType,
    ],
  )
  const hasSentGTMData = sessionStorage.getItem('sendGTMPurchaseData') !== null
  useEffect(() => {
    if (!hasSentGTMData && session) {
      sessionStorage.setItem('sendGTMPurchaseData', 'true')
      sendDataToGTM(purchaseData)
    }
  }, [hasSentGTMData, purchaseData, sendDataToGTM, session])
}

export default usePurchaseGTM
