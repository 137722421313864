import { IPassengerDetails } from '@fjordline/booking-draft'

import { passengerBirthDate } from '../../../../../fjordClub/editMemberShip/createBirthdayLists'

export function useCreateBabyBirthdayList(
  babyYearList: passengerBirthDate[],
  months,
  daysList: passengerBirthDate[],
  existingTraveler: IPassengerDetails | undefined,
) {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const currentDay = new Date().getDate()
  const currentDate = new Date(currentYear, currentMonth, currentDay)
  const currentDateMinusOneYear = new Date(currentDate.setFullYear(currentYear - 1))
  let babyMonths = months
  let days = daysList
  function leapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
  }

  if (existingTraveler?.birthMonth === 2 && !leapYear(existingTraveler.birthYear)) {
    days = days.slice(0, 28)
  } else if (existingTraveler?.birthMonth === 2 && leapYear(existingTraveler.birthYear)) {
    days = days.slice(0, 29)
  } else if (
    existingTraveler?.birthMonth === 4 ||
    existingTraveler?.birthMonth === 6 ||
    existingTraveler?.birthMonth === 9 ||
    existingTraveler?.birthMonth === 11
  ) {
    days = days.slice(0, 30)
  }

  if (existingTraveler?.birthYear === babyYearList?.[babyYearList.length - 1]?.title) {
    const minimumDay = currentDateMinusOneYear.getDate()
    const minimumMonth = currentDateMinusOneYear.getMonth() + 1
    babyMonths = months.slice(0, minimumMonth)

    if (existingTraveler.birthMonth === babyMonths[babyMonths.length - 1]?.id) {
      days = days.slice(0, minimumDay)
    }
  }

  if (existingTraveler?.birthYear === babyYearList[0]?.title) {
    const minimumDay = currentDateMinusOneYear.getDate()
    const minimumMonth = currentDateMinusOneYear.getMonth()
    babyMonths = months.slice(minimumMonth)

    if (existingTraveler.birthMonth === babyMonths[0].id) {
      days = days.slice(minimumDay)
    }
  }

  if (existingTraveler?.birthYear === currentYear && existingTraveler?.birthMonth === currentMonth + 1) {
    days = days.slice(0, currentDay)
  }

  // if(existingTraveler?.birthYear === babyYearList[0].title + 1 && existingTraveler?.birthMonth === babyMonths[0].id + 1) {
  //   days = days.slice(currentDay)
  //  }

  return { babyDays: days, babyMonths }
}
