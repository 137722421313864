import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EditInput, FormElementWrapper, HeaderWithIcon, Travelers } from '@fjordline/styles-v3'

import { Passenger } from '../../graphql/types'
import { BookingTravelerNavLink } from '../../routes/navLinkFunctions'
import { MayBe } from '../../types/MayBe'
import moment from 'moment/min/moment-with-locales'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { isPastDeadline } from '../../utils/isPastDeadline'
/***
 * @description - Render passengers available, else only the header if showHeaderEvenIfNoVehicles is true
 * @param isFuture
 * @param passengers
 * @param showHeaderEvenIfNoVehicles - hide header if no passengers
 * @constructor
 */
export default function SectionPassengers({
  isFuture,
  passengers,
  showHeaderEvenIfNoVehicles = false,
}: {
  isFuture: boolean
  passengers: Passenger[]
  showHeaderEvenIfNoVehicles?: boolean
}): JSX.Element {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { flBooking } = useBookingDetails()
  const translations = useMemo(() => {
    return {
      firstName: t('label.personalia.firstName'),
      lastName: t('label.personalia.lastName'),
      dateOfBirth: t('label.personalia.dateOfBirth'),
      memberNumber: t('label.membership.memberNumber'),
    }
  }, [t])

  return (
    <div data-cy="section-passengers">
      {showHeaderEvenIfNoVehicles || passengers.length > 0 ? (
        <HeaderWithIcon icon={Travelers}>{t('label.traveler.header')}</HeaderWithIcon>
      ) : null}
      {passengers.length > 0
        ? passengers.map((passenger: Passenger) => {
            const birthDateUTC = moment.utc(
              `${passenger.birthYear}-${passenger.birthMonth}-${passenger.birthDay}`,
              'YYYY-MM-DD',
            )
            const formattedDate = birthDateUTC.locale(i18n.language).format('D. MMMM YYYY')

            return (
              <FormElementWrapper key={passenger.rowNumber}>
                <EditInput
                  data-cy={`edit-passenger-${passenger.rowNumber}`}
                  onClick={() => navigate(BookingTravelerNavLink({ rowNumber: passenger.rowNumber }).substring(1))}
                  disabled={!isFuture || isPastDeadline({ modifyBookingDeadline: flBooking?.modificationDeadline })}
                  texts={[
                    {
                      key: MayBe.orElse(passenger.firstName || null, 'enKey'),
                      label: translations.firstName,
                      text: MayBe.orElse(passenger.firstName ?? null, translations.firstName),
                    },
                    {
                      key: MayBe.orElse(passenger.lastName || null, 'enKey'),
                      label: translations.lastName,
                      text: MayBe.orElse(passenger.lastName ?? null, translations.lastName),
                    },
                    {
                      label: translations.dateOfBirth,
                      text: formattedDate,
                    },
                    {
                      key: MayBe.orElse(passenger.customerCode || null, 'enKey'),
                      label: passenger.customerCode ? translations.memberNumber : '',
                      text: passenger.customerCode ? passenger.customerCode : undefined,
                    },
                  ]}
                />
              </FormElementWrapper>
            )
          })
        : null}
    </div>
  )
}
