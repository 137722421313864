import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApolloError } from '@apollo/client'

import { GroupedBookingResponse, useGetBookingsQuery } from '../types'
import { useKeycloak } from '../../providers/KeycloakProvider'
import { trackError } from '../../providers/TelemetryProvider'

type UseGetBookingsType = {
  getBookingsError?: ApolloError | undefined
  bookings?: GroupedBookingResponse | undefined
  bookingsLoading: boolean
}

const UseGetBookings: () => UseGetBookingsType = () => {
  const [bookings, setFlCustomer] = useState<UseGetBookingsType>({} as UseGetBookingsType)
  const { isAuthenticated: kc_isAUth } = useKeycloak()
  const isAuthenticated = kc_isAUth
  const { data, loading, error, refetch } = useGetBookingsQuery({
    skip: !isAuthenticated,
  })
  const search = window.location.search
  const params = new URLSearchParams(search)
  const shouldRefetch = params.get('refetch') === 'true'
  const navigate = useNavigate()

  useEffect(() => {
    if (!loading && error !== undefined) {
      trackError(error, {
        'location': 'UseGetBookings',
        'function': 'useGetBookingsQuery',
        'isAuthenticated': isAuthenticated
      })
    }
  }, [loading, error, isAuthenticated])

  useEffect(() => {
    if (shouldRefetch) {
      setTimeout(() => {
        refetch().then(() => {
          navigate('/bookingList', { replace: true })
        })
      }, 10000)
    }
  }, [navigate, refetch, shouldRefetch])

  useEffect(() => {
    setFlCustomer({
      bookings: data?.bookings as GroupedBookingResponse,
      bookingsLoading: loading,
      getBookingsError: error,
    })
  }, [data, error, loading])

  return bookings
}

export default UseGetBookings
