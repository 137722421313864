import { TFunction } from 'i18next'

import { IndexedBookings } from '../../types/myPage/types'

export type JourneysCountAndMessage = { journeysMessage: string; journeyCount: number }

type JourneysCountAndMessageType = (args: {
  bookings: IndexedBookings
  t: TFunction<string>
}) => JourneysCountAndMessage

/**
 * @description - Static function to returns the number of future journeys and a message to display
 * @param bookings
 * @param t
 */
export const getFutureJourneysCountAndMessage: JourneysCountAndMessageType = ({ bookings, t }) => {
  const journeyCount = Object.keys(bookings).length
  if (journeyCount > 0) {
    return {
      journeyCount,
      journeysMessage:
        journeyCount === 1
          ? t('label.booking.upcomingBooking', { count: journeyCount })
          : t('label.booking.upcomingBooking_plural', { count: journeyCount }),
    }
  }
  return { journeyCount: 0, journeysMessage: t('label.booking.noBookings') }
}

/**
 * @description - Static function to returns the number of active journeys and a message to display
 * @param bookings
 * @param t
 */
export const getActiveJourneysCountAndMessage: JourneysCountAndMessageType = ({ bookings, t }) => {
  const journeyCount = Object.keys(bookings).length
  if (journeyCount > 0) {
    return {
      journeyCount,
      journeysMessage:
        journeyCount === 1
          ? t('label.booking.activeBooking', { count: journeyCount })
          : t('label.booking.activeBooking_plural', { count: journeyCount }),
    }
  }
  return { journeyCount: 0, journeysMessage: t('label.booking.noActiveBookings') }
}
