import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormElementWrapper, Label } from '@fjordline/styles-v3'

import { Extra } from '../../../graphql/types'

import { ExtraAshoreLabel } from './extrasAshore/extrasAshore.styles'
import useFetchExtras from '../../../sanity/extras/useFetchExtras'
import useFetchSpecifications from '../../../sanity/specifications/useFetchSpecifications'

export default function ExtrasAshore({ isInbound = false, extras }: { isInbound?: boolean; extras: Extra[] }) {
  const { t } = useTranslation()
  const departure = useMemo(
    () => (isInbound ? t('component.extras.common.arrival') : t('component.extras.common.departure')),
    [isInbound, t],
  )

  if (extras === undefined || extras.length === 0) {
    return null
  }

  return (
    <>
      <FormElementWrapper dataCy="extras-ashore">
        <Label size="large">{departure}</Label>
      </FormElementWrapper>
      {extras.map((extra, index) => {
        const code = extra.code
        const subCode = extra.subCode
        const keyIndex = index
        if (!code) return null

        return <ExtraItem key={keyIndex} code={code} subCode={subCode || undefined} quantity={extra.quantity} />
      })}
    </>
  )
}

export type ExtraItemProps = {
  code: string
  subCode?: string
  quantity: number
}

export const ExtraItem: React.FC<ExtraItemProps> = ({ code, subCode, quantity }: ExtraItemProps) => {
  const { findExtrasName } = useFetchExtras(code)
  const { specByCode } = useFetchSpecifications(subCode)
  const { t, i18n } = useTranslation()

  const name = findExtrasName() ?? code

  return (
    <>
      <p style={{ margin: '0', marginTop: '-0.5rem' }}>{`${name} ${
        findExtrasName() && specByCode ? ' - ' : ''
      }${specByCode ? specByCode?.specificationName?.[i18n.language] : ''}`}</p>
      <ExtraAshoreLabel>{t('component.extras.misc.qty', { count: quantity })}</ExtraAshoreLabel>
    </>
  )
}
