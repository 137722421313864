import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { BackButton } from '@fjordline/styles-v3'
import styled from 'styled-components'

import { paramKeys } from '../routes/navLinkfunctions/types'

type CustomBackButtonArgs = {
  defaultBackLinkTo: string
  disabled?: boolean
  onClick?: React.MouseEventHandler
  title?: string
}

const MoveLeft = styled.div`
  display: flex;
`

const WithMpLoading = styled.div`
  display: flex;

  > div {
    padding-left: 1rem;
  }
`

/**
 * @description - Returns BackButton or a BackButton wrapped in a Link from @fjordline/styles-v3, if either url param linkTo or onClick has value, else null
 * @description - A querystring from param 'backTarget' will always override defaultBackLinkTo
 * @description - A querystring from param 'backTargetTitle' will always override title
 * @description - A link (from 'backTarget' querystring or defaultBackLinkTo) will always override onClick
 * @param defaultBackLinkTo - The target for a Link, if no 'backTarget' querystring is set
 * @param title - default title is 'Back'
 * @param loading
 * @param onClick - Action to perform when clicking the link
 * @param disabled -  if true, renders a disabled back button without Link
 * @constructor
 */
const CustomBackButton = ({ defaultBackLinkTo, title, onClick, disabled = false }: CustomBackButtonArgs) => {
  const { t } = useTranslation()
  const [params, setParams] = useSearchParams()
  const backTarget = params.get(paramKeys.BACK_TARGET)
  const backTargetTitle = params.get(paramKeys.BACK_TARGET_TITLE)
  const [linkTo, setLinkTo] = React.useState<string>(backTarget || defaultBackLinkTo)
  const defaultTitle = useMemo(() => (title ? title : t('label.button.back')), [title, t])
  const [backTitle, setBackTitle] = React.useState<string>(backTargetTitle || title || defaultTitle)

  useEffect(
    function setBackTargetAndClearParams() {
      if (backTarget !== null) {
        setLinkTo(backTarget)
      }
      if (backTargetTitle !== null) {
        setBackTitle(backTargetTitle)
      }
      if (backTarget !== null || backTargetTitle !== null) {
        setParams('')
      }
    },
    [backTarget, backTargetTitle, setParams],
  )

  // If no linkTo or onClick, return null
  if (!linkTo && !onClick) {
    return null
  } else {
    // if disabled, always return a disabled BackButton
    // if linkTo, always return a BackButton wrapped in a Link
    return linkTo && !disabled ? (
      <MoveLeft>
        <Link to={linkTo}>
          <WithMpLoading>
            <BackButton title={backTitle}>{backTitle}</BackButton>
          </WithMpLoading>
        </Link>
      </MoveLeft>
    ) : (
      <MoveLeft>
        <BackButton disabled={disabled} onClick={onClick} title={backTitle}>
          defaultTitle
        </BackButton>
      </MoveLeft>
    )
  }
}

export default CustomBackButton
