import { ToastMessage } from '@fjordline/styles-v3'
import { HubConnection } from '@microsoft/signalr'
import { TFunction } from 'i18next'

import { logDev, logDevError } from '../../../../components/LogDev'
import signalREvents from '../signalREvents'

export enum WebsocketErrorReason {
  ALittleBitKnown = 'ALittleBitKnown',
  Known = 'Known',
  Unknown = 'Unknown',
}

type MpCartError = {
  errorReason: WebsocketErrorReason
  message: string
}

export function unsubscribeErrorEvents(connection: HubConnection): Promise<void> {
  connection.off(signalREvents.flCustomerUpdateFailed)
  connection.off(signalREvents.updateCartFailed)
  connection.off(signalREvents.bookingUpdateFailed)
  connection.off(signalREvents.checkoutCartFailed)

  return Promise.resolve()
}
export default function subscribeToExceptionEvents({
  connection,
  dispatchToast,
  isDev = false,
  t,
}: {
  connection: HubConnection
  dispatchToast: (message: ToastMessage) => void
  isDev?: boolean
  t: TFunction<string>
}): Promise<void> {
  logDev('(#11) ==> subscribe to exception messages')

  const errorEventCallbackFunction = (mpCartError: MpCartError, signalREvent: string) =>
    errorEventCallback({
      dispatchToast,
      isDev,
      mpCartError,
      signalREvent,
      t,
    })

  unsubscribeErrorEvents(connection).then(() => {
    logDev('(#26) - Subscribe to signalR error events')
    connection.on(signalREvents.bookingUpdateFailed, (mpCartError: MpCartError) =>
      errorEventCallbackFunction(mpCartError, signalREvents.bookingUpdateFailed),
    )
    connection.on(signalREvents.updateCartFailed, (mpCartError: MpCartError) =>
      errorEventCallbackFunction(mpCartError, signalREvents.updateCartFailed),
    )
    connection.on(signalREvents.checkoutCartFailed, (mpCartError: MpCartError) =>
      errorEventCallbackFunction(mpCartError, signalREvents.checkoutCartFailed),
    )
    connection.on(signalREvents.flCustomerUpdateFailed, (mpCartError: MpCartError) =>
      errorEventCallbackFunction(mpCartError, signalREvents.flCustomerUpdateFailed),
    )
  })

  return Promise.resolve()
}

function errorEventCallback({
  dispatchToast,
  isDev,
  mpCartError: { errorReason, message },
  signalREvent,
  t,
}: {
  dispatchToast: (message: ToastMessage) => void
  isDev?: boolean
  mpCartError: MpCartError
  signalREvent: string
  t: TFunction<string>
}) {
  logDevError('(#13)signalREventsError', { errorReason, message: `${signalREvent}: ${message}` })
  if (isDev) {
    dispatchToast({
      closeOnActionClick: true,
      message: t(`cartException.${errorReason}`, 'websocket communication exception'),
      timeout: 2000,
    })
  }
  // TODO: Logg to application insights and/or do any other re-initialization of the cart
  switch (signalREvent) {
    case signalREvents.updateCartFailed:
      break
    case signalREvents.flCustomerUpdateFailed:
      break
    case signalREvents.bookingUpdateFailed:
      break
    case signalREvents.checkoutCartFailed:
      break
    default:
      break
  }
}
