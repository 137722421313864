import { CHECKOUT_CART_URL, DEFAULT_FL_VERIFY_TIMEOUT, EASSETS_API_URL, MODIFY_BOOKING, OTP_URL } from '../config'

import createClient from './clientFactory/getClients'
import ModifyBookingActions from './clients/modfiyBooking/ModifyBookingActions'
import ModifyBookingClient from './clients/modifyBookingClient'
import MyPageClient from './clients/myPageClient'
import MyPageActions from './clients/myPageClient/myPageActions'
import OtpClient from './clients/otpClient'
import OtpActions from './clients/otpClient/otpActions'
import TicketClient from './clients/ticketClient'
import TicketActions from './clients/ticketClient/ticketActions'

export const getOtpClient: (accessToken?: string) => OtpActions = (accessToken) => {
  const client = createClient({ baseURL: OTP_URL, accessToken, timeout: DEFAULT_FL_VERIFY_TIMEOUT })

  return OtpClient(client)
}

export const getTicketClient: (accessToken?: string) => TicketActions = (accessToken) => {
  const client = createClient({ baseURL: EASSETS_API_URL, accessToken, timeout: DEFAULT_FL_VERIFY_TIMEOUT })

  return TicketClient(client)
}

export const getMyPageClient: (accessToken?: string) => MyPageActions = (accessToken) => {
  const client = createClient({ baseURL: CHECKOUT_CART_URL, accessToken, timeout: DEFAULT_FL_VERIFY_TIMEOUT })

  return MyPageClient(client)
}

export const getModifyBookingClient: (accessToken?: string) => ModifyBookingActions = (accessToken) => {
  const client = createClient({ baseURL: MODIFY_BOOKING, accessToken, timeout: 1200000 })

  return ModifyBookingClient(client)
}
