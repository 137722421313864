import moment from 'moment-timezone'

export function isPastDeadline({ modifyBookingDeadline }: isPastDeadlineProps) {
  if (!modifyBookingDeadline) {
    return false
  }
  //Substraction 1 minutes to account for delay in the system etc.
  const deadline = moment.tz(modifyBookingDeadline, 'Europe/Oslo')
  const now = moment.tz('Europe/Oslo')
  const isPastDeadline = now.isAfter(deadline)

  return isPastDeadline
}

type isPastDeadlineProps = {
  modifyBookingDeadline?: string
}
