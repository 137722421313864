import { FlCustomer } from '../../../graphql/types'
import { BookingList } from '../../../types/MyPage'
import { IndexedBookings } from '../../../types/myPage/types'
import { mpCart } from '../websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'

export enum MyPageActionTypes {
  SET_BOOKINGS = 'SET_BOOKINGS',
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_FJORD_CLUB_MEMBER_STATUS_NO_PROFILE = 'SET_FJORD_CLUB_MEMBER_STATUS_NO_PROFILE',
  // userIsNotAuthenticated
  SET_USER_NOT_AUTHENTICATED = 'SET_USER_NOT_AUTHENTICATED',
  SET_MP_CARTS = 'SET_MP_CARTS',
}

export type ArgsPageDispatchToast = { message: string }
export type PageDispatchToast = ({ message }: ArgsPageDispatchToast) => void

/**
 * @description - alias for allowed payload in MyPage Provider
 */
export type MyPagePayload = IndexedBookings | FlCustomer | mpCart | BookingList | undefined

export type MyPageOperations = {
  pageDispatchToast: PageDispatchToast
  setCustomer: (customer: FlCustomer) => void
  setBookingList: (bookingList: BookingList) => void
  setCustomerWithCallback: (customer: FlCustomer) => void
  setFjordClubMemberStatusNoProfile: () => void
  setUserIsNotAuthenticated: () => void
  setChangesNotSaved: (changesNotSaved: boolean) => void
  setShowChangesNotSavedModal: (showChangesNotSavedModal: boolean) => void
  changesNotSaved: boolean
  showChangesNotSavedModal: boolean
}
