import React, { createContext, useContext, useLayoutEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import UseGetBookingDetails, { BookingDetails } from '../graphql/customerHooks/UseGetBookingDetails'

import { ContextChildren } from './genericTypes'
import { extractBookingNumber } from './WebsocketProvider'

export const bookingDetailsContext = createContext<BookingDetails>({ flBookingLoading: false })
bookingDetailsContext.displayName = 'Booking Details'
export const useBookingDetails = () => useContext<BookingDetails>(bookingDetailsContext)

const BookingDetailsProvider: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking, flBookingLoading, getFlBookingError } = UseGetBookingDetails({
    bookingCode: bookingCode || '',
  })

  useLayoutEffect(() => window.scrollTo(0, 0), [])

  const value = useMemo(
    () => ({
      flBooking,
      flBookingLoading,
      getFlBookingError,
    }),
    [flBooking, flBookingLoading, getFlBookingError],
  )

  return <bookingDetailsContext.Provider value={value}>{children}</bookingDetailsContext.Provider>
}

export default BookingDetailsProvider
