import { createContext, useContext } from 'react'

import { AddExtrasModal } from './types'

export const addExtrasModalContext = createContext<AddExtrasModal>({} as AddExtrasModal)
addExtrasModalContext.displayName = 'AddExtrasModalContext'
/**
 * @description - expose the showAddExtrasModal function from AddExtrasModalProvider
 */
export const useAddExtrasModal = () => useContext<AddExtrasModal>(addExtrasModalContext)
