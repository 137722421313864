import React, { useEffect, useState } from 'react'

import { logDev } from '../../../components/LogDev'
import UseGetBookings from '../../../graphql/customerHooks/UseGetBookings'
import { BookingList } from '../../../types/MyPage'
import { ContextChildren } from '../../genericTypes'
import { useMyPageOperations } from '../context'

import getIndexedBookingLists from './customerAndBookingListLoader/getIndexedBookingLists'
import { FlCustomer, useCheckIfActiveMemberQuery, useGetCustomerQuery } from '../../../graphql'
import { useKeycloak } from '../../KeycloakProvider'
import { trackError } from '../../TelemetryProvider'

/**
 * @description - This component loads customer and bookings for customer from the GraphQL server.
 * @description - FlCustomer
 * @description - BookingList
 * @param children - React.ReactNode
 * @constructor
 */
const CustomerAndBookingListLoader: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const { isAuthenticated: kc_isAuth, isLoading: kc_isLoading, token: kc_token } = useKeycloak()
  const isLoading = kc_isLoading
  const isAuthenticated = kc_isAuth
  const { data: isActiveCustomer } = useCheckIfActiveMemberQuery({
    skip: !isAuthenticated || isLoading,
  })

  const [token, setToken] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (kc_isAuth && kc_token) {
      setToken(kc_token)
    }
  }, [kc_isAuth, kc_token])

  const {
    data,
    loading: flCustomerLoading,
    error: getFlCustomerError,
  } = useGetCustomerQuery({
    skip: !isAuthenticated || isLoading || !token || !isActiveCustomer?.isActiveFjordClubMember,
  })
  const { bookings, bookingsLoading, getBookingsError } = UseGetBookings()
  const { setCustomer, setBookingList, setFjordClubMemberStatusNoProfile } = useMyPageOperations()

  useEffect(
    function getCustomerAndSetNoProfileIfNoProfile() {
      if (isLoading || !isAuthenticated) return
      if (!flCustomerLoading && getFlCustomerError) {
        logDev('(#19) ==> error in useGetFlCustomer', getFlCustomerError)
        trackError(getFlCustomerError, {
          'location': 'CustomerAndBookingListLoader',
          'function': 'getCustomerAndSetNoProfileIfNoProfile',
          'isAuthenticated': isAuthenticated
        })

        setFjordClubMemberStatusNoProfile()
      } else {
        if (
          flCustomerLoading !== undefined &&
          !flCustomerLoading &&
          !getFlCustomerError &&
          data?.customer !== undefined
        ) {
          setCustomer(data?.customer as FlCustomer)
        } else if (flCustomerLoading !== undefined && !flCustomerLoading) {
          setFjordClubMemberStatusNoProfile()
        }
      }
    },
    [
      data?.customer,
      flCustomerLoading,
      getFlCustomerError,
      isAuthenticated,
      isLoading,
      setCustomer,
      setFjordClubMemberStatusNoProfile,
    ],
  )

  useEffect(
    function loadCustomerBookings() {
      if (!bookingsLoading && getBookingsError) {
        logDev('(#19.1) ==> error in loadCustomerBookings', getBookingsError)
        trackError(getBookingsError, {
          'location': 'CustomerAndBookingListLoader',
          'function': 'loadCustomerBookings',
          'isAuthenticated': isAuthenticated
        })
      } else {
        if (bookingsLoading !== undefined && !bookingsLoading && !getBookingsError && bookings !== undefined) {
          const indexedBookingLists: BookingList = getIndexedBookingLists({ bookings })
          setBookingList(indexedBookingLists)
        }
      }
    },
    [bookings, bookingsLoading, getBookingsError, setBookingList, isAuthenticated],
  )

  return <div>{children}</div>
}

export default CustomerAndBookingListLoader
