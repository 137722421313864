import { ClientConfig, createClient } from '@sanity/client'
import { SANITY_DATASET, SANITY_PROJECT_ID, SANITY_USECDN } from '../config'

export const sanityConfig: ClientConfig = {
  apiVersion: new Date().toISOString().split('T')[0],
  dataset: SANITY_DATASET,
  useCdn: process.env.NODE_ENV === 'development' ? true : SANITY_USECDN,
  projectId: SANITY_PROJECT_ID,
}
export const sanityClient = createClient(sanityConfig)
