/* eslint-disable complexity */
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import UseGetinboundMeals from '../../../../../graphql/availabilityHooks/meals/UseGetInboundMeals'
import UseGetOutboundMeals from '../../../../../graphql/availabilityHooks/meals/UseGetOutboundMeals'
import UseGetBookingDetails from '../../../../../graphql/customerHooks/UseGetBookingDetails'
import { MealsDetailsFragment, Specification } from '../../../../../graphql/types'
import {
  useAvailabilityItems,
  useAvailabilityItemsOperations,
} from '../../../../../providers/AvailabilityItemsProvider'
import { useMyPage } from '../../../../../providers/myPageStateProvider/context'
//This entire file is abit(very) bloated. Can probably be done way easier if you have time to refactor it.
//This component handles when you check a meal on the accordion check mark. It automaticly add the max amount of passengers to the meal, and remove all meals if unchecked.
export const normalSubCode = ['ADL', 'CHD', 'INF', 'BABY']

type Props = {
  isOutbound: boolean
}

function useOnChangeAccordion({ isOutbound }: Props) {
  const { addMealsToCart } = useAvailabilityItemsOperations()
  const { customer } = useMyPage()
  const { bookingCode } = useParams()
  const { addToCartState } = useAvailabilityItems()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const mealsToAddOutbound = addToCartState?.[bookingCode || '']?.['outbound']?.meals
  const mealsToAddInbound = addToCartState?.[bookingCode || '']?.['inbound']?.meals
  const adultPassengerCount = flBooking?.outbound?.passengers?.filter((e) => e.fareCode === 'ADULT').length || 1
  const childPassengerCount = flBooking?.outbound?.passengers?.filter((e) => e.fareCode === 'CHILD').length || 0
  const infantPassengerCount = flBooking?.outbound?.passengers?.filter((e) => e.fareCode === 'INFANT').length || 0
  const babyPassengerCount = flBooking?.outbound?.passengers?.filter((e) => e.fareCode === 'BABY').length || 0
  const { data: outboundMealsData } = UseGetOutboundMeals()
  const { data: inboundMealsData } = UseGetinboundMeals()
  const journey = isOutbound ? mealsToAddOutbound : mealsToAddInbound
  const mealsData = isOutbound ? outboundMealsData : inboundMealsData
  const mealsGraphData = isOutbound ? outboundMealsData : inboundMealsData
  const onChangeAccordion = useCallback(
    (itemName) => {
      const mealItem = isOutbound
        ? mealsToAddOutbound?.find((e) => `${new Date(e.startTime)} itemCode: ${e.code}` === itemName)
        : mealsToAddInbound?.find((e) => `${new Date(e.startTime)} itemCode: ${e.code}` === itemName)

      if (!mealItem) {
        const mealItemToAdd = itemName.split('itemCode:')
        const startTime: Date = new Date(mealItemToAdd[0])
        const mealName: string = mealItemToAdd[1]?.trim()

        const newMeal = mealsData?.find((meal) => {
          return meal.code === mealName
        })

        const findMeals = isOutbound ? mealsToAddOutbound : mealsToAddInbound

        const meals = findMeals?.map((e) => {
          if (mealsGraphData) {
            return {
              ...e,
              category: mealsGraphData.find((meal) => meal.code === e.code)?.itemType,
            }
          }
        })

        if (newMeal) {
          const currency = newMeal.specifications?.find((e) => e?.specificationCode === e.specificationCode)
            ?.specificationPrice?.currency
          const value = newMeal.specifications?.find((e) => e?.specificationCode === e.specificationCode)
            ?.specificationPrice.value
          const differentMealTimeExist =
            meals?.find((e) => new Date(e?.startTime as Date).getDay() === new Date(startTime).getDay()) !== undefined

          //Since you only can one meal of same category on the same day (Ex. you can not choose to eat at 17:30 and 19.30 dinner on the same day, we remove the meal in the same category, then attempt to add all available meals for selected passengers (typ 2x adults, 2x childs))
          if (differentMealTimeExist) {
            meals.map((e) => {
              if (e && e.category === newMeal.itemType) {
                const adlSpec = newMeal.specifications?.find((e) => e?.specificationCode === 'ADL') as Specification
                const childSpec = newMeal.specifications?.find((e) => e?.specificationCode === 'CHD') as Specification
                const infantSpec = newMeal.specifications?.find((e) => e?.specificationCode === 'INF') as Specification
                const babySpec = newMeal.specifications?.find((e) => e?.specificationCode === 'BABY') as Specification

                if (adultPassengerCount > 0) {
                  addMealsToCart(e as MealsDetailsFragment, 0, adlSpec, bookingCode || '', isOutbound)
                }
                if (childPassengerCount > 0) {
                  addMealsToCart(e as MealsDetailsFragment, 0, childSpec, bookingCode || '', isOutbound)
                }
                if (infantPassengerCount > 0) {
                  addMealsToCart(e as MealsDetailsFragment, 0, infantSpec, bookingCode || '', isOutbound)
                }
                if (babyPassengerCount > 0) {
                  addMealsToCart(e as MealsDetailsFragment, 0, babySpec, bookingCode || '', isOutbound)
                }
              }
            })
          }

          if (currency && value && adultPassengerCount > 0) {
            const spec = newMeal.specifications?.find((e) => e?.specificationCode === 'ADL')
            if (spec) {
              addMealsToCart(newMeal as MealsDetailsFragment, adultPassengerCount, spec, bookingCode || '', isOutbound)
            }

            //Adds child meals
            if (
              childPassengerCount > 0 &&
              newMeal.quantityAvailable >=
                adultPassengerCount + childPassengerCount + infantPassengerCount + babyPassengerCount
            ) {
              const spec = newMeal.specifications?.find((e) => e?.specificationCode === 'CHD')
              if (spec) {
                addMealsToCart(
                  newMeal as MealsDetailsFragment,
                  childPassengerCount,
                  spec,
                  bookingCode || '',
                  isOutbound,
                )
              }
            }
            //Adds infant meals
            if (
              infantPassengerCount > 0 &&
              newMeal.quantityAvailable >=
                adultPassengerCount + infantPassengerCount + childPassengerCount + babyPassengerCount
            ) {
              const spec = newMeal.specifications?.find((e) => e?.specificationCode === 'INF')
              if (spec) {
                addMealsToCart(
                  newMeal as MealsDetailsFragment,
                  infantPassengerCount,
                  spec,
                  bookingCode || '',
                  isOutbound,
                )
              }
            }
            //Adds baby meals
            if (
              babyPassengerCount > 0 &&
              newMeal.quantityAvailable >=
                adultPassengerCount + babyPassengerCount + infantPassengerCount + childPassengerCount
            ) {
              const spec = newMeal.specifications?.find((e) => e?.specificationCode === 'BABY')
              if (spec) {
                addMealsToCart(newMeal as MealsDetailsFragment, babyPassengerCount, spec, bookingCode || '', isOutbound)
              }
            }
          }

          //Meals with specifications that are not adults, children, infants or babies
          if (newMeal.specifications && !newMeal.specifications.some((e) => e.specificationCode === ('ADL' || 'CLU'))) {
            const lengthOfSpec = newMeal.specifications?.length
            let spec = newMeal.specifications?.[lengthOfSpec - 1] ?? 0

            if (newMeal.specifications.find((e) => e.specificationCode === 'ADL')) {
              spec = newMeal.specifications.find((e) => e.specificationCode === 'ADL') as Specification
            }
            if (newMeal.specifications.find((e) => e.specificationCode === 'CLU' && customer?.active === true)) {
              spec = newMeal.specifications.find((e) => e.specificationCode === 'CLU') as Specification
            }
            if (spec) {
              addMealsToCart(newMeal as MealsDetailsFragment, adultPassengerCount, spec, bookingCode || '', isOutbound)
            }
          }
        }
      }
      //When a user clicks checkmark to remove a meal -> Check if meal is an attachment, if not, check if mealItem exist, and if it it a "normal meal" (ADL, CHD etc)
      if (mealItem && normalSubCode.includes(mealItem.subCode)) {
        const findMealInSession = journey?.filter((e) => e.legCode === mealItem.legCode && e.code === mealItem.code)
        findMealInSession?.map((e) => {
          const spec = mealsGraphData
            .find((meal) => meal.code === e.code)
            ?.specifications?.find((spec) => spec.specificationCode === e.subCode) as Specification
          return addMealsToCart(e as MealsDetailsFragment, 0, spec, bookingCode || '', isOutbound)
        })
      }
      //When a user clicks checkmark to remove a meal special subCodes (Ex. meals that subCodes are 3COR, 4COR instead of ADL etc.)
      if (mealItem && !normalSubCode.includes(mealItem.subCode)) {
        const findMealInSession = journey?.filter((e) => e.legCode === mealItem.legCode && e.code === mealItem.code)
        findMealInSession?.map((e) => {
          const spec = mealsGraphData
            .find((meal) => meal.code === e.code)
            ?.specifications?.find((spec) => spec.specificationCode === e.subCode) as Specification
          if (spec) {
            addMealsToCart(e as MealsDetailsFragment, 0, spec, bookingCode || '', isOutbound)
          }
        })
      }
    },
    [
      addMealsToCart,
      adultPassengerCount,
      babyPassengerCount,
      bookingCode,
      childPassengerCount,
      customer?.active,
      infantPassengerCount,
      isOutbound,
      journey,
      mealsData,
      mealsGraphData,
      mealsToAddInbound,
      mealsToAddOutbound,
    ],
  )
  return { onChangeAccordion }
}

export default useOnChangeAccordion
