import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { logDev } from '../../components/LogDev'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { getMyPageClient } from '../../rest/clients'
import { trackEvent } from '../../providers/TelemetryProvider'
import { useKeycloak } from '../../providers/KeycloakProvider'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { CartByBookingCode, CartsByBookingCodes } from '../../providers/availabilityItemsProvider/types'

function useCartCheckout() {
  const { bookingCode } = useParams()
  const { cartData, setCartData } = useWebSocketContext()

  const { isAuthenticated: kc_isAuth, token } = useKeycloak()
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [responseByBookingCode, setResponseByBookingCode] = useState<CartByBookingCode | undefined>(undefined)
  const { setCheckoutLoading } = useAvailabilityItemsOperations()
  const { addToCartState } = useAvailabilityItems()
  const { setAddToCartState } = useAvailabilityItemsOperations()

  function checkoutCart() {
    setCheckoutLoading(true)
    trackEvent('user clicked checkout cart')
    if (kc_isAuth) {
      getMyPageClient(token)
        ?.checkoutCart({ bookingCode: bookingCode || '', id: cartData?.id })
        .then((res) => {
          const response = res?.bookingCarts
          const responseByBookingCode = response[bookingCode || '']
          if (bookingCode && responseByBookingCode && responseByBookingCode.bookingResult?.paymentConfiguration) {
            setResponseByBookingCode(responseByBookingCode)
            const updatedState: CartsByBookingCodes = {
              ...addToCartState,
              [bookingCode]: {
                ...responseByBookingCode,
              },
            }
            sessionStorage.setItem(
              'cartData',
              JSON.stringify({
                ...cartData,
                bookingCarts: response,
              }),
            )

            if (setCartData) {
              setCartData({
                ...cartData,
                bookingCarts: response,
              })
            }

            sessionStorage.setItem('AddToCartState', JSON.stringify(updatedState))
            setAddToCartState(updatedState)
          }

          trackEvent('user successfully checked out cart')
        })
        .catch((err: AxiosError) => {
          logDev('checkoutCart', err)
          setError(err)
          trackEvent('user failed to checkout cart')
        })
        .finally(() => setCheckoutLoading(false))
    }
  }
  return {
    checkoutCart,
    checkoutError: error,
    responseByBookingCode,
  }
}

export default useCartCheckout
