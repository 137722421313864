import { Colors, rem } from '@fjordline/styles-v3'
import styled from 'styled-components'

export const BasketInfoWrapper = styled.div`
  margin: -22% 10% 0%;
  width: 80%;
  background: ${Colors.PROFILE_RED_2024};
  color: white;
  padding: 1rem 1rem 0rem;
  z-index: 10;
  position: relative;
  transition: all 0.25s linear;

  h5 {
    color: inherit;
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  p {
    color: inherit;
    margin: 0;
    font-size: 0.8rem;
  }
`

export const BookingDetailsBasketInfoWrapper = styled(BasketInfoWrapper)`
  width: 62%;
  margin: -18% 36% -5%;
  transition: all 0.25s linear;

  @media (max-width: 768px) {
    margin: -22% 10% 0%;
    width: 80%;
    transition: all 0.25s linear;
  }
`

export const ShoppingCartWrapper = styled.div`
  color: inherit;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(18)};
  > svg {
    stroke: white;
    fill: white;
    margin-right: ${rem(14)};
  }
  h6 {
    color: inherit;
    margin: 0;
  }
`
