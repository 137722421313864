import { VerifiedMobile } from '../pages/fjordClub/editMemberShip/types'
import { defaultMyPage, MyPage } from '../types/MyPage'

import storage from './baseStorage'

const PREFIX = 'https://fjordline.com/mypage/state/'

export const storageKeys = {
  CURRENT_PATH: 'CURRENT_PATH',
  signupStateKey: 'SIGNUP_STATE',
  signupVerifiedFieldsKey: 'VERIFIED_FIELDS',
  myPageAppStateKey: 'MY_PAGE_APP_STATE',
}

export type StorageKeyType = keyof typeof storageKeys

const getItem = <T>(key: string) => storage.getItem<T>(PREFIX + key)
const setItem = <T>(key: string, value: T) => storage.setItem<T>(PREFIX + key, value)
const removeItem = (key: string) => storage.removeItem(PREFIX + key)

const getConstrainedItem = <T>(key: StorageKeyType) => storage.getItem<T>(PREFIX + key)
const setConstrainedItem = <T>(key: StorageKeyType, value: T) => storage.setItem<T>(PREFIX + key, value)

const clearCurrentPath = () => removeItem(storageKeys.CURRENT_PATH)
const getCurrentPath = () => {
  const path = getItem<string | null>(storageKeys.CURRENT_PATH)
  return path !== null ? path : undefined
}
const setCurrentPath = (path: string) => setItem(storageKeys.CURRENT_PATH, path)

const getMyPageAppState = () => getItem<MyPage>(storageKeys.myPageAppStateKey) || defaultMyPage
const setMyPageAppState = (myPageAppState: MyPage) => setItem(storageKeys.myPageAppStateKey, myPageAppState)
const clearMyPageAppState = () => removeItem(storageKeys.myPageAppStateKey)

const getCurrentVerifiedFields: () => VerifiedMobile = () => {
  const valueFromStorage = storage.getItem<VerifiedMobile>(storageKeys.signupVerifiedFieldsKey)
  const currentVerifiedFields = valueFromStorage && Object.keys(valueFromStorage).length ? valueFromStorage : {}
  return validateVerifiedTimeStamps({ verifiedFields: currentVerifiedFields })
}

const verifiedMobileListSet: (verifiedMobile: VerifiedMobile) => void = (verifiedMobileList: VerifiedMobile) => {
  storage.setItem(storageKeys.signupVerifiedFieldsKey, verifiedMobileList)
}

const addVerifiedMobile: (verifiedMobileNumber: string) => void = (verifiedMobileNumber: string) => {
  const valueFromStorage = storage.getItem<VerifiedMobile>(storageKeys.signupVerifiedFieldsKey)
  const updatedList = {
    ...valueFromStorage,
    [verifiedMobileNumber]: {
      timeStamp: new Date().toUTCString(),
    },
  }
  storage.setItem(storageKeys.signupVerifiedFieldsKey, updatedList)
}

const validateVerifiedTimeStamps: ({ verifiedFields }: { verifiedFields: VerifiedMobile }) => VerifiedMobile = ({
  verifiedFields,
}) => {
  const now = new Date().getTime()
  return Object.keys(verifiedFields).reduce((acc: VerifiedMobile, key: string) => {
    if (now - new Date(verifiedFields[key].timeStamp).getTime() < 600000) {
      acc = {
        ...acc,
        [key]: verifiedFields[key],
      }
    }
    return acc
  }, {})
}

export const mobileList = {
  add: addVerifiedMobile,
  getVerifiedList: getCurrentVerifiedFields,
  setVerifiedField: verifiedMobileListSet,
  validateVerifiedTimeStamps,
}

export const currentPath = {
  get: getCurrentPath,
  set: setCurrentPath,
  clear: clearCurrentPath,
}

export const myPageAppState = {
  get: getMyPageAppState,
  set: setMyPageAppState,
  clear: clearMyPageAppState,
}

export const storageOperations = {
  getItem,
  setItem,
  removeItem,
}
export const storageConstrainedOperations = {
  getConstrainedItem,
  setConstrainedItem,
}

const stateStorage = {
  // ...storage,
  currentPath,
  myPageAppState,
}

export default stateStorage
