// export enum currency {
//   DKK = 'DKK',
//   EUR = 'EUR',
//   NOK = 'NOK',
// }

export enum Status {
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
  CANCELLED = 'CANCELLED',
  PASSED = 'PASSED',
  DEFAULT = 'DEFAULT',
}
