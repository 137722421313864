import { compareAsc } from 'date-fns'

import { FlBooking, GroupedBookingResponse } from '../../../../graphql/types'
import { MayBe } from '../../../../types/MayBe'
import { BookingList, BookingListWithKeys, defaultBookingListWithKeys } from '../../../../types/MyPage'
import { IndexedBookings } from '../../../../types/myPage/types'

/**
 *
 * @param bookings - a response from graphQl
 * @returns Returns a list key/value lists of bookings including a sorted list of booking codes
 * @abstract Key/valulists are by default(not changable) sorted alphabetically by key.
 * @abstract The aditional sortedBookingCodes list is sorted by departure date
 */
export default function getIndexedBookingLists({ bookings }: { bookings: GroupedBookingResponse }): BookingList {
  const { cancelled, future, ongoing, passed } = bookings

  return {
    cancelled: getIndexedBookingList(cancelled),
    future: getIndexedBookingList(future),
    ongoing: getIndexedBookingList(ongoing),
    passed: getIndexedBookingList(passed),
  }
}

/**
 *
 * @param bookings - a list of bookings from graphQl
 * @returns the list sorted by departure date (or created date if no departure date is available)
 */
function sortBookingByDepartureDateOrCreated({
  bookings,
  useCreatedDate = false,
}: {
  bookings: FlBooking[]
  useCreatedDate?: boolean
}): FlBooking[] {
  return bookings.sort((a: FlBooking, b: FlBooking) => {
    const outBoundDepartureDate = a.outbound !== null && a.outbound !== undefined ? a.outbound.departureDate : a.created
    const departureDate = useCreatedDate ? new Date(a.created) : new Date(outBoundDepartureDate)
    const inBoundDepartureDate = b.outbound !== null && b.outbound !== undefined ? b.outbound.arrivalDate : b.created
    const arrivalDate = useCreatedDate ? new Date(b.created) : new Date(inBoundDepartureDate)
    return compareAsc(departureDate, arrivalDate)
  })
}

export function getIndexedBookingList(bookings: FlBooking[]): BookingListWithKeys {
  if (bookings.length === 0) {
    return defaultBookingListWithKeys
  }

  const bookingList = sortBookingByDepartureDateOrCreated({ bookings: [...bookings] })
  // bookingList is now sorted by departure date

  // extract an array of the booking codes sorted correctly
  const sortedBookingCodes = bookingList.map((booking) => MayBe.orElse(booking.bookingCode ?? null, ''))
  const indexedBookings = bookingList.reduce((acc: IndexedBookings, booking: FlBooking) => {
    const bookingCode = MayBe.orElse(booking.bookingCode, undefined)
    if (bookingCode !== undefined) {
      return Object.assign(acc, { [bookingCode]: booking })
    }
    return acc
  }, {} as IndexedBookings)
  // indexedBookings is no longer sorted by departure date, but alpabetically by the key(bookingCode),
  // thus the need for sortedBookingCodes
  return { indexedBookings, sortedBookingCodes }
}
