import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Column, Divider, FormElementWrapper, H1, H2, Row, ShoppingCart, Spinner } from '@fjordline/styles-v3'

import CustomBackButton from '../../../components/CustomBackbutton'
import { ButtonFull } from '../../../components/globalStyles'
import UseGetInboundMeals from '../../../graphql/availabilityHooks/meals/UseGetInboundMeals'
import UseGetOutboundMeals from '../../../graphql/availabilityHooks/meals/UseGetOutboundMeals'
import { BookingNavLink } from '../../../routes/navLinkFunctions'
import AddExtrasModalButton from '../AddExtrasModalButton'

import Meals from './meals/Meals'
import { AvailabilityMealItemWithSpec } from '../../../graphql'
import { useTranslation } from 'react-i18next'

const AddMeals: React.FC = () => {
  useMemo(() => window.scrollTo(0, 0), [])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { bookingCode } = useParams()
  const { data: outboundMealsData, loading: outboundLoading } = UseGetOutboundMeals()
  const { data: inboundMealsData, loading: inboundLoading } = UseGetInboundMeals()
  const loading = outboundLoading || inboundLoading
  function sortMealsByStartDay(meals) {
    const sortedMeals = {}
    meals?.forEach((meal) => {
      const startDay = new Date(meal.startTime).toISOString().split('T')[0]
      if (!sortedMeals[startDay]) {
        sortedMeals[startDay] = []
      }
      sortedMeals[startDay].push(meal)
    })
    return sortedMeals
  }

  function sortMealsByDate(meals: Record<string, MealItem[]>): Record<string, MealItem[]> {
    const sortedKeys = Object.keys(meals).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    const sortedMeals: Record<string, MealItem[]> = {}
    sortedKeys.forEach((key) => {
      sortedMeals[key] = meals[key]
    })

    return sortedMeals
  }

  const sortedMealsOutbound = sortMealsByDate(sortMealsByStartDay(outboundMealsData))
  const sortedMealsInbound = inboundMealsData ? sortMealsByDate(sortMealsByStartDay(inboundMealsData)) : undefined

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>
      <H1 style={{ marginTop: '1rem', marginBottom: '1rem' }}>{t('component.extras.meal.title')}</H1>

      {loading ? (
        <div>
          {t('label.button.loading')}
          <Spinner />
        </div>
      ) : null}

      {outboundMealsData && sortedMealsOutbound && outboundMealsData.length > 0 ? (
        <H2>{t('component.general.departure')}</H2>
      ) : null}

      {outboundMealsData
        ? Object.entries(sortedMealsOutbound).map((meals) => {
            return (
              <>
                <Meals
                  key={meals[0]}
                  data={meals[1] as AvailabilityMealItemWithSpec[]}
                  isOutbound={true}
                  startDay={meals[0] as string}
                />
                <Divider style={{ marginBottom: '1rem', marginTop: '1rem' }} />
              </>
            )
          })
        : null}
      {outboundMealsData && outboundMealsData.length > 0 && inboundMealsData && inboundMealsData.length > 0 ? (
        <Divider style={{ marginBottom: '1rem' }} />
      ) : null}
      {inboundMealsData && sortedMealsInbound && inboundMealsData.length > 0 ? (
        <H2>{t('component.general.return')}</H2>
      ) : null}

      {inboundMealsData && sortedMealsInbound
        ? Object.entries(sortedMealsInbound)

            .map((meals) => {
              return (
                <>
                  <Meals
                    key={meals[0]}
                    data={meals[1] as AvailabilityMealItemWithSpec[]}
                    isOutbound={false}
                    startDay={meals[0] as string}
                  />
                  <Divider style={{ marginBottom: '1rem' }} />
                </>
              )
            })
        : null}

      <Row style={{ marginTop: '2rem' }}>
        <Column large={6} medium={6} small={12}>
          <FormElementWrapper>
            <ButtonFull>
              <Button
                leadingIcon={ShoppingCart}
                dataCy="btn-go-to-basket"
                onClick={() => navigate(`/bookingList/${bookingCode}/basket`)}
                size="large"
              >
                {t('label.button.gotoBasket')}{' '}
              </Button>
            </ButtonFull>
          </FormElementWrapper>
        </Column>
        <Column large={6} medium={6} small={12}>
          <AddExtrasModalButton
            largeSizedButton
            bookingCode={bookingCode || ''}
            backTargetTitle=""
            isActiveOrFuture={true}
          />
        </Column>
      </Row>
    </div>
  )
}

export default AddMeals

interface Specification {
  __typename: string
  specificationCode: string
  minimumQuantityToBook: number
  maximumQuantityToBook: number
  specificationPrice: {
    __typename: string
    currency: string
    value: number
    available: boolean
  }
}

interface MealItem {
  __typename: string
  code: string
  itemType: string
  startTime: string
  itemName: string
  legCode: string
  quantityAvailable: number
  specifications: Specification[]
}
