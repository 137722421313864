import { FlCustomer } from '../../graphql/types'
import { BookingList, defaultMyPage, FjordClubMemberStatus, MyPage } from '../../types/MyPage'
import { GenericAction } from '../genericTypes'

import { MyPageActionTypes, MyPagePayload } from './myPageReducer/types'
import { mpCart } from './websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'

type MyPageReducerType = (state: MyPage, action: GenericAction<MyPagePayload, MyPageActionTypes>) => MyPage

const MyPagerReducer: MyPageReducerType = (state: MyPage = defaultMyPage, { type, payload }) => {
  switch (type) {
    case MyPageActionTypes.SET_BOOKINGS:
      return {
        ...state,
        bookingList: payload as BookingList,
      }
    case MyPageActionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: payload as FlCustomer,
        fjordClubMemberStatus: getFjordClubMemberStatus(payload as FlCustomer),
      }
    case MyPageActionTypes.SET_FJORD_CLUB_MEMBER_STATUS_NO_PROFILE:
      return {
        ...state,
        fjordClubMemberStatus: FjordClubMemberStatus.userHasNoProfile,
      }
    case MyPageActionTypes.SET_USER_NOT_AUTHENTICATED:
      return {
        ...state,
        fjordClubMemberStatus: FjordClubMemberStatus.userIsNotAuthenticated,
      }
    case MyPageActionTypes.SET_MP_CARTS:
      return {
        ...state,
        mpCarts: payload as mpCart,
      }
    default:
      return state
  }
}

export default MyPagerReducer

function getFjordClubMemberStatus(customer: FlCustomer): FjordClubMemberStatus {
  const clubMemberships = customer?.clubMemberships?.find((m) => m?.active)
  if (clubMemberships) {
    return FjordClubMemberStatus.userIsFjordClubMember
  } else {
    // return FjordClubMemberStatus.userHasPartialProfileIsNotMember
    return FjordClubMemberStatus.userIsFjordClubMember
  }
}
