import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { BedDouble, ChairComfort, Food, MenuDialog, MenuDialogItem, Spinner, Ticket } from '@fjordline/styles-v3'

import UseGetInboundCabins from '../../../graphql/availabilityHooks/cabins/UseGetInboundCabins'
import UseGetOutboundCabins from '../../../graphql/availabilityHooks/cabins/UseGetOutboundCabins'
import UseGetInboundExtras from '../../../graphql/availabilityHooks/extras/UseGetInboundExtras'
import UseGetOutboundExtras from '../../../graphql/availabilityHooks/extras/UseGetOutboundExtras'
import UseGetinboundMeals from '../../../graphql/availabilityHooks/meals/UseGetInboundMeals'
import UseGetOutboundMeals from '../../../graphql/availabilityHooks/meals/UseGetOutboundMeals'
import UseRouteExpect from '../../../graphql/availabilityHooks/route/UseRouteExpect'
import UseGetBookingDetails from '../../../graphql/customerHooks/UseGetBookingDetails'
import {
  AddExtrasAshoreNavLink,
  AddExtrasOnboardNavLink,
  AddMealsNavLink,
  CabinsNavLink,
} from '../../../routes/navLinkFunctions'

export type SelectExtrasModalPopupType = {
  bookingCode: string
  onItemClick: (redirectUrl: string) => void
  onRequestClose: () => void
  backTargetTitle?: string
}

const SelectExtrasModalPopup: React.FC<SelectExtrasModalPopupType> = ({
  bookingCode,
  onItemClick,
  onRequestClose,
  backTargetTitle,
}: SelectExtrasModalPopupType) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { flBooking, flBookingLoading } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { data: outboundMeals, loading: outboundMealsLoading } = UseGetOutboundMeals(bookingCode)
  const { data: inboundMeals, loading: inboundMealsLoading } = UseGetinboundMeals(bookingCode)
  const {
    outboundExtrasAshore,
    outboundExtrasOnboard,
    loading: outboundExtrasLoading,
  } = UseGetOutboundExtras(bookingCode)
  const { inboundExtrasAshore, inboundExtrasOnboard, loading: inboundExtrasLoading } = UseGetInboundExtras(bookingCode)
  const { expectCabins, expectExtras, expectMeals, routeExpectLoading } = UseRouteExpect({
    originPort: flBooking?.outbound?.departurePortInfo?.portCode || '',
    destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode || '',
  })

  const hasOutboundExtrasAshore = outboundExtrasAshore && outboundExtrasAshore.length > 0
  const hasInboundExtrasAshore = inboundExtrasAshore && inboundExtrasAshore.length > 0
  const hasOutboundExtrasOnboard = outboundExtrasOnboard && outboundExtrasOnboard.length > 0
  const hasInboundExtrasOnboard = inboundExtrasOnboard && inboundExtrasOnboard.length > 0

  const hasExtrasAshore = hasOutboundExtrasAshore || hasInboundExtrasAshore ? true : false
  const hasExtrasOnboard = hasOutboundExtrasOnboard || hasInboundExtrasOnboard ? true : false

  const hasOutboundMeals = outboundMeals && outboundMeals.length > 0
  const hasInboundMeals = inboundMeals && inboundMeals.length > 0
  const hasMeals = hasOutboundMeals || hasInboundMeals ? true : false

  //CABINS
  const { data: outboundCabinsAvailable, loading: outboundCabinsLoading } = UseGetOutboundCabins(bookingCode)
  const { data: inboundCabinsAvailable, loading: inboundCabinsLoading } = UseGetInboundCabins(bookingCode)
  const hasOutboundCabinsAvailable =
    outboundCabinsAvailable?.cabinAvailability.availableCabins &&
    outboundCabinsAvailable?.cabinAvailability.availableCabins.length > 0
      ? true
      : false

  const hasOutboundSeatsAvailable =
    outboundCabinsAvailable?.cabinAvailability.availableSeats &&
    outboundCabinsAvailable?.cabinAvailability.availableSeats.length > 0
      ? true
      : false
  const hasInboundSeatsAvailable =
    inboundCabinsAvailable?.cabinAvailability.availableSeats &&
    inboundCabinsAvailable?.cabinAvailability.availableSeats.length > 0
      ? true
      : false
  const hasInboundCabinsAvailable =
    inboundCabinsAvailable?.cabinAvailability.availableCabins &&
    inboundCabinsAvailable?.cabinAvailability.availableCabins.length > 0
      ? true
      : false

  const hasNoCabins = !hasOutboundCabinsAvailable && !hasInboundCabinsAvailable
  const hasNoSeats = !hasOutboundSeatsAvailable && !hasInboundSeatsAvailable
  const hasLoungeOutbound =
    outboundCabinsAvailable?.cabinAvailability.availableSeats.some((cab) => cab.isLounge === true) || false
  const hasLoungeInbound =
    inboundCabinsAvailable?.cabinAvailability.availableSeats.some((cab) => cab.isLounge === true) || false
  const hasLounge = hasLoungeOutbound || hasLoungeInbound

  const noCabinOrSeats = hasNoCabins && hasNoSeats

  const FSTRVesselCodes = flBooking?.hasReturnJourney
    ? flBooking.outbound?.vesselCode === 'FF' && flBooking.inbound?.vesselCode === 'FF'
    : flBooking?.outbound?.vesselCode === 'FF'

  const loading =
    flBookingLoading ||
    routeExpectLoading ||
    outboundCabinsLoading ||
    inboundCabinsLoading ||
    outboundMealsLoading ||
    inboundMealsLoading ||
    outboundExtrasLoading ||
    inboundExtrasLoading

  const items: MenuDialogItem[] = [
    {
      dataCy: 'btn-meals',
      key: 'meals',
      label: t('component.extras.meal.title'),
      icon: outboundMealsLoading || inboundMealsLoading ? Spinner : Food,
      info:
        (!loading && !hasMeals) || flBooking?.canBuyBookingItems === false
          ? t('component.extras.meal.noMealsAvailable')
          : '',
      target: AddMealsNavLink({ bookingCode, backTarget: pathname, backTargetTitle }),
      disabled:
        flBooking?.canBuyBookingItems === false ||
        outboundMealsLoading ||
        inboundMealsLoading ||
        (!loading && !hasMeals),
    },
    {
      dataCy: 'btn-cabins',
      label:
        !noCabinOrSeats && hasNoCabins && !loading
          ? t('component.extras.cabin.addSeat') + (hasLounge ? '  ' + t('error.basket.or') + ' ' + 'lounge' : '')
          : t('component.extras.cabin.title') + (hasLounge ? '  ' + t('error.basket.or') + ' ' + 'lounge' : ''),
      icon:
        outboundCabinsLoading || inboundCabinsLoading ? Spinner : hasNoCabins && !loading ? ChairComfort : BedDouble,
      info:
        (!loading && noCabinOrSeats) || flBooking?.canBuyBookingItems === false
          ? t('component.extras.cabin.noAvailability')
          : '',
      key: 'cabins',
      target: CabinsNavLink({ bookingCode, backTarget: pathname, backTargetTitle }),
      disabled:
        flBooking?.canBuyBookingItems === false ||
        outboundCabinsLoading ||
        inboundCabinsLoading ||
        (!loading && noCabinOrSeats),
    },
    {
      dataCy: 'btn-extra-onboard',
      label: t('addModal.extrasOnboard'),
      icon: outboundExtrasLoading || inboundExtrasLoading ? Spinner : Ticket,
      info:
        (!loading && !hasExtrasOnboard) || flBooking?.canBuyBookingItems === false
          ? t('component.extras.misc.noExtrasAvailableOnboard')
          : '',
      key: 'extrasOnboard',
      target: AddExtrasOnboardNavLink({ bookingCode, backTarget: pathname, backTargetTitle }),
      disabled:
        flBooking?.canBuyBookingItems === false ||
        outboundExtrasLoading ||
        inboundExtrasLoading ||
        (!loading && !hasExtrasOnboard),
    },
    {
      dataCy: 'btn-extra-ashore',
      label: t('addModal.extrasAshore'),
      icon: outboundExtrasLoading || inboundExtrasLoading ? Spinner : Ticket,
      info:
        (!loading && !hasExtrasAshore) || flBooking?.canBuyBookingItems === false
          ? t('component.extras.misc.noExtrasAvailableAshore')
          : '',
      key: 'extrasAshore',
      target: AddExtrasAshoreNavLink({ bookingCode, backTarget: pathname, backTargetTitle }),
      disabled:
        flBooking?.canBuyBookingItems === false ||
        outboundExtrasLoading ||
        inboundExtrasLoading ||
        (!loading && !hasExtrasAshore),
    },
  ]

  let cyTagName = ''

  const filteredItems = items.filter((item) => {
    if (item.key === 'cabins') {
      cyTagName = 'btn-add-more-cabins'
      return expectCabins
    }
    if (item.key === 'meals') {
      cyTagName = 'btn-add-more-meals'
      return FSTRVesselCodes ? false : expectMeals
    }
    if (item.key === 'extrasAshore') {
      cyTagName = 'btn-add-more-extras-ashore'
      return expectExtras
    }
    if (item.key === 'extrasOnboard') {
      cyTagName = 'btn-add-more-extras-onboard'
      return expectExtras
    }
    return true
  })

  return (
    <MenuDialog
      data-cy={cyTagName}
      onItemClick={onItemClick}
      label={`${flBooking?.canBuyBookingItems === false ? t('label.button.noItems') : t('label.button.addMoreItems')}`}
      onRequestClose={() => onRequestClose()}
      items={filteredItems}
    />
  )
}

export default SelectExtrasModalPopup
