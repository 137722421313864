/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Column,
  Divider,
  FormElementWrapper,
  H1,
  H3,
  Label,
  OptionHeader,
  Row,
  useToastDispatcher,
} from '@fjordline/styles-v3'

import CustomBackButton from '../../../components/CustomBackbutton'
import { ButtonFull } from '../../../components/globalStyles'
import { Currency } from '../../../graphql'
import UseGetInboundCabins from '../../../graphql/availabilityHooks/cabins/UseGetInboundCabins'
import UseGetOutboundCabins from '../../../graphql/availabilityHooks/cabins/UseGetOutboundCabins'
import UseGetBookingDetails from '../../../graphql/customerHooks/UseGetBookingDetails'
import { BookingNavLink } from '../../../routes/navLinkFunctions'
import { useAvailabilityItems } from '../../../providers/AvailabilityItemsProvider'
import AddExtrasModalButton from '../AddExtrasModalButton'

import { reduceBookedCabinsFunc, reduceCabinsInStateFunc } from './editCabins/cabinFunctions'
import CabinName from './editCabins/CabinName'
import { SelectedCabinContainer } from './editCabins/editCabins.styles'
import FeedbackMessages from './editCabins/FeedbackMessages'
import SelectedCabins from './editCabins/SelectedCabins'
import { findPassengerQuantityPerFareCode, options } from './editCabins/utils'
import useQueryParams from '../../receipt/useQueryParams'

const EditCabins: React.FC = () => {
  const { queryParams } = useQueryParams()

  const missingCabinsInbound = queryParams.get('missing') === 'inbound'

  useMemo(() => window.scrollTo(0, 0), [])
  const { dispatchToast } = useToastDispatcher()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const hasOutboundCabins = flBooking?.outbound?.cabins && flBooking?.outbound?.cabins?.length > 0
  const hasInboundCabins = flBooking?.inbound?.cabins && flBooking?.inbound?.cabins?.length > 0
  const { data: outboundCabins } = UseGetOutboundCabins()
  const { data: inboundCabins } = UseGetInboundCabins()
  const { addToCartState } = useAvailabilityItems()
  const [way, setWay] = useState('outbound')

  const outboundCabinsInState = addToCartState?.[bookingCode || '']?.['outbound']?.cabins || []
  const filteredOutboundCabinsInState = outboundCabinsInState.filter((e) => e.quantityInCabin > 0)
  const filteredInboundCabinsInState = addToCartState?.[bookingCode || '']?.['inbound']?.cabins?.filter(
    (e) => e.quantityInCabin > 0,
  )
  const inboundCabinsInState = addToCartState?.[bookingCode || '']?.['inbound']?.cabins || []
  const passengersToPlace = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const passengersPlacedOutbound =
    filteredOutboundCabinsInState?.reduce((acc, cabin) => acc + cabin.quantityInCabin, 0) || 0
  const passengersArePlacedOutbound = passengersToPlace === passengersPlacedOutbound
  const passengersPlacedInbound =
    filteredInboundCabinsInState?.reduce((acc, cabin) => acc + cabin.quantityInCabin, 0) || 0
  const passengersArePlacedInbound = passengersToPlace === passengersPlacedInbound
  const opt = options(setWay, flBooking, way, t, missingCabinsInbound, navigate)
  const flBookingCabins = flBooking?.[way]?.cabins || []
  const reduceBookedCabins = reduceBookedCabinsFunc(flBookingCabins)
  const reduceCabinsInState = reduceCabinsInStateFunc(addToCartState, way, bookingCode || '')

  useEffect(() => {
    if (missingCabinsInbound) {
      setWay('inbound')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasExistingCabins = way === 'outbound' ? hasOutboundCabins : hasInboundCabins
  const hasOutboundCabinsAvailable =
    outboundCabins?.cabinAvailability.availableCabins && outboundCabins?.cabinAvailability.availableCabins.length > 0
      ? true
      : false
  const hasInboundCabinsAvailable =
    flBooking?.inbound &&
    inboundCabins?.cabinAvailability.availableCabins &&
    inboundCabins?.cabinAvailability.availableCabins.length > 0
      ? true
      : false
  const hasNoCabins = !hasOutboundCabinsAvailable && !hasInboundCabinsAvailable

  const wayState = way === 'outbound' ? outboundCabinsInState : inboundCabinsInState
  const outboundCabinsTotal: number = outboundCabinsInState?.reduce((totalPrice, cabin) => {
    return totalPrice + cabin.price.value
  }, 0)

  const inboundCabinsTotal: number = inboundCabinsInState?.reduce((totalPrice, cabin) => {
    return totalPrice + cabin.price.value
  }, 0)

  const wayPriceTotal = way === 'outbound' ? outboundCabinsTotal : inboundCabinsTotal

  useEffect(() => {
    if (wayPriceTotal && wayPriceTotal <= 0) {
      sessionStorage.setItem('newPriceIsLessThanExistingPrice', 'true')
    } else {
      sessionStorage.removeItem('newPriceIsLessThanExistingPrice')
    }
  }, [wayPriceTotal])

  const showToast = useCallback(
    (message: string) => {
      dispatchToast({
        closeOnActionClick: true,
        message,
        timeout: 5000,
      })
    },
    [dispatchToast],
  )

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>
      <H1 style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        {' '}
        {hasNoCabins ? t('component.extras.cabin.addSeat') : t('component.extras.cabin.upgradeCabin')}
      </H1>
      <OptionHeader options={opt} />
      {hasExistingCabins || (wayState && wayState.length > 0) ? (
        <SelectedCabinContainer>
          <div>
            {hasExistingCabins ? (
              <>
                <Label>{t('component.extras.cabin.selectedCabins')}</Label>
                {reduceBookedCabins?.map((e, index) => {
                  const key = index + 1 + e.code
                  return e && e.code ? (
                    <CabinName
                      isOutbound={way === 'outbound' ? true : false}
                      key={key}
                      quantity={e.quantity}
                      cabinCode={e.code}
                      price={e.price}
                      currency={flBooking?.currency as Currency}
                    />
                  ) : null
                })}
              </>
            ) : null}

            {reduceCabinsInState?.length > 0 ? (
              <>
                {hasExistingCabins ? <Divider /> : null}
                <Label>{t('component.extras.cabin.newCabins')}</Label>
              </>
            ) : null}
            {reduceCabinsInState?.map((e, index) => {
              //Hide existing cabins since it has negative price.
              if (e.price < 0 || e.quantityInCabin <= 0) {
                return null
              } else {
                const key = index + 1 + e.code
                return e && e.code && e.price >= 0 ? (
                  <CabinName
                    isOutbound={way === 'outbound' ? true : false}
                    key={key}
                    quantity={e.quantity}
                    cabinCode={e.code}
                    price={e.price}
                    currency={flBooking?.currency as Currency}
                  />
                ) : null
              }
            })}
          </div>
        </SelectedCabinContainer>
      ) : null}

      {way === 'outbound' ? (
        <>
          <FeedbackMessages
            isOutbound={true}
            newPriceIsLessThanExistingPrice={wayPriceTotal <= 0}
            passengersToPlace={passengersToPlace}
            passengersArePlacedOutbound={passengersArePlacedOutbound}
            passengersArePlacedInbound={passengersArePlacedInbound}
            passengersPlacedOutbound={passengersPlacedOutbound}
            passengersPlacedInbound={passengersPlacedInbound}
          />
          <div style={{ marginBottom: '1rem' }} />
          <SelectedCabins availability={outboundCabins} SelectedCabins={outboundCabinsInState} isOutbound={true} />
        </>
      ) : null}
      {way === 'inbound' ? (
        <>
          <FeedbackMessages
            isOutbound={false}
            newPriceIsLessThanExistingPrice={wayPriceTotal <= 0}
            passengersToPlace={passengersToPlace}
            passengersArePlacedOutbound={passengersArePlacedOutbound}
            passengersArePlacedInbound={passengersArePlacedInbound}
            passengersPlacedOutbound={passengersPlacedOutbound}
            passengersPlacedInbound={passengersPlacedInbound}
          />

          <div style={{ marginBottom: '1rem' }} />
          <SelectedCabins availability={inboundCabins} SelectedCabins={inboundCabinsInState} isOutbound={false} />
        </>
      ) : null}

      {wayState && wayState.length > 0 && wayPriceTotal > 0 ? (
        <>
          <Divider style={{ marginTop: '2rem', marginBottom: '2rem' }} />{' '}
          <div>
            {' '}
            <H3 style={{ padding: '0', marginBottom: '0.5rem' }}>
              <b>{t('component.extras.cabin.priceUpgrade')}</b>
            </H3>
            {flBooking?.currency} {wayPriceTotal}
          </div>{' '}
        </>
      ) : null}

      <Row style={{ marginTop: '2rem' }}>
        <Column large={6} medium={6} small={12}>
          <FormElementWrapper>
            <ButtonFull>
              <Button
                onClick={() => {
                  const message = `${
                    inboundCabinsInState.length > 0 && !passengersArePlacedInbound
                      ? t('component.general.return')
                      : t('component.general.departure')
                  }: ${t('component.extras.cabin.feedback.missingPassengersPlaced', {
                    passengersPlaced:
                      inboundCabinsInState.length > 0 && !passengersArePlacedInbound
                        ? passengersPlacedInbound
                        : passengersPlacedOutbound,
                    passengersToPlace,
                  })}`

                  if (
                    (inboundCabinsInState.length > 0 && !passengersArePlacedInbound) ||
                    (outboundCabinsInState.length > 0 && !passengersArePlacedOutbound)
                  ) {
                    showToast(message)
                  } else if (
                    (inboundCabinsInState.length > 0 || outboundCabinsInState.length > 0) &&
                    wayPriceTotal <= 0
                  ) {
                    showToast(t('component.extras.cabin.feedback.newPriceIsLessThanExistingPrice'))
                  } else navigate(`/bookingList/${bookingCode}/basket`)
                }}
                size="large"
                dataCy="btn-go-to-basket"
              >
                {t('label.button.gotoBasket')}{' '}
              </Button>
            </ButtonFull>
          </FormElementWrapper>
        </Column>
        <Column large={6} medium={6} small={12}>
          <AddExtrasModalButton
            largeSizedButton
            bookingCode={bookingCode || ''}
            backTargetTitle=""
            isActiveOrFuture={true}
          />
        </Column>
      </Row>
    </div>
  )
}

export default EditCabins
