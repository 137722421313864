import React from 'react'
import { useTranslation } from 'react-i18next'
import { Feedback } from '@fjordline/styles-v3'

type FeedbackMessagesProps = {
  isOutbound: boolean
  newPriceIsLessThanExistingPrice: boolean
  passengersToPlace: number
  passengersArePlacedOutbound: boolean
  passengersArePlacedInbound: boolean
  passengersPlacedOutbound: number
  passengersPlacedInbound: number
}

const FeedbackMessages: React.FC<FeedbackMessagesProps> = ({
  isOutbound,
  newPriceIsLessThanExistingPrice,
  passengersArePlacedOutbound,
  passengersToPlace,
  passengersArePlacedInbound,
  passengersPlacedOutbound,
  passengersPlacedInbound,
}: FeedbackMessagesProps) => {
  const passengersArePlaced = isOutbound ? passengersArePlacedOutbound : passengersArePlacedInbound
  const passengersPlaced = isOutbound ? passengersPlacedOutbound : passengersPlacedInbound
  const { t } = useTranslation()
  if (passengersPlaced < passengersToPlace) {
    return (
      <Feedback type="warning" variant="notification">
        {t('component.extras.cabin.feedback.missingPassengersPlaced', { passengersPlaced, passengersToPlace })}
      </Feedback>
    )
  }

  if (newPriceIsLessThanExistingPrice && passengersArePlaced) {
    return (
      <Feedback type="warning" variant="notification">
        {t('component.extras.cabin.feedback.newPriceIsLessThanExistingPrice')}
      </Feedback>
    )
  }

  if (passengersArePlaced && !newPriceIsLessThanExistingPrice) {
    return (
      <Feedback type="success" variant="notification">
        {t('component.extras.cabin.feedback.allPassengersPlaced')}
      </Feedback>
    )
  }
}

export default FeedbackMessages
