import { Currency } from '../graphql'

export function currencyTransform(lang: string) {
  let curr = Currency.Nok
  switch (lang) {
    case 'E':
    case 'DE':
      curr = Currency.Eur
      break
    case 'D':
      curr = Currency.Dkk
      break
    default:
      curr = Currency.Nok
  }
  return curr as Currency
}

export function languageTransform(lang: string) {
  switch (lang) {
    case 'E':
      return 'en'
    case 'DE':
      return 'de'
    case 'D':
      return 'da'
    case 'N':
      return 'nb'
    default:
      return 'nb'
  }
}
