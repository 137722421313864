import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Column, Divider, FormElementWrapper, Label, Row, StepperInput, TrashLight } from '@fjordline/styles-v3'

import { StepperInputWrapper } from '../../components/StepperStateWrapper'
import UseGetBookingDetails from '../../graphql/customerHooks/UseGetBookingDetails'
import { MealsDetailsFragment, Specification } from '../../graphql/types'
import { useTimeZonedDateFormatter } from '../../hooks/DateFormat'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../../providers/WebsocketProvider'
import { findMaxToBook, findPassengerQuantityPerFareCode } from '../booking/editOrAddItemsPages/meals/utils'
import { WithImageLeft, MealImage, Grow, LowerButton, DividerWrapper } from './meals/meals.styles'
import mealsHelper from './meals/mealsHelper'
import useFetchSpecifications from '../../sanity/specifications/useFetchSpecifications'
import useFetchMeals from '../../sanity/meals/useFetchMeals'
import { imageUrlFor } from '../../sanity/imageUrlBuilder'

type MealsProps = {
  isOutbound: boolean
}

const Meals: React.FC<MealsProps> = ({ isOutbound }: MealsProps) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)

  const { addToCartState } = useAvailabilityItems()
  const journey = isOutbound ? 'outbound' : 'inbound'
  const mealItemsInState = addToCartState[bookingCode || '']?.[journey]?.meals || []

  if (mealItemsInState) {
    return mealsHelper(mealItemsInState)?.map((meal, index) => {
      const key = meal.code + index
      return <MealByCodeItem meal={meal} isOutbound={isOutbound} key={key} />
    })
  } else {
    return null
  }
}

export default Meals

type MealByCodeProps = {
  meal
  isOutbound: boolean
}
const MealByCodeItem: React.FC<MealByCodeProps> = ({ meal, isOutbound }: MealByCodeProps) => {
  const { addToCartState } = useAvailabilityItems()
  const { t, i18n } = useTranslation()
  const d = useTimeZonedDateFormatter()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const direction = isOutbound ? t('component.extras.common.departure') : t('component.extras.common.departure')
  const mealsToAdd = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.meals
  const { data: mealFromSanity } = useFetchMeals({ code: meal.code })

  return (
    <div key={meal.code}>
      <Row>
        <Column>
          <FormElementWrapper>
            <WithImageLeft>
              <MealImage
                alt={mealFromSanity?.mealName?.[i18n.language]}
                src={imageUrlFor(mealFromSanity?.mealImages[0].asset._ref)?.url()}
              />
              <Grow>
                <div>
                  <Label>{mealFromSanity?.mealName?.[i18n.language]}</Label>
                </div>
                <div>{`${direction} • ${d(meal.startTime, 'doMMMM')} • ${d(meal.startTime, 'p')}`}</div>
              </Grow>
            </WithImageLeft>
          </FormElementWrapper>
        </Column>
      </Row>
      <Row>
        {meal.spec.map((spec) => {
          return <MealSpecByCode key={1} mealsToAdd={mealsToAdd} spec={spec} meal={meal} isOutbound={isOutbound} />
        })}
      </Row>
      <Row>
        <Column medium={12} large={12}>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
        </Column>
      </Row>
    </div>
  )
}

type MealSpecByCodeProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mealsToAdd: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  spec: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meal: any
  isOutbound: boolean
}
const MealSpecByCode: React.FC<MealSpecByCodeProps> = ({ mealsToAdd, spec, meal, isOutbound }: MealSpecByCodeProps) => {
  const location = useLocation()
  const { i18n } = useTranslation()
  const { cartData } = useWebSocketContext()
  const bookingCode = extractBookingNumber(location)
  const { addMealsToCart } = useAvailabilityItemsOperations()
  const { specByCode } = useFetchSpecifications(spec.specificationCode)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const hasPaymentConfig = cartData?.bookingCarts?.[bookingCode || '']?.bookingResult?.paymentConfiguration
    ? true
    : false
  const currentMealValue =
    mealsToAdd.find(
      (m) =>
        m.code === meal.code &&
        m.subCode === spec.specificationCode &&
        new Date(m.startTime).getTime() === new Date(meal.startTime.toString() as string).getTime(),
    )?.quantity || 0

  const { adults, children, infants, baby } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers ?? [])
  const { maxToBook } = findMaxToBook(spec as Specification, adults, children, infants, baby, meal, mealsToAdd)
  return (
    <>
      <Column large={11.5} medium={11.5} small={10}>
        <StepperInputWrapper>
          <StepperInput
            label={specByCode?.specificationName?.[i18n.language] || ''}
            value={currentMealValue}
            disabled
            setValue={(value) => {
              addMealsToCart(
                meal as unknown as MealsDetailsFragment,
                value,
                spec as Specification,
                bookingCode || '',
                isOutbound,
              )
            }}
            inputAsText
            min={currentMealValue}
            max={maxToBook}
            price={{
              amount: spec.specificationPrice.value * currentMealValue,
              currency: spec.specificationPrice.currency || '',
              locale: i18n.language,
            }}
            direction="row"
          />
        </StepperInputWrapper>
      </Column>
      {hasPaymentConfig ? null : (
        <Column large={0.5} medium={0.5} small={2}>
          <LowerButton>
            <Button
              dataCy="remove-meal"
              onClick={() =>
                addMealsToCart(
                  meal as unknown as MealsDetailsFragment,
                  0,
                  spec as Specification,
                  bookingCode || '',
                  isOutbound,
                )
              }
              theme="clean"
              size="small"
              icon={TrashLight}
            />
          </LowerButton>
        </Column>
      )}
    </>
  )
}
