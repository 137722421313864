import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Column, Divider, FormElementWrapper, H1, H2, Row } from '@fjordline/styles-v3'

import CustomBackButton from '../../../components/CustomBackbutton'
import { ButtonFull } from '../../../components/globalStyles'
import UseGetInboundExtras from '../../../graphql/availabilityHooks/extras/UseGetInboundExtras'
import UseGetOutboundExtras from '../../../graphql/availabilityHooks/extras/UseGetOutboundExtras'
import { BookingNavLink } from '../../../routes/navLinkFunctions'
import AddExtrasModalButton from '../AddExtrasModalButton'

import ExtrasAshore from './addExtrasAshore/ExtrasAshore'
import { AvailabilityExtraItemWithSpec } from '../../../graphql/types'
import LottieLoaderContainer from '../../../components/LottieLoader'

const AddExtrasAshore: React.FC = () => {
  const { bookingCode } = useParams()
  const { loading, outboundExtrasAshore } = UseGetOutboundExtras()
  const { inboundExtrasAshore } = UseGetInboundExtras()
  useMemo(() => window.scrollTo(0, 0), [])
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>

      <H1 style={{ marginTop: '1rem', marginBottom: '1rem' }}>{t('component.extras.misc.ashoreTitle')}</H1>
      {loading ? (
        <LottieLoaderContainer />
      ) : (
        <>
          {outboundExtrasAshore && outboundExtrasAshore.length > 0 ? (
            <ExtrasAshore data={outboundExtrasAshore as AvailabilityExtraItemWithSpec[]} isOutbound={true} />
          ) : (
            <div>
              <H2>{t('component.general.departure')}</H2>
              {t('component.extras.misc.noExtrasAvailable')}
            </div>
          )}
          {inboundExtrasAshore && inboundExtrasAshore.length > 0 ? (
            <ExtrasAshore data={inboundExtrasAshore as AvailabilityExtraItemWithSpec[]} isOutbound={false} />
          ) : (
            <div>
              <Divider style={{ marginBottom: '1rem', marginTop: '1rem' }} />
              <H2 style={{ marginTop: '1rem' }}>{t('component.general.return')}</H2>
              {t('component.extras.misc.noExtrasAvailable')}
            </div>
          )}
          <Row style={{ marginTop: '2rem' }}>
            <Column large={6} medium={6} small={12}>
              <FormElementWrapper>
                <ButtonFull>
                  <Button
                    dataCy="btn-go-to-basket"
                    onClick={() => navigate(`/bookingList/${bookingCode}/basket`)}
                    size="large"
                  >
                    {t('label.button.gotoBasket')}{' '}
                  </Button>
                </ButtonFull>
              </FormElementWrapper>
            </Column>
            <Column large={6} medium={6} small={12}>
              <AddExtrasModalButton
                largeSizedButton
                bookingCode={bookingCode || ''}
                backTargetTitle=""
                isActiveOrFuture={true}
              />
            </Column>
          </Row>
        </>
      )}
    </div>
  )
}

export default AddExtrasAshore
