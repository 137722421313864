import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Accordion, BedDouble, Button, Spinner, useAccordionState, useToastDispatcher } from '@fjordline/styles-v3'

import UseGetInboundCabins from '../../../../graphql/availabilityHooks/cabins/UseGetInboundCabins'
import UseGetOutboundCabins from '../../../../graphql/availabilityHooks/cabins/UseGetOutboundCabins'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { AvailabilityItemWithSpecOptions, Cabin, GetAvailableCabinsQuery } from '../../../../graphql/types'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../../../providers/AvailabilityItemsProvider'

import AvailableCabins from './AvailableCabins'
import SelectedCabin from './SelectedCabin'
import { findPassengersPlacement } from './utils'

type Props = {
  SelectedCabins: Cabin[]
  isOutbound: boolean
  availability?: GetAvailableCabinsQuery
}

const SelectedCabins: React.FC<Props> = ({ SelectedCabins, isOutbound, availability }: Props) => {
  const { t } = useTranslation()
  const { loading: outboundLoading } = UseGetOutboundCabins()
  const { loading: inboundLoading } = UseGetInboundCabins()
  const loading = isOutbound ? outboundLoading : inboundLoading
  const { onToggle, openedItems } = useAccordionState({ multiOpen: true })
  const { dispatchToast } = useToastDispatcher()
  const { setShowModal, addCabinsToCart } = useAvailabilityItemsOperations()
  const journey = isOutbound ? 'outbound' : 'inbound'
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const availableCabins = availability?.cabinAvailability.availableCabins || ([] as AvailabilityItemWithSpecOptions[])
  const availableSeats = availability?.cabinAvailability.availableSeats || ([] as AvailabilityItemWithSpecOptions[])
  const availableAll = [...(availableCabins ?? []), ...(availableSeats ?? [])] as AvailabilityItemWithSpecOptions[]
  const addToCartState = useAvailabilityItems().addToCartState?.[bookingCode || '']?.[journey]?.cabins
  const { allPassengersArePlaced } = findPassengersPlacement(addToCartState, flBooking)
  const showToast = useCallback(
    (message: string) => {
      dispatchToast({
        closeOnActionClick: true,
        message,
        timeout: 5000,
      })
    },
    [dispatchToast],
  )

  const hasExistingCabinsInAddToCabinState = addToCartState?.some((e) => e.quantityInCabin <= 0)
  const existingCabins = useMemo(
    () => (isOutbound ? flBooking?.outbound?.cabins ?? [] : flBooking?.inbound?.cabins ?? []),
    [flBooking?.inbound?.cabins, flBooking?.outbound?.cabins, isOutbound],
  )

  useEffect(() => {
    if (addToCartState && addToCartState.length > 0 && !hasExistingCabinsInAddToCabinState) {
      existingCabins.map((e) => {
        return addCabinsToCart(e, -e.quantityInCabin, isOutbound, bookingCode || '')
      })
    }
  }, [addCabinsToCart, bookingCode, existingCabins, hasExistingCabinsInAddToCabinState, isOutbound, addToCartState])

  const values = SelectedCabins?.map((e, index) => {
    if (e.code) return `${journey}-${e.code}-${index}`
  }) as string[]

  return (
    <>
      <Accordion variant="checkbox" openedItems={openedItems} values={values} onToggle={onToggle}>
        {SelectedCabins?.map((e, index) => {
          const key = index
          if (e && e.code) {
            return (
              <SelectedCabin
                availability={availableAll}
                key={key}
                isOutbound={isOutbound}
                code={e.code}
                quantityInCabin={e.quantityInCabin}
                index={index}
                cabin={e}
              />
            )
          }
        })}
      </Accordion>
      <AvailableCabins valueFallback={0} codeFallback="" />
      <Button
        style={{ maxWidth: '250px' }}
        trailingIcon={loading ? Spinner : BedDouble}
        size="medium"
        disabled={loading}
        theme="primary"
        onClick={() => {
          if (allPassengersArePlaced) {
            showToast(t('component.extras.cabin.feedback.deleteBeforeAdd'))
          } else {
            setShowModal({
              showModal: true,
              isOutbound: isOutbound,
            })
          }
        }}
      >
        {t('component.extras.cabin.seeAll')}
      </Button>
    </>
  )
}

export default SelectedCabins
