export const TRAVELER_FRIEND = 'travelerFriend'
export const BOOKING_LIST = 'bookingList'

const ROOT = '/'

export const routerRootPaths = {
  ROOT: `${ROOT}`,
  LOGIN: `${ROOT}`,
  FJORD_CLUB: `${ROOT}fjordClub`,
  BOOKING_LIST: `${ROOT}${BOOKING_LIST}`,
  VERIFY_EMAIL: `${ROOT}VerifyEmail`,
  PAY_WITH_CLUB_POINTS: `${ROOT}payWithClubPoints`,
}
export const routerFjordClubSubPaths = {
  EDIT_MEMBERSHIP: 'editMembership',
  SIGN_UP: 'signup',
  SIGN_UP_ADD: 'signupAdd',
  TRAVELER: 'traveler',
  TRAVELER_FRIEND: `${TRAVELER_FRIEND}/:rowNumber`,
  SIGNED_UP: 'signedUp',
}
export const routerBookingSubPathsStrings = {
  ADD_EXTRAS_ASHORE: 'addExtrasAshore',
  ADD_EXTRAS_ONBOARD: 'addExtrasOnboard',
  ADD_MEALS: 'addMeals',
  BASKET: 'basket',
  BOARDING_CARDS: 'boardingCards',
  BOOKING_PASSENGER: 'passenger',
  EDIT_CABINS: 'cabins',
  CART: 'cart',
  PAYMENT: 'payment',
  TICKETS: 'tickets',
  VEHICLE: 'vehicle',
}

const BOOKING = `${routerRootPaths.BOOKING_LIST}/:bookingCode`

export const routerBookingSubPaths = {
  ADD_EXTRAS_ASHORE: `${BOOKING}/${routerBookingSubPathsStrings.ADD_EXTRAS_ASHORE}`,
  ADD_EXTRAS_ONBOARD: `${BOOKING}/${routerBookingSubPathsStrings.ADD_EXTRAS_ONBOARD}`,
  ADD_MEALS: `${BOOKING}/${routerBookingSubPathsStrings.ADD_MEALS}`,
  BASKET: `${BOOKING}/${routerBookingSubPathsStrings.BASKET}`,
  BOARDING_CARDS: `${BOOKING}/${routerBookingSubPathsStrings.BOARDING_CARDS}`,
  BOOKING: BOOKING,
  BOOKING_PASSENGER: `${BOOKING}/${routerBookingSubPathsStrings.BOOKING_PASSENGER}/:rowNumber`,
  EDIT_CABINS: `${BOOKING}/${routerBookingSubPathsStrings.EDIT_CABINS}`,
  CART: `${BOOKING}/${routerBookingSubPathsStrings.CART}`,
  PAYMENT: `${BOOKING}/${routerBookingSubPathsStrings.PAYMENT}`,
  TICKETS: `${BOOKING}/${routerBookingSubPathsStrings.TICKETS}`,
  VEHICLE: `${BOOKING}/${routerBookingSubPathsStrings.VEHICLE}/:rowNumber`,
}
