import React from 'react'
import { PortableTextComponents } from '@portabletext/react'
import styled from 'styled-components'

export const _blankAnchor: PortableTextComponents = {
  marks: {
    link: ({ value, children }) => {
      const { href } = value
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    },
  },
}
export const StyledPortableText = styled.div`
  p {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  a {
    text-decoration: underline;
    font-weight: 500;
  }

  * {
    margin: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`
