import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { da, de, enGB, nb } from 'date-fns/locale'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

const useTimeZonedDateFormatter = () => {
  const { i18n } = useTranslation()

  return useCallback(
    (date: string | number | Date, dateFormat: string, timeZone = 'Europe/Oslo') => {
      const locales = { da, de, enGB, nb }

      return format(utcToZonedTime(date, timeZone), dateFormat, { locale: locales[i18n.language] })
    },
    [i18n.language],
  )
}

const useDateFormatter = () => {
  const { i18n } = useTranslation()

  return useCallback(
    (date: string, dateFormat: string) => {
      const locales = { da, de, enGB, nb }

      return format(new Date(date), dateFormat, { locale: locales[i18n.language] })
    },
    [i18n.language],
  )
}

const useZonedTimeToUtcDateFormatter = () => {
  const { i18n } = useTranslation()

  return useCallback(
    function formatDateToString(date: string | number | Date | undefined, dateFormat: string): string {
      const locales = { da, de, enGB, nb }

      return date ? format(zonedTimeToUtc(date, ''), dateFormat, { locale: locales[i18n.language] }) : ''
    },
    [i18n.language],
  )
}

export { useTimeZonedDateFormatter, useDateFormatter, useZonedTimeToUtcDateFormatter }
