import { useEffect, useState } from 'react'

import { useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { CartsByBookingCodes } from '../../providers/availabilityItemsProvider/types'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'

function useClearCartOfBookingCode(bookingCode: string | undefined, isFullyPaid: boolean) {
  const { setAddToCartState } = useAvailabilityItemsOperations()
  const { cartData, setCartData } = useWebSocketContext()
  function filterBookingCode(data: CartsByBookingCodes, bookingCode: string) {
    if (!isFullyPaid) return
    const filteredData = { ...data }
    if (filteredData[bookingCode]) {
      delete filteredData[bookingCode]
    }
    return filteredData
  }

  const filteredData = filterBookingCode(cartData.bookingCarts, bookingCode || '')
  function doesBookingCodeExist(data: CartsByBookingCodes, bookingCode: string): boolean {
    return !!data[bookingCode]
  }
  const doesBookingCodeExistInCart = doesBookingCodeExist(cartData?.bookingCarts, bookingCode || '')
  const [hasPushedToCart, setHasPushedToCart] = useState(false)
  useEffect(() => {
    if (!hasPushedToCart && doesBookingCodeExistInCart && filteredData && isFullyPaid) {
      setAddToCartState(filteredData)
      sessionStorage.setItem('AddToCartState', JSON.stringify(filteredData))

      if (setCartData) {
        sessionStorage.setItem('cartData', JSON.stringify({ ...cartData, bookingCarts: filteredData }))
        setCartData({
          timestamp: new Date().getTime(),
          id: cartData.id,
          bookingCarts: filteredData,
        })
      }

      setHasPushedToCart(true)
    }
  }, [
    cartData,
    cartData.id,
    doesBookingCodeExistInCart,
    filteredData,
    hasPushedToCart,
    isFullyPaid,
    setAddToCartState,
    setCartData,
  ])
}

export default useClearCartOfBookingCode
