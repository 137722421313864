import UseGetInboundCabins from '../../graphql/availabilityHooks/cabins/UseGetInboundCabins'
import UseGetOutboundCabins from '../../graphql/availabilityHooks/cabins/UseGetOutboundCabins'
import UseGetInboundExtras from '../../graphql/availabilityHooks/extras/UseGetInboundExtras'
import UseGetOutboundExtras from '../../graphql/availabilityHooks/extras/UseGetOutboundExtras'
import UseGetinboundMeals from '../../graphql/availabilityHooks/meals/UseGetInboundMeals'
import UseGetOutboundMeals from '../../graphql/availabilityHooks/meals/UseGetOutboundMeals'
import UseRouteExpect from '../../graphql/availabilityHooks/route/UseRouteExpect'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'

function useResolveGraphqlDataForBooking() {
  const { flBooking } = useBookingDetails()
  const { data: outboundMeals } = UseGetOutboundMeals()
  const { data: inboundMeals } = UseGetinboundMeals()
  const { outboundExtrasAshore, outboundExtrasOnboard } = UseGetOutboundExtras()
  const { inboundExtrasAshore, inboundExtrasOnboard } = UseGetInboundExtras()
  UseGetOutboundCabins()
  UseGetInboundCabins()

  const hasOutboundMeals = outboundMeals && outboundMeals.length > 0 ? true : false
  const hasInboundMeals = inboundMeals && inboundMeals.length > 0 ? true : false
  const hasOutboundExtrasAshore = outboundExtrasAshore && outboundExtrasAshore.length > 0 ? true : false
  const hasInboundExtrasAshore = inboundExtrasAshore && inboundExtrasAshore.length > 0 ? true : false
  const hasOutboundExtrasOnboard = outboundExtrasOnboard && outboundExtrasOnboard.length > 0 ? true : false
  const hasInboundExtrasOnboard = inboundExtrasOnboard && inboundExtrasOnboard.length > 0 ? true : false

  const {
    expectCabins: expectCabinsOutbound,
    expectExtras: expectExtrasOutbound,
    expectMeals: expectMealsOutbound,
  } = UseRouteExpect({
    originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
  })

  const {
    expectCabins: expectCabinsInbound,
    expectExtras: expectExtrasInbound,
    expectMeals: expectMealsInbound,
  } = UseRouteExpect({
    originPort: flBooking?.inbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.inbound?.arrivalPortInfo?.portCode as string,
  })

  const expectCabins = expectCabinsOutbound || expectCabinsInbound
  const expectExtras = expectExtrasOutbound || expectExtrasInbound
  const expectMeals = expectMealsOutbound || expectMealsInbound

  return {
    expectCabins,
    expectExtras,
    expectMeals,
    hasOutboundMeals,
    hasInboundMeals,
    hasOutboundExtrasAshore,
    hasInboundExtrasAshore,
    hasOutboundExtrasOnboard,
    hasInboundExtrasOnboard,
  }
}

export default useResolveGraphqlDataForBooking
