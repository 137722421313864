import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AccordionItem, Button, Column, Row, StepperInput, Trash } from '@fjordline/styles-v3'

import { StepperInputWrapper } from '../../../../components/StepperStateWrapper'
import { Passenger } from '../../../../graphql'
import UseGetSuggestedCabinsInbound from '../../../../graphql/availabilityHooks/cabins/UseGetSuggestedCabinsInbound'
import UseGetSuggestedCabinsOutbound from '../../../../graphql/availabilityHooks/cabins/UseGetSuggestedCabinsOutbound'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { AvailabilityItemWithSpecOptions, Cabin } from '../../../../graphql/types'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../../../providers/AvailabilityItemsProvider'
import { findPassengerQuantityPerFareCode } from '../meals/utils'
import useFetchCabins from '../../../../sanity/cabins/useFetchCabins'
import { imageUrlFor } from '../../../../sanity/imageUrlBuilder'
import { CabinIcons } from './CabinIcons'

type Props = {
  isOutbound: boolean
  code: string
  quantityInCabin: number
  availability?: AvailabilityItemWithSpecOptions[]
  index: number
  cabin: Cabin
}

const SelectedCabin: React.FC<Props> = ({ isOutbound, code, quantityInCabin, availability, index, cabin }: Props) => {
  const { t, i18n } = useTranslation()
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { adults, children, infants } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers as Passenger[])
  const findCabinInAvailability = availability?.find((cabin) => cabin.code === code)
  const journey = isOutbound ? 'outbound' : 'inbound'
  const { addCabinsToCart, removeCabin } = useAvailabilityItemsOperations()
  const addToCartState = useAvailabilityItems()?.addToCartState
  const cabinsInState = addToCartState?.[bookingCode || '']?.[journey]?.cabins || []
  const cabinsInStateWithQuanityBiggerThanZero = cabinsInState.filter((cabin) => cabin.quantityInCabin > 0)
  const passengersToPlace = adults + children + infants
  const passengersPlaced = cabinsInStateWithQuanityBiggerThanZero?.reduce(
    (acc, cabin) => acc + cabin.quantityInCabin,
    0,
  )
  const passengersYetToPlace = passengersToPlace - passengersPlaced
  const { outboundSuggestedCabins } = UseGetSuggestedCabinsOutbound({
    passengersToPlace: quantityInCabin,
    itemCode: code,
  })
  const { inboundSuggestedCabins } = UseGetSuggestedCabinsInbound({
    passengersToPlace: quantityInCabin,
    itemCode: code,
  })

  const suggestedCabins = isOutbound ? outboundSuggestedCabins : inboundSuggestedCabins
  const vesselCode = isOutbound ? flBooking?.outbound?.vesselCode : flBooking?.inbound?.vesselCode
  const { cabinsFromSanity } = useFetchCabins(cabin.code || undefined, vesselCode || undefined)
  const price = cabinsInState.find((e) => e.id === cabin.id).price.value

  if (quantityInCabin <= 0) return null

  return (
    <AccordionItem
      title={cabinsFromSanity?.cabinName?.[i18n.language] ?? findCabinInAvailability?.itemName}
      itemId={`${journey}-${cabin.code}-${index}`}
      subTitle={`${flBooking?.currency} ${price}`}
    >
      <Row>
        <Column large={3} medium={3}>
          <img
            style={{ maxWidth: '100%' }}
            src={imageUrlFor(cabinsFromSanity?.cabinImages?.[0]?.asset?._ref || '')?.url()}
            alt={
              cabinsFromSanity?.cabinImages?.[0].altText?.[i18n.language] ||
              cabinsFromSanity?.cabinName?.[i18n.language] ||
              ''
            }
          />
        </Column>
        <Column large={9} medium={9}>
          {cabinsFromSanity?.cabinFullDesc?.[i18n.language]}
          <div style={{ marginTop: '1rem' }}>
            <CabinIcons cabinInfo={cabinsFromSanity} />
          </div>
        </Column>
        <Column>
          <Row align="center" direction="row" justify="space-between">
            <Column large={5} medium={6} small={12}>
              <StepperInputWrapper>
                <StepperInput
                  max={
                    passengersYetToPlace === 0
                      ? quantityInCabin
                      : findCabinInAvailability?.specification.maximumQuantityToBook || 0
                  }
                  min={
                    suggestedCabins
                      ? quantityInCabin
                      : findCabinInAvailability?.specification.minimumQuantityToBook || 0
                  }
                  label={t('component.extras.cabin.quantityInCabin')}
                  value={quantityInCabin}
                  setValue={(value) => {
                    addCabinsToCart(cabin, value, isOutbound, bookingCode || '')
                  }}
                  disabled={suggestedCabins ? true : false}
                  inputAsText
                />
              </StepperInputWrapper>
            </Column>
            <Column large={6} medium={6} small={12} justifyContent="flex-end">
              <div>
                <Button
                  onClick={() => {
                    removeCabin(addToCartState, cabin?.id, isOutbound, bookingCode || '')
                  }}
                  theme="ghost"
                  leadingIcon={Trash}
                >
                  {t('component.extras.cabin.removeCabin')}
                </Button>
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
    </AccordionItem>
  )
}

export default SelectedCabin
