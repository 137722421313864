import { useMemo } from 'react'

import { useRouteExpectQuery } from '../../types'

interface Props {
  originPort: string
  destinationPort: string
}

function UseRouteExpect({ originPort, destinationPort }: Props) {
  const { data, loading } = useRouteExpectQuery({
    variables: {
      destinationPortCode: destinationPort,
      originPortCode: originPort,
    },
    skip: !originPort || !destinationPort,
  })

  const expectCabins = useMemo(() => data?.route.expectCabins, [data?.route.expectCabins])
  const expectMeals = useMemo(() => data?.route.expectMeals, [data?.route.expectMeals])
  const expectExtras = useMemo(() => data?.route.expectExtras, [data?.route.expectExtras])

  const routeExpectLoading = loading

  return {
    expectCabins,
    expectMeals,
    expectExtras,
    routeExpectLoading,
  }
}

export default UseRouteExpect
