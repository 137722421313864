const cabinSpec = {
  P: 'Person',
}

const mealSpec = {
  ADL: 'Adult(s)',
  BABY: 'Baby',
  CHD: 'Child/Children',
  CLU: 'Club/Adult(s)',
  INF: 'Infant',
}

const country = {
  NO: 'Norway',
  DK: 'Denmark',
  DE: 'Germany',
  S: 'Sweden',
  SE: 'Sweden',
  FR: 'France',
  EE: 'Estonia',
  CZ: 'Czech Republic',
  CH: 'Switzerland',
  AT: 'Austria',
  IT: 'Italy',
  PL: 'Poland',
  NL: 'Netherlands',
  UK: 'United Kingdom',
  PT: 'Portugal',
  BG: 'Bulgaria',
  GB: 'United Kingdom',
  RO: 'Romania',
  EI: 'Ireland',
  GR: 'Greece',
  RU: 'Russian Federation',
  HU: 'Hungary',
  HR: 'Croatia',
  SI: 'Slovenia',
  SK: 'Slovakia',
  BE: 'Belgium',
  AD: 'ANDORRA',
  AE: 'UNITED ARAB EMIRATES',
  AF: 'AFGHANISTAN',
  AG: 'ANTIGUA AND BARBUDA',
  AI: 'ANGUILLA',
  AL: 'ALBANIA',
  AM: 'ARMENIA',
  AN: 'Andorra',
  AO: 'ANGOLA',
  AQ: 'Antartica',
  AR: 'ARGENTINA',
  AS: 'American Samoa',
  AU: 'AUSTRALIA',
  AW: 'ARUBA',
  AZ: 'AZERBAIJAN',
  BA: 'Bosnia and Herzegovina',
  BB: 'BARBADOS',
  BD: 'BANGLADESH',
  BF: 'BURKINA FASO',
  BH: 'BAHRAIN',
  BI: 'BURUNDI',
  BJ: 'BENIN',
  BM: 'BERMUDA',
  BN: 'BRUNEI DARUSSALAM',
  BO: 'BOLIVIA',
  BR: 'BRAZIL',
  BS: 'BAHAMAS',
  BT: 'BHUTAN',
  BV: 'BOUVET ISLAND',
  BW: 'BOTSWANA',
  BY: 'BELARUS',
  BZ: 'BELIZE',
  CA: 'CANADA',
  CC: 'COCOS (KEELING) ISLAND',
  CD: 'CONGO, THE DEMOCRATIC REPUBLIC',
  CF: 'CENTRAL AFRICA REPUBLIC',
  CG: 'CONGO',
  CI: 'CÔTE D\'IVOIRE',
  CK: 'COOK ISLANDS',
  CL: 'CHILE',
  CM: 'CAMEROON',
  CN: 'China',
  CO: 'COLOMBIA',
  CR: 'COSTA RICA',
  CS: 'Former Czechoslovakia',
  CU: 'CUBA',
  CV: 'Cabo Verde',
  CX: 'CHRISTMAS ISLAND',
  CY: 'CYPRUS',
  DJ: 'Djbouti',
  DM: 'DOMINICA',
  DO: 'DOMINICAN REPUBLIC',
  DZ: 'ALGERIA',
  EC: 'ECUADOR',
  EG: 'EGYPT',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  EST: 'Estonia',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands (the)',
  GA: 'Gabon',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GS: 'S. Georgia & S. Sandwich Isls.',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard and McDonald Islands',
  HN: 'Honduras',
  HT: 'Haiti',
  IA: 'Iran',
  ID: 'Indonesia',
  IL: 'Israel',
  IN: 'India',
  INT: 'International',
  IO: 'BRITISH INDIAN OCEAN TERRITORY',
  IQ: 'Iraq',
  IS: 'Iceland',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'KYRGYZSTAN',
  KH: 'CAMBODIA',
  KI: 'Kiribati',
  KM: 'COMOROS',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KY: 'CAYMAN ISLANDS',
  KZ: 'Kazakhstan',
  LA: 'Lao People\'s Democratic Republic',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LE: 'Lebanon',
  LI: 'Lichtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  M: 'Malta',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova, Republic of',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macau',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique (French)',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia (French)',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NP: 'Nepal',
  NR: 'Nauru',
  NT: 'Neutral Zone',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestina',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Reunion',
  RS: 'Serbia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SJ: 'Svalbard and Jan Mayen Islands',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'SAO TOME AND PRINCIPE',
  SU: 'Former USSR',
  SV: 'EL SALVADOR',
  SY: 'SYRIAN ARAB REPUBLIC',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'CHAD',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'TAJIKISTAN',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TP: 'EAST TIMOR',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'TAIWAN, PROVINCE OF CHINA',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'USA Minor Outlying Islands',
  UN: 'Undefined',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Heard and McDonald Islands',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (USA)',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna Islands',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZR: 'Zaire',
  ZW: 'Zimbabwe',
}

const en = {
  cabinSpec,
  mealSpec,
  country,
}

export default en
