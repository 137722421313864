import { H3, Paragraph } from '@fjordline/styles-v3'
import styled from 'styled-components'

export const BoardingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 20px 20px 5px 20px;
`

export const CarouselContainer = styled.div`
  max-width: 550px;
`

export const MarginLessH3 = styled(H3)`
  margin: 0;
`

export const MarginLessParagraph = styled(Paragraph)`
  margin: 0;
`
