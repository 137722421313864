import { useParams } from 'react-router-dom'

import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { Currency, FareCode, useGetSuggestedItemsQuery } from '../../types'

type Props = {
  passengersToPlace: number
  itemCode: string
}

function UseGetSuggestedCabinsOutbound({ passengersToPlace, itemCode }: Props) {
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })

  const journeyCode = flBooking?.outbound?.journeyCode as string

  const { data, loading } = useGetSuggestedItemsQuery({
    variables: {
      requestParams: {
        journeyCode: journeyCode,
        departureDate: flBooking?.outbound?.departureDate as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.outbound?.departureCode as string,
        destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
        ticketCode: flBooking?.outbound?.ticket?.ticketCode as string,
        hasVehicles: flBooking?.outbound?.vehicles && flBooking?.outbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.outbound?.passengers?.[0].customerCode &&
          flBooking?.outbound?.passengers[0].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.outbound?.vesselCode,
        itemCode: itemCode,
        passengers: [
          {
            code: FareCode.Adult,
            quantity: passengersToPlace,
          },
        ],
      },
    },
    skip:
      journeyCode === undefined ||
      flBooking?.outbound?.departureCode === undefined ||
      itemCode === undefined ||
      itemCode !== 'LOUNGE' ||
      passengersToPlace === 0,
  })

  return {
    outboundSuggestedCabins: data?.suggest.cabins,
    outboundSuggestedLoading: loading,
  }
}

export default UseGetSuggestedCabinsOutbound
