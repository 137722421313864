import React, { useEffect, useMemo } from 'react'
import { fetchFjordClubJourneysFromSanity, fetchPayableWithClubPointsFromSanity } from '../fetchData'
import { useTranslation } from 'react-i18next'

function useFetchPayableWithClubPointsFromSanity({ showFCPoints }: { showFCPoints: boolean }) {
  const { i18n } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [payableWithClubPoints, setPayableWithClubPoints] = React.useState<any>([])

  const endpoint = useMemo(
    () => (showFCPoints ? fetchPayableWithClubPointsFromSanity() : fetchFjordClubJourneysFromSanity()),
    [showFCPoints],
  )

  useEffect(() => {
    endpoint.then((e) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = e.filter((item: any) => item.supportedLanguages.includes(i18n.language))
      setPayableWithClubPoints(res)
      sessionStorage.setItem('payableWithClubPoints', JSON.stringify(e))
    })
  }, [endpoint, i18n.language])

  return {
    payableWithClubPoints,
  }
}

export default useFetchPayableWithClubPointsFromSanity
