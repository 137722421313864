/* eslint-disable @typescript-eslint/naming-convention */
import { PartialTranslation } from './nb'
import CarRes from '../CarRes/da'

export const translation: PartialTranslation = {
  language: {
    da: 'Dansk',
    de: 'Deutsch',
    en: 'English',
    nb: 'Norsk',

  },
  country: {
    da: 'Danmark',
    de: 'Tyskland',
    en: 'England',
    nb: 'Norge',
    nl: 'Holland',
  },
  number: {
    1: 'En',
    2: 'To',
    3: 'Tre',
    4: 'Fire',
    5: 'Fem',
    6: 'Seks',
    7: 'Syv',
    8: 'otte',
    9: 'Ni',
    10: 'Ti',
    11: 'Eleven',
    12: 'Tolv',
    13: 'Tretten',
    14: 'Fjorten',
    15: 'Femten',
  },
  bookingListStatus: {
    journeys:  'Rejser',
    ACTIVE_AND_FUTURE: 'Aktiv og fremtidig$t(bookingListStatus.journeys)',
    CANCELLED: 'Aflyst $t(bookingListStatus.journeys)',
    PASSED: 'Passeret $t(bookingListStatus.journeys)',
    DEFAULT: '$t(bookingListStatus.ACTIVE_AND_FUTURE)',
  },
  component: {
    general: {
      loading: 'Indlæser',
      departure: 'Udrejse',
      return: 'Hjemrejse',
    },
    welcome: {
      oldMyPage: 'Oplever du problemer med den nye Min side?',
      goToOldMyPage: 'Gå tilbage til den gamle Min side',
      toMyPage: 'Velkommen til Min Side',
      generalInfo:
        'Her finder du en oversigt over dine rejser og/eller dit medlemskab af Fjord Club. Hvis du endnu ikke er medlem af Fjord Club, kan du tilmelde dig her.',
    },
    auth: {
      renew: {
        headline: 'Du skal forny din bruger',
        paragraph1: 'Vi har opgraderet vores login-systemer, og derfor skal du forny din bruger. Du kan bruge den samme e-mail og adgangskode som før.',
        paragraph2: 'Alle originale oplysninger og reservationer vil stadig være tilgængelige.',
        renewBtn: 'Forny bruger',
      },

      verifyAndProceed: 'Bekræft og fortsæt',
      header: 'Min Side',
      login: 'Log ind',
      logout: 'Log ud',
      noCode: 'Ikke modtaget kode?',
      oneTimeCode: 'Engangskode',
      email: 'E-mail',
      phone: 'Mobilnummer',
      resendCode: 'Send ny engangskode',
      codeInstructions:
        'Ikke luk denne fane men åben en ny for at finde dine kode. Hvis du lukker denne fane må du starte forfra',
      loginError: 'Login mislykkedes, venligst prøv igen.',
      facebookEmailRequired:
        'Vi skal have adgang til din e-mailadresse for at Min Side kan fungere. Log venligst ind igen og giv adgang til din e-mailadresse, eller vælg en anden login-mulighed.',
      auth0Error: {
        access_denied: 'Forkert engangskode. Tjek at koden er korrekt indtastet og forsøg igen.',
      },
      multipleProfiled: {
        header: 'Dit brugernavn',
        ingress1: 'Der er mere end én profil tilknyttet dit brugernavn ({{userName}})',
        ingress2:
          'Vi har registreret en supportsag om problemet og kontakter dig, så snart sagen er løst, eller hvis vi har brug for flere oplysninger fra dig',
        ingress3: 'Kontakt venligst kundecenteret, så de kan hjælpe dig',
        phone: 'Telefonnummer',
        email: 'E-mail',
        buttonText: 'Se kontaktoplysninger',
      },
      emailNeedsVerification:
        'Før du kan logge ind første gang, skal vi bekræfte din e-mailadresse. Indtast den engangskode, du har modtaget i din e-mail.',
      emailVerified: 'Din e-mailadresse er blevet bekræftet, og du kan nu logge ind.',
      signUp: 'Registrer dig',
    },
    cancelBooking: {
      info: 'Afbestillingsgebyret varierer afhængigt af billettypen og tidspunktet for afbestilling.',
     description: 'Eventuel refusion beregnes og udbetales i overensstemmelse med vilkårene, der blev angivet ved bestilling.  ',
     readMore: ' Læs mere om vores afbestillingsvilkår her.',
      cost: 'Afbestilling af denne rejse koster',
      disclaimer: 'Denne handling kan ikke gøres om.',
      dontCancel: 'Jeg vil ikke annulere',
      prompt: 'Er du sikker på, at du vil annullere din rejse?',
      title: 'Annuller bestilling',
      notify: {
        loading: 'Afbestilling i gang...',
        success: 'Afbestilling udført!',
        failure: 'Afbestilling mislykkedes!',
      },
    },
    requestBookingModification: {
      button: 'Ændring af rejsen',
      header: 'Ændring af rejsen',
      content:
        'Vælg at blive kontaktet for at booke rejsen igen, eller modtag en kupon, der kan bruges på fremtidige ture.',
      reArrange: 'Booke rejsen igen',
      valueCheck: 'Kupon',
      success: 'Besked sendt, du vil snart høre fra os.',
      error: 'Beskeden blev ikke sendt på grund af en fejl. Prøv igen senere, eller kontakt kundeservice.',
    },
    extras: {
      button: 'Vis ekstraprodukter',
      noExtras: 'Du har endnu ikke bestilt yderligere produkter',
      currencyInfo: 'Den viste valuta er den, du har bestilt din rejse med.',
      cabin: {
        title: 'Kahytter',
        title_seat: 'Sæder',
        loungeError: 'Loungen er ikke tilgængelig for det valgte antal passagerer.',
        booked:
          'Reserveret: $t(component.extras.cabin.qty, { \'count\': {{count}} }) med $t(component.extras.cabin.specQty, { \'count\': {{specQty}} })',
        booked_plural:
          'Reserveret: $t(component.extras.cabin.qty, { \'count\': {{count}} }) med $t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) hver',
        booked_noseats: '$t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) uden specificeret sæde',
        qty: '{{count}} kahyt',
        qty_plural: '{{count}} kahytter',
        specQty: '{{count}} passager',
        specQty_plural: '{{count}} passagerer',
        spec: CarRes.cabinSpec,
        changeCabin: 'Ændring af kahyttype',
        chooseCabins: 'Se alternativer',
        searchingForCabins: 'Søger efter tilgængelige kahytter',
        passengersToPlace_plural: 'Du skal placere {{count}} passagerer, før du kan fortsætte.',
        passengersToPlace_singular: 'Du skal placere {{count}} passager, før du kan fortsætte.',
        addCabin: 'Ændre/tilføje kabine',
        addSeat: 'Tilføj sæde',
        selectedCabins: 'Nuværende ordre:',
        newCabins: 'Ny ordre:',
        removeCabin: 'Fjern',
        passengersToPlaceModal: 'Du skal placere {{count}} passagerer',
        totalPrice: 'Samlet pris',
        allPassengersPlaced: 'Alle passagerer er placeret!',
        lowerPriceWarning: 'De hytter, du har valgt, koster mindre end din tidligere bestilling af hytter.',
        window: 'Vindue',
        persons: 'Personer',
        price: 'Pris',
        crib: 'Plads til barneseng: ',
        goToBasketToast: 'Alle passagerer er placeret. Gå til kurv',
        cabinOutbound_basket: 'Udgående kahytter',
        cabinInbound_basket: 'Indgående kahytter',
        changeCabin_basket: 'Klik her for at ændre kahytt.',
        addMissingCabin_basket: 'Tilføj manglende kahytt..',
        allPassengersMustBePlaced_basket: 'Alle passagerer skal være placeret, før du kan gennemføre ordren.',
        noAvailability: 'Der er ingen ledige kahytter på denne rejse.',
        oldCabins_basket: 'Hytter, der vil blive fjernet:',
        upgradeCabin: 'Opgradering af kabine',
        seeAll: 'Se alle kahytter/sæder',
        quantityInCabin: 'Antal passagerer',
        priceUpgrade: 'Pris opgradering',
        feedback: {
          toast: {
            minReq: 'Du skal vælge mindst 1 person for at tilføje denne kahyt.',
            minOrd: 'Denne kahyt har en minimumsbestilling på {{minOrd}} personer. Du har endnu {{passengersYetToPlace}} personer tilbage at placere.',
          },
          deleteBeforeAdd: 'Beklager, alle passagerer har allerede fået tildelt en placering. Hvis du vil ændre placeringen, skal du først fjerne nogle passagerer fra det valgte kahyt- eller sædevalg.',
          missingPassengersPlaced: 'Du har placeret {{passengersPlaced}} af {{passengersToPlace}} passagerer. Alle passagerer skal placeres for at kunne gå til indkøbskurven.',
          newPriceIsLessThanExistingPrice: 'De valgte kahytter koster mindre end de allerede bestilte. Vælg venligst andre eller flere kahytter, så de opgraderede kahytter overstiger prisen på de allerede bestilte.',
          allPassengersPlaced: 'Alle passagerer er placeret!',
          basket: {
            allPassengersPlacedError: 'Ikke alle passagerer fra {{errorType}} er placeret. Tilføj venligst de manglende kahytter/sæder for at fortsætte til betaling, eller fjern dem fra indkøbskurven.',
            goToCabins: 'Gå til kahytter',
            missingCabins: 'Manglende kahytter/sæder',
            removeCabins: 'Fjern kahytter',
            existingRemoved: 'Fjernet'
          }
        }
      },
      common: {
        arrival: 'Returreise',
        count: 'Antall',
        departure: 'Afgang',
        readMore: 'Læs mere',
        deleteHeader: {
          remove: 'Remove',
          itemGeneral: '$t(component.extras.common.deleteHeader.remove) produktet',
          cabins: '$t(component.extras.common.deleteHeader.remove) kabinen',
          extrasAshore: '$t(component.extras.common.deleteHeader.remove) ekstra på land',
          extrasOnboardInbound: '$t(component.extras.deleteHeader.common.remove) ekstra extra on board',
          extrasOnboardOutbound: '$t(component.extras.deleteHeader.common.remove) ekstra extra on board',
          meals: '$t(component.extras.common.deleteHeader.remove) måltid',
          meetingRooms: '$t(component.extras.common.deleteHeader.remove) møderum',
        },
        deleteItem: {
          itemGeneral: 'dette produkt',
          cabins: 'denne kabine',
          extrasAshore: 'dette ekstra produkt',
          extrasOnboardInbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          extrasOnboardOutbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          meals: 'dette måltid',
          meetingRooms: 'dette mødelokale',
          deleteItemQuestion:
            'Vil du slette $t(component.extras.common.deleteItem.{{extraCategory}}) fra indkøbskurven?',
        },
      },
      taxesAndFees: {
        headline: 'Skatter og afgifter',
        envTax: 'EU-miljøafgift',
      },
      meal: {
        add: 'Tilføj måltider',
        arrival: 'Returrejse',
        count: 'Antall',
        departure: 'Afgang',
        yourMeals: 'Your Meals',
        selectDay: 'Vælg dag',
        existingOrder: 'Bestilt og betalt',
        title: 'Måltider',
        time: 'Reservationstid: {{time}}',
        totalPrice: 'Totalpris: {{amount}}',
        spec: CarRes.mealSpec,
        spec_plural: CarRes.mealSpec,
        qty: 'Antal reservationer: {{count}} Passager',
        qty_plural: 'Antal reservationer: {{count}} Passagerer',
        nomeals: 'Ingen registrerede reservationer',
        chooseTime: 'Vælg tid',
        meal: 'måltid',
        meals: 'måltider',
        fromPrice: 'Fra',
        buttonLabelCount: 'Vælg antal',
        noDetailsAvailable: 'Ingen detaljer tilgængelige i øjeblikket',
        labelTime: 'Vælg tid',
        searchingForMeals: 'Søger efter tilgængelige måltider',
        deleteToastMessage: '{{mealName}} fjernet fra handlekurven',
        AddOneToastMessage: '{{specName}} tilføjet',
        RemoveOneToastMessage: '{{specName}} fjernet',
        loadingMeals: 'Indlæser tilgængelige måltider',
        inTotal: 'Totalt',
        noMealsAvailable: 'Ingen måltider tilgængelige ',
        adult: 'voksen',
        adults: 'voksne',
        child: 'børn',
        baby: 'baby',
        infant: 'spædbarn',
        sixcour: '6-retters',
        fivecour: '5-retters',
        fourcour: '4-retters',
        threecour: '3-retters',
        discount: 'Bestil nu og få 20% rabat på alle måltider',
      },
      basket: {
        conditions: {
          ingress: 'Jeg har læst og forstået',
          terms: 'betingelserne',
          force: 'Accepter venligst betingelserne',
        },
        added: 'Tilføjet',
        sumMeal: 'Sum måltid',
        totalToPay: 'Total til betaling',
        completeOrder: 'Komplet ordre',
        cancellingOrder: 'Indkøbskurven er ved at blive tømt...',
        availableSoon: 'kommer snart...',
        receipt: 'Kvittering',
        thankYou: 'Tak for din ordre',
        payed: 'Betalt',
        remainsToPay: 'Rester til at betale',
        timeoutError: 'Noget gik galt. Prøv igen',
        processingOrder: 'Ordren er ved at blive behandlet. Vent venligst...',
        deleteOrder: 'Slet ordre og start forfra',
        retry: 'Genoptag',
        orderCancelled: 'Indkøbskurven annulleret',
        cancelOrder: 'Annuller indkøbskurven',
        paymentFailed: 'Betalingen kunne ikke gennemføres.',
        basketInfoErrorMessage: 'Beklager, der gik noget galt med betalingen.',
        paymentErrorMessage:
          'Det er sandsynligt, at der allerede er foretaget betaling for disse poster. Gå tilbage til Mine rejser og tjek rejsens status.',
        goToPayment: 'Gå til betaling',
        orderIsReady: 'Din ordre er klar',
        showOrderAndPay: 'Vis Indkøbskurven og betal',
        empty: 'Indkøbskurven er tom...',
        alreadyReservedInCart_description: 'Du skal fjerne eller betale for reserverede varer, før du kan tilføje flere varer. Du kan vælge at fortsætte til betaling, tømme kurven eller se produkter i kurven',
        emptyCart: 'Tøm kurv',
        alreadyReservedInCart: 'Du har allerede reserverede varer i kurven',
        seeCart: 'Se kurv',

      },
      misc: {
        title: 'Forskellige',
        other: 'Andet',
        qty: '{{count}} stk.',
        tickets: 'Se billetter',
        onShoreTickets: '{{count}} billetter',
        loadingExtraOnboard: 'Afhenter tilgængelige yderligere produkter ombord',
        loadingExtraAshore: 'Afhenter tilgængelige yderligere produkter ombord',
        noExtrasAvailable: 'Der er ingen tilgængelige yderligere produkter til din rejse',
        noExtrasAvailableOnboard: 'Der er ingen tilgængelige yderligere produkter ombord til din rejse',
        noExtrasAvailableAshore: 'Der er ingen tilgængelige yderligere produkter i land til din rejse',
        ashoreTitle: 'Ekstra i land',
        onboardTitle: 'Ekstra ombord',
      },
      ashore: {
        title: 'Indkvartering i land',
        booked: 'Reserveret: $t(component.extras.ashore.qty, { \'count\': {{count}} })',
        qty: '{{count}} ',
        qty_plural: '{{count}} ',
        checkin: 'Check-in',
        checkout: 'Tjek ud',
        roomType: 'Værelser',
      },
    },
    fjordClub: {
      header: 'Fjord Club',
      registered :'Dit Fjord Club-medlemskab er registreret',
      updated : 'Dit Fjord Club-medlemskab er blevet opdateret',
      unsavedChanges: 'Du har ikke gemte ændringer. Vil du gerne gemme dine ændringer?',
      terms: {
        link: 'https://www.fjordline.com/da/p/fjord-club/vilkar-og-betingelser',
        view: 'Se betingelser',
      },
      consent:
        'Jeg accepterer Fjord Clubs vilkår og betingelser, herunder at modtage e-mail fra Fjord Line, og at Fjord Line gemmer mine person- og købsoplysninger.',
      description:
        'Fjord Club er vores måde at værdsætte dem, der synes om at rejse med Fjord Line. Deltag gratis, og du kan optjene point på dine rejser og modtage eksklusive tilbud og rabatter.',
      delay:
        'BEMÆRK: Når du har oprettet Fjord Club-profilen, kan det tage op til et døgn, før profilen automatisk hentes, når du logger ind. Profilen kan altid hentes manuelt ved at udfylde de påkrævede felter og indsende formularen igen.',
      join: 'Bliv medlem af Fjord Club!',
      join_description: 'Fjord Club er vores måde at værdsætte dig, der rejser med Fjord Line. Bliv medlem gratis og optjen point på dine rejser samt modtag eksklusive tilbud og rabatter.',
      minimumAge: 'Du skal være 18 år for at blive medlem af Fjord Club.',
      joinBannerMessage: 'Altid fordele med Fjord Club - Bliv medlem gratis!',
      fcMemberCreateFailed: 'Vi oplever midlertidige problemer med oprettelsen af medlemskabet. Prøv igen senere, eller kontakt vores kundeservice for hjælp med vedvarende problemer.',
      fcMemberUpdateFailed: 'Det ser ud som om, vi i øjeblikket oplever problemer med at opdatere medlemskaber. Prøv igen senere eller kontakt kundeservice, hvis problemet fortsætter.',
      mustVerifyMobileFeedback: 'Du skal bekræfte dit mobiltelefonnummer for at gemme.',
      manualVerificationMessage: 'Dit telefonnummer kan ikke automatisk modtage en bekræftelseskode. Kontakt kundeservice for at bekræfte dit telefonnummer. Kundeservice vil kunne oprette en kode til dig, som du indtaster i feltet nedenfor.',
      contactCustomerService: 'Kontakt kundeservice',
    },
    boardingCards: {
      button: 'Åbne boardingkort',
      header: 'Boardingkort',
      messageNotAvailable:
        'Boardingkort er tilgængelige {{hoursBeforeJourneyStartToShowBoardingCard}} timer før afgang',
      messageNotAvailableTitle: 'BEMÆRK!',
      departure: 'afgang',
      arrival: 'returrejse',
      switchTo: 'Skift til {{journey}}',
    },
    footer: {
      contactUs: 'Kontakt os',
      followUs: 'Følg os',
    },
    editVehicles: {
      updated: 'Registreringsnummeret er opdateret!',
      error: 'Noget gik galt. Kunne ikke opdatere. Prøv igen senere, eller kontakt kundeservice, hvis problemet fortsætter.'
    },
    editPassengers: {
      updated: 'Passageroplysninger er blevet opdateret!'
    },

  },
  metadata: {
    title: {
      booking: 'Rejse {{bookingCode}}',
      bookings: 'Mine rejser',
      extras: 'Ekstraprodukter {{bookingCode}}',
      login: 'Min Side Login',
      fjordClub: 'Fjord Club',
      accommodation: 'Indkvartering',
      default: 'Min side - Fjord Line',
    },
    traveller: {
      ADL: 'Voksne 16+',
      CHD: 'Børn 4-15 år',
      INF: 'Spædbørn',
      BABY: 'Baby 0-11 måneder',
    },
  },
  navbar: {
    bookings: '$t(metadata.title.bookings)',
    fjordClub: '$t(metadata.title.fjordClub)',
    login: '$t(component.auth.login)',
    logout: '$t(component.auth.logout)',
    menu: 'Menu',
  },
  addModal: {
    extrasOnboard: 'Ekstra ombord',
    extrasAshore: 'Ekstra i land',
  },
  error: {
    reset: 'Gå tilbage til Min Side',
    timeout: {
      title: 'Intet svar fra serveren',
      message:
        'Det ser ud til, at det tager for lang tid at få et svar fra serveren. Dette kan enten skyldes en dårlig forbindelse eller en fejl med vores servere. Prøv at genindlæse siden på et senere tidspunkt.',
    },
    authorization: 'Autoriseringsfejl',
    authTimeOut: 'Godkendelsesproceduren tog for lang tid',
    booking: {
      cancellationFailed: 'Kunne ikke afbestille rejsen',
      getFailed: 'Kunne ikke hente rejsen',
      updateFailed: 'Kunne ikke opdatere rejsen',
      cancelled: 'Rejsen er afbestilt',
      notFound: 'Kunne ikke finde rejsen',
      pdfFailed: 'Kunne ikke hente rejsebekræftelse',
      resendPdfFailed: 'Kunne ikke sende rejsebekræftelse',
      regnumFailed: 'Kunne ikke opdatere registreringsnummer',
      travelerFailed: 'Kunne ikke opdatere passageroplysninger',
      invalid: 'Den rejse, du prøver at hente, er ugyldig.',
      connectionError: 'Kunne ikke hente ordrer. Prøv at genindlæse',
    },
    customer: {
      notFound: 'Kunne ikke finde profilen',
      getFailed: 'Kunne ikke hente profilen',
      createFailed: 'Kunne ikke oprette profilen',
      createAlreadyExists: 'Profilen findes allerede',
      updateFailed: 'Kunne ikke opdatere profilen',
      travelFriends: {
        addFailed: 'Kunne ikke tilføje rejseven',
        updateFailed: 'Kunne ikke opdatere rejseven',
        deleteFailed: 'Kunne ikke slette rejseven',
      },
    },
    notify: {
      loading: 'Ændring igang...',
      success: 'Ændring foretaget!',
      failure: 'Ændring mislykkes!',
    },
    response:
      'Serveren svarer ikke. \n Sørg for, at du har en netværksforbindelse og indlæs siden på ny for at prøve igen. ',
    generic: 'Noget gik galt...',
    unchanged: 'Formularen indeholdt ingen ændringer',
    unknown: 'Ukend fejl',
    gofish: 'Stik til søs!',
    basket: {
      generic: 'Noget gik galt... Kunne ikke fuldføre betalingen. Prøv igen senere',
      or: 'eller',
      contactCustomerService: 'kontakt kundeservice, hvis problemet fortsætter.',
      rollback: 'Kunne ikke tømme indkøbskurven. Prøv igen, eller kontakt kundeservice, hvis problemet fortsætter.',
      refused:
      'Betalingen blev afvist. Tjek kortoplysningerne, og prøv igen, eller kontakt os, hvis problemet fortsætter.',
      help: 'Har du brug for hjælp? Kontakt kundeservice her',
      refused_headline: 'Betalingen blev afvist',

    }
  },
  label: {
    allFieldsRequired: '* Alle felter skal udfyldes',
    booking: {
      header: 'Mine rejser',
      code: 'Bookingnummer',
      resendPDF: 'Send rejsedokumenter via e -mail',
      resendFailed: 'Forsendelse mislykkedes',
      noBookings: 'Du har ingen kommende rejse.',
      activeBooking_plural: 'Du har {{count}} aktive rejser',
      upcomingBooking_plural: 'Du har {{count}} kommende rejser',
      noActiveBookings: 'Du har ingen aktive rejser.',
      activeBooking: 'Du har en aktiv rejse',
      upcomingBooking: 'Du har en kommende rejse',
      makeBooking: 'Book din næste rejse i dag',
      makeNewBooking: 'Book en ny rejse',
      active: 'Aktiv',
      future: 'Fremtidig',
      cancelled: 'Afbestilt',
      unavailable: 'Utilgængelig',
      pastBooking: 'Rejse gennemført',
      showCancelled: 'Vis afbestilte',
      showPassed: 'Se tidligere ture',
      hidePassed: 'Skjul tidligere reiser',
      hideCancelled: 'Skjul afbestilte',
      cancelValidTime: 'Du kan afbestille rejsen op til 24 timer før afgang',
      paymentDone: 'Betaling gennemført',
      paymentMessage: 'Tak for dit køb! De produkter, du har købt, vil være synlige i din bookingoversigt.',
      basketInfo: {
        yourBasket: 'Din indkøbskurv',
        info: 'Gå til indkøbskurven for at fuldføre eller slette ændringerne på turen',
        toPay: 'At betale: ',
      },
    },
    boolean: {
      false: 'Nej',
      true: 'Ja',
    },
    button: {
      add: 'Tilføj',
      addMore: 'Tilføj mere',
      addMoreItems: 'Føj varer til turen',
      noItems: 'Ingen varer tilgængelige på denne rejse',
      addMoreExtraOnboard: 'Føj til ekstra ombord',
      addMoreExtraAshore: 'Føj til ekstra i land',
      back: 'Tilbage',
      cancel: 'Afbryd',
      close: 'Luk',
      closeAlert: 'Luk advarsel',
      confirm: 'Bekræft',
      delete: 'Slet',
      deselectAll: 'Fjern alt',
      edit: 'Ændre',
      gotoBasket: 'Gå til indkøbskurv',
      join: 'Bliv medlem gratis!',
      loading: 'Loading...',
      moreInfo: 'Åbne rejsedetaljer',
      resetChanges: 'Nulstil endringer',
      save: 'Gem',
      saveChanges: 'Gem ændringer',
      selectAll: 'Vælg alt',
      submit: 'Indsend',
      show: 'Vise',
      ACTIVE_AND_FUTURE: 'Active and future',
      CANCELLED: 'Aflyst ',
      PASSED: 'Bestået ',
      FUTURE: 'Kommende ',
      ACTIVE: 'Aktiv ',
      bookingOverview: 'Gå til rejseoversigt',
      downloadPDF: 'Download rejsebekræftelse (PDF)',
    },
    country: CarRes.country,
    currency: {
      NOK: '{{amount}} NOK',
      DKK: '{{amount}} DKK',
      EUR: '€{{amount}}',
    },
    date: {
      day: 'Dag',
      month: 'Måned',
      month_1: 'Januar',
      month_2: 'Februar',
      month_3: 'Marts',
      month_4: 'April',
      month_5: 'Maj',
      month_6: 'Juni',
      month_7: 'Juli',
      month_8: 'August',
      month_9: 'September',
      month_10: 'Oktober',
      month_11: 'November',
      month_12: 'December',
      year: 'År',
    },
    gender: {
      F: 'Kvinde',
      M: 'Mand',
    },
    journey: {
      arrival: 'Ankomst',
      arrivalJourney: 'Returrejse',
      arrivalPort: 'Destinationshavn',
      arrivalTime: 'Ankomsttid',
      departure: 'Afgang',
      departureJourney: 'Afrejse',
      departurePort: 'Afgangshavn',
      departureTime: 'Afgangstid',
      product: 'Billettype',
      stretch: 'Strækning',
    },
    customer: {
      allowContact: 'Tillad at blive kontaktet',
      countryCode: 'Landekode',
      currency: 'Valuta',
      customerCode: 'Kunde ID',
      customerGroup: 'Dine interesser',
      language: 'Sprog',
      name: 'Navn',
      travelFriend: 'Rejseven',
      consents: {
        header: 'Samtykker',
        pretext:
          'Jeg accepterer Fjord Clubs vilkår og betingelser og accepterer, at Fjord Line opbevarer mine oplysninger til følgende formål:',
        posttext:
          'Fjord Line bruger kun oplysningerne til at give dig en bedre oplevelse, og de deles ikke med tredjepart.',
        EMAIL: 'Sende e-mails, brugerprofilering, købsoplysninger',
        MAIL: 'Sende post',
        SMS: 'Sende SMS',
      },
    },
    membership: {
      header: 'Medlemsoplysninger',
      memberNumber: 'Medlemsnummer',
      membershipLevel: 'medlemsniveau',
      memberSince: 'Medlem siden',
      loyaltyPointsEarned: 'Samlede optjente point',
      loyaltyPointsBalance: 'Point',
      loyaltyPointsSoonExpiring: 'Point, der snart udløber',
    },
    signedUp:  {
     goToMyTravels: 'Gå til Mine Rejser',
      seeMembership : 'Se medlemskab',
    },
    personalia: {
      code: 'kode',
      dateOfBirth: 'Fødselsdato',
      personalData: 'Personoplysninger',
      email: 'E-mail',
      firstName: 'Fornavn',
      gender: 'Køn',
      header: 'Personoplysninger',
      lastName: 'Efternavn',
      mobile: 'Mobilnummer',
      mobileWord: 'tekstbesked',
      receiveNewVerificationCode: 'Modtag ny verifikationskode',
      verificationCode: 'Bekræftelseskode',
      verificationCompleted: '{{field}} er bekræftet',
      verificationModalMessage: 'Indtast bekræftelseskode modtaget i {{field}}.',
      verificationRequired: 'Ændret {{field}} skal bekræftes',
      verify: 'Bekræft {{value}}',
      verifyErrorMessage: 'Indtastet kode kan ikke bekræftes',
      tpoCannotSendCodeMessage: 'Kunne ikke sende engangskode. Prøv igen.',
    },
    address: {
      header: 'Adresse',
      address: 'Adresse',
      zip: 'Postnummer',
      city: 'By',
      country: 'Land',
      nationality: 'Nationalitet',
    },
    traveler: {
      edit: 'Ændre rejsende',
      header: 'Rejsende',
      guestType: 'Billettype',
      disabledPerson: 'Handicappet / har brug for hjælp',
      passportNumber: 'ID-nummer',
    },
    travelFriend: {
      tempDisabled: 'Rejseven blir snart tilgængelig',
      header: 'Mine rejsevenner',
      add: 'Tilføj rejseven',
      delete: 'Slet rejseven',
      edit: 'Ændre rejseven',
      find: 'Eksisterende medlem',
      fill: 'Udfyld oplysninger',
      new: 'Ny rejseven',
      notEditable: 'Denne rejseven er medlem af Fjord Club og kan ikke ændre.',
    },
    unknown: 'Ukend',
    vehicle: {
      edit: 'Ændre køretøj',
      header: 'Køretøj',
      category: 'Biltype',
      length: 'Længde',
      length_upto: 'Op til {{length}} m lang',
      regNum: 'Registreringsnummer',
      code: {
        vehicle195: 'Køretøj op til 1,95 m høj',
        vehicle220: 'Køretøj op til 2,20 m høj',
        vehicle235: 'Køretøj op til 2,35 m høj',
        vehicle450: 'Køretøj op til 4,50 m høj',
        bik: 'Cykel',
        mc: 'Motorcykel',
        mcs: 'Motorcykel med sidevogn',
      }
    },
    preferences: {
      header: 'Rejse',
      portOfDeparture: 'Foretrukne afgangshavn',
    },
    tickets: {
      header: 'Billetter',
      sendToEmail: 'Send til e-mail',
      departureDate: 'Afgangsdato:',
      resentTickets: 'Billetter er blevet sendt til din e -mail',
      resendTicketsFailed: 'Vi beklager, men vi kan ikke sende dine billetter igen på nuværende tidspunkt. Prøv venligst igen senere. Hvis problemet fortsætter, bedes du kontakte kundeservice for assistance.',
      resendTimeout:'Kan sendes igen på {{seconds}} sekunder',
      sendToEmailAgain: 'Send igen',
      ticketError: 'Vi beklager, men vi kan ikke hente dine billetter i øjeblikket. Prøv venligst igen senere. Hvis problemet fortsætter, bedes du kontakte kundeservice for assistance.',
      ticketError_delay: 'Bemærk, at hvis bestillingen lige er foretaget, kan det tage nogle minutter, før billetterne er tilgængelige på denne side.',

      barcode: {
        alt: 'barcode',
      },
    },
  },
  placeholder: {
    personalia: {
      firstName: 'Dit fornavn',
      lastName: 'Dit efternavn',
      gender: 'Velg køn',
      email: 'Din email adresse',
      mobile: 'Dit telefonnummer',
      mobileNoCC: 'Dit telefonnummer',
    },
    address: {
      address: 'Din adresse',
      zip: 'Dit postnummer',
      city: 'Din hjemby',
      country: 'Vælg land',
      nationality: 'Vælg nationalitet',
    },
    traveler: {
      passportNumber: 'Dit pasnummer',
    },
    vehicle: {
      regNum: 'Dit registreringsnummer',
    },
    preferences: {
      portOfDeparture: 'Vælg din foretrukne afgangshavn',
    },
  },
  validation: {
    isRequired: 'Dette felt er påkrævet',
    memberNumber: 'Medlemskabsnummer skal være 7 eller 10 cifre',
    regNumber: 'Registreringsnummer skal være mindst 2',
    isAlphabetic: 'Dette felt skal kun indeholde bogstaver',
    isAlphanumeric: 'Dette felt skal kun indeholde bogstaver og tal',
    isDate: 'Den valgte dato er ikke gyldig',
    isDateInFuture: 'Den valgte dato er ikke en gyldig fødselsdato',
    isGuestType: 'Fødselsdato stemmer ikke overens med billettens aldersbegrænsninger',
    isEmail: 'Dette er ikke en gyldig e-mail-adresse',
    isMobile: 'Dette er ikke et gyldigt telefonnummer',
    isCountryCode: 'Dette er ikke en gyldig landekode',
    isOneTimeCode: 'Dette er ikke en gyldig engangskode',
    isOwnCustomerCode: 'Kan ikke bruge dit eget kunde-ID',
    isNumeric: 'Dette felt må kun indeholde tal',
    isPostalCode: 'Dette er ikke et gyldigt postnummer',
    hasDigits: 'Dette felt må ikke indeholde tal',
    hasLetters: 'Dette felt må ikke indeholde bogstaver',
    hasCountryCode: 'Landekode er påkrævet (+45)',
    cannotChangeUserNameMessage: 'Feltet kan ikke ændres, fordi dette er dit brugernavn, når du logger ind',
    missingRegNum: 'Registreringsnummeret mangler for dette køretøj',
    isRegnumber: 'Dette er ikke et gyldigt registreringsnummer',


  },
  ports: {
    LA: 'Langesund',
    B: 'Bergen',
    HH: 'Hirtshals',
    S: 'Stavanger',
    SS: 'Strømstad',
    K: 'Kristiansand',
    SA: 'Sandefjord',
    ST: 'Strömstad',
    ALB: 'Aalborg'
  },
  stepperInputWrapper: {
    selectValidValue: 'Skal være imellem',
    validValuesFrom: 'Mængde fra {{from}} til {{to}}',
    validValuesTo: ' til {{to}}',
  },
  payment: {
    partlyPaid: 'Delvist betalt',
    partlyPaid_description: '',
    fullyPaid: 'Betaling gennemført',
    fullyPaid_description: '',
    declined: 'Betaling afvist',
  },
  icons: {
    seaView: 'Havudsigt',
    shower: 'Bruser',
    sofaBed: 'Sovesofa',
    roomForCrib: 'Plads til tremmeseng',
    doubleBed: 'Dobbeltseng',
  },

  payWithFjordClubPoints: {
    headline: 'Betal med Fjord Club poeng',
    ingress: 'Du har {{points}} point. Du kan bruge disse point til at betale for din rejse.',
    readMore: 'Læs mere om denne tur',
    bookNow: 'Book nu',
    navBar: 'Fjord Club rejser',
    from: 'Fra',
    EuTaxLabel: 'per person + EU-skat'
  },
  modificationDeadline: {
    seeWhatCloses: 'Se hvad der udløber',
    timerText: 'Ændringsfristen for denne rejse udløber om ',
    modalHeadline: 'Ændringsmuligheder der udløber',
    deadlineIsPastModal: 'Ændringsfristen for denne rejse er udløbet.',
  }


}
