import { useTranslation } from 'react-i18next'
import { fareCodes } from '@fjordline/booking-draft'
import { DynamicList } from '@fjordline/styles-v3'

import { createBirthdayLists } from '../../../../../fjordClub/editMemberShip/createBirthdayLists'

import { useCreateAdultBirthdayList } from './AdultBirthdayList'
import { useCreateBabyBirthdayList } from './BabyBirthdayList'
import { useCreateChildBirthdayList } from './ChildBirthdayList'
import { useCreateInfantBirthdayList } from './InfantBirthdayList'

function useCreateDynamicDates(passengerType: fareCodes, existingTraveler) {
  const { adultYearList, daysList, infantYearList, babyYearList, childYearList, months } = createBirthdayLists()
  const { adultDays, adultMonths } = useCreateAdultBirthdayList(adultYearList, months, daysList, existingTraveler)
  const { infantDays, infantMonths } = useCreateInfantBirthdayList(infantYearList, months, daysList, existingTraveler)
  const { childDays, childMonths } = useCreateChildBirthdayList(childYearList, months, daysList, existingTraveler)
  const { babyDays, babyMonths } = useCreateBabyBirthdayList(babyYearList, months, daysList, existingTraveler)

  const { t } = useTranslation()

  let passengerTypeTranslation
  let dynamicYearList: DynamicList<number>[] = adultYearList
  let dynamicDaysList: DynamicList<number>[] = daysList
  let dynamicMonthsList: DynamicList<string>[] = months

  switch (passengerType) {
    case fareCodes.ADULT:
      passengerTypeTranslation = t('pages.journey.traveller.ADULT')
      dynamicYearList = adultYearList
      dynamicDaysList = adultDays
      dynamicMonthsList = adultMonths

      break
    case fareCodes.CHILD:
      passengerTypeTranslation = t('pages.journey.traveller.CHILD')
      dynamicYearList = childYearList
      dynamicDaysList = childDays
      dynamicMonthsList = childMonths

      break
    case fareCodes.INFANT:
      passengerTypeTranslation = t('pages.journey.traveller.INFANT')
      dynamicYearList = infantYearList
      dynamicDaysList = infantDays
      dynamicMonthsList = infantMonths

      break
    case fareCodes.BABY:
      passengerTypeTranslation = t('pages.journey.traveller.BABY')
      dynamicYearList = babyYearList
      dynamicDaysList = babyDays
      dynamicMonthsList = babyMonths

      break
  }

  return {
    dynamicDaysList,
    dynamicMonthsList,
    dynamicYearList,
    passengerTypeTranslation,
  }
}

export default useCreateDynamicDates
