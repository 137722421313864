//Lint rule below is used to avoid prettier from changing quotes in CSP
/* eslint-disable quotes */

//App Base Path
const BASE_PATH = window.BASE_PATH || '/'

//Logging / App Insights
const APP_INSIGHTS_KEY = window.APP_INSIGHTS_KEY || ''

//Eassets (Ex. Legoland)
const EASSETS_API_URL: string =
  window.EASSETS_API_URL || 'https://digidev.fjordline.com/api/easset/external/easset-by-bookingId'
const EASSETS_API_URL_RESEND_TO_EMAIL: string =
  window.EASSETS_API_URL_RESEND_TO_EMAIL || 'https://digidev.fjordline.com/api/easset/external/resend-by-bookingId'
const EASSETS_CODES: string[] = window.EASSETS_CODES || [
  'LEGO',
  'LEGOH',
  'FARUP',
  'DJURS1',
  'SKAGMU',
  'AALZOO',
  'MEALZOO',
]

//Keycloak
const KEYCLOAK_URL: string = window.KEYCLOAK_URL || 'https://kc-test.fjordline.com/'
const KEYCLOAK_REALM: string = window.KEYCLOAK_REALM || 'fjordline'
const KEYCLOAK_CLIENT_ID: string = window.KEYCLOAK_CLIENT_ID || 'mypage-client'
const LOGOUT_URI = window.LOGOUT_URI || 'https://fjordline.com'
const REDIRECT_URI = window.REDIRECT_URI || 'http://localhost:3000/'

//Migration Service
const MIGRATION_SERVICE_URL =
  window.MIGRATION_SERVICE_URL || 'https://digidev.fjordline.com/api/auth-migration/choose_provider'

//CSP (Content Security Policy)
const CSP_DEFAULT_SRC =
  window.CSP_DEFAULT_SRC ||
  "default-src 'self' ws://127.0.0.1:54647/ https://*.fjordline.com/ wss://my-page-stage-sigr.service.signalr.net/ https://my-page-stage-sigr.service.signalr.net https://auth.dev.fjordline.com/ https://dc.services.visualstudio.com/v2/track https://digidev.fjordline.com/"
const CSP_FONT_SRC = window.CSP_FONT_SRC || "font-src 'self' data:"
const CSP_FRAME_SRC =
  window.CSP_FRAME_SRC ||
  'frame-src https://auth.fjordline.com/ auth.digidev.fjordline.com https://api.dev.fjordline.com '
const CSP_IMG_SRC = window.CSP_IMG_SRC || "img-src 'self' https://*.fjordline.com/ data: cdn.sanity.io"
const CSP_OBJECT_SRC = window.CSP_OBJECT_SRC || "object-src 'none'"
const CSP_SCRIPT_SRC = window.CSP_SCRIPT_SRC || "script-src 'self'"
const CSP_STYLE_SRC = window.CSP_STYLE_SRC || "style-src 'self' 'unsafe-inline'"
const CSP_WORKER_SRC = window.CSP_WORKER_SRC || "worker-src 'self' blob:"

//Environment
const ENVIRONMENT = window.ENVIRONMENT || 'DEV'
const HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD = window.HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD || 48
const LOG_DEV = window.LOG_DEV || true

//GQL
const GRAPHQL_URL = window.GRAPHQL_URL || 'https://digidev.fjordline.com/api/graphql/'

//OTP
const OTP_URL = window.OTP_URL || 'https://digidev.fjordline.com/api/totp'

//Timeouts
const VALIDATED_FIELD_TIMEOUT: number = window.VALIDATED_FIELD_TIMEOUT || 600000
const DEFAULT_FL_VERIFY_TIMEOUT: number = window.DEFAULT_FL_VERIFY_TIMEOUT || 60000

//Checkout Cart
const CHECKOUT_CART_URL = window.CHECKOUT_CART_URL || 'https://digidev.fjordline.com/api/fl-cart-mypage'
const WS_FL_CART_URL = window.WS_FL_CART_URL || 'https://digidev.fjordline.com/api/fl-cart-mypage/cart'

//GTM
const GOOGLE_TAG_MANAGER_ID = window.GOOGLE_TAG_MANAGER_ID || 'GTM-MHVSJH3'

//Modify Booking
const MODIFY_BOOKING = window.MODIFY_BOOKING || 'https://digidev.fjordline.com/api/fl-booking-modifier/'

//Sanity
const SANITY_DATASET = window.SANITY_DATASET || 'stage'
const SANITY_PROJECT_ID = window.SANITY_PROJECT_ID || '90kslno9'
const SANITY_API_VERSION = window.SANITY_API_VERSION || '2023-11-14'
const SANITY_USECDN = window.SANITY_USECDN || true

//Feature Toggles
const FEATURE_SHOW_CANCEL_BOOKING = window.FEATURE_SHOW_CANCEL_BOOKING
const SHOW_PAY_WITH_CLUB_POINTS_PAGE = window.SHOW_PAY_WITH_CLUB_POINTS_PAGE || true

//Redirects
const FL_BOOKING_URL = window.FL_BOOKING_URL || 'https://digidev.fjordline.com/booking-v2/'
const OLD_MYPAGE_URL = window.OLD_MYPAGE_URL || 'https://digidev.fjordline.com/minside/'

//CDN Images
const CDN_IMG_LOGIN_BACKGROUND: string =
  window.CDN_IMG_LOGIN_BACKGROUND ||
  'https://cdn.sanity.io/images/90kslno9/stage/3b087d932248c0051994eec67e8bf5d555a59309-1722x1200.jpg?auto=format'
const CDN_IMG_BOOK_NEXT_TRIP: string =
  window.CDN_IMG_BOOK_NEXT_TRIP ||
  'https://cdn.sanity.io/images/90kslno9/stage/28aff3e916d182efc5f8eb9810808ddcd6b9f5a9-2000x1333.jpg?auto=format'
const CDN_IMG_BECOME_MEMBER: string =
  window.CDN_IMG_BECOME_MEMBER ||
  'https://cdn.sanity.io/images/90kslno9/stage/f226bfc7e598dcadc1ec1b4db2a43bd4b1123158-2000x1333.jpg?auto=format'
const CDN_IMG_UPGRADE_CABIN: string =
  window.CDN_IMG_UPGRADE_CABIN ||
  'https://cdn.sanity.io/images/90kslno9/stage/455a42ee7964ebd9efa72a91491092c1251bd265-5465x3644.jpg?auto=format'
const CDN_IMG_UPGRADE_MEAL: string =
  window.CDN_IMG_UPGRADE_MEAL ||
  'https://cdn.sanity.io/images/90kslno9/stage/e4d4e255ef70aa4d51c54e2b3be2b532addaabd3-680x320.png?auto=format'
const CDN_IMG_LOUNGE: string =
  'https://cdn.sanity.io/images/90kslno9/stage/3bc99e1b0966ecef6439d96067a5d3672e75e0c2-1300x867.jpg'

//New Year Cruise Codes
const NEW_YEAR_CRUISE_CODES = ['HCD337', 'HCD297']

export {
  APP_INSIGHTS_KEY,
  LOGOUT_URI,
  REDIRECT_URI,
  BASE_PATH,
  CDN_IMG_BECOME_MEMBER,
  CDN_IMG_BOOK_NEXT_TRIP,
  CDN_IMG_LOGIN_BACKGROUND,
  CDN_IMG_LOUNGE,
  CDN_IMG_UPGRADE_CABIN,
  CDN_IMG_UPGRADE_MEAL,
  CHECKOUT_CART_URL,
  CSP_DEFAULT_SRC,
  CSP_FONT_SRC,
  CSP_FRAME_SRC,
  CSP_IMG_SRC,
  CSP_OBJECT_SRC,
  CSP_SCRIPT_SRC,
  CSP_STYLE_SRC,
  CSP_WORKER_SRC,
  DEFAULT_FL_VERIFY_TIMEOUT,
  EASSETS_API_URL_RESEND_TO_EMAIL,
  EASSETS_API_URL,
  EASSETS_CODES,
  ENVIRONMENT,
  FEATURE_SHOW_CANCEL_BOOKING,
  FL_BOOKING_URL,
  GOOGLE_TAG_MANAGER_ID,
  GRAPHQL_URL,
  HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  LOG_DEV,
  MIGRATION_SERVICE_URL,
  MODIFY_BOOKING,
  OLD_MYPAGE_URL,
  OTP_URL,
  SANITY_API_VERSION,
  SANITY_DATASET,
  SANITY_PROJECT_ID,
  SANITY_USECDN,
  VALIDATED_FIELD_TIMEOUT,
  WS_FL_CART_URL,
  SHOW_PAY_WITH_CLUB_POINTS_PAGE,
  NEW_YEAR_CRUISE_CODES,
}

declare global {
  interface Window {
    GRAPHQL_URL: string
    LOGOUT_URI: string
    REDIRECT_URI: string
    BASE_PATH: string
    CSP_DEFAULT_SRC: string
    CSP_FONT_SRC: string
    CSP_FRAME_SRC: string
    CSP_IMG_SRC: string
    CSP_OBJECT_SRC: string
    CSP_SCRIPT_SRC: string
    CSP_STYLE_SRC: string
    CSP_WORKER_SRC: string
    ENVIRONMENT: string
    FL_BOOKING_URL: string
    GOOGLE_TAG_MANAGER_ID: string
    HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD: number
    LOG_DEV: boolean
    WS_FL_CART_URL: string
    VALIDATED_FIELD_TIMEOUT: number
    DEFAULT_FL_VERIFY_TIMEOUT: number
    CDN_IMG_LOGIN_BACKGROUND: string
    CDN_IMG_BOOK_NEXT_TRIP: string
    CDN_IMG_BECOME_MEMBER: string
    CDN_IMG_UPGRADE_CABIN: string
    CDN_IMG_UPGRADE_MEAL: string
    MODIFY_BOOKING: string
    CHECKOUT_CART_URL: string
    FEATURE_SHOW_CANCEL_BOOKING: boolean
    APP_INSIGHTS_KEY: string
    OLD_MYPAGE_URL: string
    OTP_URL: string
    SANITY_DATASET: string
    SANITY_PROJECT_ID: string
    SANITY_API_VERSION: string
    SANITY_USECDN: boolean
    EASSETS_API_URL: string
    EASSETS_API_URL_RESEND_TO_EMAIL: string
    MIGRATION_SERVICE_URL: string
    KEYCLOAK_URL: string
    KEYCLOAK_REALM: string
    KEYCLOAK_CLIENT_ID: string
    EASSSETS_CODES: string[]
    SHOW_PAY_WITH_CLUB_POINTS_PAGE: boolean
  }
}
