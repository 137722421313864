import { routerRootPaths } from '../appRoutes/routerPaths'

import { paramKeys } from './types'

export default function getNavLink({
  bookingCode,
  subPath,
  backTarget,
  backTargetTitle,
}: {
  bookingCode?: string
  subPath?: string
  backTarget?: string
  backTargetTitle?: string
}): string {
  const url = `${routerRootPaths.BOOKING_LIST}/${bookingCode}${subPath ? `/${subPath}` : ''}${
    backTargetTitle || backTarget ? '?' : ''
  }`
  const backTargetParam = `${backTarget ? `${paramKeys.BACK_TARGET}=${backTarget}` : ''}`
  const backTargetTitleParam = `${backTarget ? '&' : ''}${
    backTargetTitle ? `${paramKeys.BACK_TARGET_TITLE}=${backTargetTitle}` : ''
  }`
  return bookingCode === undefined ? routerRootPaths.BOOKING_LIST : `${url}${backTargetParam}${backTargetTitleParam}`
}
