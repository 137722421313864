import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import PhonenumberInput, { PhoneInputProps } from 'react-phone-input-2'
import { StyledPhoneInputWrapper, textInputThemes } from './PhoneInput/style'
import { Label } from '@fjordline/styles-v3'
import { TestCyCommonType } from '@fjordline/styles-v3/dist/components/CommonInterface'
import { ITextInputStates } from '@fjordline/styles-v3/dist/types/sharedInputTypes'

export type PhoneInputPropType = {
  label: string
  state: ITextInputStates | 'default'
  value?: string
  country?: string
  onChange: (value: string) => void
  disabled?: boolean
  size?: 'large' | 'medium' | 'small'
} & PhoneInputProps &
  TestCyCommonType

const isValidNumber = /^(\+|00)[0-9 ()-]{1,20}$/
export const PhoneInput = ({
  label,
  'data-cy': testId,
  value = '',
  country = '',
  onChange,
  disabled,
  state = 'default',
  size = 'medium',
  ...props
}: PhoneInputPropType) => {
  const [isValid, setIsValid] = React.useState(state !== 'error')
  const validationState = isValid ? 'default' : 'error'
  const currentState = !state ? validationState : state
  const themeVars = textInputThemes[currentState]

  return (
    <ThemeProvider theme={{ vars: themeVars }}>
      <StyledPhoneInputWrapper data-cy={testId} state={currentState} hasValue={!!value && value !== ''}>
        <Label size={size}>
          {label}
          <PhonenumberInput
            isValid={isValid}
            disabled={disabled}
            autocompleteSearch={false}
            inputProps={{ autoComplete: 'none' }}
            containerClass={`input-state-${state} ${!!value && value !== '' ? 'hasValue' : ''}`}
            buttonClass={`input-state-${state} ${!!value && value !== '' ? 'hasValue' : ''}`}
            onBlur={(_e, selectedCountry) => {
              if (
                isValidNumber.test(`+${value}`) &&
                'countryCode' in selectedCountry &&
                value.startsWith(selectedCountry.dialCode) &&
                state !== 'error'
              ) {
                setIsValid(true)
              } else {
                setIsValid(false)
              }
            }}
            value={value}
            onFocus={() => {
              setIsValid(true)
            }}
            country={country}
            onChange={onChange}
            enableTerritories
            {...props}
          />
        </Label>
      </StyledPhoneInputWrapper>
    </ThemeProvider>
  )
}

PhoneInput.displayName = 'PhoneInput'
export default PhoneInput
