import { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'

import getDeparturePorts from '../../pages/booking/getBookingObjects/getDeparturePorts'
import { BoardingCard, useGetBoardingCardsQuery, useGetBookingQuery } from '../types'
import { trackError } from '../../providers/TelemetryProvider'
import { useKeycloak } from '../../providers/KeycloakProvider'

export type BoardingCardsDetails = {
  boardingCards: BoardingCard[]
  boardingCardsLoading: boolean
  departureDate?: string
  departurePorts: string
  getBoardingCardsError?: ApolloError | undefined
  isActiveOrFuture: boolean
  isPassedOrCancelled: boolean
}

const defaultBoardingCardsDetails: BoardingCardsDetails = {
  boardingCards: [],
  boardingCardsLoading: false,
  departurePorts: '',
  isActiveOrFuture: false,
  isPassedOrCancelled: false,
}

const UseGetBoardingCards: (args: { bookingCode?: string }) => BoardingCardsDetails = ({ bookingCode }) => {
  const { isAuthenticated } = useKeycloak()

  const [boardingCardsDetails, setBoardingCardsDetails] = useState<BoardingCardsDetails>(defaultBoardingCardsDetails)

  const { data: bookingData, loading: bookingLoading, error: bookingError } = useGetBookingQuery({
    variables: { bookingCode: bookingCode ? bookingCode : '' },
    skip: !bookingCode || bookingCode === '',
  })

  const { data, loading, error } = useGetBoardingCardsQuery({
    variables: { bookingCode: bookingCode ? bookingCode : '' },
    skip: !bookingCode || bookingCode === '' || bookingLoading || bookingData?.booking?.showBoardingCards !== true,
  })

  useEffect(() => {
    if (!bookingLoading && bookingError !== undefined) {
      trackError(bookingError, {
        'location': 'UseGetBoardingCards',
        'function': 'useGetBookingQuery',
        'isAuthenticated': isAuthenticated,
        'bookingCode': bookingCode
      })
    }
  }, [bookingLoading, bookingError, isAuthenticated, bookingCode])

  useEffect(() => {
    if (loading) {
      setBoardingCardsDetails({
        ...defaultBoardingCardsDetails,
        boardingCardsLoading: loading,
      })
    }
  }, [loading])

  useEffect(() => {
    if (!loading && error !== undefined) {
      trackError(error, {
        'location': 'UseGetBoardingCards',
        'function': 'useGetBoardingCardsQuery',
        'isAuthenticated': isAuthenticated,
        'bookingCode': bookingCode
      })

      setBoardingCardsDetails({
        ...defaultBoardingCardsDetails,
        getBoardingCardsError: error,
      })
    }
  }, [error, loading, bookingCode, isAuthenticated])

  useEffect(() => {
    if (!loading && error === undefined && data) {
      const boardingCards = data.booking.boardingCards as BoardingCard[]
      const { isOngoing, isInFuture, isInPast, isCancelled } = data.booking
      const isActiveOrFuture: boolean = (isOngoing || isInFuture) ?? false
      const isPassedOrCancelled = isInPast || isCancelled
      const departurePorts = getDeparturePorts({
        arrivalPortName: data.booking?.outbound?.arrivalPortInfo?.portName,
        departurePortName: data.booking?.outbound?.departurePortInfo?.portName,
      })
      setBoardingCardsDetails({
        ...defaultBoardingCardsDetails,
        boardingCards,
        departureDate: data.booking?.outbound?.departureDate,
        isActiveOrFuture,
        isPassedOrCancelled,
        departurePorts,
      })
    }
  }, [data, error, loading])

  return boardingCardsDetails
}

export default UseGetBoardingCards
