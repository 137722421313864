export const isAlphaNumeric = /^([0-9A-Za-zÀ-ý]+[-. ]?)+$/

export const isPostalCode = /^([A-Za-z0-9][A-Za-z0-9\- ]{0,10}[A-Za-z0-9])$/

export const isStreetAddressRegEx = /^[ -ÿ]{0,100}$/

export const isAlphabeticRegEx = /^([A-Za-zÀ-ý]+[- ]?)+$/
export const isAlphabeticNameRegEx = /^([A-Za-zÀ-ý]+[-. ]?)+$/
export const isCurrentlyValidPostalInfoRegEx = /.{0,100}/
export const isEmail = /^\S+@\S+\.\S{2,6}$/
export const isMobile = /^(\+|00)[0-9 ()-]{1,20}$/
export const isNumericRegEx = /^([0-9]+[- ]?)+$/
export const formatName = (value: string) => {
  return value.replace(/\s\s+/g, ' ').replace(/\s[-]/g, '-').replace(/[-]\s/g, '-').replace(/\s[.]/g, '.')
}

export const formatStringRemoveCurrentYear = (value: string) => {
  const currentYear = new Date().getFullYear()
  return value.replace(JSON.stringify(currentYear), '')
}
