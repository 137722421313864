import React, { useEffect } from 'react'
import { fetchImageContainerFromSanity } from '../fetchData'
import { imageUrlFor } from '../imageUrlBuilder'

function useFetchImageContainer(code?: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [imageContainer, setImageContainer] = React.useState<any>(undefined)
  useEffect(() => {
    if (!code) return
    fetchImageContainerFromSanity(code).then((image) => {
      setImageContainer(image?.[0])
    })
  }, [code])
  const image = imageUrlFor(imageContainer?.images?.[0]?.asset._ref)?.url()
  return {
    image,
  }
}

export default useFetchImageContainer

export enum ImageContainerType {
  DOG = 'hundebur',
}
