import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuDialog } from '@fjordline/styles-v3'

import VerifyModalContent from './VerifyMobileOTP/VerifyModalContent'
import { AddVerifiedMobile, AddVerifiedMobileArgs } from './types'

export default function VerifyEmailMenuDialog({
  setShowVerifyEmail,
  mobile,
  addVerifiedMobile,
}: {
  setShowVerifyEmail: React.Dispatch<React.SetStateAction<boolean>>
  mobile: string
  addVerifiedMobile: AddVerifiedMobile
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <MenuDialog
      label={`${t('label.personalia.verify', {
        value: t(`label.personalia.${'mobile'}`).toLowerCase(),
      })}`}
      onRequestClose={() => {
        setShowVerifyEmail(false)
      }}
    >
      <VerifyModalContent
        mobile={mobile}
        onBack={() => {
          setShowVerifyEmail(false)
        }}
        onCodeVerified={({ MOB }: AddVerifiedMobileArgs) => {
          addVerifiedMobile({ MOB })
          setShowVerifyEmail(false)
        }}
      />
    </MenuDialog>
  )
}
