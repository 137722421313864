import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToastDispatcher } from '@fjordline/styles-v3'
import { AxiosError } from 'axios'

import { logDev } from '../../components/LogDev'
import { useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../../providers/WebsocketProvider'
import { getMyPageClient } from '../../rest/clients'
import { CartsByBookingCodes } from '../../providers/availabilityItemsProvider/types'
import { useWebSocketOperationsContext } from '../../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import { trackEvent } from '../../providers/TelemetryProvider'
import { client } from '../../providers/KeycloakProvider'

function useCartRollback() {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { cartData } = useWebSocketContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const { setAddToCartState, setRollbackLoading } = useAvailabilityItemsOperations()
  const { dispatchToast } = useToastDispatcher()
  const { updateCart } = useWebSocketOperationsContext()

  function rollbackCart() {
    trackEvent('user clicked rollback cart')
    setRollbackLoading(true)
    client.updateToken(999999999).then((refreshed) => {
      if (refreshed) {
        getMyPageClient(client.token)
          ?.rollbackCart({ bookingCode: bookingCode || '', id: cartData?.id })
          .then((res) => {
            logDev(res, 'ROLBACK RESPONSE')
            if (res && bookingCode && res?.bookingCarts?.[bookingCode].status === 'ROLLED_BACK') {
              setRollbackLoading(false)
              setAddToCartState(filterRolledBackObjects(res?.bookingCarts))
              sessionStorage.setItem('AddToCartState', JSON.stringify(filterRolledBackObjects(res.bookingCarts)))
              updateCart({
                id: cartData.id,
                bookingCarts: filterRolledBackObjects(res?.bookingCarts),
                timestamp: new Date().getTime(),
              })
              trackEvent('user successfully rolled back cart')
              sessionStorage.setItem(bookingCode, 'refetchBooking')
              navigate(`/bookingList/${bookingCode}`)
              dispatchToast({
                message: t('component.extras.basket.empty'),
                timeout: 5000,
              })
            }
          })
          .catch((err: AxiosError) => {
            setRollbackLoading(false)
            logDev('checkoutCart', err)
            setError(err)
            trackEvent('user failed to rollback cart')
          })
      }
    })
  }
  return {
    rollbackCart,
    rollbackError: error,
  }
}

export default useCartRollback

function filterRolledBackObjects(data) {
  // Create an object to store filtered objects with bookingCode as key
  const filteredData = {} as CartsByBookingCodes

  // Iterate through the keys in the input data
  for (const key in data) {
    // Check if the status property is not 'ROLLED_BACK'
    if (data[key].status !== 'ROLLED_BACK') {
      // Add the object to the filteredData object with bookingCode as key
      filteredData[key] = data[key]
    }
  }

  return filteredData
}
