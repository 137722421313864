import { useEffect, useState } from 'react'

function useHandleTimeoutResendButton() {
  //creata a counter from 30s to 0s when click a button
  const hasSecondsLeft = sessionStorage.getItem('resendTimer') ? JSON.parse(sessionStorage.getItem('resendTimer')!) : 0
  const [seconds, setSeconds] = useState(hasSecondsLeft ?? 30)
  const [timerActive, setTimerActive] = useState(false)

  useEffect(() => {
    if (hasSecondsLeft && !timerActive) {
      setSeconds(hasSecondsLeft)
      setTimerActive(true)

      if (hasSecondsLeft === 0) {
        setTimerActive(false)
        sessionStorage.removeItem('resendTimer')
      }
    }
  }, [hasSecondsLeft, timerActive])

  useEffect(() => {
    let intervalId
    if (timerActive && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
        sessionStorage.setItem('resendTimer', JSON.stringify(seconds))
      }, 1000)
    } else if (seconds === 0) {
      clearInterval(intervalId)
      setTimerActive(false)
      sessionStorage.removeItem('resendTimer')
    }

    return () => clearInterval(intervalId)
  }, [timerActive, seconds])

  const handleStartTimer = () => {
    setSeconds(30)
    setTimerActive(true)
  }

  return {
    handleStartTimer,
    timerActive,
    seconds,
  }
}

export default useHandleTimeoutResendButton
