import React from 'react'

import { FlCustomer } from '../../graphql/types'
import { BookingList } from '../../types/MyPage'
import { GenericAction, GetGenericAction } from '../genericTypes'

import { MyPageActionTypes, MyPagePayload } from './myPageReducer/types'
import { mpCart } from './websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'

/**
 * @description - Gets an action with constraints to MyPage state
 * @param type - a type constraint by MyPageActionTypes
 * @param payload - a payload constraint by MyPagePayload
 */
const getMyPageAction: GetGenericAction<MyPagePayload, MyPageActionTypes> = ({ type, payload }) => ({
  type: type,
  payload: payload,
})

/**
 * @description gets a method that dispatches one or more actions related to Customer
 * @description This implementation is meant as an example
 * @param dispatch - the dispach method from useReducer
 * @param customer - the final payload, in this case customer
 */
export const setCustomerCallback = (
  dispatch: React.Dispatch<GenericAction<MyPagePayload, MyPageActionTypes>>,
  customer: FlCustomer,
) => {
  dispatch(getMyPageAction({ payload: customer, type: MyPageActionTypes.SET_CUSTOMER }))
}

export const setBookingListAction = (bookings: BookingList) =>
  getMyPageAction({
    payload: bookings,
    type: MyPageActionTypes.SET_BOOKINGS,
  })

/**
 * @description - Gets an action for updating Customer in global state
 * @param customer - the full customer object
 */
export const setCustomerAction = (customer: FlCustomer) =>
  getMyPageAction({
    payload: customer,
    type: MyPageActionTypes.SET_CUSTOMER,
  })

/**
 * @description - Gets an action for updating MpCarts in global state
 * @param payload - a list of carts in a key-value list
 */
export const setMpCartsAction = (payload: mpCart) =>
  getMyPageAction({
    payload,
    type: MyPageActionTypes.SET_MP_CARTS,
  })
