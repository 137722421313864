const localStorage = window.localStorage

const baseStorage = {
  getItem: <T>(key: string) => {
    const item = localStorage.getItem(key)
    return item ? (JSON.parse(item) as T) : null
  },
  setItem: <T>(key: string, value: T) => localStorage.setItem(key, JSON.stringify(value)),
  removeItem: (key: string) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
}

export default baseStorage
