import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useToastDispatcher } from '@fjordline/styles-v3'

import UseGetBookingDetails from '../graphql/customerHooks/UseGetBookingDetails'
import { Currency, FlCustomer } from '../graphql/types'
import { myPageAppState } from '../storage/StateStorage'
import { BookingList } from '../types/MyPage'
import { languageTransform } from '../utils/currencyConvert'

import { setBookingListAction, setCustomerAction, setCustomerCallback } from './myPageStateProvider/actions'
import { myPageStateContext, myPageStateOperationContext } from './myPageStateProvider/context'
import { MyPageActionTypes, MyPageOperations, PageDispatchToast } from './myPageStateProvider/myPageReducer/types'
import MyPagerReducer from './myPageStateProvider/reducer'
import CustomerAndBookingListLoader from './myPageStateProvider/websocketProvider/CustomerAndBookingListLoader'
import { ContextChildren } from './genericTypes'
import WebsocketProvider, { extractBookingNumber } from './WebsocketProvider'
import { useKeycloak } from './KeycloakProvider'

/**
 * @description - This provider holds the state for the application using
 * @description - The state is accessible through the useMyPage hook
 * @description - Operations on the state is accessible through the useMyPageOperations hook
 * @description - ------------------------
 * @description - This provider also loads the customer data from the graphql server through GraphQlLoader
 * @description - ------------------------
 * @description - State in myPageContext is immutable and  of type MyPage managed with MyPageReducer
 * @param children - React.ReactNode
 * @constructor
 */
const MyPageStateProvider: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const location = useLocation()
  const bookingNumber = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingNumber || '' })

  const {
    i18n: { language, changeLanguage },
  } = useTranslation()
  const [state, dispatch] = useReducer(MyPagerReducer, myPageAppState.get())
  const { dispatchToast } = useToastDispatcher()
  const { isAuthenticated: kc_isAuth } = useKeycloak()
  const isAuthenticated = kc_isAuth
  const setBookingList = useCallback((bookingsList: BookingList) => dispatch(setBookingListAction(bookingsList)), [])
  const setCustomer = useCallback((customer: FlCustomer) => dispatch(setCustomerAction(customer)), [])
  const setCustomerWithCallback = useCallback((customer: FlCustomer) => setCustomerCallback(dispatch, customer), [])
  const setFjordClubMemberStatusNoProfile = useCallback(
    () => dispatch({ type: MyPageActionTypes.SET_FJORD_CLUB_MEMBER_STATUS_NO_PROFILE }),
    [],
  )

  const setUserIsNotAuthenticated = useCallback(
    () => dispatch({ type: MyPageActionTypes.SET_USER_NOT_AUTHENTICATED }),
    [],
  )
  const [changesNotSaved, setChangesNotSaved] = useState(false)
  const [showChangesNotSavedModal, setShowChangesNotSavedModal] = useState(false)

  const pageDispatchToast: PageDispatchToast = useCallback(
    ({ message }) => {
      dispatchToast({
        closeOnActionClick: true,
        message,
        timeout: 2000,
      })
    },
    [dispatchToast],
  )

  useEffect(() => {
    if (!location.pathname.includes('fjordClub') && !changesNotSaved) {
      sessionStorage.removeItem('form_values')
    }
  }, [changesNotSaved, location.pathname])

  const operations: MyPageOperations = useMemo(() => {
    return {
      setBookingList,
      setCustomer,
      setCustomerWithCallback,
      setFjordClubMemberStatusNoProfile,
      setUserIsNotAuthenticated,
      pageDispatchToast,
      setChangesNotSaved,
      setShowChangesNotSavedModal,
      changesNotSaved,
      showChangesNotSavedModal,
    }
  }, [
    changesNotSaved,
    pageDispatchToast,
    setBookingList,
    setCustomer,
    setCustomerWithCallback,
    setFjordClubMemberStatusNoProfile,
    setUserIsNotAuthenticated,
    showChangesNotSavedModal,
  ])

  useEffect(() => {
    if (bookingNumber && flBooking) {
      switch (flBooking?.currency) {
        case Currency.Dkk:
          if (language !== 'da') {
            changeLanguage('da')
            // pageDispatchToast({ message: 'Språk endret til dansk for å matche bookingens valuta' })
          }
          break
        case Currency.Nok:
          if (language !== 'nb') {
            changeLanguage('nb')
            // pageDispatchToast({ message: 'Språk endret til norsk for å matche bookingens valuta' })
          }
          break
        case Currency.Eur:
          if (language === 'nb' || language === 'da' || language === 'nl') {
            if (flBooking.language?.toLowerCase() === 'nl') {
              changeLanguage('en')
            } else {
              changeLanguage(languageTransform((flBooking.language as string) ?? 'en'))
            }
            // pageDispatchToast({ message: 'Språk endret til engelsk for å matche bookingens valuta' })
          }

          break
      }
    }
  }, [bookingNumber, changeLanguage, flBooking, flBooking?.currency, language])

  return (
    <myPageStateContext.Provider value={state}>
      <myPageStateOperationContext.Provider value={operations}>
        <WebsocketProvider>
          {isAuthenticated ? (
            <>
              <CustomerAndBookingListLoader>{children}</CustomerAndBookingListLoader>{' '}
            </>
          ) : (
            children
          )}
        </WebsocketProvider>
      </myPageStateOperationContext.Provider>
    </myPageStateContext.Provider>
  )
}

export default MyPageStateProvider
