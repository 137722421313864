import { Status } from '../types/myPage/enums'

import {
  routerBookingSubPathsStrings,
  routerFjordClubSubPaths,
  routerRootPaths,
  TRAVELER_FRIEND,
} from './appRoutes/routerPaths'
import getFjordClubNavLink from './navLinkfunctions/getFjordClubNavLink'
import getNavLink from './navLinkfunctions/getNavLink'
import getSubNavLink from './navLinkfunctions/getSubNavLink'
import { AddNavLinkArgs, AddNavLinkWithRowNumberArgs, paramKeys } from './navLinkfunctions/types'

export const BookingListNavLink = ({ bookingListView }: { bookingListView?: Status }) => {
  return `${routerRootPaths.BOOKING_LIST}${bookingListView ? `?${paramKeys.BOOKING_LIST_VIEW}=${bookingListView}` : ''}`
}

export const SignUpNavLink = `${routerRootPaths.FJORD_CLUB}/${routerFjordClubSubPaths.SIGN_UP}`
export const SignUpAddNavLink = `${routerRootPaths.FJORD_CLUB}/${routerFjordClubSubPaths.SIGN_UP_ADD}`

export const AddExtrasAshoreNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.ADD_EXTRAS_ASHORE,
  })

export const AddExtrasOnboardNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.ADD_EXTRAS_ONBOARD,
  })
/**
 * @description - creates a link uri including query params for back-target label
 */
export const AddMealsNavLink = (args: AddNavLinkArgs) => {
  return getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.ADD_MEALS,
  })
}

export const BasketNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.BASKET,
  })

export const BoardingCardsNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.BOARDING_CARDS,
  })

export const BookingNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
  })

export const CabinsNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.EDIT_CABINS,
  })

export const CartNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.CART,
  })

export const PaymentNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.PAYMENT,
  })

export const TicketsNavLink = (args: AddNavLinkArgs) =>
  getNavLink({
    ...args,
    subPath: routerBookingSubPathsStrings.TICKETS,
  })

export const BookingTravelerNavLink = ({
  rowNumber,
  useFullPath = false,
  bookingCode,
  ...rest
}: AddNavLinkWithRowNumberArgs) => {
  const subPath = `${routerBookingSubPathsStrings.BOOKING_PASSENGER}/${rowNumber}`
  return useFullPath === true && bookingCode
    ? getNavLink({
        ...rest,
        subPath,
        bookingCode,
      })
    : getSubNavLink({
        ...rest,
        subPath,
      })
}

export const BookingVehicleNavLink = ({
  rowNumber,
  useFullPath = false,
  bookingCode,
  ...rest
}: AddNavLinkWithRowNumberArgs) => {
  const subPath = `${routerBookingSubPathsStrings.VEHICLE}/${rowNumber}`
  return useFullPath === true && bookingCode
    ? getNavLink({
        ...rest,
        subPath,
        bookingCode,
      })
    : getSubNavLink({
        ...rest,
        subPath,
      })
}

export const TravelerFriendNavLink = ({ backTargetTitle, rowNumber, backTarget }: AddNavLinkWithRowNumberArgs) => {
  const subPath = `/${TRAVELER_FRIEND}/${rowNumber}`
  return getFjordClubNavLink({
    backTarget,
    backTargetTitle,
    subPath,
  })
}
