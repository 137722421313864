import { FlBooking, Journey, Passenger } from '../../../../graphql/types'
import { MayBe } from '../../../../types/MayBe'
import getDeparturePorts from '../../getBookingObjects/getDeparturePorts'

export type EditPassengerObject = {
  departureDate?: Date
  isInFuture: boolean
  journeyPorts: string
  passenger?: Passenger
}

export default function getEditPassengerObject({
  flBooking,
  passengerRowNumber,
}: {
  flBooking?: FlBooking
  passengerRowNumber?: string
}): EditPassengerObject {
  const isInFuture: boolean = flBooking?.isInFuture || false
  const outbound: Journey | undefined = MayBe.orElse(flBooking?.outbound ?? null, undefined)
  const departureDateOrNothing = outbound?.departureDate ? new Date(outbound?.departureDate) : undefined
  const departureDate = departureDateOrNothing
    ? new Date(
        departureDateOrNothing.getFullYear(),
        departureDateOrNothing.getMonth(),
        departureDateOrNothing.getDate(),
      )
    : undefined
  const journeyPorts: string = getDeparturePorts({
    arrivalPortName: outbound?.arrivalPortInfo?.portName,
    departurePortName: outbound?.departurePortInfo?.portName,
  })
  const hasEditedPassengers = sessionStorage.getItem('editedPassengers') !== null
  const editedPassengers = hasEditedPassengers
    ? JSON.parse(sessionStorage.getItem('editedPassengers') || '{}')
    : undefined
  const rowNumber = parseInt(passengerRowNumber || '-1', 10)
  const passenger =
    hasEditedPassengers && editedPassengers
      ? editedPassengers.passengers.find((e) => e.rowNumber === rowNumber)
      : outbound?.passengers?.find((p) => p.rowNumber === rowNumber)
  return {
    departureDate,
    isInFuture,
    journeyPorts,
    passenger,
  }
}
