import { AvailabilityItemWithSpecOptions } from '../../../../graphql/types'

import { reduceCabinArrayToQuantityAndCodeType } from './utils'

export function findMaxCabinSelect(
  cabin: AvailabilityItemWithSpecOptions,
  passengersToPlace: number,
  passengersYetToPlace: number,
) {
  const maxFromAvailability = cabin.specification.maximumQuantityToBook
  let maxToBook = passengersToPlace

  if (maxFromAvailability < passengersToPlace) {
    maxToBook = maxFromAvailability
  }

  if (maxToBook > passengersYetToPlace) {
    maxToBook = passengersYetToPlace
  }

  return maxToBook
}

export const reduceBookedCabinsFunc = (flBookingCabins) =>
  flBookingCabins?.reduce((acc, cabin) => {
    const existingCabin = acc.find((item) => item.code === cabin.code)
    if (existingCabin) {
      existingCabin.quantity += 1
    } else {
      acc.push({
        code: cabin.code || '',
        quantity: 1,
        price: cabin.price || 0,
      })
    }
    return acc
  }, []) as reduceCabinArrayToQuantityAndCodeType[]

export const reduceCabinsInStateFunc = (state, way: string, bookingCode: string) =>
  state?.[bookingCode || '']?.[way]?.cabins?.reduce((acc, cabin) => {
    if (cabin.quantityInCabin <= 0) {
      return acc
    }

    const existingCabin = acc.find((item) => item.code === cabin.code && item.price === cabin.price)

    if (existingCabin) {
      existingCabin.quantity += 1
    } else {
      acc.push({
        code: cabin.code || '',
        quantity: 1,
        price: cabin.price.value || 0,
      })
    }
    return acc
  }, [])

export const totalPriceExistingCabinsReduce = (reduceBookedCabins) =>
  reduceBookedCabins?.reduce((acc, cabin) => {
    return acc + cabin.price * cabin.quantity
  }, 0)

export const totalPriceNewCabinsReduce = (reduceCabinsInState) =>
  reduceCabinsInState?.reduce((acc, cabin) => {
    return acc + cabin.price * cabin.quantity
  }, 0)
