import { PaginatedCarouselItem } from '@fjordline/styles-v3'

import { BoardingCard as BoardingCardType } from '../../../graphql'
import { MayBe } from '../../../types/MayBe'
import { BoardingCardData } from '../BoardingCards'

import firstAndLastName from './firstAndLastName'

export default function getCarouselItem(boardingCards: BoardingCardType[]): PaginatedCarouselItem<BoardingCardData>[] {
  if (boardingCards.length === 0) {
    return []
  }

  return boardingCards.map((boardingCard: BoardingCardType, index) => {
    const title = firstAndLastName(boardingCard)
    const data: BoardingCardData = { qrcode: MayBe.orElse(boardingCard.qrCode, '') || '' }
    return {
      key: index,
      title,
      data,
    }
  })
}
