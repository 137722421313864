import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContextChildren } from '../../providers/genericTypes'

import SelectExtrasModalPopup from './addExtrasModalProvider/AddExtrasModal'
import { addExtrasModalContext } from './addExtrasModalProvider/addExtrasModalContext'
import { AddExtraModalParams, AddExtrasModal, ShowAddExtrasModalParams } from './addExtrasModalProvider/types'
import { trackEvent } from '../../providers/TelemetryProvider'

/**
 * @description - provides showAddExtrasModal from useAddExtrasModal
 * @description - showAddExtrasModal
 * @param children - React.ReactNode element
 * @constructor
 */
const AddExtrasModalProvider: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const navigate = useNavigate()
  const [{ bookingCode, isVisible, backTargetTitle }, setAddExtraModalParams] = useState<AddExtraModalParams>({
    isVisible: false,
    bookingCode: '',
    backTargetTitle: undefined,
  })

  const showModal = useCallback(({ bookingCode, backTargetTitle }: ShowAddExtrasModalParams) => {
    setAddExtraModalParams({
      bookingCode,
      isVisible: true,
      backTargetTitle,
    })
  }, [])

  const contextValue: AddExtrasModal = useMemo<AddExtrasModal>(
    function setContextValue() {
      return {
        showAddExtrasModal: showModal,
      }
    },
    [showModal],
  )

  /**
   * @description - navigate to url for action selected in the modal
   */
  const handleItemClick = useCallback(
    (redirectUrl: string) => {
      navigate(redirectUrl)
      trackEvent('user clicked add extras modal item')
    },
    [navigate],
  )
  useMemo(() => window.scrollTo(0, 0), [])

  return (
    <addExtrasModalContext.Provider value={contextValue}>
      {isVisible ? (
        <SelectExtrasModalPopup
          bookingCode={bookingCode}
          onItemClick={handleItemClick}
          backTargetTitle={backTargetTitle}
          onRequestClose={() =>
            setAddExtraModalParams({
              bookingCode,
              isVisible: false,
            })
          }
        />
      ) : null}
      {children}
    </addExtrasModalContext.Provider>
  )
}

export default AddExtrasModalProvider
