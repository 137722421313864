import { LottieLoader } from '@fjordline/styles-v3'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    margin-top: 1.5rem;
  }
  svg {
    height: 500px !important;
  }
`

const LottieLoaderContainer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <LoaderContainer>
      <LottieLoader />
      <div className="text">{t('label.button.loading')}</div>
    </LoaderContainer>
  )
}

export default LottieLoaderContainer
