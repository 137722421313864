import { Maybe } from '../graphql/types'

export const MayBe = {
  of<T>(value: T | null): Maybe<T> {
    return value
  },

  map<T, U>(maybe: Maybe<T>, fn: (value: T) => U): Maybe<U> {
    return maybe === null ? null : fn(maybe)
  },

  flatMap<T, U>(maybe: Maybe<T>, fn: (value: T) => Maybe<U>): Maybe<U> {
    return maybe === null ? null : fn(maybe)
  },

  orElse<T>(maybe: Maybe<T>, defaultValue: T): T {
    return maybe === null ? defaultValue : maybe
  },
}
