function cabincodeForSanity(code: string, vesselCode: string) {
  let codeForSanity = code
  //Here we add ship code to the LOUNGE code to get the correct image from sanity. This is because LOUNGE is a container used for all lounges,
  //and we have different lounges for each ship.
  if (code.includes('LOUNGE') && vesselCode) {
    codeForSanity = `LOUNGE-${vesselCode}`
  }
  return codeForSanity
}

export default cabincodeForSanity
