import { useEffect, useState } from 'react'
import { ShipExtra } from './types'
import { fetchMultipleExtrasByCodeFromSanity } from '../fetchData'

function useFetchExtrasMultipleExtras({ code }: { code?: string[] }) {
  //Single extra
  const [multipleExtrasFromSanity, setMultipleExtrasFromSanity] = useState<ShipExtra[] | undefined>(undefined)
  useEffect(() => {
    if (!code) return
    fetchMultipleExtrasByCodeFromSanity(code).then((extra: ShipExtra[]) => {
      setMultipleExtrasFromSanity(extra)
    })
  }, [code])

  return {
    multipleExtrasFromSanity,
  }
}

export default useFetchExtrasMultipleExtras
