import { styled } from 'styled-components'

export const JourneyStyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    gap: 0px;
    padding: 0px;
  }
`

export const JourneyStyledCard = styled.div`
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #e5e5e5;
  min-width: calc(33% - 20px);
  max-width: 400px;

  @media (max-width: 950px) {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .header-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      cursor: pointer;
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    .pay_with_points_badge {
      align-items: center;
      background: #f03;
      box-shadow: 0 0 10px 0 rgba(95, 95, 95, 0.5);
      color: #fff;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      height: auto;
      height: 200px;
      justify-content: center;
      left: -100px;
      padding: 10px;
      position: absolute !important;
      text-align: center;
      text-decoration: none;
      top: -100px;
      transform: rotate(-45deg);
      width: 200px;
      z-index: 2;

      span {
        bottom: 8px;
        font-weight: 500;
        left: 30px;
        line-height: 1.2;
        position: absolute;
        text-align: center;
        width: calc(100% - 60px);
      }
    }
  }

  .body {
    padding: 22px 25px 34px;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    max-height: 300px;
    height: 100%;
    min-height: 300px;

    @media (max-width: 950px) {
      height: auto;
      padding: 12px 15px 24px;
    }
  }

  .footer {
    align-items: center;
    background-color: #f03;
    border-top: 1px solid #f7f7f7;
    color: #fff;
    display: flex;
    justify-content: space-between;
    min-height: 85px;
    padding: 0 14px 0 14px;
    border-radius: 0 0 8px 8px;
    margin-top: 1rem;

    .footer_price {
      color: #fff;
      display: block;
      font-weight: 600;
    }

    .footer_price-from {
      color: #fff;
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      margin-right: 3px;
    }

    .footer_price-title {
      display: inline-block;
      font-size: 30px;
      line-height: 1;
      font-weight: 600;
    }

    .footer_button {
      background-color: #fff;
      border-radius: 10px;
      color: #222 !important;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px 9px;
      text-decoration: none;
      transition: background 0.2s ease-out;
      cursor: pointer;

      &:hover {
        background: #e0e0e0;
        color: #222 !important;
        text-decoration: none;
      }
    }

    .footer_price_deparature {
      border-top: none;
      color: #fff;
      display: block;
      font-size: 12px !important;
      font-weight: 400;
      margin-bottom: unset;
      padding-top: unset;
    }
  }
`
