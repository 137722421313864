import React, { useCallback, useMemo } from 'react'
import { HubConnectionState } from '@microsoft/signalr'

import { FlCustomer } from '../../../graphql'
import { ContextChildren } from '../../genericTypes'

import {
  mpCart,
  MpCartOperationsContextType,
  websocketOperationsContext,
} from './websocketProviderFunctions/WebsocketOperationsProvider/context'
import useCartFunctions, {
  InvokeUpdateResult,
} from './websocketProviderFunctions/WebsocketOperationsProvider/useMpCartFunctions'
import { useWebSocketContext } from './websocketContext'

/**
 * @description - The provider exposes functions to update the cart
 * @param children
 */
const WebsocketOperationsProvider: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const { invokeUpdateCart, invokeUpdateFlCustomer, invokeCreateFlCustomer } = useCartFunctions()
  const { connection } = useWebSocketContext()

  const updateCart = useCallback(
    function invokeUpdateFlCustomerIfConnectionIsReady(mpCart: mpCart): void {
      if (connection !== null && connection.state === HubConnectionState.Connected) {
        return invokeUpdateCart({ mpCart, connection })
      }
    },
    [connection, invokeUpdateCart],
  )

  const updateFlCustomer = useCallback(
    function invokeUpdateCartIfConnectionIsReady(fjordClubMembership: FlCustomer): Promise<InvokeUpdateResult> {
      if (connection !== null && connection.state === HubConnectionState.Connected) {
        return invokeUpdateFlCustomer({ fjordClubMembership, connection })
      } else {
        return Promise.reject<InvokeUpdateResult>({ status: 'error', message: 'no connection', error: '' })
      }
    },
    [connection, invokeUpdateFlCustomer],
  )

  const createFlCustomer = useCallback(
    function invokeUpdateCartIfConnectionIsReady(fjordClubMembership: FlCustomer): Promise<InvokeUpdateResult> {
      if (connection !== null && connection.state === HubConnectionState.Connected) {
        return invokeCreateFlCustomer({ fjordClubMembership, connection })
      } else {
        return Promise.reject<InvokeUpdateResult>({ status: 'error', message: 'no connection', error: '' })
      }
    },
    [connection, invokeCreateFlCustomer],
  )

  const mpCartOperations: MpCartOperationsContextType = useMemo(
    function getWebsocketOperations() {
      return {
        updateFlCustomer,
        createFlCustomer,
        addCompanion: () => null,
        updateCompanion: () => null,
        deleteCompanion: () => null,
        updateCart,
        clearMpCart: () => null,
        updateBookingContactPerson: () => null,
        updateBookingPassenger: () => null,
        updateBookingVehicle: () => null,
        checkoutCart: () => null,
      }
    },
    [createFlCustomer, updateCart, updateFlCustomer],
  )

  return <websocketOperationsContext.Provider value={mpCartOperations}>{children}</websocketOperationsContext.Provider>
}

export default WebsocketOperationsProvider
