import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Download, Feedback, FormElementWrapper, LottieLoader, Spinner } from '@fjordline/styles-v3'

import CustomBackButton from '../components/CustomBackbutton'
import { BookedAccommodation, Passenger, useGetBoardingCardsQuery, useGetBookingLazyQuery, Vehicle } from '../graphql'
import { navLinks } from '../routes/navlinks'
import { useBookingDetails } from '../providers/BookingDetailsProvider'
import { useWebSocketContext } from '../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../providers/WebsocketProvider'

import AddExtrasModalButton from './booking/AddExtrasModalButton'
import BoardingCardLinkOrMessage from './booking/BoardingCardLinkOrMessage'
import CancelBooking from './booking/CancelBooking'
import getBookingObjects from './booking/getBookingObjects'
import SectionCabins from './booking/SectionCabins'
import SectionExtrasAshore from './booking/SectionExtrasAshore'
import SectionExtrasOnboard from './booking/SectionExtrasOnboard'
import SectionJourneyDetails from './booking/SectionJourneyDetails'
import SectionMeals from './booking/SectionMeals'
import SectionPassengers from './booking/SectionPassengers'
import SectionVehicles from './booking/SectionVehicles'
import useDownloadPDF from './booking/useDownloadPDF'
import useResolveGraphqlDataForBooking from './booking/useResolveGraphqlDataForBooking'
import OrderNextJourneyBannerOrButton from './bookingList/OrderNextJourneyBanner'
import { FEATURE_SHOW_CANCEL_BOOKING } from '../config'
import SectionAccommodations from './booking/SectionAccommodations'
import SectionTaxesAndFees from './booking/SectionTaxesAndFees'
import { mergeRoomsByCode } from './booking/sectionAccommodations/mergeRoomsHelper'
import { isPastDeadline } from '../utils/isPastDeadline'
import ModifyBookingDeadlineTimer from '../components/ModifyBooingDeadlineTimer'
import { useAvailabilityItems } from '../providers/AvailabilityItemsProvider'

const Booking: React.FC = () => {
  const { hasActivePayment } = useAvailabilityItems()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { pathname } = useLocation()
  const { flBooking, flBookingLoading } = useBookingDetails()
  const { downloadPDF, fetchingPDF } = useDownloadPDF()
  const { updatedBookings } = useWebSocketContext()
  const bookingCodeFromUrl = extractBookingNumber(location)
  const hasUpdatedBooking = updatedBookings && Object.keys(updatedBookings).includes(bookingCodeFromUrl || '')
  const updatedBooking = hasUpdatedBooking ? updatedBookings[bookingCodeFromUrl || ''] : undefined
  const { expectCabins, expectExtras, expectMeals } = useResolveGraphqlDataForBooking()
  const {
    t,

    i18n: { language },
  } = useTranslation()

  const modifyBookingDeadline = flBooking?.modificationDeadline

  const {
    inbound,
    inboundCabins,
    inboundExtrasAshore,
    inboundExtrasOnboard,
    inboundMealsGroupedByDateAndStartTime,
    isActiveOrFuture,
    isFuture,
    journeyPorts,
    outbound,
    outboundCabins,
    outboundExtraOnboard,
    outboundExtrasAshore,
    outboundMealsGroupedByDateAndStartTime,
    validatedBookingCode,
    outboundEtax,
    inboundEtax,
  } = useMemo(
    () => getBookingObjects({ flBooking: flBooking, bookingCode, t, language, updatedBooking }),
    [flBooking, bookingCode, t, language, updatedBooking],
  )

  const updatedBookingOutbound = updatedBooking ? updatedBooking.outbound : flBooking?.outbound
  const passengersToShow: Passenger[] | undefined =
    updatedBookingOutbound?.passengers || (outbound?.passengers as Passenger[])
  const vehiclesToShow: Vehicle[] | undefined = updatedBookingOutbound?.vehicles || (outbound?.vehicles as Vehicle[])

  const shouldRefetchBooking = sessionStorage.getItem(bookingCode || '') === 'refetchBooking'
  const [refetchBooking] = useGetBookingLazyQuery()

  const { data: boardingCardsData } = useGetBoardingCardsQuery({
    variables: {
      bookingCode: bookingCode || '',
    },
    skip: !bookingCode || bookingCode === '' || flBooking?.showBoardingCards === false,
  })

  useEffect(() => {
    if (shouldRefetchBooking) {
      setTimeout(() => {
        refetchBooking({
          variables: {
            bookingCode: bookingCode || '',
          },
          fetchPolicy: 'network-only',
        })
        sessionStorage.removeItem(bookingCode || '')
      }, 15000)
    }
  }, [bookingCode, refetchBooking, shouldRefetchBooking])

  const navigate = useNavigate()

  if (flBookingLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
        <LottieLoader />
      </div>
    )
  }

  return (
    <div style={{ maxWidth: '50rem' }}>
      {hasActivePayment && (
        <div style={{ marginBottom: '1rem' }}>
          <Feedback type="info" variant="notification">
            {t('component.extras.basket.alreadyReservedInCart_description')} ({hasActivePayment.bookingCode}) <br />{' '}
            <span
              onClick={() => navigate(`/bookingList/${hasActivePayment.bookingCode}/basket`)}
              style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
            >
              {t('label.button.gotoBasket')}
            </span>
          </Feedback>
        </div>
      )}
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={navLinks.BOOKING_LIST} />
      </FormElementWrapper>
      {flBooking !== undefined ? (
        <>
          <SectionJourneyDetails
            backTargetTitle={journeyPorts}
            inbound={inbound}
            outbound={outbound}
            bookingCode={validatedBookingCode}
          />
          {boardingCardsData?.booking.boardingCards && boardingCardsData.booking.boardingCards.length > 0 ? (
            <BoardingCardLinkOrMessage
              bookingCode={bookingCode || ''}
              backTargetTitle={journeyPorts}
              backTarget={pathname}
              departureDate={outbound?.departureDate}
              isActiveOrFuture={isActiveOrFuture}
            />
          ) : null}

          {flBooking.canBuyBookingItems === false ||
          isPastDeadline({ modifyBookingDeadline }) ||
          hasActivePayment?.bookingResult ? null : (
            <>
              <AddExtrasModalButton
                bookingCode={validatedBookingCode}
                backTargetTitle={journeyPorts}
                isActiveOrFuture={isActiveOrFuture}
              />

              <ModifyBookingDeadlineTimer modifyBookingDeadline={modifyBookingDeadline} />
            </>
          )}

          <SectionPassengers isFuture={isFuture} passengers={passengersToShow} />
          <SectionVehicles
            vehicles={vehiclesToShow}
            bookingCode={validatedBookingCode}
            isActiveOrFuture={isActiveOrFuture}
          />
          {expectCabins ? (
            <SectionCabins
              bookingCode={validatedBookingCode}
              inboundCabins={inboundCabins}
              journeyPorts={journeyPorts}
              outboundCabins={outboundCabins}
            />
          ) : null}
          {expectMeals === true ? (
            <SectionMeals
              bookingCode={validatedBookingCode}
              inboundMealsGroupedByDateAndStartTime={inboundMealsGroupedByDateAndStartTime}
              journeyPorts={journeyPorts}
              outboundMealsGroupedByDateAndStartTime={outboundMealsGroupedByDateAndStartTime}
            />
          ) : null}
          {expectExtras === true ? (
            <SectionExtrasOnboard
              bookingCode={validatedBookingCode}
              inboundExtrasOnboard={inboundExtrasOnboard}
              journeyPorts={journeyPorts}
              outboundExtraOnboard={outboundExtraOnboard}
            />
          ) : null}

          {expectExtras === true ? (
            <SectionExtrasAshore
              bookingCode={validatedBookingCode}
              inboundExtrasAshore={inboundExtrasAshore}
              journeyPorts={journeyPorts}
              outboundExtraAshore={outboundExtrasAshore}
            />
          ) : null}

          {flBooking.accommodations && flBooking.accommodations?.length > 0 ? (
            <SectionAccommodations
              accommodations={mergeRoomsByCode(flBooking?.accommodations) as BookedAccommodation[]}
            />
          ) : null}
          {outboundEtax || inboundEtax ? (
            <SectionTaxesAndFees inboundEtax={inboundEtax} outboundEtax={outboundEtax} />
          ) : null}

          <OrderNextJourneyBannerOrButton showBanner={!isActiveOrFuture} />
        </>
      ) : null}
      {flBooking?.isInPast ? null : (
        <Button
          dataCy="btn-download-pdf"
          style={{ marginTop: '1rem' }}
          disabled={fetchingPDF}
          onClick={downloadPDF}
          theme="secondary"
          size="medium"
          leadingIcon={fetchingPDF ? Spinner : Download}
        >
          {t('label.button.downloadPDF')}
        </Button>
      )}

      {flBooking?.isOngoing ||
      FEATURE_SHOW_CANCEL_BOOKING !== true ||
      flBooking?.isInPast ||
      flBooking?.isOngoing ? null : (
        <CancelBooking />
      )}
    </div>
  )
}

export default Booking
