export enum paramKeys {
  BACK_TARGET = 'backTarget',
  BOOKING_LIST_VIEW = 'view',
  BACK_TARGET_TITLE = 'backTargetTitle',
  ANCHOR = 'anchor',
}

/**
 * @description -
 */
export type AddNavLinkArgs = {
  bookingCode?: string
  backTargetTitle?: string
  backTarget?: string
}

export interface AddNavLinkWithRowNumberArgs extends AddNavLinkArgs {
  rowNumber: number
  useFullPath?: boolean
}
