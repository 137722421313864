import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { logDev } from '../../../../components/LogDev'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { useWebSocketContext } from '../../../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { getModifyBookingClient } from '../../../../rest/clients'
import { trackEvent } from '../../../../providers/TelemetryProvider'
import { useKeycloak } from '../../../../providers/KeycloakProvider'

function UpdateVehicles() {
  const { bookingCode } = useParams()
  const [loadingUpdateVehicles, setLoadingUpdateVehicles] = useState(false)
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [success, setSuccess] = useState(false)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { setUpdatedBookings, updatedBookings } = useWebSocketContext()
  const { token, isAuthenticated: kc_isAuth, isLoading: kc_isLoading } = useKeycloak()
  const isAuthenticated = kc_isAuth
  const [accessToken, setAccessToken] = useState<string>('')
  useEffect(() => {
    if (!kc_isLoading && kc_isAuth && token) {
      setAccessToken(token)
    }
  }, [kc_isAuth, kc_isLoading, token])

  function updateVehicles(vehicles) {
    setLoadingUpdateVehicles(true)
    if (bookingCode && accessToken && isAuthenticated) {
      getModifyBookingClient(accessToken)
        ?.updateVehicles(bookingCode, vehicles)
        .then(() => {
          trackEvent('user updated vehicles registration number')
          setSuccess(true)
          setError(undefined)
          setUpdatedBookings({
            ...updatedBookings,
            [bookingCode]: {
              ...flBooking,
              outbound: {
                ...flBooking?.outbound,
                vehicles: vehicles.outboundVehicles,
              },
              inbound: {
                ...flBooking?.inbound,
                vehicles: vehicles.inboundVehicles,
              },
            },
          })
          sessionStorage.setItem(
            'updatedBookings',
            JSON.stringify({
              ...updatedBookings,
              [bookingCode]: {
                ...flBooking,
                outbound: {
                  ...flBooking?.outbound,
                  vehicles: vehicles,
                },
              },
            }),
          )
        })

        .catch((error) => {
          //Handle error
          setError(error)
          setSuccess(false)
          logDev(error)
          trackEvent('user failed to update vehicles registration number')
        })
        .finally(() => {
          setLoadingUpdateVehicles(false)
        })
    }
  }

  return {
    updateVehicles,
    loadingUpdateVehicles,
    error,
    success,
  }
}

export default UpdateVehicles
