import styled from 'styled-components'

interface TicketsStyleProps {
  mobileMaxWidth: string
  overrideMobile: boolean
}

export const TicketWrapper = styled.div<{ isMobile?: boolean }>`
  cursor: ${({ isMobile }) => `${isMobile === true ? 'default' : 'pointer'}`};
`

export const TicketsStyle = styled.div<TicketsStyleProps>`
  @media only screen and (min-width: ${({ mobileMaxWidth, overrideMobile }) =>
    overrideMobile && mobileMaxWidth ? mobileMaxWidth : '0'})
    > * {
      text-align: center;
      width: fit-content;
    }
  }

  .ticketHeader {
    padding: 2rem 0.5rem 0rem 0.5rem;
  }

  .ticketCard {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    h3,
    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    img {
      max-width: 18em;
    }

    .categoryCode {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  > * {
    margin-bottom: 1em;
  }
`
