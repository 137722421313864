import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@fjordline/styles-v3'

export default function TicketModal({
  modalIsOpen,
  setTicketModalIsOpen,
  src,
}: {
  modalIsOpen: boolean
  setTicketModalIsOpen: (value: React.SetStateAction<boolean>) => void
  src: string | undefined
}): JSX.Element | null {
  const { t } = useTranslation()

  if (!modalIsOpen) {
    return null
  }

  return (
    <Modal data-cy="ticket-modal" label="" onRequestClose={() => setTicketModalIsOpen(false)} dialog fullscreen>
      <div onClick={() => setTicketModalIsOpen(false)} className="modalImage">
        <img src={src} alt={`${t('label.tickets.barcode.alt')}`} />
      </div>
    </Modal>
  )
}
