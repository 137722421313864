import React from 'react'
import { useTranslation } from 'react-i18next'
import { Footer, FooterHeader, FooterSection, FooterSocialLinks, Paragraph, TextLink } from '@fjordline/styles-v3'
import { SupportedLocale } from '@fjordline/styles-v3/dist/components/Navigation/TopMenu/LanguageSelector'

const MinSideFooter: React.FC = () => {
  const { t, i18n } = useTranslation()

  return (
    <Footer locale={i18n.language as SupportedLocale} data-cy="footer">
      <FooterSection>
        <FooterHeader>{t('component.footer.contactUs')}</FooterHeader>
        <Paragraph>
          E-mail:
          <TextLink href="mailto:info@fjordline.com"> info@fjordline.com</TextLink>
        </Paragraph>
      </FooterSection>
      <FooterSection>
        <FooterHeader>{t('component.footer.followUs')}</FooterHeader>
        <FooterSocialLinks locale={i18n.language as SupportedLocale} />
      </FooterSection>
    </Footer>
  )
}

export default MinSideFooter
