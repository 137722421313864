import { FareCode, FlBooking, Passenger } from '../../../../graphql'

export const options = (setWay, flBooking: FlBooking | undefined, way, t, missingCabinsInbound, navigate) => {
  const isPastOrActive =
    flBooking?.outbound?.departureDate &&
    new Date(flBooking?.outbound?.departureDate as string).getTime() < new Date().getTime()
      ? true
      : false
  const opt1 = [
    {
      title: t('component.general.return'),
      id: 'inbound',
      isSelected: way === 'inbound',
      onClick: () => {
        setWay('inbound')
      },
    },
  ]

  const opt2 = [
    {
      title: t('component.general.departure'),
      id: 'outbound',
      isSelected: way === 'outbound',
      onClick: () => {
        setWay('outbound')
      },
    },
    flBooking?.inbound
      ? {
          title: t('component.general.return'),
          id: 'inbound',
          isSelected: way === 'inbound',
          onClick: () => {
            setWay('inbound')
          },
        }
      : {
          title: '',
          id: '',
          isSelected: false,
          onClick: (_) => {
            _
          },
        },
  ]

  const opt3 = [
    {
      title: t('component.general.departure'),
      id: 'outbound',
      isSelected: false,
      onClick: () => {
        setWay('outbound')
        navigate(`/bookingList/${flBooking?.bookingCode}/cabins`)
      },
    },
    {
      title: t('component.general.return'),
      id: 'inbound',
      isSelected: true,
      onClick: () => {
        setWay('inbound')
      },
    },
  ]

  return missingCabinsInbound ? opt3 : isPastOrActive ? opt1 : opt2
}

export function findPassengerQuantityPerFareCode(passengers: Passenger[]) {
  const adults = passengers?.filter((pass) => pass.fareCode === FareCode.Adult).length || 0
  const children = passengers?.filter((pass) => pass.fareCode === FareCode.Child).length || 0
  const infants = passengers?.filter((pass) => pass.fareCode === FareCode.Infant).length || 0

  return adults + children + infants
}

export function findPassengersPlacement(cabinsInState, flBooking?: FlBooking) {
  const passengersToPlace = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const filterOutExistingCabins = cabinsInState?.filter((cabin) => cabin.quantityInCabin > 0)
  const passengersPlaced = filterOutExistingCabins?.reduce((acc, cabin) => acc + cabin.quantityInCabin, 0)
  const passengersYetToPlace = passengersToPlace - passengersPlaced
  const allPassengersArePlaced = passengersYetToPlace === 0

  return {
    passengersToPlace,
    passengersPlaced,
    passengersYetToPlace,
    allPassengersArePlaced,
  }
}

export type reduceCabinArrayToQuantityAndCodeType = {
  code: string
  quantity: number
  price: number
}
