import React from 'react'

import { BookingList, BookingListWithKeys, defaultBookingListWithKeys } from '../../types/MyPage'
import { Status } from '../../types/myPage/enums'

type SetBookingListBasedOnViewStatus = ({
  statusToView,
  setCurrentBookingListToView,
}: {
  statusToView: Status
  setCurrentBookingListToView: React.Dispatch<React.SetStateAction<BookingListWithKeys>>
  bookingList: BookingList | undefined
}) => Promise<void>
const setBookingListBasedOnViewStatus: SetBookingListBasedOnViewStatus = async ({
  statusToView,
  setCurrentBookingListToView,
  bookingList,
}) => {
  switch (statusToView) {
    case Status.ACTIVE:
      await setCurrentBookingListToView(
        bookingList && bookingList.ongoing ? bookingList.ongoing : defaultBookingListWithKeys,
      )
      break
    case Status.FUTURE:
      await setCurrentBookingListToView(
        bookingList && bookingList.future ? bookingList.future : defaultBookingListWithKeys,
      )
      break
    case Status.CANCELLED:
      await setCurrentBookingListToView(
        bookingList && bookingList.cancelled ? bookingList.cancelled : defaultBookingListWithKeys,
      )
      break
    case Status.PASSED:
      await setCurrentBookingListToView(
        bookingList && bookingList.passed ? bookingList.passed : defaultBookingListWithKeys,
      )
      break
    case Status.DEFAULT:
      await setCurrentBookingListToView(
        bookingList && bookingList.ongoing ? bookingList.ongoing : defaultBookingListWithKeys,
      )
      break
  }
  return Promise.resolve()
}

export default setBookingListBasedOnViewStatus
