import { v4 as uuidv4 } from 'uuid'

import { GOOGLE_TAG_MANAGER_ID } from '../../config'

const nonce: string = uuidv4()
export const gtmParams = {
  id: GOOGLE_TAG_MANAGER_ID,
  dataLayerName: 'PageDataLayer',
  nonce,
}
