import styled from 'styled-components'

export const ColumnWrapper = styled.div`
  height: 1rem;

  min-width: 100%;
`

export const DividerWrapper = styled(ColumnWrapper)`
  display: block;
`

export const LowerButton = styled.span`
  > button {
    position: relative;
    right: 0.5rem;
    top: 0.3rem;
  }
`

export const LowerButtonForCabin = styled.span`
  > button {
    position: relative;
    right: 0.5rem;
    top: -0.3rem;
  }
`

export const WithImageLeft = styled.div`
  display: flex;
  > img {
    min-width: 3.5rem;
    max-width: 5rem;
    min-height: 3.5rem;
    max-height: 5rem;
    padding-right: 1rem;
  }
`

export const NoMealImage = styled.div`
  min-width: 3.5rem;
  max-width: 5rem;
  padding-right: 1rem;
`

export const Grow = styled.div`
  flex-grow: 1;
`

export const MealImage = styled.img`
  max-width: 100%;
  width: 100%;
  object-fit: cover;
`
