import styled from 'styled-components'

export const Centered = styled.div`
  display: flex;
  justify-content: center;
`
export const BookingPreviewList = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

export const BookingCardFill = styled.div`
  height: 100%;
  > div {
    min-width: 20rem;
    max-width: unset;
    height: 100%;
  }

  @media (max-width: 768px) {
    > div {
      min-width: 20rem;
      max-width: 100%;
    }
  }
`

export const AllignContentBottom = styled.div`
  > div:first-child {
    justify-content: flex-end;
    display: flex;
    -webkit-box-pack: center;
    justify-content: end;
    flex-direction: column;
    margin-top: 1rem;
  }
`

export const PlaceHolder = styled.div`
  border: 1px solid red;
  height: 4rem;
`
