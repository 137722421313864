import { mpCart } from '../../../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import unpack from '../../clientFactory/unpack'
import { HttpClient } from '../../clientFactory/unpack/types'

/**
 * @description - actions for otp
 * @description -  => async getMobileOtp(mobile: string)
 * @description - => async verifyOtp(mobile: string, customerTotp: string)
 */
class MyPageActions {
  client: HttpClient

  constructor(client: HttpClient) {
    this.client = client
  }

  async checkoutCart({ bookingCode, id }: { bookingCode: string; id: string }) {
    return unpack(this.client.post(`checkout-cart/${id}/${bookingCode}`))
      .then((data) => data as mpCart)
      .catch((e) => {
        throw e
      })
  }

  async rollbackCart({ bookingCode, id }: { bookingCode: string; id: string }) {
    return unpack(this.client.post(`rollback-cart/${id}/${bookingCode}`))
      .then((data) => data as mpCart)
      .catch((e) => {
        throw e
      })
  }
}

export default MyPageActions
