import { AxiosError, AxiosResponse } from 'axios'

export type customErrorType = {
  status: number
  response?: AxiosResponse
  translationKey: string
}

export function handleResponse<T>(response: AxiosResponse<T>) {
  if ([200, 201, 202].includes(response.status)) {
    return response
  }

  const { baseURL = '', url = '', method } = response.config
  const request = method + ':' + url.replace(baseURL, '').replace(/\/[0-9,]+/, '')
  const errorMessage = getError(request, response.status, response)

  return Promise.reject(errorMessage)
}

export function handleError(error: AxiosError) {
  if (error.code === 'ECONNABORTED') {
    return Promise.reject('error.timeout')
  }

  if (error.response === undefined) {
    return Promise.reject({
      ...error,
      customError: 'error.response',
    })
  }

  if (error.response.status === 401) {
    window.location.href = '/'
    return Promise.reject()
  }

  return handleResponse(error.response)
}

function getError(request: string, status: number, response?: AxiosResponse): customErrorType {
  const customError: customErrorType = {
    status,
    response,
    translationKey: '',
  }
  switch (request) {
    case '[some:likely:request]':
      switch (status) {
        case 204:
          return {
            ...customError,
            translationKey: 'something.usefull',
          }
        case 404:
          return {
            ...customError,
            translationKey: 'something.usefull.like.notFound',
          }
        default:
          return {
            ...customError,
            translationKey: 'something.usefull.like.failed',
          }
      }
    default:
      return customError
  }
}
