import { useQuery } from '@tanstack/react-query'
import { fetchMealByCodeOrRelationFromSanity } from '../fetchData'
import { ShipMeal } from './types'

type props = {
  code: string
}

export default function useFetchMeals({ code }: props) {
  return useQuery({
    queryKey: [code],
    enabled: code !== '' || code !== undefined,
    queryFn: async () => {
      const data = await fetchMealByCodeOrRelationFromSanity(code)
      return data?.[0] as ShipMeal
    },
  })
}
