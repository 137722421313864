import axios, { AxiosInstance } from 'axios'

import { handleError, handleResponse } from './getClient/interceptors'
import { createClientType } from './getClient/types'

function createClient({ baseURL, accessToken, timeout }: createClientType): AxiosInstance {
  const client = axios.create({
    baseURL,
    timeout,
    maxContentLength: 1000000,
  })
  if (accessToken) {
    client.interceptors.request.use((config) => {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    })
  }
  client.interceptors.response.use(handleResponse, handleError)
  return client
}

export default createClient
