import { useEffect, useMemo } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import * as Scroll from 'react-scroll'

import { paramKeys } from '../routes/navLinkfunctions/types'

type UseAnchor = ({ currentPath }: { currentPath: string }) => string | null

const useAnchor: UseAnchor = ({ currentPath }) => {
  const [params, setParams] = useSearchParams()
  const scroller = useMemo(() => Scroll.scroller, [])
  const navigate: NavigateFunction = useNavigate()
  // const anchor: string | null = useMemo(() => queryParams.get('anchor'), [queryParams])
  const anchor: string | null = useMemo(() => params.get(paramKeys.ANCHOR), [params])

  useEffect(() => {
    if (anchor !== null) {
      setTimeout(() => {
        scroller.scrollTo(anchor, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuart',
        })
        setParams('')
        navigate(currentPath, { replace: true })
      }, 500)
    }
  }, [anchor, currentPath, navigate, scroller, setParams])

  return anchor
}

export default useAnchor

export enum anchors {
  futureBookings = 'futureBookings',
  fjordClubMobile = 'fjordClubMobile',
}
