import { Passenger } from '../../../graphql'
import unpack from '../../clientFactory/unpack'
import { HttpClient } from '../../clientFactory/unpack/types'

export type cancelBookedResultProps = {
  bookingBalance: string
  cancellationFee: string
  cancelled: boolean
  currency: string
  id: string
  bookingCode: string
}

class ModifyBookingActions {
  client: HttpClient

  constructor(client: HttpClient) {
    this.client = client
  }

  async updatePassengers(id: string, passengers: Passenger[]) {
    const endpoint = 'update-passengers'
    return unpack(this.client.put(`${endpoint}/${id}`, passengers))
  }

  async updateVehicles(id: string, vehicles) {
    const endpoint = 'update-vehicles'
    return unpack(this.client.put(`${endpoint}/${id}`, vehicles))
  }

  async getBookingPDF(bookingCode: string) {
    return unpack(this.client.get<BlobPart>(`booking/${bookingCode}/pdf`)).then(
      (data) => new Blob([data], { type: 'application/pdf' }),
    )
  }
  async cancelConfirmed(id: string) {
    const endpoint = 'cancel-booking'
    return unpack(this.client.post(`${endpoint}/${id}`)).then((res: cancelBookedResultProps | unknown) => {
      if (res) {
        return res as cancelBookedResultProps
      } else {
        throw new Error('Something went wrong')
      }
    })
  }

  async getCanellationFee(id: string) {
    const endpoint = 'cancellation-fee'
    return unpack(this.client.get(`${endpoint}/${id}`))
  }
}

export default ModifyBookingActions
