import React, { useEffect } from 'react'
import { fetchAllSpecificationsFromSanity, fetchSpecificationByCodeFromSanity } from '../fetchData'
import { Specification } from './types'

function useFetchSpecifications(code?: string) {
  const [specByCode, setSpecByCode] = React.useState<Specification | undefined>(undefined)
  const [allSpecs, setAllSpecs] = React.useState<Specification[] | undefined>(undefined)

  useEffect(() => {
    if (code) return
    fetchAllSpecificationsFromSanity().then((spec: Specification[]) => {
      setAllSpecs(spec)
    })
  }, [code])

  useEffect(() => {
    if (!code) return
    fetchSpecificationByCodeFromSanity(code).then((spec: Specification) => {
      setSpecByCode(spec[0])
    })
  }, [code])
  return {
    specByCode,
    allSpecs,
  }
}

export default useFetchSpecifications
