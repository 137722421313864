import { HubConnection } from '@microsoft/signalr'

import { logDev } from '../../../../components/LogDev'
import { FlCustomer } from '../../../../graphql/types'
import signalREvents from '../signalREvents'

import { mpCart } from './WebsocketOperationsProvider/context'

export const unsubscribeEvents: (connection: HubConnection) => Promise<void> = (connection: HubConnection) => {
  connection.off(signalREvents.initCart)
  connection.off(signalREvents.flCustomerUpdated)
  connection.off(signalREvents.cartUpdated)
  connection.off(signalREvents.bookingUpdated)
  connection.off(signalREvents.cartPaid)

  return Promise.resolve()
}

type SubscribeParams = {
  connection: HubConnection
}

export type SetCart = (cart: mpCart) => void
export type SetFjordClubMembership = (flCustomer: FlCustomer) => void

interface SubscribeToCartUpdatedParams extends SubscribeParams {
  setCart: SetCart
}

interface SubscribeFjordClubUpdatedParams extends SubscribeParams {
  setFjordClubMembership: SetFjordClubMembership
  setFlCustomerLoading: (loading: boolean) => void
  setCustomerSuccessUpdated: (success: boolean) => void
}

type SubscribeToCartUpdated = (p: SubscribeToCartUpdatedParams) => void
type SubscribeFjordClubUpdated = (p: SubscribeFjordClubUpdatedParams) => void

export const subscribeToCartUpdated: SubscribeToCartUpdated = ({
  connection,
  setCart,
}: SubscribeToCartUpdatedParams) => {
  logDev('(#21)subscribe to cartUpdated')
  connection.off(signalREvents.cartUpdated)
  connection.on(signalREvents.cartUpdated, (cart: mpCart) => {
    logDev('(#1)signalREvents.cartUpdated', cart)
    setCart(cart)
  })
}

export const subscribeFlCustomerUpdated: SubscribeFjordClubUpdated = ({
  connection,
  setFjordClubMembership,
  setFlCustomerLoading,
  setCustomerSuccessUpdated,
}: SubscribeFjordClubUpdatedParams) => {
  logDev('(#22)subscribe to flCustomerUpdated')
  connection.off(signalREvents.flCustomerUpdated)
  connection.on(signalREvents.flCustomerUpdated, (flCustomer: FlCustomer) => {
    if (flCustomer) {
      setFlCustomerLoading(false)
      if (flCustomer.active === true) {
        setCustomerSuccessUpdated(true)
      }
      logDev('(#20)signalREvents.flCustomerUpdated', flCustomer)
      setFjordClubMembership(flCustomer)
    }
  })
}
