import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Container, FormActionsWrapper, H2 } from '@fjordline/styles-v3'

import { useMyPage } from '../../providers/myPageStateProvider/context'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { trackEvent } from '../../providers/TelemetryProvider'
import { useGetCustomerLazyQuery } from '../../graphql'

function SignedUp() {
  useMemo(() => window.scrollTo(0, 0), [])
  const { customer } = useMyPage()

  const navigate = useNavigate()
  const { t } = useTranslation()
  sessionStorage.removeItem('nonMember')
  const { setFlCustomerLoading, flCustomerLoading, setCustomerSuccessUpdated, customerSuccessUpdated } =
    useWebSocketContext()

  const [loadCustomer] = useGetCustomerLazyQuery({})

  useEffect(() => {
    trackEvent('user landed on signed up page')
    loadCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (flCustomerLoading) {
      setFlCustomerLoading(false)
    }
    if (customerSuccessUpdated) {
      setCustomerSuccessUpdated(false)
    }
  }, [customerSuccessUpdated, flCustomerLoading, setCustomerSuccessUpdated, setFlCustomerLoading])

  return (
    <Container>
      <H2 style={{ marginBottom: '3rem' }}>{t('component.fjordClub.registered')}</H2>
      <FormActionsWrapper>
        <Button dataCy="go-to-my-travels-btn" onClick={() => navigate('/bookingList')} size="large">
          {t('label.signedUp.goToMyTravels')}
        </Button>
        {customer?.active ? (
          <Button dataCy="see-membership-btn" onClick={() => navigate('/fjordClub')} size="large" theme="secondary">
            {t('label.signedUp.seeMembership')}
          </Button>
        ) : null}
      </FormActionsWrapper>
    </Container>
  )
}

export default SignedUp
