import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { logDev } from '../../components/LogDev'
import { getModifyBookingClient } from '../../rest/clients'
import { trackEvent } from '../../providers/TelemetryProvider'
import { useKeycloak } from '../../providers/KeycloakProvider'

interface ICancelBookingResponse {
  currency: string
  cancellationFee: string
}

function useCancelBooking() {
  const { bookingCode } = useParams()
  const [cancelling, setCancelling] = useState(false)
  // const navigate = useNavigate()
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [isCancelled, setIsCancelled] = useState(false)

  const [cancellationFee, setCancellationFee] = useState<ICancelBookingResponse | undefined>(undefined)
  const { token, isAuthenticated: kc_isAuth } = useKeycloak()
  const isAuthenticated = kc_isAuth
  const [accessToken, setAccessToken] = useState<string>('')
  useEffect(() => {
    if (kc_isAuth && token) {
      setAccessToken(token)
    }
  }, [kc_isAuth, token])

  function getCancellationFee() {
    setCancelling(true)
    if (bookingCode && accessToken && isAuthenticated) {
      getModifyBookingClient(accessToken)
        ?.getCanellationFee(bookingCode)
        .then((res) => {
          logDev(res, 'cancelBooking response')
          if (res) {
            const response = res as ICancelBookingResponse
            setCancellationFee(response)
            setCancelling(false)
            setIsCancelled(false)
            trackEvent('user got cancellation fee')
          }
        })
        .catch((e) => {
          logDev(e, 'Cancellation fee - error')
          setError(e)
          setCancelling(false)
          setIsCancelled(false)
        })
        .finally(() => setCancelling(false))
    }
  }
  function cancelBooking() {
    setCancelling(true)
    if (bookingCode && accessToken && isAuthenticated) {
      getModifyBookingClient(accessToken)
        ?.cancelConfirmed(bookingCode)
        .then((res) => {
          if (res) {
            setCancelling(false)
            setIsCancelled(true)
            trackEvent('user cancelled booking')
          }
        })
        .catch((e) => {
          logDev(e, 'cancelBooking error')
          trackEvent('user failed to cancel booking')
          setError(e)
          setCancelling(false)
          setIsCancelled(false)
        })
        .finally(() => setCancelling(false))
    }
  }
  return {
    cancelBooking,
    cancelling,
    error,
    getCancellationFee,
    cancellationFee,
    isCancelled,
  }
}

export default useCancelBooking
