import styled from 'styled-components'

export const HomeDiv = styled.div`
  max-width: 100%;
  //width: -webkit-fill-available;
  margin: 0 auto;
  text-align: left;

  & .label {
    margin-left: 0;
  }

  & form > label:first-child {
    padding-left: 0;
    padding-right: 0;
    border: 0;
  }
`

export const LoginBackground = styled.div`
  width: 100%;
  height: 100%;

  .bg-container {
    position: fixed;
    z-index: -1;
    margin: -1.25rem 0 0 -1.25rem;

    .bg-overlay {
      box-shadow: inset 0px -20px 20px 0px #ffffff;
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .bg-img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
`
