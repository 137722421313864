import { AvailabilityMealItemWithSpec, Specification } from '../../../../graphql/types'
import { FareCode, Passenger } from '../../../../graphql/types'
import { IMeal } from '../../../../types/myPage/customerBookingDetails/types'

export function capitalizeFirstLetter(string) {
  if (string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase()?.replace(/_/g, ' ')
  } else return ''
}

export function findPassengerQuantityPerFareCode(passengers?: Passenger[]) {
  const adults = passengers?.filter((pass) => pass.fareCode === FareCode.Adult).length || 0
  const children = passengers?.filter((pass) => pass.fareCode === FareCode.Child).length || 0
  const infants = passengers?.filter((pass) => pass.fareCode === FareCode.Infant).length || 0
  const baby = passengers?.filter((pass) => pass.fareCode === FareCode.Baby).length || 0

  return { adults, children, infants, baby }
}

export function findMaxToBook(
  specs: Specification,
  adultPassengerCount: number,
  childPassengerCount: number,
  infantPassengerCount: number,
  babyPassengerCount: number,
  item,
  mealsInCart?: IMeal[],
) {
  let maxToBook

  const mealInCart = mealsInCart?.filter(
    (meal) => meal.code === item.code && new Date(meal.startTime).getTime() === new Date(item.startTime).getTime(),
  )
  const mealInCartQuantity =
    mealInCart?.reduce((acc, meal) => {
      return acc + meal.quantity
    }, 0) ?? 0

  const disableMeal = mealInCartQuantity === item.quantityAvailable
  const availableMeals = item.quantityAvailable - (mealInCartQuantity ?? 0)

  switch (specs.specificationCode) {
    case 'CLU':
      maxToBook = adultPassengerCount
      adultPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = adultPassengerCount)
      break
    case 'ADL':
      maxToBook = adultPassengerCount
      adultPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = adultPassengerCount)
      break
    case 'CHD':
      maxToBook = childPassengerCount
      childPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = childPassengerCount)
      break
    case 'INF':
      maxToBook = infantPassengerCount
      infantPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = infantPassengerCount)
      break
    case 'BABY':
      maxToBook = babyPassengerCount
      babyPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = babyPassengerCount)
      break
    default:
      maxToBook =
        adultPassengerCount + childPassengerCount <= specs.maximumQuantityToBook
          ? adultPassengerCount + childPassengerCount
          : specs.maximumQuantityToBook
      break
  }
  return { maxToBook, disableMeal, availableMeals }
}

export function findAvailableQuantityMeal(item: AvailabilityMealItemWithSpec, mealsInCart?: IMeal[]) {
  const mealInCart = mealsInCart?.filter(
    (meal) =>
      meal.code === item?.code && new Date(meal.startTime).getTime() === new Date(item?.startTime as string).getTime(),
  )
  const mealInCartQuantity =
    mealInCart?.reduce((acc, meal) => {
      return acc + meal.quantity
    }, 0) ?? 0

  const disableMeal = mealInCartQuantity === item?.quantityAvailable

  const availableMeals = item?.quantityAvailable - (mealInCartQuantity ?? 0)

  return { disableMeal, availableMeals }
}

export function getMealCount(mealsToAdd: IMeal[], specificationCode: string, startTime, code: string) {
  const itemCount = mealsToAdd?.find(
    (item) =>
      item?.subCode === specificationCode &&
      new Date(item?.startTime).getTime() === new Date(startTime).getTime() &&
      item.code === code,
  )

  return itemCount?.quantity || 0
}
