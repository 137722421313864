import { v4 as uuidv4 } from 'uuid'

import { FlBooking } from '../../graphql/types'
import { mpCart } from '../../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'

import { ICabin, IExtras, IMeal } from './customerBookingDetails/types'

export type Availability = {
  meals?: {
    inbound: { [date: string]: IMeal } // date as ISO date string
    outbound: { [date: string]: IMeal }
  }
  cabins?: {
    inbound: ICabin[]
    outbound: ICabin[]
  }
  extrasOnboard?: {
    inbound: IExtras[]
    outbound: IExtras[]
  }
  extrasAshore?: {
    inbound: IExtras[]
    outbound: IExtras[]
  }
}

export type modify<T> = {
  add?: T
  update?: T
  remove?: T
  noLongerAvailable?: T
}

export type IndexedBookings = { [bookingCode: string]: FlBooking }
export type CurrentAvailability = { [bookingCode: string]: Availability }

export const defaultMpCarts: mpCart = {
  bookingCarts: {},
  id: uuidv4(),
  timestamp: Date.now(),
}
