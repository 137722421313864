import styled from 'styled-components'

export const SelectedCabinContainer = styled.div`
  background: #fef3d8;
  border: 1px solid #fcba30;
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;

  div:first-child {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
`
