import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import {
  Button,
  Divider,
  Feedback,
  FormElementWrapper,
  mediaQuerySizes,
  PaginatedCarousel,
  PaginatedCarouselItem,
  Spinner,
} from '@fjordline/styles-v3'

import CustomBackButton from '../../components/CustomBackbutton'
import { BookingNavLink } from '../../routes/navLinkFunctions'

import { CarouselContainer } from './boardingCards/boardingCards.style'
import CarouselTicket from './tickets/CarouselTicket'
import TicketModal from './tickets/TicketModal'
import { TicketsStyle } from './tickets/tickets.styles'
import { trackEvent } from '../../providers/TelemetryProvider'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import LottieLoaderContainer from '../../components/LottieLoader'
import { useFetchEassetTicketByBookingCode, useResendTicketToCustomerEmail } from './tickets/useHandleEassets'
import useFetchSpecifications from '../../sanity/specifications/useFetchSpecifications'
import useHandleTimeoutResendButton from './tickets/useHandleTimeoutResendButton'
import { useKeycloak } from '../../providers/KeycloakProvider'
export type TicketData = {
  barcodeImage: string
  departureDate: string
  description: string
  subCode: string
  code: string
}

type ExternalTicket = {
  id: string
  name: string
  description: string
  properties: {
    eassetCode: string
    type: {
      code: string
      subCode: string
    }
    expires: string
    bookingId: string
    sendTo: string
    departureDate: string
    sent: string
    lastModified: string
    barcodeImage: string
  }
  ticketSourceId: string
}

const Tickets: React.FC = () => {
  const { bookingCode } = useParams()
  const { token: kc_token, isAuthenticated: kc_isAuth, isLoading: kc_isLoading } = useKeycloak()
  const isLoading = kc_isLoading
  const { flBookingLoading } = useBookingDetails()
  const { allSpecs } = useFetchSpecifications()
  const { t, i18n } = useTranslation()
  const mobileMaxWidth = `${mediaQuerySizes.small}px`
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMaxWidth})` })
  const [token, setToken] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (kc_isAuth && kc_token) {
      setToken(kc_token)
    }
  }, [kc_isAuth, kc_token])

  //Fetch tickets based on BookingCode from eAssets
  const {
    data: dataTickets,
    isLoading: isLoadingTickets,
    isError,
  } = useFetchEassetTicketByBookingCode({ code: bookingCode, token })

  const tickets = dataTickets?.data as ExternalTicket[]
  const [resend, setResend] = useState<boolean>(false)

  const {
    data: resendData,
    isFetching: resendIsFetching,
    isLoading: resendIsloading,
    isError: resendIsError,
  } = useResendTicketToCustomerEmail({ code: bookingCode, token, resend: resend })
  const [currentTicketSlide, setCurrentTicketSlide] = useState(0)
  const [ticketModalIsOpen, setTicketModalIsOpen] = useState<boolean>(false)
  const [focusedItem, setFocusedItem] = useState<string>()
  const { timerActive, handleStartTimer, seconds } = useHandleTimeoutResendButton()

  useEffect(() => {
    if (resend && (resendData || resendIsError || resendIsFetching || resendIsloading)) {
      setResend(false)
    }
  }, [resend, resendData, resendIsError, resendIsFetching, resendIsloading])

  useMemo(() => window.scrollTo(0, 0), [])

  const showlargeTicketImage = useCallback((item: string) => {
    setTicketModalIsOpen(true)
    setFocusedItem(item)
  }, [])

  useEffect(() => {
    trackEvent('user viewed tickets')
  }, [])

  const items =
    tickets &&
    (tickets?.map((ticket, index) => {
      const numbered = index + 1
      return {
        key: ticket.id,
        title: `${numbered}: ${ticket.name} - ${
          allSpecs?.find((e) => e.specificationCode === ticket.properties.type.subCode)?.specificationName?.[
            i18n.language
          ] || ticket.properties.type.subCode
        }`,
        data: {
          barcodeImage: ticket.properties.barcodeImage ? ticket.properties.barcodeImage : '',
          departureDate: ticket.properties.departureDate ? ticket.properties.departureDate : '',
          description: ticket.description ? ticket.description : '',
          code: ticket.properties.type.code ? ticket.properties.type.code : '',
          subCode: ticket.properties.type.subCode ? ticket.properties.type.subCode : '',
        },
      }
    }) as PaginatedCarouselItem<TicketData>[])

  const [disableResendButton, setDisableResendButton] = useState(false)

  if (flBookingLoading || isLoading || isLoadingTickets) {
    return <LottieLoaderContainer />
  }

  if (isError || dataTickets?.status === 204) {
    return (
      <>
        <FormElementWrapper>
          <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
        </FormElementWrapper>
        <Feedback type="warning" variant="notification">
          {t('label.tickets.ticketError')}
          <Divider />
          {t('label.tickets.ticketError_delay')}
        </Feedback>
      </>
    )
  }

  return (
    tickets &&
    !isLoadingTickets && (
      <TicketsStyle mobileMaxWidth={mobileMaxWidth} overrideMobile={tickets && tickets.length < 3 ? true : false}>
        <FormElementWrapper>
          <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
        </FormElementWrapper>
        <CarouselContainer>
          <PaginatedCarousel
            data-cy="tickets-carousel"
            dropdownPlaceholder={`${items?.[currentTicketSlide].title}`}
            currentSlide={currentTicketSlide}
            onChange={(index) => setCurrentTicketSlide(index)}
            items={items as PaginatedCarouselItem<TicketData>[]}
            renderItem={(item) => (
              <CarouselTicket
                isMobile={isMobile}
                item={item as PaginatedCarouselItem<TicketData>}
                showlargeTicketImage={showlargeTicketImage}
              />
            )}
          />
        </CarouselContainer>
        <TicketModal modalIsOpen={ticketModalIsOpen} setTicketModalIsOpen={setTicketModalIsOpen} src={focusedItem} />
        <div style={{ marginTop: '2rem' }} />
        {resendData?.status === 200 ? (
          <Feedback type={'success'} variant="notification">
            {t('label.tickets.resentTickets')} ({tickets?.[0]?.properties.sendTo})
          </Feedback>
        ) : null}
        {resendIsError || (resendData && resendData?.status >= 400) ? (
          <Feedback type={'error'} variant="notification">
            {t('label.tickets.resendTicketsFailed')}
          </Feedback>
        ) : null}

        <Button
          data-cy="resend-ticket-button"
          style={{ marginTop: '2rem' }}
          trailingIcon={resendIsloading ? Spinner : undefined}
          disabled={disableResendButton || resendIsloading || (timerActive && seconds > 0)}
          onClick={() => {
            setDisableResendButton(true)
            setTimeout(() => {
              setDisableResendButton(false)
            }, 30000)
            handleStartTimer()
            setResend(true)
          }}
        >
          {`${t('label.tickets.sendToEmail')} `}{' '}
          {!resendIsloading && timerActive && seconds > 0 ? `(${t('label.tickets.resendTimeout', { seconds })})` : null}
        </Button>
      </TicketsStyle>
    )
  )
}

export default Tickets
