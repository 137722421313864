import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: string; output: string }
  Long: { input: any; output: any }
  UUID: { input: string; output: string }
}

export type AccommodationInfo = {
  __typename?: 'AccommodationInfo'
  externalHotelId: Scalars['String']['output']
  facilities: Array<Scalars['String']['output']>
  images?: Maybe<Array<ImageInfo>>
  location: LocationInfo
  name: Scalars['String']['output']
  rooms: Array<RoomInfo>
  shortDesc: Scalars['String']['output']
  text: Scalars['String']['output']
}

export type AccommodationRequestInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>
  area?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  guests: Array<PassengerFareRequestInput>
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  inbound?: InputMaybe<JourneyRequestInput>
  journeyType: JourneyType
  language?: InputMaybe<Scalars['String']['input']>
  outbound: JourneyRequestInput
  passengers?: InputMaybe<Array<PassengerFareRequestInput>>
  region?: InputMaybe<Scalars['String']['input']>
  ticketCode: Scalars['String']['input']
  vehicles?: InputMaybe<Array<VehicleFareRequestInput>>
}

export type AccommodationResponse = {
  __typename?: 'AccommodationResponse'
  availableAccommodations: Array<AvailableAccommodation>
}

export type Agency = {
  __typename?: 'Agency'
  affiliateId: Scalars['String']['output']
  agencyId: Scalars['String']['output']
  auth0OrganizationId: Scalars['String']['output']
  countryId: Scalars['String']['output']
  created: Scalars['DateTime']['output']
  currencyId: Scalars['String']['output']
  deliveryAddress: Scalars['String']['output']
  invoiceEmailAddress: Scalars['String']['output']
  isFIT: Scalars['Boolean']['output']
  languageId: Scalars['String']['output']
  lastModified: Scalars['DateTime']['output']
  lastModifiedByOperator: Scalars['UUID']['output']
  name: Scalars['String']['output']
  phoneAreaCode: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  postalAddress: Scalars['String']['output']
  products: Array<AgencyProduct>
  zip: Scalars['String']['output']
}

export type AgencyOperator = {
  __typename?: 'AgencyOperator'
  agencyId: Scalars['String']['output']
  connectedAgencies: Array<Agency>
  created?: Maybe<Scalars['DateTime']['output']>
  currencyId: Scalars['String']['output']
  emailAddress: Scalars['String']['output']
  firstName: Scalars['String']['output']
  languageId: Scalars['String']['output']
  lastModified?: Maybe<Scalars['DateTime']['output']>
  lastModifiedByEmail: Scalars['String']['output']
  lastName: Scalars['String']['output']
  operatorId: Scalars['UUID']['output']
  personId: Scalars['UUID']['output']
  roleName: Scalars['String']['output']
  roleTypeId: Scalars['Int']['output']
}

export type AgencyOrder = {
  __typename?: 'AgencyOrder'
  agencyId: Scalars['String']['output']
  bookedByOperator?: Maybe<Scalars['UUID']['output']>
  bookingId?: Maybe<Scalars['String']['output']>
  created: Scalars['DateTime']['output']
  createdByOperator: Scalars['UUID']['output']
  deletionDate?: Maybe<Scalars['DateTime']['output']>
  lastModified?: Maybe<Scalars['DateTime']['output']>
  lastModifiedByOperator?: Maybe<Scalars['UUID']['output']>
  orderId: Scalars['UUID']['output']
  status: Scalars['String']['output']
}

export type AgencyProduct = {
  __typename?: 'AgencyProduct'
  allowVehicle: Scalars['Boolean']['output']
  domestic: Scalars['Boolean']['output']
  productCode: Scalars['String']['output']
  productName: Scalars['String']['output']
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type AvailabilityExtraItemWithSpec = {
  __typename?: 'AvailabilityExtraItemWithSpec'
  code: Scalars['String']['output']
  isCancellationInsurance: Scalars['Boolean']['output']
  isDogCage: Scalars['Boolean']['output']
  isPetInCar: Scalars['Boolean']['output']
  itemName?: Maybe<Scalars['String']['output']>
  itemType?: Maybe<Scalars['String']['output']>
  quantityAvailable: Scalars['Int']['output']
  specifications?: Maybe<Array<Specification>>
}

export type AvailabilityItemWithSpecOptions = {
  __typename?: 'AvailabilityItemWithSpecOptions'
  code: Scalars['String']['output']
  isLounge: Scalars['Boolean']['output']
  itemName?: Maybe<Scalars['String']['output']>
  itemType?: Maybe<Scalars['String']['output']>
  quantityAvailable: Scalars['Int']['output']
  specification: ItemSpecificationWithOptions
}

export type AvailabilityMealItemWithSpec = {
  __typename?: 'AvailabilityMealItemWithSpec'
  code: Scalars['String']['output']
  itemName?: Maybe<Scalars['String']['output']>
  itemType?: Maybe<Scalars['String']['output']>
  legCode: Scalars['String']['output']
  quantityAvailable: Scalars['Int']['output']
  specifications?: Maybe<Array<Specification>>
  startTime: Scalars['DateTime']['output']
}

export type AvailabilityRequestInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  inbound?: InputMaybe<JourneyRequestInput>
  journeyType: JourneyType
  language?: InputMaybe<Scalars['String']['input']>
  outbound: JourneyRequestInput
  passengers?: InputMaybe<Array<PassengerFareRequestInput>>
  ticketCode?: InputMaybe<Scalars['String']['input']>
  vehicles?: InputMaybe<Array<VehicleFareRequestInput>>
}

export type AvailableAccommodation = {
  __typename?: 'AvailableAccommodation'
  area?: Maybe<Scalars['String']['output']>
  category: Scalars['String']['output']
  checkinDate: Scalars['DateTime']['output']
  checkoutDate: Scalars['DateTime']['output']
  code: Scalars['String']['output']
  facilities?: Maybe<Array<Facility>>
  info?: Maybe<AccommodationInfo>
  name: Scalars['String']['output']
  region?: Maybe<Scalars['String']['output']>
  rooms: Array<AvailableRoom>
}

export type AvailableRoom = {
  __typename?: 'AvailableRoom'
  accommodationCode: Scalars['String']['output']
  code: Scalars['String']['output']
  info?: Maybe<RoomInfo>
  maxGuests: Scalars['Int']['output']
  minDuration?: Maybe<Scalars['Int']['output']>
  minGuests: Scalars['Int']['output']
  name: Scalars['String']['output']
  price: Price
  quantity: Scalars['Int']['output']
  quantityAvailable: Scalars['Int']['output']
}

export type BoardingCard = {
  __typename?: 'BoardingCard'
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  qrCode?: Maybe<Scalars['String']['output']>
  rowNumber?: Maybe<Scalars['String']['output']>
}

export type BookedAccommodation = {
  __typename?: 'BookedAccommodation'
  area?: Maybe<Scalars['String']['output']>
  category?: Maybe<Scalars['String']['output']>
  checkinDate: Scalars['DateTime']['output']
  checkoutDate: Scalars['DateTime']['output']
  code: Scalars['String']['output']
  guests?: Maybe<Array<Passenger>>
  name?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['Float']['output']>
  region?: Maybe<Scalars['String']['output']>
  rooms: Array<Room>
  rowNumber: Scalars['Int']['output']
}

export type BookingAttachmentInfo = {
  __typename?: 'BookingAttachmentInfo'
  attachmentIsPerPassenger: Scalars['Boolean']['output']
  code: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type BookingHeader = {
  __typename?: 'BookingHeader'
  bookingContactName: Scalars['String']['output']
  bookingId: Scalars['String']['output']
  bookingStatus: Scalars['String']['output']
  dateCreated?: Maybe<Scalars['DateTime']['output']>
  departureDate?: Maybe<Scalars['DateTime']['output']>
  destinationPortCode: Scalars['String']['output']
  orderId: Scalars['String']['output']
  originPortCode: Scalars['String']['output']
  routeName?: Maybe<Scalars['String']['output']>
}

export type Cabin = {
  __typename?: 'Cabin'
  bookedItemReference?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  edit?: Maybe<Scalars['String']['output']>
  groupCode?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  isAttachment: Scalars['Boolean']['output']
  price: Scalars['Float']['output']
  quantity: Scalars['Int']['output']
  quantityInCabin: Scalars['Int']['output']
  rowNumber: Scalars['Int']['output']
  subCode?: Maybe<Scalars['String']['output']>
}

export type CabinAvailabilityResponse = {
  __typename?: 'CabinAvailabilityResponse'
  availableCabins: Array<AvailabilityItemWithSpecOptions>
  availableSeats: Array<AvailabilityItemWithSpecOptions>
  departureCode: Scalars['String']['output']
  journeyCode: Scalars['String']['output']
  previewSeats: Scalars['Boolean']['output']
  suggestedCabins?: Maybe<CabinSuggestResponse>
  suggestedSeats?: Maybe<CabinSuggestResponse>
  ticketCode: Scalars['String']['output']
}

export type CabinRequestInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  departureCode: Scalars['String']['input']
  departureDate: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  fareType?: InputMaybe<Scalars['String']['input']>
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  isFjordClubMember?: InputMaybe<Scalars['Boolean']['input']>
  journeyCode: Scalars['String']['input']
  language?: InputMaybe<Scalars['String']['input']>
  originPort: Scalars['String']['input']
  passengers: Array<PassengerFareRequestInput>
  suggestOptions?: InputMaybe<SuggestOptionsInput>
  ticketCode: Scalars['String']['input']
  vesselCode?: InputMaybe<Scalars['String']['input']>
}

export type CabinSuggestResponse = {
  __typename?: 'CabinSuggestResponse'
  assignedPassengers: Scalars['Int']['output']
  cabins?: Maybe<Array<SuggestedCabin>>
  unassignedPassengers: Scalars['Int']['output']
}

export type CheckAvailabilityResponse = {
  __typename?: 'CheckAvailabilityResponse'
  isJourneyAvailable: Scalars['Boolean']['output']
}

export type ClearCacheResponse = {
  __typename?: 'ClearCacheResponse'
  isCacheCleared: Scalars['Boolean']['output']
}

export type ClubMembership = {
  __typename?: 'ClubMembership'
  active: Scalars['Boolean']['output']
  clubCode: Scalars['String']['output']
  clubLevelCode: Scalars['String']['output']
  clubTypeId: Scalars['Int']['output']
  memberSince?: Maybe<Scalars['DateTime']['output']>
  membershipNumber?: Maybe<Scalars['String']['output']>
}

export type Consent = {
  __typename?: 'Consent'
  allowContact: Scalars['Boolean']['output']
  consentVerified?: Maybe<Scalars['DateTime']['output']>
  consentsMetadata?: Maybe<ConsentsMetadata>
  type: ConsentType
  value?: Maybe<Scalars['String']['output']>
}

export enum ConsentType {
  Email = 'EMAIL',
  Mail = 'MAIL',
  Newsletter = 'NEWSLETTER',
  Sms = 'SMS',
  Unknown = 'UNKNOWN',
}

export type Consents = {
  __typename?: 'Consents'
  email?: Maybe<Scalars['Boolean']['output']>
  emailConsentUpdatedDate?: Maybe<Scalars['DateTime']['output']>
  mail?: Maybe<Scalars['Boolean']['output']>
  mailConsentUpdatedDate?: Maybe<Scalars['DateTime']['output']>
  newsletter?: Maybe<Scalars['Boolean']['output']>
  newsletterConsentUpdatedDate?: Maybe<Scalars['DateTime']['output']>
  sms?: Maybe<Scalars['Boolean']['output']>
  smsConsentUpdatedDate?: Maybe<Scalars['DateTime']['output']>
}

export type ConsentsMetadata = {
  __typename?: 'ConsentsMetadata'
  host: Scalars['String']['output']
  origin: Scalars['String']['output']
  referer: Scalars['String']['output']
  userAgent: Scalars['String']['output']
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  address?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country: Country
  email?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type ContactPerson = {
  __typename?: 'ContactPerson'
  contactInfo?: Maybe<ContactInfo>
  customerCode?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export enum Country {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gh = 'GH',
  Gi = 'GI',
  Gm = 'GM',
  Gn = 'GN',
  Gq = 'GQ',
  Gr = 'GR',
  Gt = 'GT',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mq = 'MQ',
  Mr = 'MR',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Ne = 'NE',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pt = 'PT',
  Py = 'PY',
  Qa = 'QA',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Si = 'SI',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Ve = 'VE',
  Vn = 'VN',
  Vu = 'VU',
  Ws = 'WS',
  Ye = 'YE',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export enum Currency {
  Dkk = 'DKK',
  Eur = 'EUR',
  Nok = 'NOK',
}

export type CustomerAddress = {
  __typename?: 'CustomerAddress'
  countryCode: Country
  postalAddress: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
}

export type CustomerConsents = {
  __typename?: 'CustomerConsents'
  consents: Consents
  created: Scalars['DateTime']['output']
  customerId?: Maybe<Scalars['String']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  lastModified: Scalars['DateTime']['output']
  lastModifiedBy?: Maybe<Scalars['String']['output']>
  personId: Scalars['UUID']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  uid: Scalars['UUID']['output']
}

export type CustomerLoyaltyPoints = {
  __typename?: 'CustomerLoyaltyPoints'
  balance: Scalars['Float']['output']
  earnedPoints: Scalars['Float']['output']
  recentlyEarnedPoints: Scalars['Float']['output']
  redeemablePoints: Scalars['Float']['output']
  soonExpiringPoints: Scalars['Float']['output']
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type Departure = {
  __typename?: 'Departure'
  arrivalTime: Scalars['DateTime']['output']
  closeTimeBeforeInMin: Scalars['Int']['output']
  departureCode: Scalars['String']['output']
  departureDate: Scalars['String']['output']
  departureStatus: Scalars['String']['output']
  departureTime: Scalars['DateTime']['output']
  estimatedDepartureTime?: Maybe<Scalars['DateTime']['output']>
  isDomestic: Scalars['Boolean']['output']
  journeyCode: Scalars['String']['output']
  productCodes: Array<Scalars['String']['output']>
  vesselCode: Scalars['String']['output']
}

export type DepartureAvailability = {
  __typename?: 'DepartureAvailability'
  arrivalDate: Scalars['DateTime']['output']
  checkinDate?: Maybe<Scalars['DateTime']['output']>
  departureCode: Scalars['String']['output']
  departureDate: Scalars['DateTime']['output']
  duration: Scalars['Int']['output']
  isDomestic: Scalars['Boolean']['output']
  journeyCode: Scalars['String']['output']
  tickets?: Maybe<Array<ExtendedTicket>>
  vesselCode: Scalars['String']['output']
}

export type DepartureCalendar = {
  __typename?: 'DepartureCalendar'
  departures: Array<Departure>
  portFrom?: Maybe<Scalars['String']['output']>
  portTo?: Maybe<Scalars['String']['output']>
  routeName?: Maybe<Scalars['String']['output']>
  vessels: Array<Scalars['String']['output']>
}

export type DepartureCalendarRequestInput = {
  destinationPort: Scalars['String']['input']
  fromDate: Scalars['DateTime']['input']
  journeyType?: InputMaybe<JourneyType>
  originPort: Scalars['String']['input']
  productCode?: InputMaybe<Scalars['String']['input']>
  toDate: Scalars['DateTime']['input']
}

export type DepartureRouteResponse = {
  __typename?: 'DepartureRouteResponse'
  routes: Array<DepartureCalendar>
}

export type DisabilityInfo = {
  __typename?: 'DisabilityInfo'
  disability: Scalars['Boolean']['output']
  disabilityNote?: Maybe<Scalars['String']['output']>
}

export type Discount = {
  __typename?: 'Discount'
  code?: Maybe<Scalars['String']['output']>
  price: Scalars['Float']['output']
  quantity: Scalars['Int']['output']
  rowNumber: Scalars['Int']['output']
}

export type ExtendedTicket = {
  __typename?: 'ExtendedTicket'
  fareType: FareType
  fares: Array<PersonFare>
  isEconomyOrFlex: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  ticketCode: Scalars['String']['output']
  vehicleFares?: Maybe<Array<VehicleFare>>
  vehiclesAllowed: Scalars['Boolean']['output']
}

export type Extra = {
  __typename?: 'Extra'
  code?: Maybe<Scalars['String']['output']>
  isAttachment: Scalars['Boolean']['output']
  price?: Maybe<Scalars['Float']['output']>
  quantity: Scalars['Int']['output']
  rowNumber: Scalars['Int']['output']
  startTime?: Maybe<Scalars['DateTime']['output']>
  subCode?: Maybe<Scalars['String']['output']>
}

export type ExtrasAvailabilityResponse = {
  __typename?: 'ExtrasAvailabilityResponse'
  availableExtrasAshore: Array<AvailabilityExtraItemWithSpec>
  availableExtrasOnboard: Array<AvailabilityExtraItemWithSpec>
  departureCode: Scalars['String']['output']
  headerImage?: Maybe<ImageInfo>
  journeyCode: Scalars['String']['output']
  ticketCode: Scalars['String']['output']
}

export type ExtrasRequestInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  departureCode: Scalars['String']['input']
  departureDate: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  fareType?: InputMaybe<Scalars['String']['input']>
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  isFjordClubMember?: InputMaybe<Scalars['Boolean']['input']>
  journeyCode: Scalars['String']['input']
  language?: InputMaybe<Scalars['String']['input']>
  originPort: Scalars['String']['input']
  ticketCode: Scalars['String']['input']
  vesselCode?: InputMaybe<Scalars['String']['input']>
}

export type Facility = {
  __typename?: 'Facility'
  description?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export enum FareCode {
  Adult = 'ADULT',
  Baby = 'BABY',
  Child = 'CHILD',
  Infant = 'INFANT',
}

export enum FareType {
  CruiseAshore = 'CRUISE_ASHORE',
  CruiseOnboard = 'CRUISE_ONBOARD',
  Custom = 'CUSTOM',
  Economy = 'ECONOMY',
  Fjordclub = 'FJORDCLUB',
  Flex = 'FLEX',
}

export type FlBooking = {
  __typename?: 'FlBooking'
  accommodations?: Maybe<Array<BookedAccommodation>>
  agencyId?: Maybe<Scalars['String']['output']>
  boardingCards: Array<BoardingCard>
  bookingCode?: Maybe<Scalars['String']['output']>
  canBuyBookingItems: Scalars['Boolean']['output']
  contactPerson?: Maybe<ContactPerson>
  created: Scalars['DateTime']['output']
  currency: Currency
  hasReturnJourney: Scalars['Boolean']['output']
  id?: Maybe<Scalars['String']['output']>
  inbound?: Maybe<Journey>
  isAgencyBooking: Scalars['Boolean']['output']
  isCancelled: Scalars['Boolean']['output']
  isInFuture: Scalars['Boolean']['output']
  isInPast: Scalars['Boolean']['output']
  isManuallyCancelled: Scalars['Boolean']['output']
  isOngoing: Scalars['Boolean']['output']
  language?: Maybe<Scalars['String']['output']>
  lastArrivalDate?: Maybe<Scalars['DateTime']['output']>
  lastModified: Scalars['DateTime']['output']
  lastModifiedByUser?: Maybe<Scalars['String']['output']>
  modificationDeadline: Scalars['DateTime']['output']
  nothingPaid: Scalars['Boolean']['output']
  outbound?: Maybe<Journey>
  passengerDetails?: Maybe<Array<Passenger>>
  paymentBalance: Scalars['Float']['output']
  promotionCode?: Maybe<Scalars['String']['output']>
  referralCode?: Maybe<Scalars['String']['output']>
  showBoardingCards: Scalars['Boolean']['output']
  statusCode?: Maybe<Scalars['String']['output']>
  totalPrice: Scalars['Float']['output']
  username?: Maybe<Scalars['String']['output']>
  voucherCode?: Maybe<Scalars['String']['output']>
}

export type FlCode = {
  __typename?: 'FlCode'
  codeType: FlCodeType
  codeValue: Scalars['String']['output']
  isValidCode: Scalars['Boolean']['output']
}

export enum FlCodeType {
  AgencyCode = 'AGENCY_CODE',
  CustomerCode = 'CUSTOMER_CODE',
  PackageCode = 'PACKAGE_CODE',
  PromotionCode = 'PROMOTION_CODE',
  ReferralCode = 'REFERRAL_CODE',
  TicketCode = 'TICKET_CODE',
  Unknown = 'UNKNOWN',
  VoucherCode = 'VOUCHER_CODE',
}

export type FlCustomer = {
  __typename?: 'FlCustomer'
  active: Scalars['Boolean']['output']
  address: CustomerAddress
  birthDay: Scalars['Int']['output']
  birthMonth: Scalars['Int']['output']
  birthYear: Scalars['Int']['output']
  bookingIds?: Maybe<Array<Scalars['String']['output']>>
  clubMemberships?: Maybe<Array<ClubMembership>>
  consents?: Maybe<Array<Consent>>
  customerCode?: Maybe<Scalars['String']['output']>
  customerConsents?: Maybe<CustomerConsents>
  customerLoyaltyPoints?: Maybe<CustomerLoyaltyPoints>
  defaultDeparturePortCode?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  gender: Gender
  lastName: Scalars['String']['output']
  loyaltyPoints?: Maybe<CustomerLoyaltyPoints>
  meansOfContacts: Array<MeansOfContact>
  nationalityCode: Country
  personId?: Maybe<Scalars['UUID']['output']>
  travelFriends?: Maybe<Array<TravelFriend>>
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Organization = 'ORGANIZATION',
  Unknown = 'UNKNOWN',
  Unspecified = 'UNSPECIFIED',
}

export type GroupedBookingResponse = {
  __typename?: 'GroupedBookingResponse'
  cancelled: Array<FlBooking>
  future: Array<FlBooking>
  ongoing: Array<FlBooking>
  passed: Array<FlBooking>
}

export type Id = {
  __typename?: 'Id'
  idNumber?: Maybe<Scalars['String']['output']>
  idType: IdType
}

export enum IdType {
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT',
}

export type ImageInfo = {
  __typename?: 'ImageInfo'
  altText: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ItemFacility = {
  __typename?: 'ItemFacility'
  description: Scalars['String']['output']
  iconUrl: Scalars['String']['output']
}

export type ItemSpecificationWithOptions = {
  __typename?: 'ItemSpecificationWithOptions'
  /** @deprecated No longer in use. It will be removed in the future */
  itemFacilities?: Maybe<Array<ItemFacility>>
  maximumQuantityToBook: Scalars['Long']['output']
  minimumQuantityToBook: Scalars['Int']['output']
  options: Array<SpecificationOption>
  specificationCode: Scalars['String']['output']
  specificationName?: Maybe<Scalars['String']['output']>
}

export type Journey = {
  __typename?: 'Journey'
  arrivalDate: Scalars['DateTime']['output']
  arrivalPort: PortCode
  arrivalPortInfo?: Maybe<PortInfo>
  cabins?: Maybe<Array<Cabin>>
  departureCode?: Maybe<Scalars['String']['output']>
  departureDate: Scalars['DateTime']['output']
  departurePort: PortCode
  departurePortInfo?: Maybe<PortInfo>
  discounts?: Maybe<Array<Discount>>
  extrasAshore?: Maybe<Array<Extra>>
  extrasAshoreWithOptions?: Maybe<Array<Extra>>
  extrasOnboard?: Maybe<Array<Extra>>
  journeyCode?: Maybe<Scalars['String']['output']>
  journeyNumber: Scalars['Int']['output']
  meals?: Maybe<Array<Meal>>
  passengerPrices?: Maybe<Array<PassengerPrice>>
  passengers?: Maybe<Array<Passenger>>
  secondArrivalPort?: Maybe<PortCode>
  secondDeparturePort?: Maybe<PortCode>
  ticket?: Maybe<Ticket>
  vehicles?: Maybe<Array<Vehicle>>
  vesselCode?: Maybe<Scalars['String']['output']>
}

export type JourneyAvailability = {
  __typename?: 'JourneyAvailability'
  departurePort: Port
  destination: JourneyDestination
}

export type JourneyAvailabilityResponse = {
  __typename?: 'JourneyAvailabilityResponse'
  disclaimer?: Maybe<Scalars['String']['output']>
  inbound?: Maybe<JourneyAvailability>
  isPackage: Scalars['Boolean']['output']
  isReturn: Scalars['Boolean']['output']
  journeyType: JourneyType
  outbound: JourneyAvailability
  responseTime?: Maybe<Scalars['Int']['output']>
}

export type JourneyDestination = {
  __typename?: 'JourneyDestination'
  arrivalPort: Port
  availability: Array<DepartureAvailability>
  cabinsRequired: Scalars['Boolean']['output']
  seatsRequired: Scalars['Boolean']['output']
}

export type JourneyRequestInput = {
  arrivalDate?: InputMaybe<Scalars['String']['input']>
  departureCode?: InputMaybe<Scalars['String']['input']>
  departureDate: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  originPort: Scalars['String']['input']
  passengers?: InputMaybe<Array<PassengerFareRequestInput>>
  tickets?: InputMaybe<Array<TicketFareRequestInput>>
  vehicles?: InputMaybe<Array<VehicleFareRequestInput>>
}

export type JourneyRules = {
  __typename?: 'JourneyRules'
  allowDifferentReturn: Scalars['Boolean']['output']
  defaultJourneyCode?: Maybe<Scalars['String']['output']>
  destinationPorts?: Maybe<Array<Scalars['String']['output']>>
  forceExtras: Scalars['Boolean']['output']
  journeyTypes?: Maybe<Array<JourneyType>>
  minDaysBeforeReturn: Scalars['Int']['output']
  originPorts?: Maybe<Array<Scalars['String']['output']>>
  outboundDate?: Maybe<Scalars['DateTime']['output']>
  outboundDateLocked: Scalars['Boolean']['output']
  ports?: Maybe<Array<Scalars['String']['output']>>
  returnDate?: Maybe<Scalars['DateTime']['output']>
  returnDateLocked: Scalars['Boolean']['output']
  ticketCode?: Maybe<Scalars['String']['output']>
}

export enum JourneyType {
  Cruise = 'CRUISE',
  Oneway = 'ONEWAY',
  Package = 'PACKAGE',
  Return = 'RETURN',
}

export type LocaleHtmlContent = {
  __typename?: 'LocaleHtmlContent'
  da?: Maybe<Scalars['String']['output']>
  de?: Maybe<Scalars['String']['output']>
  en?: Maybe<Scalars['String']['output']>
  nb?: Maybe<Scalars['String']['output']>
  nl?: Maybe<Scalars['String']['output']>
}

export type LocationInfo = {
  __typename?: 'LocationInfo'
  address: Scalars['String']['output']
}

export type Meal = {
  __typename?: 'Meal'
  code?: Maybe<Scalars['String']['output']>
  groupCode?: Maybe<Scalars['String']['output']>
  isAttachment: Scalars['Boolean']['output']
  isOutbound?: Maybe<Scalars['Boolean']['output']>
  legCode?: Maybe<Scalars['String']['output']>
  price: Scalars['Float']['output']
  quantity: Scalars['Int']['output']
  rowNumber: Scalars['Int']['output']
  startTime: Scalars['DateTime']['output']
  subCode?: Maybe<Scalars['String']['output']>
}

export type MealAvailabilityResponse = {
  __typename?: 'MealAvailabilityResponse'
  availableMeals?: Maybe<Array<AvailabilityMealItemWithSpec>>
  departureCode: Scalars['String']['output']
  headerImage?: Maybe<ImageInfo>
  journeyCode: Scalars['String']['output']
  ticketCode: Scalars['String']['output']
}

export type MealRequestInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  departureCode: Scalars['String']['input']
  departureDate: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  fareType?: InputMaybe<Scalars['String']['input']>
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  isFjordClubMember?: InputMaybe<Scalars['Boolean']['input']>
  journeyCode: Scalars['String']['input']
  language?: InputMaybe<Scalars['String']['input']>
  originPort: Scalars['String']['input']
  ticketCode: Scalars['String']['input']
  vesselCode?: InputMaybe<Scalars['String']['input']>
}

export type MeansOfContact = {
  __typename?: 'MeansOfContact'
  type: MeansOfContactType
  value: Scalars['String']['output']
}

export enum MeansOfContactType {
  Email = 'EMAIL',
  Mail = 'MAIL',
  Mob = 'MOB',
  Unknown = 'UNKNOWN',
}

export type NonRegisteredTravelFriendInfo = {
  __typename?: 'NonRegisteredTravelFriendInfo'
  birthDate: Scalars['DateTime']['output']
  disabilityInfo?: Maybe<DisabilityInfo>
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  guestTypeCode?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  middleName?: Maybe<Scalars['String']['output']>
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>
  nationalityCode?: Maybe<Scalars['String']['output']>
  sendConfirmationViaEmail: Scalars['Boolean']['output']
  sendConfirmationViaMobilePhoneNumber: Scalars['Boolean']['output']
}

export type PackageRulesResponse = {
  __typename?: 'PackageRulesResponse'
  daysOfWeek: Array<DayOfWeek>
  journeyRules: JourneyRules
  packageEnd?: Maybe<Scalars['DateTime']['output']>
  packageStart?: Maybe<Scalars['DateTime']['output']>
  passengerRules: Array<PassengerRule>
  vehicleRules: VehicleRules
}

export type Passenger = {
  __typename?: 'Passenger'
  birthDay: Scalars['Int']['output']
  birthMonth: Scalars['Int']['output']
  birthYear: Scalars['Int']['output']
  customerCode?: Maybe<Scalars['String']['output']>
  disabledPerson: Scalars['Boolean']['output']
  fareCode: FareCode
  firstName?: Maybe<Scalars['String']['output']>
  gender: Gender
  id?: Maybe<Id>
  lastName?: Maybe<Scalars['String']['output']>
  nationality: Country
  rowNumber: Scalars['Int']['output']
}

export type PassengerFareRequestInput = {
  code: FareCode
  quantity: Scalars['Int']['input']
}

export type PassengerPrice = {
  __typename?: 'PassengerPrice'
  code: FareCode
  price?: Maybe<Scalars['Float']['output']>
}

export type PassengerRule = {
  __typename?: 'PassengerRule'
  code: FareCode
  locked: Scalars['Boolean']['output']
  quantity: Scalars['Int']['output']
}

export type PersonFare = {
  __typename?: 'PersonFare'
  code: FareCode
  hasPrice: Scalars['Boolean']['output']
  itemDescription?: Maybe<Scalars['String']['output']>
  itemName?: Maybe<Scalars['String']['output']>
  price: Price
}

export type Port = {
  __typename?: 'Port'
  allowUserSelection: Scalars['Boolean']['output']
  carresPortCode: Scalars['String']['output']
  closeTimeMin: Scalars['Int']['output']
  countryCode: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  isEnabled: Scalars['Boolean']['output']
  portCode: Scalars['String']['output']
  portName: Scalars['String']['output']
}

export enum PortCode {
  Dkaal = 'DKAAL',
  Dkcph = 'DKCPH',
  Dkhir = 'DKHIR',
  Nobgo = 'NOBGO',
  Nokrs = 'NOKRS',
  Nolad = 'NOLAD',
  Nosvg = 'NOSVG',
  Notrf = 'NOTRF',
  Sesmd = 'SESMD',
}

export type PortInfo = {
  __typename?: 'PortInfo'
  allowUserSelection: Scalars['Boolean']['output']
  carresPortCode: Scalars['String']['output']
  closeTimeMin: Scalars['Int']['output']
  countryCode: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  isEnabled: Scalars['Boolean']['output']
  portCode: Scalars['String']['output']
  portName: Scalars['String']['output']
}

export type PortLeg = {
  __typename?: 'PortLeg'
  allowedDestinations: Array<PortLegDestination>
  originPort: Port
}

export type PortLegDestination = {
  __typename?: 'PortLegDestination'
  expectCabins: Scalars['Boolean']['output']
  expectExtras: Scalars['Boolean']['output']
  expectMeals: Scalars['Boolean']['output']
  portCode: Scalars['String']['output']
  portName: Scalars['String']['output']
}

export type Price = {
  __typename?: 'Price'
  available?: Maybe<Scalars['Boolean']['output']>
  currency: Currency
  value: Scalars['Float']['output']
}

export type ProductRules = {
  __typename?: 'ProductRules'
  allowedJourneys?: Maybe<Array<Maybe<SimpleCarresJourneyRule>>>
  bookingAttachments?: Maybe<Array<BookingAttachmentInfo>>
  fareType: FareType
  hasJourneys: Scalars['Boolean']['output']
  journeys: Array<SimpleCarresJourneyRule>
  maxDaysBeforeDeparture: Scalars['Int']['output']
  maxNumPassengers: Scalars['Int']['output']
  maxNumVehicles: Scalars['Int']['output']
  minDaysBeforeDeparture: Scalars['Int']['output']
  minNumPassengers: Scalars['Int']['output']
  minNumVehicles: Scalars['Int']['output']
  payableWithClubPoints: Scalars['Boolean']['output']
  productCode: Scalars['String']['output']
  productName: Scalars['String']['output']
  productTypes: Array<Scalars['String']['output']>
  showOnlyTotalPrice: Scalars['Boolean']['output']
  vehiclesAllowed: Scalars['Boolean']['output']
  vehiclesNotRequired: Scalars['Boolean']['output']
  vehiclesOptional: Scalars['Boolean']['output']
  vehiclesRequired: Scalars['Boolean']['output']
}

export type PromotionRules = {
  __typename?: 'PromotionRules'
  bannerBgColor?: Maybe<Scalars['String']['output']>
  bannerImageUrl?: Maybe<Scalars['String']['output']>
  bannerLocaleContentLong?: Maybe<LocaleHtmlContent>
  bannerLocaleContentShort?: Maybe<LocaleHtmlContent>
  /** @deprecated Use BannerLocaleContentShort */
  bannerText?: Maybe<LocaleHtmlContent>
  isActive: Scalars['Boolean']['output']
  isEnabled: Scalars['Boolean']['output']
  promotionCode: Scalars['String']['output']
  promotionEndDate: Scalars['DateTime']['output']
  promotionJourneyTypes: Array<Scalars['String']['output']>
  promotionRouteCodes: Array<Scalars['String']['output']>
  promotionStartDate: Scalars['DateTime']['output']
  travelEndDate: Scalars['DateTime']['output']
  travelStartDate: Scalars['DateTime']['output']
}

export type Query = {
  __typename?: 'Query'
  accommodationAvailability: AccommodationResponse
  agencies: Array<Agency>
  agency: Agency
  agencyOrders: Array<AgencyOrder>
  allRoutes: Array<Route>
  availability: CheckAvailabilityResponse
  booking: FlBooking
  bookingHeaders: Array<BookingHeader>
  bookingList: Array<FlBooking>
  bookings: GroupedBookingResponse
  cabinAvailability: CabinAvailabilityResponse
  clearAllCache: ClearCacheResponse
  clearDepartureCache: ClearCacheResponse
  clearGraphQlCache: ClearCacheResponse
  clearProductsCache: ClearCacheResponse
  /** @deprecated Use clear GraphQlCache instead */
  clearSanityCache: ClearCacheResponse
  customer: FlCustomer
  departure?: Maybe<Departure>
  departureCalendar: Array<Departure>
  departureRoutes: DepartureRouteResponse
  departures: Array<Departure>
  departuresByPackageCode: Array<Departure>
  extrasAvailability: ExtrasAvailabilityResponse
  flCode: FlCode
  isActiveFjordClubMember: Scalars['Boolean']['output']
  isHealthy: Scalars['Boolean']['output']
  isProductCodeValid: Scalars['Boolean']['output']
  isRunning: Scalars['Boolean']['output']
  isValidPromotionCode: Scalars['Boolean']['output']
  journeyAvailability: JourneyAvailabilityResponse
  journeyAvailabilityForAgency: JourneyAvailabilityResponse
  journeyTypes: Array<JourneyType>
  mealAvailability: MealAvailabilityResponse
  operator?: Maybe<AgencyOperator>
  packageRules?: Maybe<PackageRulesResponse>
  port?: Maybe<Port>
  portLegs: Array<PortLeg>
  ports: Array<Port>
  /** @deprecated Use GetPossibleReturnPortLegsForRoute */
  possiblePortLegsByRoute: Array<PortLeg>
  possibleReturnPortLegsForRoute: Array<PortLeg>
  product?: Maybe<SimpleCarresProduct>
  productRules?: Maybe<ProductRules>
  promotionRules?: Maybe<PromotionRules>
  route: Route
  /** @deprecated Use GetDepartureRoutesAsync */
  routes: DepartureRouteResponse
  suggest: CabinSuggestResponse
  suggestedCabins: CabinSuggestResponse
  suggestedLounges: CabinSuggestResponse
  suggestedSeats: CabinSuggestResponse
}

export type QueryAccommodationAvailabilityArgs = {
  request: AccommodationRequestInput
}

export type QueryAgencyArgs = {
  agencyId: Scalars['String']['input']
}

export type QueryAvailabilityArgs = {
  requestParams: AvailabilityRequestInput
}

export type QueryBookingArgs = {
  bookingCode: Scalars['String']['input']
}

export type QueryBookingHeadersArgs = {
  agencyId: Scalars['String']['input']
}

export type QueryCabinAvailabilityArgs = {
  request: CabinRequestInput
}

export type QueryDepartureArgs = {
  departureCode: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  journeyCode: Scalars['String']['input']
  originPort: Scalars['String']['input']
}

export type QueryDepartureCalendarArgs = {
  departureRequest: DepartureCalendarRequestInput
}

export type QueryDepartureRoutesArgs = {
  departureRequest: DepartureCalendarRequestInput
}

export type QueryDeparturesArgs = {
  destinationPort: Scalars['String']['input']
  fromDate?: InputMaybe<Scalars['DateTime']['input']>
  journeyType?: InputMaybe<JourneyType>
  originPort: Scalars['String']['input']
  productCode?: InputMaybe<Scalars['String']['input']>
  toDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryDeparturesByPackageCodeArgs = {
  destinationPort: Scalars['String']['input']
  originPort: Scalars['String']['input']
  packageCode: Scalars['Int']['input']
}

export type QueryExtrasAvailabilityArgs = {
  request: ExtrasRequestInput
}

export type QueryFlCodeArgs = {
  code: Scalars['String']['input']
}

export type QueryIsProductCodeValidArgs = {
  productCode: Scalars['String']['input']
}

export type QueryIsValidPromotionCodeArgs = {
  promotionCode: Scalars['String']['input']
}

export type QueryJourneyAvailabilityArgs = {
  requestParams: AvailabilityRequestInput
}

export type QueryJourneyAvailabilityForAgencyArgs = {
  requestParams: AvailabilityRequestInput
}

export type QueryJourneyTypesArgs = {
  productCode?: InputMaybe<Scalars['String']['input']>
}

export type QueryMealAvailabilityArgs = {
  request: MealRequestInput
}

export type QueryPackageRulesArgs = {
  packageId: Scalars['Int']['input']
}

export type QueryPortArgs = {
  portCode: Scalars['String']['input']
}

export type QueryPortLegsArgs = {
  journeyType?: InputMaybe<JourneyType>
  productCode?: InputMaybe<Scalars['String']['input']>
}

export type QueryPortsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPossiblePortLegsByRouteArgs = {
  destinationPortCode: Scalars['String']['input']
  originPortCode: Scalars['String']['input']
}

export type QueryPossibleReturnPortLegsForRouteArgs = {
  destinationPortCode: Scalars['String']['input']
  originPortCode: Scalars['String']['input']
}

export type QueryProductArgs = {
  productCode: Scalars['String']['input']
}

export type QueryProductRulesArgs = {
  language?: InputMaybe<Scalars['String']['input']>
  productCode: Scalars['String']['input']
}

export type QueryPromotionRulesArgs = {
  promotionCode: Scalars['String']['input']
}

export type QueryRouteArgs = {
  destinationPortCode: Scalars['String']['input']
  originPortCode: Scalars['String']['input']
}

export type QueryRoutesArgs = {
  departureRequest: DepartureCalendarRequestInput
}

export type QuerySuggestArgs = {
  suggestRequest: SuggestRequestInput
}

export type QuerySuggestedCabinsArgs = {
  suggestRequest: SuggestRequestInput
}

export type QuerySuggestedLoungesArgs = {
  suggestRequest: SuggestRequestInput
}

export type QuerySuggestedSeatsArgs = {
  suggestRequest: SuggestRequestInput
}

export type RegisteredTravelFriendInfo = {
  __typename?: 'RegisteredTravelFriendInfo'
  customerCode?: Maybe<Scalars['String']['output']>
}

export type Room = {
  __typename?: 'Room'
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  price: Price
  quantity: Scalars['Int']['output']
}

export type RoomInfo = {
  __typename?: 'RoomInfo'
  externalRoomId: Scalars['String']['output']
  images?: Maybe<Array<ImageInfo>>
  name?: Maybe<Scalars['String']['output']>
  shortDesc?: Maybe<Scalars['String']['output']>
}

export type Route = {
  __typename?: 'Route'
  cabinsRequired: Scalars['Boolean']['output']
  carresRouteCode: Scalars['String']['output']
  destinationPortCode: Scalars['String']['output']
  disable: Scalars['Boolean']['output']
  expectCabins: Scalars['Boolean']['output']
  expectExtras: Scalars['Boolean']['output']
  expectMeals: Scalars['Boolean']['output']
  isCruise: Scalars['Boolean']['output']
  isDomestic: Scalars['Boolean']['output']
  originPortCode: Scalars['String']['output']
  possibleReturnRouteCodes?: Maybe<Array<Scalars['String']['output']>>
  routeCode: Scalars['String']['output']
  routeName: Scalars['String']['output']
  seatsRequired: Scalars['Boolean']['output']
}

export type SimpleCarresJourneyRule = {
  __typename?: 'SimpleCarresJourneyRule'
  allowedDaysOfWeek: Array<DayOfWeek>
  destinationPort: Scalars['String']['output']
  fromDate: Scalars['DateTime']['output']
  journeyCode: Scalars['String']['output']
  originPort: Scalars['String']['output']
  requireReturnOnSameDay: Scalars['Boolean']['output']
  toDate: Scalars['DateTime']['output']
}

export type SimpleCarresProduct = {
  __typename?: 'SimpleCarresProduct'
  fareType: FareType
  maxNumVehicles: Scalars['Int']['output']
  minNumVehicles: Scalars['Int']['output']
  productCode: Scalars['String']['output']
  productName: Scalars['String']['output']
  productTypes: Array<Scalars['String']['output']>
  vehiclesAllowed: Scalars['Boolean']['output']
  vehiclesNotRequired: Scalars['Boolean']['output']
  vehiclesOptional: Scalars['Boolean']['output']
  vehiclesRequired: Scalars['Boolean']['output']
}

export type Specification = {
  __typename?: 'Specification'
  maximumQuantityToBook: Scalars['Long']['output']
  minimumQuantityToBook: Scalars['Int']['output']
  specificationCode: Scalars['String']['output']
  specificationName?: Maybe<Scalars['String']['output']>
  specificationPrice: Price
}

export type SpecificationOption = {
  __typename?: 'SpecificationOption'
  maxQuantity: Scalars['Long']['output']
  optionName?: Maybe<Scalars['String']['output']>
  optionPrice: Price
  priceIsPerItem: Scalars['Boolean']['output']
  priceIsPerPassenger: Scalars['Boolean']['output']
}

export enum SuggestCriteria {
  Comfort = 'COMFORT',
  LowestFare = 'LOWEST_FARE',
}

export enum SuggestItemGroup {
  ExteriorCabins = 'EXTERIOR_CABINS',
  InteriorCabins = 'INTERIOR_CABINS',
  Lounges = 'LOUNGES',
  Seats = 'SEATS',
}

export type SuggestOptionsInput = {
  criteria?: InputMaybe<SuggestCriteria>
  itemCodes?: InputMaybe<Array<Scalars['String']['input']>>
  preferredSuggestGroup?: InputMaybe<SuggestItemGroup>
}

export type SuggestRequestInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  departureCode: Scalars['String']['input']
  departureDate: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  fareType?: InputMaybe<Scalars['String']['input']>
  hasVehicles?: InputMaybe<Scalars['Boolean']['input']>
  isFjordClubMember?: InputMaybe<Scalars['Boolean']['input']>
  itemCode?: InputMaybe<Scalars['String']['input']>
  journeyCode: Scalars['String']['input']
  language?: InputMaybe<Scalars['String']['input']>
  originPort: Scalars['String']['input']
  passengers: Array<PassengerFareRequestInput>
  suggestOptions?: InputMaybe<SuggestOptionsInput>
  ticketCode: Scalars['String']['input']
  vesselCode?: InputMaybe<Scalars['String']['input']>
}

export type SuggestedCabin = {
  __typename?: 'SuggestedCabin'
  code: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  price: Price
  quantityInCabin: Scalars['Int']['output']
}

export type Ticket = {
  __typename?: 'Ticket'
  fareType: FareType
  ticketCode?: Maybe<Scalars['String']['output']>
}

export type TicketFareRequestInput = {
  fareType?: InputMaybe<FareType>
  name?: InputMaybe<Scalars['String']['input']>
  ticketCode: Scalars['String']['input']
}

export type TravelFriend = {
  __typename?: 'TravelFriend'
  countryCode?: Maybe<Scalars['String']['output']>
  friendTypeCode?: Maybe<Scalars['String']['output']>
  nonRegisteredCustomerInfo?: Maybe<NonRegisteredTravelFriendInfo>
  registeredCustomerInfo?: Maybe<RegisteredTravelFriendInfo>
  rowNumber?: Maybe<Scalars['Int']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Vehicle = {
  __typename?: 'Vehicle'
  code?: Maybe<Scalars['String']['output']>
  groupCode?: Maybe<Scalars['String']['output']>
  hasTrailer?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['String']['output']>
  length?: Maybe<Scalars['Float']['output']>
  price?: Maybe<Scalars['Float']['output']>
  quantity: Scalars['Int']['output']
  regNum?: Maybe<Scalars['String']['output']>
  rowNumber: Scalars['Int']['output']
}

export type VehicleFare = {
  __typename?: 'VehicleFare'
  code: VehicleFareCode
  hasPrice: Scalars['Boolean']['output']
  hasTrailer: Scalars['Boolean']['output']
  isAvailable: Scalars['Boolean']['output']
  itemDescription?: Maybe<Scalars['String']['output']>
  itemName?: Maybe<Scalars['String']['output']>
  length: Scalars['Float']['output']
  maxAvailableLength: Scalars['Float']['output']
  maxQuantityAllowed: Scalars['Int']['output']
  minAvailableLength: Scalars['Float']['output']
  price: Price
}

export enum VehicleFareCode {
  Bik = 'BIK',
  FlVehicle_195Cm = 'FL_VEHICLE_195_CM',
  FlVehicle_220Cm = 'FL_VEHICLE_220_CM',
  FlVehicle_235Cm = 'FL_VEHICLE_235_CM',
  FlVehicle_450Cm = 'FL_VEHICLE_450_CM',
  Mc = 'MC',
  Mcs = 'MCS',
}

export type VehicleFareRequestInput = {
  code: VehicleFareCode
  hasTrailer?: InputMaybe<Scalars['Boolean']['input']>
  length?: InputMaybe<Scalars['Float']['input']>
  quantity: Scalars['Int']['input']
}

export type VehicleRules = {
  __typename?: 'VehicleRules'
  bikesLocked: Scalars['Boolean']['output']
  bikesQuantity: Scalars['Int']['output']
  carCategories: Array<Scalars['String']['output']>
  carCategoryLocked: Scalars['Boolean']['output']
  carsLocked: Scalars['Boolean']['output']
  carsQuantity: Scalars['Int']['output']
  motorcyclesLocked: Scalars['Boolean']['output']
  motorcyclesQuantity: Scalars['Int']['output']
}

export type GetPassengersQueryVariables = Exact<{
  bookingCode: Scalars['String']['input']
}>

export type GetPassengersQuery = {
  __typename?: 'Query'
  booking: {
    __typename?: 'FlBooking'
    isInFuture: boolean
    isOngoing: boolean
    outbound?: {
      __typename?: 'Journey'
      arrivalPortInfo?: { __typename?: 'PortInfo'; portName: string } | null
      departurePortInfo?: { __typename?: 'PortInfo'; portName: string } | null
      passengers?: Array<{
        __typename?: 'Passenger'
        birthYear: number
        birthMonth: number
        birthDay: number
        customerCode?: string | null
        disabledPerson: boolean
        firstName?: string | null
        fareCode: FareCode
        gender: Gender
        lastName?: string | null
        nationality: Country
        rowNumber: number
        id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
      }> | null
    } | null
  }
}

export type GetAvailableCabinsQueryVariables = Exact<{
  requestParams: CabinRequestInput
}>

export type GetAvailableCabinsQuery = {
  __typename?: 'Query'
  cabinAvailability: {
    __typename?: 'CabinAvailabilityResponse'
    departureCode: string
    availableCabins: Array<{
      __typename: 'AvailabilityItemWithSpecOptions'
      code: string
      quantityAvailable: number
      isLounge: boolean
      itemName?: string | null
      specification: {
        __typename?: 'ItemSpecificationWithOptions'
        specificationCode: string
        minimumQuantityToBook: number
        maximumQuantityToBook: any
        options: Array<{
          __typename?: 'SpecificationOption'
          maxQuantity: any
          priceIsPerItem: boolean
          optionPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
        }>
      }
    }>
    availableSeats: Array<{
      __typename: 'AvailabilityItemWithSpecOptions'
      code: string
      quantityAvailable: number
      isLounge: boolean
      itemName?: string | null
      specification: {
        __typename?: 'ItemSpecificationWithOptions'
        specificationCode: string
        minimumQuantityToBook: number
        maximumQuantityToBook: any
        options: Array<{
          __typename?: 'SpecificationOption'
          maxQuantity: any
          priceIsPerItem: boolean
          optionPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
        }>
      }
    }>
  }
}

export type ItemSpecsWithOptionsFragment = {
  __typename: 'AvailabilityItemWithSpecOptions'
  code: string
  quantityAvailable: number
  isLounge: boolean
  itemName?: string | null
  specification: {
    __typename?: 'ItemSpecificationWithOptions'
    specificationCode: string
    minimumQuantityToBook: number
    maximumQuantityToBook: any
    options: Array<{
      __typename?: 'SpecificationOption'
      maxQuantity: any
      priceIsPerItem: boolean
      optionPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
    }>
  }
}

export type GetAvailableExtrasQueryVariables = Exact<{
  requestParams: ExtrasRequestInput
}>

export type GetAvailableExtrasQuery = {
  __typename?: 'Query'
  extrasAvailability: {
    __typename?: 'ExtrasAvailabilityResponse'
    departureCode: string
    headerImage?: { __typename?: 'ImageInfo'; url: string } | null
    availableExtrasOnboard: Array<{
      __typename: 'AvailabilityExtraItemWithSpec'
      code: string
      isCancellationInsurance: boolean
      isPetInCar: boolean
      isDogCage: boolean
      itemName?: string | null
      quantityAvailable: number
      specifications?: Array<{
        __typename?: 'Specification'
        specificationCode: string
        minimumQuantityToBook: number
        maximumQuantityToBook: any
        specificationPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
      }> | null
    }>
    availableExtrasAshore: Array<{
      __typename: 'AvailabilityExtraItemWithSpec'
      code: string
      isCancellationInsurance: boolean
      isPetInCar: boolean
      isDogCage: boolean
      itemName?: string | null
      quantityAvailable: number
      specifications?: Array<{
        __typename?: 'Specification'
        specificationCode: string
        minimumQuantityToBook: number
        maximumQuantityToBook: any
        specificationPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
      }> | null
    }>
  }
}

export type ExtrasFragment = {
  __typename: 'AvailabilityExtraItemWithSpec'
  code: string
  isCancellationInsurance: boolean
  isPetInCar: boolean
  isDogCage: boolean
  itemName?: string | null
  quantityAvailable: number
  specifications?: Array<{
    __typename?: 'Specification'
    specificationCode: string
    minimumQuantityToBook: number
    maximumQuantityToBook: any
    specificationPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
  }> | null
}

export type GetAvailableMealsQueryVariables = Exact<{
  requestParams: MealRequestInput
}>

export type GetAvailableMealsQuery = {
  __typename?: 'Query'
  mealAvailability: {
    __typename?: 'MealAvailabilityResponse'
    departureCode: string
    availableMeals?: Array<{
      __typename: 'AvailabilityMealItemWithSpec'
      code: string
      itemType?: string | null
      startTime: string
      itemName?: string | null
      legCode: string
      quantityAvailable: number
      specifications?: Array<{
        __typename?: 'Specification'
        specificationCode: string
        minimumQuantityToBook: number
        maximumQuantityToBook: any
        specificationPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
      }> | null
    }> | null
  }
}

export type MealsDetailsFragment = {
  __typename: 'AvailabilityMealItemWithSpec'
  code: string
  itemType?: string | null
  startTime: string
  itemName?: string | null
  legCode: string
  quantityAvailable: number
  specifications?: Array<{
    __typename?: 'Specification'
    specificationCode: string
    minimumQuantityToBook: number
    maximumQuantityToBook: any
    specificationPrice: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
  }> | null
}

export type GetSuggestedItemsQueryVariables = Exact<{
  requestParams: SuggestRequestInput
}>

export type GetSuggestedItemsQuery = {
  __typename?: 'Query'
  suggest: {
    __typename: 'CabinSuggestResponse'
    assignedPassengers: number
    unassignedPassengers: number
    cabins?: Array<{
      __typename?: 'SuggestedCabin'
      code: string
      quantityInCabin: number
      name?: string | null
      price: { __typename?: 'Price'; currency: Currency; value: number; available?: boolean | null }
    }> | null
  }
}

export type RouteExpectQueryVariables = Exact<{
  originPortCode: Scalars['String']['input']
  destinationPortCode: Scalars['String']['input']
}>

export type RouteExpectQuery = {
  __typename?: 'Query'
  route: {
    __typename?: 'Route'
    expectCabins: boolean
    expectMeals: boolean
    expectExtras: boolean
    cabinsRequired: boolean
    seatsRequired: boolean
  }
}

export type CheckIfActiveMemberQueryVariables = Exact<{ [key: string]: never }>

export type CheckIfActiveMemberQuery = { __typename?: 'Query'; isActiveFjordClubMember: boolean }

export type GetBoardingCardsQueryVariables = Exact<{
  bookingCode: Scalars['String']['input']
}>

export type GetBoardingCardsQuery = {
  __typename?: 'Query'
  booking: {
    __typename?: 'FlBooking'
    isOngoing: boolean
    isInFuture: boolean
    isInPast: boolean
    isCancelled: boolean
    boardingCards: Array<{
      __typename?: 'BoardingCard'
      rowNumber?: string | null
      firstName?: string | null
      lastName?: string | null
      qrCode?: string | null
    }>
    outbound?: {
      __typename?: 'Journey'
      departureDate: string
      arrivalPortInfo?: { __typename?: 'PortInfo'; portName: string } | null
      departurePortInfo?: { __typename?: 'PortInfo'; portName: string } | null
    } | null
  }
}

export type BoardingCardsFragment = {
  __typename?: 'BoardingCard'
  rowNumber?: string | null
  firstName?: string | null
  lastName?: string | null
  qrCode?: string | null
}

export type GetBookingQueryVariables = Exact<{
  bookingCode: Scalars['String']['input']
}>

export type GetBookingQuery = {
  __typename?: 'Query'
  booking: {
    __typename: 'FlBooking'
    bookingCode?: string | null
    created: string
    currency: Currency
    isCancelled: boolean
    isInFuture: boolean
    isInPast: boolean
    isOngoing: boolean
    language?: string | null
    lastModified: string
    statusCode?: string | null
    totalPrice: number
    username?: string | null
    canBuyBookingItems: boolean
    showBoardingCards: boolean
    modificationDeadline: string
    accommodations?: Array<{
      __typename?: 'BookedAccommodation'
      price?: number | null
      checkinDate: string
      checkoutDate: string
      code: string
      rooms: Array<{ __typename?: 'Room'; quantity: number; code: string }>
    }> | null
    outbound?: {
      __typename?: 'Journey'
      departureDate: string
      departureCode?: string | null
      arrivalDate: string
      journeyCode?: string | null
      vesselCode?: string | null
      journeyNumber: number
      ticket?: { __typename?: 'Ticket'; fareType: FareType; ticketCode?: string | null } | null
      arrivalPortInfo?: {
        __typename?: 'PortInfo'
        portCode: string
        carresPortCode: string
        portName: string
        isEnabled: boolean
      } | null
      departurePortInfo?: {
        __typename?: 'PortInfo'
        portCode: string
        carresPortCode: string
        portName: string
        isEnabled: boolean
      } | null
      cabins?: Array<{
        __typename?: 'Cabin'
        id?: string | null
        code?: string | null
        subCode?: string | null
        edit?: string | null
        price: number
        quantityInCabin: number
        bookedItemReference?: string | null
        rowNumber: number
        groupCode?: string | null
        isAttachment: boolean
      }> | null
      meals?: Array<{
        __typename?: 'Meal'
        code?: string | null
        subCode?: string | null
        startTime: string
        isOutbound?: boolean | null
        legCode?: string | null
        quantity: number
        price: number
        rowNumber: number
        isAttachment: boolean
      }> | null
      extrasOnboard?: Array<{
        __typename?: 'Extra'
        code?: string | null
        subCode?: string | null
        startTime?: string | null
        rowNumber: number
        quantity: number
        price?: number | null
        isAttachment: boolean
      }> | null
      extrasAshore?: Array<{
        __typename?: 'Extra'
        code?: string | null
        subCode?: string | null
        startTime?: string | null
        rowNumber: number
        quantity: number
        price?: number | null
        isAttachment: boolean
      }> | null
      extrasAshoreWithOptions?: Array<{
        __typename?: 'Extra'
        code?: string | null
        subCode?: string | null
        startTime?: string | null
        rowNumber: number
        quantity: number
        price?: number | null
        isAttachment: boolean
      }> | null
      passengerPrices?: Array<{ __typename?: 'PassengerPrice'; code: FareCode; price?: number | null }> | null
      passengers?: Array<{
        __typename?: 'Passenger'
        birthYear: number
        birthMonth: number
        birthDay: number
        customerCode?: string | null
        disabledPerson: boolean
        firstName?: string | null
        fareCode: FareCode
        gender: Gender
        lastName?: string | null
        nationality: Country
        rowNumber: number
        id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
      }> | null
      vehicles?: Array<{
        __typename?: 'Vehicle'
        code?: string | null
        regNum?: string | null
        length?: number | null
        hasTrailer?: boolean | null
        id?: string | null
        quantity: number
        rowNumber: number
        price?: number | null
      }> | null
    } | null
    inbound?: {
      __typename?: 'Journey'
      departureDate: string
      departureCode?: string | null
      arrivalDate: string
      journeyCode?: string | null
      vesselCode?: string | null
      journeyNumber: number
      ticket?: { __typename?: 'Ticket'; fareType: FareType; ticketCode?: string | null } | null
      arrivalPortInfo?: {
        __typename?: 'PortInfo'
        portCode: string
        carresPortCode: string
        portName: string
        isEnabled: boolean
      } | null
      departurePortInfo?: {
        __typename?: 'PortInfo'
        portCode: string
        carresPortCode: string
        portName: string
        isEnabled: boolean
      } | null
      cabins?: Array<{
        __typename?: 'Cabin'
        id?: string | null
        code?: string | null
        subCode?: string | null
        edit?: string | null
        price: number
        quantityInCabin: number
        bookedItemReference?: string | null
        rowNumber: number
        groupCode?: string | null
        isAttachment: boolean
      }> | null
      meals?: Array<{
        __typename?: 'Meal'
        code?: string | null
        subCode?: string | null
        startTime: string
        isOutbound?: boolean | null
        legCode?: string | null
        quantity: number
        price: number
        rowNumber: number
        isAttachment: boolean
      }> | null
      extrasOnboard?: Array<{
        __typename?: 'Extra'
        code?: string | null
        subCode?: string | null
        startTime?: string | null
        rowNumber: number
        quantity: number
        price?: number | null
        isAttachment: boolean
      }> | null
      extrasAshore?: Array<{
        __typename?: 'Extra'
        code?: string | null
        subCode?: string | null
        startTime?: string | null
        rowNumber: number
        quantity: number
        price?: number | null
        isAttachment: boolean
      }> | null
      extrasAshoreWithOptions?: Array<{
        __typename?: 'Extra'
        code?: string | null
        subCode?: string | null
        startTime?: string | null
        rowNumber: number
        quantity: number
        price?: number | null
        isAttachment: boolean
      }> | null
      passengerPrices?: Array<{ __typename?: 'PassengerPrice'; code: FareCode; price?: number | null }> | null
      passengers?: Array<{
        __typename?: 'Passenger'
        birthYear: number
        birthMonth: number
        birthDay: number
        customerCode?: string | null
        disabledPerson: boolean
        firstName?: string | null
        fareCode: FareCode
        gender: Gender
        lastName?: string | null
        nationality: Country
        rowNumber: number
        id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
      }> | null
      vehicles?: Array<{
        __typename?: 'Vehicle'
        code?: string | null
        regNum?: string | null
        length?: number | null
        hasTrailer?: boolean | null
        id?: string | null
        quantity: number
        rowNumber: number
        price?: number | null
      }> | null
    } | null
  }
}

export type BookingDetailsFragment = {
  __typename: 'FlBooking'
  bookingCode?: string | null
  created: string
  currency: Currency
  isCancelled: boolean
  isInFuture: boolean
  isInPast: boolean
  isOngoing: boolean
  language?: string | null
  lastModified: string
  statusCode?: string | null
  totalPrice: number
  username?: string | null
  canBuyBookingItems: boolean
  showBoardingCards: boolean
  modificationDeadline: string
  accommodations?: Array<{
    __typename?: 'BookedAccommodation'
    price?: number | null
    checkinDate: string
    checkoutDate: string
    code: string
    rooms: Array<{ __typename?: 'Room'; quantity: number; code: string }>
  }> | null
  outbound?: {
    __typename?: 'Journey'
    departureDate: string
    departureCode?: string | null
    arrivalDate: string
    journeyCode?: string | null
    vesselCode?: string | null
    journeyNumber: number
    ticket?: { __typename?: 'Ticket'; fareType: FareType; ticketCode?: string | null } | null
    arrivalPortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
    departurePortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
    cabins?: Array<{
      __typename?: 'Cabin'
      id?: string | null
      code?: string | null
      subCode?: string | null
      edit?: string | null
      price: number
      quantityInCabin: number
      bookedItemReference?: string | null
      rowNumber: number
      groupCode?: string | null
      isAttachment: boolean
    }> | null
    meals?: Array<{
      __typename?: 'Meal'
      code?: string | null
      subCode?: string | null
      startTime: string
      isOutbound?: boolean | null
      legCode?: string | null
      quantity: number
      price: number
      rowNumber: number
      isAttachment: boolean
    }> | null
    extrasOnboard?: Array<{
      __typename?: 'Extra'
      code?: string | null
      subCode?: string | null
      startTime?: string | null
      rowNumber: number
      quantity: number
      price?: number | null
      isAttachment: boolean
    }> | null
    extrasAshore?: Array<{
      __typename?: 'Extra'
      code?: string | null
      subCode?: string | null
      startTime?: string | null
      rowNumber: number
      quantity: number
      price?: number | null
      isAttachment: boolean
    }> | null
    extrasAshoreWithOptions?: Array<{
      __typename?: 'Extra'
      code?: string | null
      subCode?: string | null
      startTime?: string | null
      rowNumber: number
      quantity: number
      price?: number | null
      isAttachment: boolean
    }> | null
    passengerPrices?: Array<{ __typename?: 'PassengerPrice'; code: FareCode; price?: number | null }> | null
    passengers?: Array<{
      __typename?: 'Passenger'
      birthYear: number
      birthMonth: number
      birthDay: number
      customerCode?: string | null
      disabledPerson: boolean
      firstName?: string | null
      fareCode: FareCode
      gender: Gender
      lastName?: string | null
      nationality: Country
      rowNumber: number
      id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
    }> | null
    vehicles?: Array<{
      __typename?: 'Vehicle'
      code?: string | null
      regNum?: string | null
      length?: number | null
      hasTrailer?: boolean | null
      id?: string | null
      quantity: number
      rowNumber: number
      price?: number | null
    }> | null
  } | null
  inbound?: {
    __typename?: 'Journey'
    departureDate: string
    departureCode?: string | null
    arrivalDate: string
    journeyCode?: string | null
    vesselCode?: string | null
    journeyNumber: number
    ticket?: { __typename?: 'Ticket'; fareType: FareType; ticketCode?: string | null } | null
    arrivalPortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
    departurePortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
    cabins?: Array<{
      __typename?: 'Cabin'
      id?: string | null
      code?: string | null
      subCode?: string | null
      edit?: string | null
      price: number
      quantityInCabin: number
      bookedItemReference?: string | null
      rowNumber: number
      groupCode?: string | null
      isAttachment: boolean
    }> | null
    meals?: Array<{
      __typename?: 'Meal'
      code?: string | null
      subCode?: string | null
      startTime: string
      isOutbound?: boolean | null
      legCode?: string | null
      quantity: number
      price: number
      rowNumber: number
      isAttachment: boolean
    }> | null
    extrasOnboard?: Array<{
      __typename?: 'Extra'
      code?: string | null
      subCode?: string | null
      startTime?: string | null
      rowNumber: number
      quantity: number
      price?: number | null
      isAttachment: boolean
    }> | null
    extrasAshore?: Array<{
      __typename?: 'Extra'
      code?: string | null
      subCode?: string | null
      startTime?: string | null
      rowNumber: number
      quantity: number
      price?: number | null
      isAttachment: boolean
    }> | null
    extrasAshoreWithOptions?: Array<{
      __typename?: 'Extra'
      code?: string | null
      subCode?: string | null
      startTime?: string | null
      rowNumber: number
      quantity: number
      price?: number | null
      isAttachment: boolean
    }> | null
    passengerPrices?: Array<{ __typename?: 'PassengerPrice'; code: FareCode; price?: number | null }> | null
    passengers?: Array<{
      __typename?: 'Passenger'
      birthYear: number
      birthMonth: number
      birthDay: number
      customerCode?: string | null
      disabledPerson: boolean
      firstName?: string | null
      fareCode: FareCode
      gender: Gender
      lastName?: string | null
      nationality: Country
      rowNumber: number
      id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
    }> | null
    vehicles?: Array<{
      __typename?: 'Vehicle'
      code?: string | null
      regNum?: string | null
      length?: number | null
      hasTrailer?: boolean | null
      id?: string | null
      quantity: number
      rowNumber: number
      price?: number | null
    }> | null
  } | null
}

export type JourneyDetailsFragment = {
  __typename?: 'Journey'
  departureDate: string
  departureCode?: string | null
  arrivalDate: string
  journeyCode?: string | null
  vesselCode?: string | null
  journeyNumber: number
  ticket?: { __typename?: 'Ticket'; fareType: FareType; ticketCode?: string | null } | null
  arrivalPortInfo?: {
    __typename?: 'PortInfo'
    portCode: string
    carresPortCode: string
    portName: string
    isEnabled: boolean
  } | null
  departurePortInfo?: {
    __typename?: 'PortInfo'
    portCode: string
    carresPortCode: string
    portName: string
    isEnabled: boolean
  } | null
  cabins?: Array<{
    __typename?: 'Cabin'
    id?: string | null
    code?: string | null
    subCode?: string | null
    edit?: string | null
    price: number
    quantityInCabin: number
    bookedItemReference?: string | null
    rowNumber: number
    groupCode?: string | null
    isAttachment: boolean
  }> | null
  meals?: Array<{
    __typename?: 'Meal'
    code?: string | null
    subCode?: string | null
    startTime: string
    isOutbound?: boolean | null
    legCode?: string | null
    quantity: number
    price: number
    rowNumber: number
    isAttachment: boolean
  }> | null
  extrasOnboard?: Array<{
    __typename?: 'Extra'
    code?: string | null
    subCode?: string | null
    startTime?: string | null
    rowNumber: number
    quantity: number
    price?: number | null
    isAttachment: boolean
  }> | null
  extrasAshore?: Array<{
    __typename?: 'Extra'
    code?: string | null
    subCode?: string | null
    startTime?: string | null
    rowNumber: number
    quantity: number
    price?: number | null
    isAttachment: boolean
  }> | null
  extrasAshoreWithOptions?: Array<{
    __typename?: 'Extra'
    code?: string | null
    subCode?: string | null
    startTime?: string | null
    rowNumber: number
    quantity: number
    price?: number | null
    isAttachment: boolean
  }> | null
  passengerPrices?: Array<{ __typename?: 'PassengerPrice'; code: FareCode; price?: number | null }> | null
  passengers?: Array<{
    __typename?: 'Passenger'
    birthYear: number
    birthMonth: number
    birthDay: number
    customerCode?: string | null
    disabledPerson: boolean
    firstName?: string | null
    fareCode: FareCode
    gender: Gender
    lastName?: string | null
    nationality: Country
    rowNumber: number
    id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
  }> | null
  vehicles?: Array<{
    __typename?: 'Vehicle'
    code?: string | null
    regNum?: string | null
    length?: number | null
    hasTrailer?: boolean | null
    id?: string | null
    quantity: number
    rowNumber: number
    price?: number | null
  }> | null
}

export type PassengerFragment = {
  __typename?: 'Passenger'
  birthYear: number
  birthMonth: number
  birthDay: number
  customerCode?: string | null
  disabledPerson: boolean
  firstName?: string | null
  fareCode: FareCode
  gender: Gender
  lastName?: string | null
  nationality: Country
  rowNumber: number
  id?: { __typename?: 'Id'; idType: IdType; idNumber?: string | null } | null
}

export type PortInfoFragment = {
  __typename?: 'PortInfo'
  portCode: string
  carresPortCode: string
  portName: string
  isEnabled: boolean
}

export type CabinFragment = {
  __typename?: 'Cabin'
  id?: string | null
  code?: string | null
  subCode?: string | null
  edit?: string | null
  price: number
  quantityInCabin: number
  bookedItemReference?: string | null
  rowNumber: number
  groupCode?: string | null
  isAttachment: boolean
}

export type MealFragment = {
  __typename?: 'Meal'
  code?: string | null
  subCode?: string | null
  startTime: string
  isOutbound?: boolean | null
  legCode?: string | null
  quantity: number
  price: number
  rowNumber: number
  isAttachment: boolean
}

export type ExtraFragment = {
  __typename?: 'Extra'
  code?: string | null
  subCode?: string | null
  startTime?: string | null
  rowNumber: number
  quantity: number
  price?: number | null
  isAttachment: boolean
}

export type GetBookingsQueryVariables = Exact<{ [key: string]: never }>

export type GetBookingsQuery = {
  __typename?: 'Query'
  bookings: {
    __typename?: 'GroupedBookingResponse'
    future: Array<{
      __typename: 'FlBooking'
      bookingCode?: string | null
      created: string
      statusCode?: string | null
      language?: string | null
      currency: Currency
      canBuyBookingItems: boolean
      showBoardingCards: boolean
      modificationDeadline: string
      outbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
      inbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
    }>
    passed: Array<{
      __typename: 'FlBooking'
      bookingCode?: string | null
      created: string
      statusCode?: string | null
      language?: string | null
      currency: Currency
      canBuyBookingItems: boolean
      showBoardingCards: boolean
      modificationDeadline: string
      outbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
      inbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
    }>
    cancelled: Array<{
      __typename: 'FlBooking'
      bookingCode?: string | null
      created: string
      statusCode?: string | null
      language?: string | null
      currency: Currency
      canBuyBookingItems: boolean
      showBoardingCards: boolean
      modificationDeadline: string
      outbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
      inbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
    }>
    ongoing: Array<{
      __typename: 'FlBooking'
      bookingCode?: string | null
      created: string
      statusCode?: string | null
      language?: string | null
      currency: Currency
      canBuyBookingItems: boolean
      showBoardingCards: boolean
      modificationDeadline: string
      outbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
      inbound?: {
        __typename?: 'Journey'
        arrivalDate: string
        departureDate: string
        arrivalPortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
        departurePortInfo?: {
          __typename?: 'PortInfo'
          portCode: string
          carresPortCode: string
          portName: string
          isEnabled: boolean
        } | null
      } | null
    }>
  }
}

export type FlBookingFragment = {
  __typename: 'FlBooking'
  bookingCode?: string | null
  created: string
  statusCode?: string | null
  language?: string | null
  currency: Currency
  canBuyBookingItems: boolean
  showBoardingCards: boolean
  modificationDeadline: string
  outbound?: {
    __typename?: 'Journey'
    arrivalDate: string
    departureDate: string
    arrivalPortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
    departurePortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
  } | null
  inbound?: {
    __typename?: 'Journey'
    arrivalDate: string
    departureDate: string
    arrivalPortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
    departurePortInfo?: {
      __typename?: 'PortInfo'
      portCode: string
      carresPortCode: string
      portName: string
      isEnabled: boolean
    } | null
  } | null
}

export type JourneyFragment = {
  __typename?: 'Journey'
  arrivalDate: string
  departureDate: string
  arrivalPortInfo?: {
    __typename?: 'PortInfo'
    portCode: string
    carresPortCode: string
    portName: string
    isEnabled: boolean
  } | null
  departurePortInfo?: {
    __typename?: 'PortInfo'
    portCode: string
    carresPortCode: string
    portName: string
    isEnabled: boolean
  } | null
}

export type GetCustomerQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'FlCustomer'
    personId?: any | null
    active: boolean
    customerCode?: string | null
    nationalityCode: Country
    gender: Gender
    firstName: string
    lastName: string
    birthYear: number
    birthMonth: number
    birthDay: number
    defaultDeparturePortCode?: string | null
    address: {
      __typename?: 'CustomerAddress'
      streetAddress: string
      postalCode: string
      postalAddress: string
      countryCode: Country
    }
    meansOfContacts: Array<{ __typename?: 'MeansOfContact'; type: MeansOfContactType; value: string }>
    clubMemberships?: Array<{
      __typename?: 'ClubMembership'
      active: boolean
      clubCode: string
      clubLevelCode: string
      clubTypeId: number
      memberSince?: string | null
      membershipNumber?: string | null
    }> | null
    customerConsents?: {
      __typename?: 'CustomerConsents'
      uid: any
      personId: any
      lastModified: string
      created: string
      consents: {
        __typename?: 'Consents'
        email?: boolean | null
        sms?: boolean | null
        mail?: boolean | null
        newsletter?: boolean | null
      }
    } | null
    loyaltyPoints?: {
      __typename?: 'CustomerLoyaltyPoints'
      balance: number
      earnedPoints: number
      redeemablePoints: number
      recentlyEarnedPoints: number
      soonExpiringPoints: number
    } | null
  }
}

export type GetDepartureQueryVariables = Exact<{
  departureCode: Scalars['String']['input']
  originPort: Scalars['String']['input']
  destinationPort: Scalars['String']['input']
  journeyCode: Scalars['String']['input']
}>

export type GetDepartureQuery = {
  __typename?: 'Query'
  departure?: { __typename?: 'Departure'; departureCode: string; journeyCode: string; vesselCode: string } | null
}

export const ItemSpecsWithOptionsFragmentDoc = gql`
  fragment ItemSpecsWithOptions on AvailabilityItemWithSpecOptions {
    code
    quantityAvailable
    isLounge
    itemName
    specification {
      specificationCode
      minimumQuantityToBook
      maximumQuantityToBook
      options {
        maxQuantity
        optionPrice {
          currency
          value
          available
        }
        priceIsPerItem
      }
    }
    __typename
  }
`
export const ExtrasFragmentDoc = gql`
  fragment Extras on AvailabilityExtraItemWithSpec {
    code
    isCancellationInsurance
    isPetInCar
    isDogCage
    itemName
    quantityAvailable
    specifications {
      specificationCode
      minimumQuantityToBook
      maximumQuantityToBook
      specificationPrice {
        currency
        value
        available
      }
    }
    __typename
  }
`
export const MealsDetailsFragmentDoc = gql`
  fragment MealsDetails on AvailabilityMealItemWithSpec {
    code
    itemType
    startTime
    itemName
    legCode
    quantityAvailable
    specifications {
      specificationCode
      minimumQuantityToBook
      maximumQuantityToBook
      specificationPrice {
        currency
        value
        available
      }
    }
    __typename
  }
`
export const BoardingCardsFragmentDoc = gql`
  fragment BoardingCards on BoardingCard {
    rowNumber
    firstName
    lastName
    qrCode
  }
`
export const PortInfoFragmentDoc = gql`
  fragment PortInfo on PortInfo {
    portCode
    carresPortCode
    portName
    isEnabled
  }
`
export const CabinFragmentDoc = gql`
  fragment Cabin on Cabin {
    id
    code
    subCode
    edit
    price
    quantityInCabin
    bookedItemReference
    rowNumber
    groupCode
    isAttachment
  }
`
export const MealFragmentDoc = gql`
  fragment Meal on Meal {
    code
    subCode
    startTime
    isOutbound
    legCode
    quantity
    price
    rowNumber
    isAttachment
  }
`
export const ExtraFragmentDoc = gql`
  fragment Extra on Extra {
    code
    subCode
    startTime
    rowNumber
    quantity
    price
    isAttachment
  }
`
export const PassengerFragmentDoc = gql`
  fragment Passenger on Passenger {
    birthYear
    birthMonth
    birthDay
    customerCode
    disabledPerson
    firstName
    fareCode
    gender
    id {
      idType
      idNumber
    }
    lastName
    nationality
    rowNumber
  }
`
export const JourneyDetailsFragmentDoc = gql`
  fragment JourneyDetails on Journey {
    departureDate
    departureCode
    arrivalDate
    journeyCode
    vesselCode
    journeyNumber
    ticket {
      fareType
      ticketCode
    }
    arrivalPortInfo {
      ...PortInfo
    }
    departurePortInfo {
      ...PortInfo
    }
    cabins {
      ...Cabin
    }
    meals {
      ...Meal
    }
    extrasOnboard {
      ...Extra
    }
    extrasAshore {
      ...Extra
    }
    extrasAshoreWithOptions {
      ...Extra
    }
    passengerPrices {
      code
      price
    }
    passengers {
      ...Passenger
    }
    vehicles {
      code
      regNum
      length
      hasTrailer
      id
      quantity
      rowNumber
      price
    }
  }
  ${PortInfoFragmentDoc}
  ${CabinFragmentDoc}
  ${MealFragmentDoc}
  ${ExtraFragmentDoc}
  ${PassengerFragmentDoc}
`
export const BookingDetailsFragmentDoc = gql`
  fragment BookingDetails on FlBooking {
    bookingCode
    created
    currency
    currency
    isCancelled
    isInFuture
    isInPast
    isOngoing
    language
    lastModified
    statusCode
    totalPrice
    username
    canBuyBookingItems
    showBoardingCards
    modificationDeadline
    accommodations {
      rooms {
        quantity
        code
      }
      price
      checkinDate
      checkoutDate
      code
    }
    outbound {
      ...JourneyDetails
    }
    inbound {
      ...JourneyDetails
    }
    __typename
  }
  ${JourneyDetailsFragmentDoc}
`
export const JourneyFragmentDoc = gql`
  fragment Journey on Journey {
    arrivalDate
    arrivalPortInfo {
      ...PortInfo
    }
    departureDate
    departurePortInfo {
      ...PortInfo
    }
  }
  ${PortInfoFragmentDoc}
`
export const FlBookingFragmentDoc = gql`
  fragment FlBooking on FlBooking {
    __typename
    bookingCode
    created
    statusCode
    language
    currency
    canBuyBookingItems
    showBoardingCards
    modificationDeadline
    outbound {
      ...Journey
    }
    inbound {
      ...Journey
    }
  }
  ${JourneyFragmentDoc}
`
export const GetPassengersDocument = gql`
  query GetPassengers($bookingCode: String!) {
    booking(bookingCode: $bookingCode) {
      isInFuture
      isOngoing
      outbound {
        arrivalPortInfo {
          portName
        }
        departurePortInfo {
          portName
        }
        passengers {
          ...Passenger
        }
      }
    }
  }
  ${PassengerFragmentDoc}
`

/**
 * __useGetPassengersQuery__
 *
 * To run a query within a React component, call `useGetPassengersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassengersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassengersQuery({
 *   variables: {
 *      bookingCode: // value for 'bookingCode'
 *   },
 * });
 */
export function useGetPassengersQuery(
  baseOptions: Apollo.QueryHookOptions<GetPassengersQuery, GetPassengersQueryVariables> &
    ({ variables: GetPassengersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPassengersQuery, GetPassengersQueryVariables>(GetPassengersDocument, options)
}
export function useGetPassengersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPassengersQuery, GetPassengersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPassengersQuery, GetPassengersQueryVariables>(GetPassengersDocument, options)
}
export function useGetPassengersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPassengersQuery, GetPassengersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPassengersQuery, GetPassengersQueryVariables>(GetPassengersDocument, options)
}
export type GetPassengersQueryHookResult = ReturnType<typeof useGetPassengersQuery>
export type GetPassengersLazyQueryHookResult = ReturnType<typeof useGetPassengersLazyQuery>
export type GetPassengersSuspenseQueryHookResult = ReturnType<typeof useGetPassengersSuspenseQuery>
export type GetPassengersQueryResult = Apollo.QueryResult<GetPassengersQuery, GetPassengersQueryVariables>
export const GetAvailableCabinsDocument = gql`
  query GetAvailableCabins($requestParams: CabinRequestInput!) {
    cabinAvailability(request: $requestParams) {
      departureCode
      availableCabins {
        ...ItemSpecsWithOptions
      }
      availableSeats {
        ...ItemSpecsWithOptions
      }
    }
  }
  ${ItemSpecsWithOptionsFragmentDoc}
`

/**
 * __useGetAvailableCabinsQuery__
 *
 * To run a query within a React component, call `useGetAvailableCabinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableCabinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableCabinsQuery({
 *   variables: {
 *      requestParams: // value for 'requestParams'
 *   },
 * });
 */
export function useGetAvailableCabinsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAvailableCabinsQuery, GetAvailableCabinsQueryVariables> &
    ({ variables: GetAvailableCabinsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAvailableCabinsQuery, GetAvailableCabinsQueryVariables>(GetAvailableCabinsDocument, options)
}
export function useGetAvailableCabinsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableCabinsQuery, GetAvailableCabinsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAvailableCabinsQuery, GetAvailableCabinsQueryVariables>(
    GetAvailableCabinsDocument,
    options,
  )
}
export function useGetAvailableCabinsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAvailableCabinsQuery, GetAvailableCabinsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetAvailableCabinsQuery, GetAvailableCabinsQueryVariables>(
    GetAvailableCabinsDocument,
    options,
  )
}
export type GetAvailableCabinsQueryHookResult = ReturnType<typeof useGetAvailableCabinsQuery>
export type GetAvailableCabinsLazyQueryHookResult = ReturnType<typeof useGetAvailableCabinsLazyQuery>
export type GetAvailableCabinsSuspenseQueryHookResult = ReturnType<typeof useGetAvailableCabinsSuspenseQuery>
export type GetAvailableCabinsQueryResult = Apollo.QueryResult<
  GetAvailableCabinsQuery,
  GetAvailableCabinsQueryVariables
>
export const GetAvailableExtrasDocument = gql`
  query GetAvailableExtras($requestParams: ExtrasRequestInput!) {
    extrasAvailability(request: $requestParams) {
      departureCode
      headerImage {
        url
      }
      availableExtrasOnboard {
        ...Extras
      }
      availableExtrasAshore {
        ...Extras
      }
    }
  }
  ${ExtrasFragmentDoc}
`

/**
 * __useGetAvailableExtrasQuery__
 *
 * To run a query within a React component, call `useGetAvailableExtrasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableExtrasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableExtrasQuery({
 *   variables: {
 *      requestParams: // value for 'requestParams'
 *   },
 * });
 */
export function useGetAvailableExtrasQuery(
  baseOptions: Apollo.QueryHookOptions<GetAvailableExtrasQuery, GetAvailableExtrasQueryVariables> &
    ({ variables: GetAvailableExtrasQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAvailableExtrasQuery, GetAvailableExtrasQueryVariables>(GetAvailableExtrasDocument, options)
}
export function useGetAvailableExtrasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableExtrasQuery, GetAvailableExtrasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAvailableExtrasQuery, GetAvailableExtrasQueryVariables>(
    GetAvailableExtrasDocument,
    options,
  )
}
export function useGetAvailableExtrasSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAvailableExtrasQuery, GetAvailableExtrasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetAvailableExtrasQuery, GetAvailableExtrasQueryVariables>(
    GetAvailableExtrasDocument,
    options,
  )
}
export type GetAvailableExtrasQueryHookResult = ReturnType<typeof useGetAvailableExtrasQuery>
export type GetAvailableExtrasLazyQueryHookResult = ReturnType<typeof useGetAvailableExtrasLazyQuery>
export type GetAvailableExtrasSuspenseQueryHookResult = ReturnType<typeof useGetAvailableExtrasSuspenseQuery>
export type GetAvailableExtrasQueryResult = Apollo.QueryResult<
  GetAvailableExtrasQuery,
  GetAvailableExtrasQueryVariables
>
export const GetAvailableMealsDocument = gql`
  query GetAvailableMeals($requestParams: MealRequestInput!) {
    mealAvailability(request: $requestParams) {
      departureCode
      availableMeals {
        ...MealsDetails
      }
    }
  }
  ${MealsDetailsFragmentDoc}
`

/**
 * __useGetAvailableMealsQuery__
 *
 * To run a query within a React component, call `useGetAvailableMealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableMealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableMealsQuery({
 *   variables: {
 *      requestParams: // value for 'requestParams'
 *   },
 * });
 */
export function useGetAvailableMealsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAvailableMealsQuery, GetAvailableMealsQueryVariables> &
    ({ variables: GetAvailableMealsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAvailableMealsQuery, GetAvailableMealsQueryVariables>(GetAvailableMealsDocument, options)
}
export function useGetAvailableMealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableMealsQuery, GetAvailableMealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAvailableMealsQuery, GetAvailableMealsQueryVariables>(
    GetAvailableMealsDocument,
    options,
  )
}
export function useGetAvailableMealsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAvailableMealsQuery, GetAvailableMealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetAvailableMealsQuery, GetAvailableMealsQueryVariables>(
    GetAvailableMealsDocument,
    options,
  )
}
export type GetAvailableMealsQueryHookResult = ReturnType<typeof useGetAvailableMealsQuery>
export type GetAvailableMealsLazyQueryHookResult = ReturnType<typeof useGetAvailableMealsLazyQuery>
export type GetAvailableMealsSuspenseQueryHookResult = ReturnType<typeof useGetAvailableMealsSuspenseQuery>
export type GetAvailableMealsQueryResult = Apollo.QueryResult<GetAvailableMealsQuery, GetAvailableMealsQueryVariables>
export const GetSuggestedItemsDocument = gql`
  query GetSuggestedItems($requestParams: SuggestRequestInput!) {
    suggest(suggestRequest: $requestParams) {
      assignedPassengers
      unassignedPassengers
      cabins {
        code
        quantityInCabin
        name
        price {
          currency
          value
          available
        }
      }
      __typename
    }
  }
`

/**
 * __useGetSuggestedItemsQuery__
 *
 * To run a query within a React component, call `useGetSuggestedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedItemsQuery({
 *   variables: {
 *      requestParams: // value for 'requestParams'
 *   },
 * });
 */
export function useGetSuggestedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables> &
    ({ variables: GetSuggestedItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables>(GetSuggestedItemsDocument, options)
}
export function useGetSuggestedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables>(
    GetSuggestedItemsDocument,
    options,
  )
}
export function useGetSuggestedItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables>(
    GetSuggestedItemsDocument,
    options,
  )
}
export type GetSuggestedItemsQueryHookResult = ReturnType<typeof useGetSuggestedItemsQuery>
export type GetSuggestedItemsLazyQueryHookResult = ReturnType<typeof useGetSuggestedItemsLazyQuery>
export type GetSuggestedItemsSuspenseQueryHookResult = ReturnType<typeof useGetSuggestedItemsSuspenseQuery>
export type GetSuggestedItemsQueryResult = Apollo.QueryResult<GetSuggestedItemsQuery, GetSuggestedItemsQueryVariables>
export const RouteExpectDocument = gql`
  query RouteExpect($originPortCode: String!, $destinationPortCode: String!) {
    route(originPortCode: $originPortCode, destinationPortCode: $destinationPortCode) {
      expectCabins
      expectMeals
      expectExtras
      cabinsRequired
      seatsRequired
    }
  }
`

/**
 * __useRouteExpectQuery__
 *
 * To run a query within a React component, call `useRouteExpectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteExpectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteExpectQuery({
 *   variables: {
 *      originPortCode: // value for 'originPortCode'
 *      destinationPortCode: // value for 'destinationPortCode'
 *   },
 * });
 */
export function useRouteExpectQuery(
  baseOptions: Apollo.QueryHookOptions<RouteExpectQuery, RouteExpectQueryVariables> &
    ({ variables: RouteExpectQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RouteExpectQuery, RouteExpectQueryVariables>(RouteExpectDocument, options)
}
export function useRouteExpectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RouteExpectQuery, RouteExpectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RouteExpectQuery, RouteExpectQueryVariables>(RouteExpectDocument, options)
}
export function useRouteExpectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RouteExpectQuery, RouteExpectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RouteExpectQuery, RouteExpectQueryVariables>(RouteExpectDocument, options)
}
export type RouteExpectQueryHookResult = ReturnType<typeof useRouteExpectQuery>
export type RouteExpectLazyQueryHookResult = ReturnType<typeof useRouteExpectLazyQuery>
export type RouteExpectSuspenseQueryHookResult = ReturnType<typeof useRouteExpectSuspenseQuery>
export type RouteExpectQueryResult = Apollo.QueryResult<RouteExpectQuery, RouteExpectQueryVariables>
export const CheckIfActiveMemberDocument = gql`
  query CheckIfActiveMember {
    isActiveFjordClubMember
  }
`

/**
 * __useCheckIfActiveMemberQuery__
 *
 * To run a query within a React component, call `useCheckIfActiveMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfActiveMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfActiveMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckIfActiveMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckIfActiveMemberQuery, CheckIfActiveMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckIfActiveMemberQuery, CheckIfActiveMemberQueryVariables>(
    CheckIfActiveMemberDocument,
    options,
  )
}
export function useCheckIfActiveMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckIfActiveMemberQuery, CheckIfActiveMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckIfActiveMemberQuery, CheckIfActiveMemberQueryVariables>(
    CheckIfActiveMemberDocument,
    options,
  )
}
export function useCheckIfActiveMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CheckIfActiveMemberQuery, CheckIfActiveMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CheckIfActiveMemberQuery, CheckIfActiveMemberQueryVariables>(
    CheckIfActiveMemberDocument,
    options,
  )
}
export type CheckIfActiveMemberQueryHookResult = ReturnType<typeof useCheckIfActiveMemberQuery>
export type CheckIfActiveMemberLazyQueryHookResult = ReturnType<typeof useCheckIfActiveMemberLazyQuery>
export type CheckIfActiveMemberSuspenseQueryHookResult = ReturnType<typeof useCheckIfActiveMemberSuspenseQuery>
export type CheckIfActiveMemberQueryResult = Apollo.QueryResult<
  CheckIfActiveMemberQuery,
  CheckIfActiveMemberQueryVariables
>
export const GetBoardingCardsDocument = gql`
  query GetBoardingCards($bookingCode: String!) {
    booking(bookingCode: $bookingCode) {
      isOngoing
      isInFuture
      isInPast
      isCancelled
      boardingCards {
        ...BoardingCards
      }
      outbound {
        departureDate
        arrivalPortInfo {
          portName
        }
        departurePortInfo {
          portName
        }
      }
    }
  }
  ${BoardingCardsFragmentDoc}
`

/**
 * __useGetBoardingCardsQuery__
 *
 * To run a query within a React component, call `useGetBoardingCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoardingCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoardingCardsQuery({
 *   variables: {
 *      bookingCode: // value for 'bookingCode'
 *   },
 * });
 */
export function useGetBoardingCardsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBoardingCardsQuery, GetBoardingCardsQueryVariables> &
    ({ variables: GetBoardingCardsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBoardingCardsQuery, GetBoardingCardsQueryVariables>(GetBoardingCardsDocument, options)
}
export function useGetBoardingCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBoardingCardsQuery, GetBoardingCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBoardingCardsQuery, GetBoardingCardsQueryVariables>(GetBoardingCardsDocument, options)
}
export function useGetBoardingCardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBoardingCardsQuery, GetBoardingCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetBoardingCardsQuery, GetBoardingCardsQueryVariables>(
    GetBoardingCardsDocument,
    options,
  )
}
export type GetBoardingCardsQueryHookResult = ReturnType<typeof useGetBoardingCardsQuery>
export type GetBoardingCardsLazyQueryHookResult = ReturnType<typeof useGetBoardingCardsLazyQuery>
export type GetBoardingCardsSuspenseQueryHookResult = ReturnType<typeof useGetBoardingCardsSuspenseQuery>
export type GetBoardingCardsQueryResult = Apollo.QueryResult<GetBoardingCardsQuery, GetBoardingCardsQueryVariables>
export const GetBookingDocument = gql`
  query GetBooking($bookingCode: String!) {
    booking(bookingCode: $bookingCode) {
      ...BookingDetails
    }
  }
  ${BookingDetailsFragmentDoc}
`

/**
 * __useGetBookingQuery__
 *
 * To run a query within a React component, call `useGetBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingQuery({
 *   variables: {
 *      bookingCode: // value for 'bookingCode'
 *   },
 * });
 */
export function useGetBookingQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingQuery, GetBookingQueryVariables> &
    ({ variables: GetBookingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options)
}
export function useGetBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingQuery, GetBookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options)
}
export function useGetBookingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBookingQuery, GetBookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options)
}
export type GetBookingQueryHookResult = ReturnType<typeof useGetBookingQuery>
export type GetBookingLazyQueryHookResult = ReturnType<typeof useGetBookingLazyQuery>
export type GetBookingSuspenseQueryHookResult = ReturnType<typeof useGetBookingSuspenseQuery>
export type GetBookingQueryResult = Apollo.QueryResult<GetBookingQuery, GetBookingQueryVariables>
export const GetBookingsDocument = gql`
  query GetBookings {
    bookings {
      future {
        ...FlBooking
      }
      passed {
        ...FlBooking
      }
      cancelled {
        ...FlBooking
      }
      ongoing {
        ...FlBooking
      }
    }
  }
  ${FlBookingFragmentDoc}
`

/**
 * __useGetBookingsQuery__
 *
 * To run a query within a React component, call `useGetBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBookingsQuery, GetBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBookingsQuery, GetBookingsQueryVariables>(GetBookingsDocument, options)
}
export function useGetBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingsQuery, GetBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBookingsQuery, GetBookingsQueryVariables>(GetBookingsDocument, options)
}
export function useGetBookingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBookingsQuery, GetBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetBookingsQuery, GetBookingsQueryVariables>(GetBookingsDocument, options)
}
export type GetBookingsQueryHookResult = ReturnType<typeof useGetBookingsQuery>
export type GetBookingsLazyQueryHookResult = ReturnType<typeof useGetBookingsLazyQuery>
export type GetBookingsSuspenseQueryHookResult = ReturnType<typeof useGetBookingsSuspenseQuery>
export type GetBookingsQueryResult = Apollo.QueryResult<GetBookingsQuery, GetBookingsQueryVariables>
export const GetCustomerDocument = gql`
  query GetCustomer {
    customer {
      personId
      active
      customerCode
      nationalityCode
      gender
      firstName
      lastName
      birthYear
      birthMonth
      birthDay
      defaultDeparturePortCode
      address {
        streetAddress
        postalCode
        postalAddress
        countryCode
      }
      meansOfContacts {
        type
        value
      }
      clubMemberships {
        active
        clubCode
        clubLevelCode
        clubTypeId
        memberSince
        membershipNumber
      }
      customerConsents {
        uid
        personId
        lastModified
        created
        consents {
          email
          sms
          mail
          newsletter
        }
      }
      loyaltyPoints {
        balance
        earnedPoints
        redeemablePoints
        recentlyEarnedPoints
        soonExpiringPoints
      }
    }
  }
`

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options)
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options)
}
export function useGetCustomerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options)
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>
export type GetCustomerSuspenseQueryHookResult = ReturnType<typeof useGetCustomerSuspenseQuery>
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>
export const GetDepartureDocument = gql`
  query GetDeparture($departureCode: String!, $originPort: String!, $destinationPort: String!, $journeyCode: String!) {
    departure(
      departureCode: $departureCode
      originPort: $originPort
      destinationPort: $destinationPort
      journeyCode: $journeyCode
    ) {
      departureCode
      journeyCode
      vesselCode
    }
  }
`

/**
 * __useGetDepartureQuery__
 *
 * To run a query within a React component, call `useGetDepartureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartureQuery({
 *   variables: {
 *      departureCode: // value for 'departureCode'
 *      originPort: // value for 'originPort'
 *      destinationPort: // value for 'destinationPort'
 *      journeyCode: // value for 'journeyCode'
 *   },
 * });
 */
export function useGetDepartureQuery(
  baseOptions: Apollo.QueryHookOptions<GetDepartureQuery, GetDepartureQueryVariables> &
    ({ variables: GetDepartureQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDepartureQuery, GetDepartureQueryVariables>(GetDepartureDocument, options)
}
export function useGetDepartureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDepartureQuery, GetDepartureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDepartureQuery, GetDepartureQueryVariables>(GetDepartureDocument, options)
}
export function useGetDepartureSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDepartureQuery, GetDepartureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetDepartureQuery, GetDepartureQueryVariables>(GetDepartureDocument, options)
}
export type GetDepartureQueryHookResult = ReturnType<typeof useGetDepartureQuery>
export type GetDepartureLazyQueryHookResult = ReturnType<typeof useGetDepartureLazyQuery>
export type GetDepartureSuspenseQueryHookResult = ReturnType<typeof useGetDepartureSuspenseQuery>
export type GetDepartureQueryResult = Apollo.QueryResult<GetDepartureQuery, GetDepartureQueryVariables>
