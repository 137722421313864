import React from 'react'
import { useTranslation } from 'react-i18next'
import { Add, Button, FormElementWrapper } from '@fjordline/styles-v3'

import { ButtonFull } from '../../components/globalStyles'
import { useAddExtrasModal } from '../../components/navbar/addExtrasModalProvider/addExtrasModalContext'

/**
 * @description - Render button for adding products to the booking
 * @param bookingCode
 * @param backTargetTitle - custom title for the back target button
 * @param isActiveOrFuture
 * @constructor
 */
export default function AddExtrasModalButton({
  bookingCode,
  backTargetTitle,
  isActiveOrFuture,
  largeSizedButton,
}: {
  bookingCode: string
  backTargetTitle: string
  isActiveOrFuture: boolean
  largeSizedButton?: boolean
}): JSX.Element | null {
  const { t } = useTranslation()
  const { showAddExtrasModal } = useAddExtrasModal()

  if (!isActiveOrFuture) {
    return null
  }
  return (
    <FormElementWrapper>
      <ButtonFull>
        <Button
          onClick={() =>
            showAddExtrasModal({
              bookingCode,
              backTargetTitle,
            })
          }
          dataCy="div-add-items"
          theme="ghost"
          leadingIcon={Add}
          size={largeSizedButton ? 'large' : 'medium'}
        >
          {t('label.button.addMoreItems')}
        </Button>
      </ButtonFull>
    </FormElementWrapper>
  )
}
