import { IMeal } from '../../../types/myPage/customerBookingDetails/types'

export type ItemsArray = Array<{
  startTime: Date
  code: string
  legCode: string
  spec: Array<{
    specificationCode: string
    specificationPrice: {
      value: number
      currency: string
    }
  }>
}>

export default function mealsHelper(meals: IMeal[]): ItemsArray {
  const items: ItemsArray = []

  for (const item of meals) {
    const { subCode, price } = item

    const spec = {
      specificationCode: subCode,
      specificationPrice: {
        value: price.value,
        currency: price.currency,
      },
    }

    const existingItem = items.find(
      (i) => i.code === item.code && new Date(i.startTime).getTime() === new Date(item.startTime).getTime(),
    )
    if (existingItem) {
      existingItem.spec.push(spec)
    } else {
      items.push({
        startTime: item.startTime,
        code: item.code,
        legCode: item.legCode,
        spec: [spec],
      })
    }
  }

  return items
}
