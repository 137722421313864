import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Colors, Column, GlobalStyles, H2, H3, Logo, Row } from '@fjordline/styles-v3'
import styled from 'styled-components'

import { logDev } from '../components/LogDev'
import { ENVIRONMENT } from '../config'
import { trackEvent, trackError } from '../providers/TelemetryProvider'
import { extractBookingNumber } from '../providers/WebsocketProvider'

const ErrorFallbackContainer = styled.div`
  height: 100vh;
  background: ${Colors.PROFILE_RED};
  display: grid;
  place-items: center;
  min-height: 100vh;
  color: white;
  overflow: hidden;
  margin-bottom: -1.5rem;
  div > h2 {
    color: white;
  }
  div > h3 {
    color: white;
  }
`

type props = {
  error: Error
  resetErrorBoundary: () => void
}

export function ErrorFallback({ error, resetErrorBoundary }: props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  useEffect(() => {
    trackEvent('errorBoundary')
    trackEvent(`errorBoundary: ${location.pathname}, bookingCode: ${bookingCode}`)
  }, [bookingCode, location.pathname])

  useEffect(() => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      navigate(location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorFallbackContainer>
      <GlobalStyles />
      <Row>
        <Column justifyContent="center">
          <Logo width={400} />
        </Column>
        <Column justifyContent="center">
          <H2 style={{ fontWeight: '500' }}>{t('Something went wrong....')}</H2>
        </Column>
        <Column justifyContent="center">
          {' '}
          {ENVIRONMENT === 'PROD' ? (
            <H3> {t('error.errorBoundaryGeneric')}</H3>
          ) : (
            <H3>
              {' '}
              {error?.name} - {error?.message} (only visible in DEV)
            </H3>
          )}
        </Column>
        <Column justifyContent="center">
          <Button
            onClick={() => {
              resetErrorBoundary()
              navigate('/')
            }}
          >
            {t('error.reset')}
          </Button>
        </Column>
      </Row>
    </ErrorFallbackContainer>
  )
}

export const logErrors = (error: Error, info: { componentStack?: string | null }) => {
  logDev(error, info)
  trackError(error, { 'location': 'ErrorBoundary', ...info})
}
