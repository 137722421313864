import { Meal } from '../../types'

export function filterMeals(mealsAttatched, mealsAvailability) {
  const mealDates1 = mealsAttatched?.map((meal) => new Date(meal.startTime).getDate())

  const mealMap = {}

  mealDates1?.forEach((date, index) => {
    const key = mealsAttatched[index].itemType + date
    mealMap[key] = true
  })

  const filteredMeals2 = mealsAvailability?.filter((meal) => {
    const key = meal.itemType + new Date(meal.startTime).getDate()
    return !mealMap[key]
  })

  return filteredMeals2
}

export function sortMealsByStartTime(meals) {
  return meals?.sort((a, b) => {
    const startTimeA = new Date(a.startTime).getTime()
    const startTimeB = new Date(b.startTime).getTime()
    return startTimeA - startTimeB
  })
}

export const addCategoryToAttatchedMeals = (meals?: Meal[]) => {
  return meals?.map((meal) => {
    let category
    if (meal.code?.slice(0, 2) === 'BR') {
      category = itemTypes.BREAKFAST_BUFET
    }

    if (meal.code?.slice(0, 2) === 'DI') {
      category = itemTypes.DINNER_BUFFET
    }

    if (meal.code?.slice(0, 2) === 'GR') {
      category = itemTypes.A_LA_CARTE
    }

    return {
      ...meal,
      itemType: category,
    } as Meal
  })
}

export enum itemTypes {
  BREAKFAST_BUFET = 'BREAKFAST_BUFFET',
  DINNER_BUFFET = 'DINNER_BUFFET',
  A_LA_CARTE = 'A_LA_CARTE',
}
