import { addHours } from 'date-fns'

export default function isLessThanGivenHoursUntil({
  departureDate,
  givenHours,
}: {
  departureDate: string | undefined
  givenHours: number
}): boolean {
  if (departureDate === undefined) {
    return false
  } else {
    const date = new Date(departureDate)
    return new Date() > addHours(date, -givenHours)
  }
}
