import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormActionsWrapper, H2, mediaQuerySizes, MenuDialog, Paragraph } from '@fjordline/styles-v3'

import LoadingWave from '../components/LoadingWave'
import { BASE_PATH, CDN_IMG_LOGIN_BACKGROUND } from '../config'
import { routerRootPaths } from '../routes/appRoutes/routerPaths'
import { useMyPage, useMyPageOperations } from '../providers/myPageStateProvider/context'
import { currentPath } from '../storage/StateStorage'
import { FjordClubMemberStatus } from '../types/MyPage'

import { HomeDiv, LoginBackground } from './start/start.styles'
import LottieLoaderContainer from '../components/LottieLoader'
import Login from '../auth/Login'
import { useKeycloak, useKeycloakOperations } from '../providers/KeycloakProvider'
import useQueryParams from './receipt/useQueryParams'
import { useMediaQuery } from 'react-responsive'

const Start: React.FC = () => {
  const { isAuthenticated: kc_isAuthenticated, isLoading: kc_isLoading } = useKeycloak()
  const isAuthenticated = kc_isAuthenticated
  const isLoading = kc_isLoading
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { fjordClubMemberStatus } = useMyPage()
  const { setUserIsNotAuthenticated } = useMyPageOperations()
  const isMobile = useMediaQuery({ query: `(max-width: ${mediaQuerySizes.small}px)` })

  const { queryParams } = useQueryParams()
  const register_fc = queryParams.get('register_fc') === 'true'
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const { register } = useKeycloakOperations()

  useEffect(() => {
    if (!isLoading && register_fc) {
      sessionStorage.setItem('register_fc', 'true')
      register()
    }
  }, [isLoading, register, register_fc])

  useEffect(() => {
    if (isAuthenticated) {
      const path = currentPath.get() || routerRootPaths.BOOKING_LIST
      const pathname = path === '/' || path === '' ? routerRootPaths.BOOKING_LIST : path
      currentPath.clear()
      BASE_PATH !== '/' ? navigate(routerRootPaths.BOOKING_LIST) : navigate(pathname)
    } else {
      if (fjordClubMemberStatus === FjordClubMemberStatus.valueNotSet) {
        setUserIsNotAuthenticated()
      }
    }
  }, [fjordClubMemberStatus, isAuthenticated, navigate, setUserIsNotAuthenticated])

  if (fjordClubMemberStatus === FjordClubMemberStatus.valueNotSet || isLoading) {
    return <LottieLoaderContainer />
  }

  return (
    <LoginBackground>
      <div className="bg-container">
        <img
          src={!isMobile ? CDN_IMG_LOGIN_BACKGROUND : ''}
          className="bg-img"
          alt={!isMobile ? 'Fjord Line vessel sailing' : ''}
        />
      </div>
      <HomeDiv>
        <H2>{t('component.welcome.toMyPage')}</H2>
        <Paragraph>{t('component.welcome.generalInfo')}</Paragraph>
        {isLoading ? null : (
          <FormActionsWrapper>
            <Login />
          </FormActionsWrapper>
        )}
      </HomeDiv>
      {isLoading ? <LoadingWave hideSpinner={true}>Authenticating</LoadingWave> : null}
      {loginModalOpen ? (
        <MenuDialog onRequestClose={() => setLoginModalOpen(false)}>
          <Login />
        </MenuDialog>
      ) : null}
    </LoginBackground>
  )
}
export default Start
