import { t } from 'i18next'

export type passengerBirthDate = {
  id: number
  title: number
}

enum idType {
  PASSPORT = 'PASSPORT',
  NATIONAL_ID = 'NATIONAL_ID',
}

export function createBirthdayLists() {
  const firstDay = 1
  const lastDay = 31
  const days: number[] = []
  for (let i = firstDay; i <= lastDay; i++) {
    days.push(i)
  }

  const childLowYear: number = new Date().getFullYear() - 16
  const childHighYear: number = new Date().getFullYear() - 4
  const childListYear: number[] = []
  for (let i = childLowYear; i <= childHighYear; i++) {
    childListYear.push(i)
  }

  const babyLowYear: number = new Date().getFullYear() - 1
  const babyHighYear: number = new Date().getFullYear()
  const babyListYear: number[] = []
  for (let i = babyLowYear; i <= babyHighYear; i++) {
    babyListYear.push(i)
  }

  const infantLowYear: number = new Date().getFullYear() - 4
  const infantHighYear: number = new Date().getFullYear() - 1
  const infantListYear: number[] = []
  for (let i = infantLowYear; i <= infantHighYear; i++) {
    infantListYear.push(i)
  }

  const adultLowYear: number = new Date().getFullYear() - 110
  const adultHighYear: number = new Date().getFullYear() - 16
  const fcSignupYearLow = new Date().getFullYear() - 110
  const fcSignupYearHigh = new Date().getFullYear() - 18

  const fcSignupYearList: number[] = []
  for (let i = fcSignupYearLow; i <= fcSignupYearHigh; i++) {
    fcSignupYearList.push(i)
  }

  const adultListYear: number[] = []
  for (let i = adultLowYear; i <= adultHighYear; i++) {
    adultListYear.push(i)
  }

  const daysList: passengerBirthDate[] = days.map((a) => {
    return { id: a, title: a }
  })

  const infantYearList: passengerBirthDate[] = infantListYear?.map((a) => {
    return {
      id: a,
      title: a,
    }
  })

  const babyYearList: passengerBirthDate[] = babyListYear?.map((a) => {
    return {
      id: a,
      title: a,
    }
  })

  const childYearList: passengerBirthDate[] = childListYear?.map((a) => {
    return {
      id: a,
      title: a,
    }
  })

  const adultYearList = adultListYear?.map((a) => {
    return {
      id: a,
      title: a,
    }
  })

  const fcSignUpList = fcSignupYearList?.map((a) => {
    return {
      id: a,
      title: a,
    }
  })

  const idTypes = [
    { id: idType.PASSPORT, title: `${t('pages.passengerDetails.passport')}` },
    {
      id: idType.NATIONAL_ID,
      title: `${t('pages.passengerDetails.nationalId')}`,
    },
  ]

  const months = [
    { id: 1, title: `${t('label.date.month_1')}` },
    { id: 2, title: `${t('label.date.month_2')}` },
    { id: 3, title: `${t('label.date.month_3')}` },
    { id: 4, title: `${t('label.date.month_4')}` },
    { id: 5, title: `${t('label.date.month_5')}` },
    { id: 6, title: `${t('label.date.month_6')}` },
    { id: 7, title: `${t('label.date.month_7')}` },
    { id: 8, title: `${t('label.date.month_8')}` },
    { id: 9, title: `${t('label.date.month_9')}` },
    { id: 10, title: `${t('label.date.month_10')}` },
    { id: 11, title: `${t('label.date.month_11')}` },
    { id: 12, title: `${t('label.date.month_12')}` },
  ]

  return {
    months,
    adultYearList,
    childYearList,
    babyYearList,
    infantYearList,
    daysList,
    idTypes,
    fcSignUpList,
  }
}
