import { FlCustomer } from '../graphql/types'
import { mpCart } from '../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'

import { CurrentAvailability, defaultMpCarts, IndexedBookings } from './myPage/types'

/**
 * @description - userIsNotAuthenticated: user is not authenticated
 * @description - userHasNoProfile: authenticated user has never booked any journeys
 * @description - userHasPartialProfileIsNotMember: authenticated user has booked journeys but has not completed the profile
 * @description - userIsFjordClubMember: authenticated user is Fjord Club member
 */
export enum FjordClubMemberStatus {
  valueNotSet = 'valueNotSet',
  userIsNotAuthenticated = 'userIsNotAuthenticated',
  userHasNoProfile = 'userHasNoProfile',
  userHasPartialProfileIsNotMember = 'hasPartialProfile',
  userIsFjordClubMember = 'userIsFjordClubMember',
}

export type BookingListWithKeys = {
  indexedBookings: IndexedBookings
  sortedBookingCodes: string[]
}

export type BookingList = {
  cancelled: BookingListWithKeys
  future: BookingListWithKeys
  ongoing: BookingListWithKeys
  passed: BookingListWithKeys
}

export type MyPage = {
  bookingList?: BookingList
  customer?: FlCustomer
  currentAvailability: CurrentAvailability
  mpCarts: mpCart
  fjordClubMemberStatus: FjordClubMemberStatus
}

export const defaultBookingListWithKeys = {
  indexedBookings: {},
  sortedBookingCodes: [],
}
export const defaultMyPage: MyPage = {
  bookingList: {
    cancelled: defaultBookingListWithKeys,
    future: defaultBookingListWithKeys,
    ongoing: defaultBookingListWithKeys,
    passed: defaultBookingListWithKeys,
  },
  currentAvailability: {},
  mpCarts: defaultMpCarts,
  fjordClubMemberStatus: FjordClubMemberStatus.valueNotSet,
}
