import { gender } from '@fjordline/booking-draft'
import { t } from 'i18next'

import { FlCustomer, MeansOfContact, MeansOfContactType } from '../../../graphql/types'

export const defaltMeansOfContact: MeansOfContact[] = [
  {
    type: MeansOfContactType.Mob,
    value: '',
  },
  {
    type: MeansOfContactType.Email,
    value: '',
  },
]

// Create a default value for FlCustomer type
const hasFallbackValues = sessionStorage.getItem('form_values') !== null
const fallbackValues = JSON.parse(sessionStorage.getItem('form_values') || '{}')

// Usage example
export const defaultFjordClubMembership: FlCustomer = hasFallbackValues
  ? fallbackValues
  : {
      customerCode: '',
      active: false,
      personId: '',
      firstName: '',
      lastName: '',
      gender: '',
      nationalityCode: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      address: {
        countryCode: '',
        postalAddress: '',
        postalCode: '',
        streetAddress: '',
      },
      defaultDeparturePortCode: '',
      meansOfContacts: defaltMeansOfContact,
      customerConsents: {
        consents: {
          mail: false,
          sms: false,
          email: true,
          newsletter: true,
        },
      },
    }

export const genders = [
  {
    description: gender.MALE,
    value: gender.MALE,
    label: t('label.gender.M'),
  },
  {
    description: gender.FEMALE,
    value: gender.FEMALE,
    label: t('label.gender.F'),
  },
]
