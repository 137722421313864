import { mpCart } from '../myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'

import { CartsByBookingCodes } from './types'

export const removeCabinAction = (
  {
    addToCartState,
    id,
    isOutbound,
    bookingCode,
    cartData,
  }: { addToCartState: CartsByBookingCodes; id: string; isOutbound: boolean; bookingCode: string; cartData: mpCart },
  setAddToCartState,
  updateCart,
) => {
  const cabinsToAddOutbound = addToCartState[bookingCode]?.outbound?.cabins || []
  const cabinsToAddInbound = addToCartState[bookingCode]?.inbound?.cabins || []
  let newCabins = isOutbound
    ? cabinsToAddOutbound.filter((cabin) => cabin?.id !== id)
    : cabinsToAddInbound.filter((cabin) => cabin?.id !== id)

  if (newCabins.every((e) => e.quantityInCabin <= 0)) {
    newCabins = []
  }

  const updatedState = {
    ...addToCartState,
    [bookingCode]: {
      ...addToCartState[bookingCode],
      [isOutbound ? 'outbound' : 'inbound']: {
        ...addToCartState[bookingCode]?.[isOutbound ? 'outbound' : 'inbound'],
        cabins: newCabins,
      },
    },
  }
  setAddToCartState(updatedState)
  sessionStorage.setItem('AddToCartState', JSON.stringify(updatedState))
  updateCart({
    id: cartData?.id,
    timestamp: new Date().getTime(),
    bookingCarts: {
      ...updatedState,
    },
  })
}
