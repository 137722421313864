import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyCard, H2, H3, PaginatedCarouselItem } from '@fjordline/styles-v3'

import { TicketWrapper } from './tickets.styles'
import { TicketData } from '../Tickets'
import useFetchSpecifications from '../../../sanity/specifications/useFetchSpecifications'
import useFetchExtras from '../../../sanity/extras/useFetchExtras'

export default function CarouselTicket({
  isMobile,
  item,
  showlargeTicketImage,
}: {
  isMobile: boolean
  item: PaginatedCarouselItem<TicketData>
  showlargeTicketImage: (item: string) => void
}): JSX.Element {
  const { t, i18n } = useTranslation()
  const data = item.data

  const { specByCode } = useFetchSpecifications(data?.subCode)
  const { findExtrasName } = useFetchExtras(data?.code)

  if (data === undefined) {
    return <span>{item.title}</span>
  } else {
    return (
      <TicketWrapper isMobile={isMobile} onClick={() => !isMobile && showlargeTicketImage(data.barcodeImage)}>
        <EmptyCard fullWidth header={<H2 className="ticketHeader">{findExtrasName() ?? data?.description}</H2>}>
          <H3>{specByCode?.specificationName?.[i18n.language] ?? data.subCode}</H3>
          <div className="ticketCard">
            <img src={`${data.barcodeImage}`} alt={`${t('label.tickets.barcode.alt')}`} />
            <H3>{t('label.tickets.departureDate')}</H3>
            <p>{new Date(data.departureDate).toDateString()}</p>
          </div>
        </EmptyCard>
      </TicketWrapper>
    )
  }
}
