const signalREndpoints = {
  //-----
  updateFlCustomer: 'UpdateFlCustomer',
  createFlCustomer: 'CreateFlCustomer',
  addCompanion: 'AddCompanion',
  updateCompanion: 'UpdateCompanion',
  deleteCompanion: 'DeleteCompanion',
  //-----
  updateCart: 'UpdateCart',
  clearMpCart: 'ClearMpCart',
  //-----
  updateBookingContactPerson: 'UpdateBookingContactPerson',
  updateBookingPassenger: 'UpdateBookingPassenger',
  updateBookingVehicle: 'UpdateBookingVehicle',
  //-----
  checkoutCart: 'CheckoutCart',
}

export default signalREndpoints
