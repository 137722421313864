import React from 'react'
import { FormElementWrapper } from '@fjordline/styles-v3'

import CustomBackButton from '../../components/CustomBackbutton'
import { navLinks } from '../../routes/navlinks'
import { ColoredDiv } from '../comon.styles'

const Traveler: React.FC = () => {
  return (
    <>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={navLinks.FJORD_CLUB} />
      </FormElementWrapper>
      <ColoredDiv color="black">Traveler</ColoredDiv>
    </>
  )
}

export default Traveler
