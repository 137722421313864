import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import BookingDetailsProvider from '../providers/BookingDetailsProvider'

import { routerBookingSubPaths, routerFjordClubSubPaths, routerRootPaths } from './appRoutes/routerPaths'
import Basket from '../pages/Basket'
import Booking from '../pages/Booking'
import BookingList from '../pages/BookingList'
import FjordClub from '../pages/FjordClub'
import Payment from '../pages/Receipt'
import Start from '../pages/Start'
import BoardingCards from '../pages/booking/BoardingCards'
import Tickets from '../pages/booking/Tickets'
import AddExtrasAshore from '../pages/booking/editOrAddItemsPages/AddExtrasAshore'
import AddExtrasOnboard from '../pages/booking/editOrAddItemsPages/AddExtrasOnboard'
import AddMeals from '../pages/booking/editOrAddItemsPages/AddMeals'
import EditCabins from '../pages/booking/editOrAddItemsPages/EditCabins'
import EditPassenger from '../pages/booking/editOrAddItemsPages/EditPassenger'
import EditVehicle from '../pages/booking/editOrAddItemsPages/EditVehicle'
import EditMemberShip from '../pages/fjordClub/EditMemberShip'
import SignedUp from '../pages/fjordClub/SignedUp'
import Traveler from '../pages/fjordClub/Traveler'
import TravelerFriend from '../pages/fjordClub/TravelerFriend'
import { useKeycloak } from '../providers/KeycloakProvider'
import { PayWithClubPoints } from '../pages/PayWithClubPoints'

const AppRoutes: React.FC = () => {
  const { isAuthenticated: kc_isAuth, isLoading: kc_isLoading } = useKeycloak()

  const userIsNotAuthenticated = !kc_isAuth && !kc_isLoading
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (userIsNotAuthenticated && location.pathname !== routerRootPaths.LOGIN) {
      navigate(routerRootPaths.LOGIN)
    }
  }, [location.pathname, navigate, userIsNotAuthenticated])

  if (!kc_isAuth && !kc_isLoading) {
    return <Routes>{<Route path={routerRootPaths.ROOT} element={<Start />} />}</Routes>
  } else
    return (
      <Routes>
        <Route path={routerRootPaths.ROOT} element={<Start />} />
        <Route path={routerRootPaths.LOGIN} element={<Start />} />
        <Route path={routerRootPaths.PAY_WITH_CLUB_POINTS} element={<PayWithClubPoints />} />
        <Route path={routerRootPaths.FJORD_CLUB}>
          <Route index element={<FjordClub />} />
          <Route path={routerFjordClubSubPaths.SIGN_UP} element={<EditMemberShip />} />
          <Route path={routerFjordClubSubPaths.SIGN_UP_ADD} element={<EditMemberShip />} />
          <Route path={routerFjordClubSubPaths.EDIT_MEMBERSHIP} element={<EditMemberShip />} />
          <Route path={routerFjordClubSubPaths.SIGNED_UP} element={<SignedUp />} />
          <Route path={routerFjordClubSubPaths.TRAVELER} element={<Traveler />} />
          <Route path={routerFjordClubSubPaths.TRAVELER_FRIEND} element={<TravelerFriend />} />
        </Route>
        <Route path={routerRootPaths.BOOKING_LIST}>
          <Route index element={<BookingList />} />
          <Route path={routerBookingSubPaths.BOOKING}>
            <Route index element={<BookingDetailsProvider>{<Booking />}</BookingDetailsProvider>} />
            <Route path={routerBookingSubPaths.ADD_MEALS} element={<AddMeals />} />
            <Route path={routerBookingSubPaths.ADD_EXTRAS_ASHORE} element={<AddExtrasAshore />} />
            <Route path={routerBookingSubPaths.ADD_EXTRAS_ONBOARD} element={<AddExtrasOnboard />} />
            <Route
              path={routerBookingSubPaths.BOOKING_PASSENGER}
              element={<BookingDetailsProvider>{<EditPassenger />}</BookingDetailsProvider>}
            />
            <Route
              path={routerBookingSubPaths.BASKET}
              element={<BookingDetailsProvider>{<Basket />}</BookingDetailsProvider>}
            />
            <Route path={routerBookingSubPaths.VEHICLE} element={<EditVehicle />} />
            <Route path={routerBookingSubPaths.ADD_EXTRAS_ASHORE} element={<AddExtrasAshore />} />
            <Route path={routerBookingSubPaths.ADD_EXTRAS_ONBOARD} element={<AddExtrasOnboard />} />
            <Route path={routerBookingSubPaths.BOARDING_CARDS} element={<BoardingCards />} />
            <Route path={routerBookingSubPaths.EDIT_CABINS} element={<EditCabins />} />
            <Route path={routerBookingSubPaths.CART} element={<Basket />} />
            <Route path={routerBookingSubPaths.PAYMENT} element={<Payment />} />
            <Route path={routerBookingSubPaths.TICKETS} element={<Tickets />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to={routerRootPaths.ROOT} />} />
      </Routes>
    )
}

export default AppRoutes
