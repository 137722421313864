import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LOGOUT_URI,
  REDIRECT_URI,
  BASE_PATH,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  ENVIRONMENT,
} from '../config'
import { ContextChildren } from './genericTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import Keycloak from 'keycloak-js'
import Cookies from 'js-cookie'

export type KeycloakContextType = {
  token: string | undefined
  isAuthenticated: boolean
  isLoading: boolean
  email: string | undefined
  userEmail: string | undefined
}

export const KeycloakContext = createContext({} as KeycloakContextType)
KeycloakContext.displayName = 'KeycloakContext'
export const KeycloakContextOperations = createContext({} as KeycloakOperationsType)
KeycloakContextOperations.displayName = 'KeycloakContextOperations'

export const useKeycloak = () => useContext(KeycloakContext)
export const useKeycloakOperations = () => useContext(KeycloakContextOperations)

export type KeycloakOperationsType = {
  logout: () => void
  login: () => void
  setEmail: (email: string) => void
  register: () => void
  setToken: (token: string) => void
  refreshToken: () => void
}

export const client = new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
})

const KeycloakProvider: React.FunctionComponent<React.PropsWithChildren<ContextChildren>> = ({
  children,
}: React.PropsWithChildren<ContextChildren>) => {
  const [token, setToken] = useState<string | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem('isAuthenticated') === 'true' ? true : false,
  )
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const isDevelopment = ENVIRONMENT === 'DEV'

  const location = useLocation()
  useEffect(() => {
    if (isAuthenticated && location.pathname === '/') navigate('/bookingList')
  }, [isAuthenticated, location.pathname, navigate])

  const [email, setEmail] = useState<string | undefined>(undefined)
  const logout = useCallback(() => {
    sessionStorage.removeItem('isAuthenticated')
    sessionStorage.removeItem('token')
    Cookies.set(isDevelopment ? 'FL_LOGOUT_TEST' : 'FL_LOGOUT', 'true', {
      expires: 999,
      sameSite: 'lax',
      domain: '.fjordline.com',
    })
    Cookies.set(isDevelopment ? 'FL_LOGOUT_TEST' : 'FL_LOGOUT', 'true', {
      expires: 999,
      sameSite: 'lax',
      domain: '.fjordline.no',
    })
    Cookies.remove(isDevelopment ? 'FL_LOGOUT_FRONTPAGE_TEST' : 'FL_LOGOUT_FRONTPAGE', {
      sameSite: 'lax',
      domain: '.fjordline.com',
    })
    Cookies.remove(isDevelopment ? 'FL_LOGOUT_FRONTPAGE_TEST' : 'FL_LOGOUT_FRONTPAGE', {
      sameSite: 'lax',
      domain: '.fjordline.no',
    })
    Cookies.remove(isDevelopment ? 'FL_LOGIN_BOOKING_TEST' : 'FL_LOGIN_BOOKING', {
      sameSite: 'lax',
      domain: '.fjordline.com',
    })
    Cookies.remove(isDevelopment ? 'FL_LOGIN_BOOKING_TEST' : 'FL_LOGIN_BOOKING', {
      sameSite: 'lax',
      domain: '.fjordline.no',
    })
    client.logout({
      redirectUri: `${LOGOUT_URI}/${i18n.language}`,
    })
  }, [i18n.language, isDevelopment])
  const isRun = useRef(false)

  let lang = i18n.language

  if (lang === 'nb') lang = 'no'

  const login = useCallback(() => {
    client.login({
      loginHint: email,
      locale: lang,
      redirectUri: `${REDIRECT_URI}bookingList`,
    })
  }, [email, lang])

  const register = useCallback(() => {
    client.register({
      loginHint: email,
      locale: lang,
      redirectUri: `${REDIRECT_URI}bookingList`,
    })
  }, [email, lang])

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    client.onTokenExpired = () => {
      client.updateToken().then((refreshed) => {
        if (refreshed) {
          setToken(client.token)
        }
      })
    }
  }, [])

  const refreshToken = () => {
    client.updateToken(99999999).then(() => {
      setToken(client.token)
    })
  }

  useEffect(() => {
    if (isRun.current) return
    setIsLoading(true)
    isRun.current = true
    client
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + BASE_PATH + 'silent-check-sso.html',
        checkLoginIframe: false,
      })
      .then((res) => {
        setIsAuthenticated(res)
        sessionStorage.setItem('isAuthenticated', res ? 'true' : 'false')
        if (client.token) {
          Cookies.set(isDevelopment ? 'FL_LOGIN_BOOKING_TEST' : 'FL_LOGIN_BOOKING', 'true', {
            expires: 999,
            sameSite: 'lax',
            domain: '.fjordline.com',
          })
          Cookies.set(isDevelopment ? 'FL_LOGIN_BOOKING_TEST' : 'FL_LOGIN_BOOKING', 'true', {
            expires: 999,
            sameSite: 'lax',
            domain: '.fjordline.no',
          })
          sessionStorage.setItem('token', client.token)
          setToken(client.token)
          setUserEmail(client.idTokenParsed?.email)
          if (Cookies.get(isDevelopment ? 'FL_LOGOUT_FRONTPAGE_TEST' : 'FL_LOGOUT_FRONTPAGE') === 'true') {
            client
              .logout({
                redirectUri: `${LOGOUT_URI}`,
              })
              .then(() => {
                Cookies.remove(isDevelopment ? 'FL_LOGOUT_FRONTPAGE_TEST' : 'FL_LOGOUT_FRONTPAGE', {
                  sameSite: 'lax',
                  domain: '.fjordline.com',
                })
                Cookies.remove(isDevelopment ? 'FL_LOGOUT_FRONTPAGE_TEST' : 'FL_LOGOUT_FRONTPAGE', {
                  sameSite: 'lax',
                  domain: '.fjordline.no',
                })
                Cookies.remove(isDevelopment ? 'FL_LOGIN_BOOKING_TEST' : 'FL_LOGIN_BOOKING', {
                  sameSite: 'lax',
                  domain: '.fjordline.com',
                })
                Cookies.remove(isDevelopment ? 'FL_LOGIN_BOOKING_TEST' : 'FL_LOGIN_BOOKING', {
                  sameSite: 'lax',
                  domain: '.fjordline.no',
                })
              })

            sessionStorage.removeItem('isAuthenticated')
            sessionStorage.removeItem('token')
          }
        }
        setIsLoading(false)
      })
      .catch(() => {
        navigate('/')
        setIsLoading(false)
      })
  }, [isDevelopment, navigate])

  const KeycloakValues: KeycloakContextType = useMemo(
    () => ({
      token,
      isAuthenticated,
      isLoading,
      email,

      userEmail,
    }),
    [email, isAuthenticated, isLoading, token, userEmail],
  )

  //Refresh kc token every 15 minutes
  useEffect(() => {
    if (!isAuthenticated || !client.token) return
    const refreshToken_call = () => {
      refreshToken()
    }
    refreshToken_call()
    const intervalId = setInterval(refreshToken, 15 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [isAuthenticated])

  const KeycloakOperations: KeycloakOperationsType = useMemo(
    () => ({
      logout,
      login,
      setEmail,
      register,
      setToken,
      refreshToken,
    }),
    [login, register, logout],
  )

  return (
    <KeycloakContext.Provider value={KeycloakValues}>
      <KeycloakContextOperations.Provider value={KeycloakOperations}>{children}</KeycloakContextOperations.Provider>
    </KeycloakContext.Provider>
  )
}

export default KeycloakProvider
