import { useEffect, useState } from 'react'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

import { logDev } from '../../../components/LogDev'
import { WS_FL_CART_URL } from '../../../config'

import { unsubscribeEvents } from './websocketProviderFunctions/subscribeToEvents'
import { unsubscribeErrorEvents } from './websocketProviderFunctions/subscribeToExceptionEvents'
import { useKeycloak } from '../../KeycloakProvider'

const getNewConnection: (accessToken: string) => HubConnection = (accessToken: string) =>
  new HubConnectionBuilder()
    .withUrl(WS_FL_CART_URL, { accessTokenFactory: () => accessToken })
    .withAutomaticReconnect()
    .build()

type UseWebsocket = () => {
  connection: HubConnection | null
  isWebsocketInitializing: boolean
  authenticationError?: string
}

/**
 * @description - Returns a websocket connection. If the user is not authorized, the connection is null.
 * @description - If the user is authorized, the connection is created and returned.
 * @description - connection: HubConnection | null
 * @description - isWebsocketInitializing: boolean
 * @description - authenticationError?: string
 */
const useWebsocket: UseWebsocket = () => {
  const { isAuthenticated: kc_isAuth, isLoading: kc_isLoading, token } = useKeycloak()

  const [connection, setConnection] = useState<HubConnection | null>(null)
  const [authenticationError, setAuthenticationError] = useState<string | undefined>(undefined)
  const isAuthenticated = kc_isAuth
  const isLoading = kc_isLoading

  useEffect(() => {
    if (kc_isAuth && !isLoading && token && connection === null) {
      const newConnection = getNewConnection(token)
      setConnection(newConnection)
      setAuthenticationError(undefined)
    }
  }, [connection, isLoading, kc_isAuth, token])

  /**
   * @description - If the user is not authenticated, unsubscribe from events and stop the connection
   */
  useEffect(() => {
    if (!isAuthenticated && connection !== null) {
      unsubscribeEvents(connection).then(() => {
        unsubscribeErrorEvents(connection).then(() => {
          logDev('(#9)unsubscribed from events')
          connection.stop().then(() => {
            setConnection(null)
            logDev('(#10)connection stopped')
          })
        })
      })
    }
  }, [connection, isAuthenticated])

  return {
    connection,
    isWebsocketInitializing: isLoading || (connection !== null && connection.state === undefined),
    authenticationError,
  }
}

export default useWebsocket
