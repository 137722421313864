import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getModifyBookingClient } from '../../rest/clients'
import { trackEvent } from '../../providers/TelemetryProvider'
import { useKeycloak } from '../../providers/KeycloakProvider'

function useDownloadPDF() {
  const { bookingCode } = useParams()
  const [fetchingPDF, setFetchingPDF] = useState(false)

  const { token, isAuthenticated: kc_isAuth, isLoading: kc_isLoading } = useKeycloak()
  const isAuthenticated = kc_isAuth
  const [accessToken, setAccessToken] = useState<string>('')
  useEffect(() => {
    if (!kc_isLoading && kc_isAuth && token) {
      setAccessToken(token)
    }
  }, [kc_isAuth, kc_isLoading, token])

  function downloadPDF() {
    setFetchingPDF(true)
    if (bookingCode && accessToken && isAuthenticated) {
      getModifyBookingClient(accessToken)
        ?.getBookingPDF(bookingCode)
        .then((res) => {
          const url = window.URL.createObjectURL(res)
          trackEvent('user downloaded booking pdf')
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${bookingCode}.pdf`)
          document.body.appendChild(link)
          link.click()

          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          trackEvent('user failed to download booking pdf')
        })
        .finally(() => setFetchingPDF(false))
    }
  }
  return {
    downloadPDF,
    fetchingPDF,
  }
}

export default useDownloadPDF
