import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EditInput, FormElementWrapper, HeaderWithIcon, UserStar } from '@fjordline/styles-v3'

import { ClubMembership, Maybe, useGetCustomerQuery } from '../../graphql/types'
import { navLinks } from '../../routes/navlinks'
import { useMyPage } from '../../providers/myPageStateProvider/context'
import moment from 'moment/min/moment-with-locales'

const FjordClubCustomerMembership = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { customer } = useMyPage()
  const { data } = useGetCustomerQuery({})

  const clubMemberShips: Array<Maybe<ClubMembership>> | null | undefined = useMemo(
    () => data?.customer?.clubMemberships,
    [data?.customer?.clubMemberships],
  )

  const clubMemberShip: Maybe<ClubMembership> | undefined = useMemo(
    () => clubMemberShips?.find((m) => m?.membershipNumber && m.membershipNumber.length > 0),
    [clubMemberShips],
  )

  const membershipNumber = useMemo(
    () => (clubMemberShip?.membershipNumber ? clubMemberShip?.membershipNumber : 'pending'),
    [clubMemberShip],
  )

  const texts = useMemo(
    function getEditMemberFields() {
      return [
        {
          label: `${t('label.personalia.firstName')}`,
          text: customer?.firstName || '',
        },
        {
          label: `${t('label.personalia.lastName')}`,
          text: customer?.lastName || '',
        },
        {
          label: `${t('label.membership.memberNumber')}`,
          text: membershipNumber,
        },
        {
          label: `${t('label.membership.loyaltyPointsBalance')}`,
          text: data?.customer?.loyaltyPoints?.balance || '0',
        },
        // {
        //   label: `${data?.customer?.loyaltyPoints?.soonExpiringPoints === 0 ? '' : t('label.membership.loyaltyPointsSoonExpiring')}`,
        //   text: data?.customer?.loyaltyPoints?.soonExpiringPoints || '',
        // },
        {
          label: `${t('label.membership.membershipLevel')}`,
          text: clubMemberShip?.clubLevelCode || '',
        },
        {
          label: `${t('label.membership.memberSince')}`,
          text:
            clubMemberShip && clubMemberShip.membershipNumber && clubMemberShip.memberSince
              ? moment(clubMemberShip.memberSince).locale(i18n.language).format('D. MMMM YYYY')
              : '',
        },
      ]
    },
    [
      clubMemberShip,
      customer?.firstName,
      customer?.lastName,
      data?.customer?.loyaltyPoints?.balance,
      i18n.language,
      membershipNumber,
      t,
    ],
  )
  if (customer === undefined) {
    return null
  }

  return (
    <>
      <HeaderWithIcon icon={UserStar}>{t('label.membership.header')}</HeaderWithIcon>
      <FormElementWrapper>
        <EditInput texts={texts} onClick={() => navigate(navLinks.EDIT_MEMBERSHIP)} />
      </FormElementWrapper>
    </>
  )
}

export default FjordClubCustomerMembership
