import { rem } from '@fjordline/styles-v3'
import styled from 'styled-components'

export const BookingInfoHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const BookingInfoDetailsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
  padding-left: 1rem;
`

export const BookingNumber = styled.p`
  font-size: ${rem(28)};
  line-height: ${rem(24)};
  margin: ${rem(10)} 0 ${rem(12)};
`

export const QrWrapper = styled.div`
  justify-content: left;
  margin-bottom: ${rem(24)};

  > * {
    width: 100%;
    max-width: ${rem(280)};
  }
`

export const TravelWay = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(18)};

  > svg {
    margin-right: ${rem(22)};
  }

  h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`
