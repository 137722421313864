export default function getDeparturePorts({
  arrivalPortName,
  departurePortName,
}: {
  arrivalPortName?: string
  departurePortName?: string
}): string {
  if (arrivalPortName === undefined || departurePortName === undefined) {
    return ''
  }
  return `${departurePortName} ${arrivalPortName ? `- ${arrivalPortName}` : ''}`
}
