import { createContext, useContext } from 'react'

import { FlCustomer } from '../../../../../graphql'
import { CartsByBookingCodes } from '../../../../availabilityItemsProvider/types'

import { InvokeUpdateResult } from './useMpCartFunctions'

export type mpCart = {
  id: string
  timestamp: number
  bookingCarts: CartsByBookingCodes
  status?: string
}

export type MpCartOperationsContextType = {
  updateFlCustomer: (fjordClubMembership: FlCustomer) => Promise<InvokeUpdateResult>
  createFlCustomer: (fjordClubMembership: FlCustomer) => Promise<InvokeUpdateResult>
  updateCart: (mpCart: mpCart) => void
}

export const websocketOperationsContext = createContext<MpCartOperationsContextType>({} as MpCartOperationsContextType)
websocketOperationsContext.displayName = 'Websocket operations'

export const useWebSocketOperationsContext = () => useContext(websocketOperationsContext)
