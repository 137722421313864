import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { MIGRATION_SERVICE_URL } from '../config'

export function useGetLoginProvider({ email }: { email?: string }) {
  return useQuery({
    queryKey: [email],
    enabled: false,
    queryFn: async () => {
      const data = await axios.post(MIGRATION_SERVICE_URL, {
        email_address: email,
      })
      return data
    },
  })
}

export enum providerEnum {
  none = 'NONE',
  Auth0 = 'AUTH0',
  Keycloak = 'KEYCLOAK',
  Renew = 'RENEW',
}

export type LoginProviderResponse = {
  provider: providerEnum
  exists_in_auth0: boolean
  exists_in_keycloak: boolean
  has_active_bookings: boolean
}
