import React from 'react'
import { useTranslation } from 'react-i18next'
import { Bed, FormElementWrapper, HeaderWithIcon, Label } from '@fjordline/styles-v3'

import { BookedAccommodation, Room } from '../../graphql'
import { useTimeZonedDateFormatter } from '../../hooks/DateFormat'
import useFetchAccommodations from '../../sanity/accommodations/useFetchAccommodations'

type Props = {
  accommodations: BookedAccommodation[]
}

export default function SectionAccommodations({ accommodations }: Props): JSX.Element | null {
  const { t } = useTranslation()

  return (
    <FormElementWrapper>
      <HeaderWithIcon icon={Bed}>{t('metadata.title.accommodation')}</HeaderWithIcon>

      {accommodations.map((acc) => {
        return <AccommodationsComponent key={acc.code} acc={acc} />
      })}
    </FormElementWrapper>
  )
}

type accProps = {
  acc: BookedAccommodation
}
const AccommodationsComponent = ({ acc }: accProps) => {
  const d = useTimeZonedDateFormatter()
  const { t } = useTranslation()

  const { accommodation } = useFetchAccommodations(acc.code)
  const name = accommodation?.name
  return (
    <div style={{ marginBottom: '2rem', padding: '1rem', border: '1px solid rgb(187, 187, 187)' }}>
      <Label>{name}</Label>
      <div style={{ marginTop: '0.5rem' }} />
      <Label>{t('component.extras.ashore.checkin')}</Label>
      <span>{d(acc.checkinDate, 'HH:mm, d. MMMM yyyy')}</span>
      <div style={{ marginTop: '5px' }} />
      <Label>{t('component.extras.ashore.checkout')}</Label>
      <span style={{ display: 'block' }}>{d(acc.checkoutDate, 'HH:mm, d. MMMM yyyy')}</span>
      <div style={{ marginTop: '5px' }} />
      <Label>{t('component.extras.ashore.roomType')}</Label>
      {acc.rooms.map((room, index) => {
        const key = index + 1
        return <RoomContainer accommodation={accommodation} room={room} key={room.code + key} />
      })}
    </div>
  )
}

type RoomProps = {
  room: Room
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accommodation: any
}

const RoomContainer = ({ room, accommodation }: RoomProps) => {
  const roomInfo = accommodation?.rooms?.find((e) => e.externalRoomId === room.code)
  const { i18n } = useTranslation()

  return <span style={{ marginBottom: '5px', display: 'block' }}>{roomInfo?.name?.[i18n.language] ?? room.code}</span>
}
