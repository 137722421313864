/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENVIRONMENT, LOG_DEV } from '../config'

/**
 * @description console.log wrapper
 * @description Only logs in DEV environment and if LOG_DEV is true
 * @param message
 * @param optionalParams
 */
export const logDev: (message?: any, ...optionalParams: any[]) => void = (message, ...optionalParams) => {
  if (ENVIRONMENT === 'DEV' && LOG_DEV) {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams)
  }
}

/**
 * @description console.error wrapper
 * @description Only logs in DEV environment and if LOG_DEV is true
 * @param message
 * @param optionalParams
 */
export const logDevError: (message?: any, ...optionalParams: any[]) => void = (message, ...optionalParams) => {
  if (ENVIRONMENT === 'DEV' && LOG_DEV) {
    // eslint-disable-next-line no-console
    console.error('myPage-v3 says":', message, ...optionalParams)
  }
}

/**
 * @description console.info wrapper
 * @description Only logs in DEV environment and if LOG_DEV is true
 * @param message
 * @param optionalParams
 */
export const logDevInfo: (message?: any, ...optionalParams: any[]) => void = (message, ...optionalParams) => {
  if (ENVIRONMENT === 'DEV' && LOG_DEV) {
    // eslint-disable-next-line no-console
    console.info(message, ...optionalParams)
  }
}

/**
 * @description console.table wrapper
 * @description Only logs in DEV environment and if LOG_DEV is true
 * @param message
 * @param optionalParams
 */
export const logDevTable: (message?: object, ...optionalParams: any[]) => void = (message, ...optionalParams) => {
  if (ENVIRONMENT === 'DEV' && LOG_DEV) {
    // eslint-disable-next-line no-console
    console.table(message, ...optionalParams)
  }
}

export const logDevCount: (message?: string) => void = (message) => {
  if (ENVIRONMENT === 'DEV' && LOG_DEV) {
    // eslint-disable-next-line no-console
    console.count(message)
  }
}
