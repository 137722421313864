import axios from 'axios'
import { EASSETS_API_URL, EASSETS_API_URL_RESEND_TO_EMAIL } from '../../../config'
import { useQuery } from '@tanstack/react-query'

export function useFetchEassetTicketByBookingCode({ code, token }: { code?: string; token?: string }) {
  const getTicketsEndpoint = `${EASSETS_API_URL}/${code}`

  return useQuery({
    queryKey: [code],
    enabled: !!code && !!token,
    queryFn: async () => {
      if (!code) return
      const data = await axios.get(getTicketsEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
  })
}

export function useResendTicketToCustomerEmail({
  code,
  token,
  resend,
}: {
  code?: string
  token?: string
  resend: boolean
}) {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }

  return useQuery({
    queryKey: [`resend-${code}`],
    enabled: resend,
    queryFn: async () => {
      if (!code) return
      const data = await axios.post(
        EASSETS_API_URL_RESEND_TO_EMAIL,
        {
          bookingId: code,
        },
        { headers },
      )
      return data
    },
  })
}
