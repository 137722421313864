import React, { createContext, useContext } from 'react'
import { HubConnection } from '@microsoft/signalr'

import { FlBooking } from '../../../graphql'
import { defaultMpCarts } from '../../../types/myPage/types'

import { mpCart } from './websocketProviderFunctions/WebsocketOperationsProvider/context'

export type MpCartsContext = {
  hasUpdatedBooking: boolean
  cartData: mpCart
  connection: HubConnection | null
  setCartData: React.Dispatch<React.SetStateAction<mpCart>> | null
  initCart: mpCart | undefined
  setFlCustomerLoading: React.Dispatch<React.SetStateAction<boolean>>
  flCustomerLoading: boolean
  customerSuccessUpdated: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUpdatedBookings: React.Dispatch<React.SetStateAction<any>>
  setCustomerSuccessUpdated: React.Dispatch<React.SetStateAction<boolean>>
  updatedBookings:
    | {
        [bookingCode: string]: FlBooking
      }
    | undefined
}

export const websocketContext = createContext<MpCartsContext>({
  hasUpdatedBooking: false,
  cartData: defaultMpCarts,
  connection: null,
  setCartData: null,
  initCart: undefined,
  setFlCustomerLoading: (boolean) => boolean,
  flCustomerLoading: false,
  customerSuccessUpdated: false,
  setCustomerSuccessUpdated: (boolean) => boolean,
  updatedBookings: undefined,
  setUpdatedBookings: (flBookings) => flBookings,
})
websocketContext.displayName = 'Websocket'
export const useWebSocketContext = () => useContext(websocketContext)
