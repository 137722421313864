import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import
{
  BackButton,
  Button,
  Feedback,
  FormActionsWrapper,
  FormElementWrapper,
  Input,
  Paragraph,
  Spinner,
  TextButton,
} from '@fjordline/styles-v3'
import styled from 'styled-components'

import { OTP_URL, VALIDATED_FIELD_TIMEOUT } from '../../../../config'

import { requestCodeStateType, userEnteredCodeStateType, verifyModalContentType } from './verifyModalContent/types'
import { useKeycloak } from '../../../../providers/KeycloakProvider'
import axios from 'axios'

export const MessageWrapper = styled.div`
  padding: 1rem;
  margin: 0;
  max-width: 100vw;

  > div {
    padding-bottom: 1.5rem;
  }
`

export const AlertMessageWrapper = styled.div`
  height: 1.25rem;
  padding-top: 0.5rem;
`

/**
 * This modal has two purposes:
 *  1.  Verify a known username. @param fieldToVerify is then required to run @param onCodeVerified
 *  2.  Verify an implicit username unknown by user, resolved by JWT serverside
 * @param messageToEnterCode The message shown to user when password could not be verified
 * @param onBack Action to perform when modal is closed without verification
 * @param onCodeVerified Action when password is verified
 * @constructor
 */
const VerifyModalContent: React.FC<verifyModalContentType> = ({
  mobile,
  onBack,
  onCodeVerified,
}: verifyModalContentType) =>
{
  const { t, i18n } = useTranslation()
  const { isAuthenticated: kc_isAuth, token: kc_token } = useKeycloak()

  const isBannedNumber = blackListedCountryCodes.includes(mobile.substring(0, 4))

  useEffect(() =>
  {
    if (kc_isAuth && kc_token) {
      setToken(kc_token)
    }
  }, [ kc_isAuth, kc_token ])

  const [ token, setToken ] = useState<string | undefined>(undefined)

  const [ userEnteredCode, setUserEnteredCode ] = useState<userEnteredCodeStateType>({
    isChecked: false,
    showMissMatchError: false,
    value: '',
  })
  const [ requestCodeState, setRequestCodeState ] = useState<requestCodeStateType>({
    isRequested: false,
    isRequestSuccessFull: false,
  })

  const messageToEnterCode = t('label.personalia.verificationModalMessage', {
    field: t('label.personalia.mobileWord').toLowerCase(),
  })

  const verificationWillExpireMessage = t('label.personalia.verificationWillExpireMessage', {
    minutes: (VALIDATED_FIELD_TIMEOUT / (60 * 1000)).toString(),
  })

  const [ requestCodeWait, setRequestCodeWait ] = useState<boolean>(false)

  const handleInputChange = (e) =>
  {
    setUserEnteredCode({ ...userEnteredCode, value: e.target.value.trim() })
  }

  /**
   * Request a secret password for an e-mail or a mobile number
   * @param userName e-mail or mobile to where the secret password will be sent
   */

  const requestOtp = useCallback(() =>
  {
    setRequestCodeWait(true)
    setUserEnteredCode({ isChecked: false, showMissMatchError: false, value: '' })
    setRequestCodeState({ isRequested: true, isRequestSuccessFull: true })

    if (!token || !mobile) return
    if (kc_isAuth) {
      getOTPMobileCode(mobile, token)
        .then((_) =>
        {
          setRequestCodeState({ isRequested: true, isRequestSuccessFull: true })
        })
        .catch(() =>
        {
          setRequestCodeState({ isRequested: true, isRequestSuccessFull: false })
        })
    }
  }, [ kc_isAuth, mobile, token ])

  const requestOtpVerification = useCallback(
    (MOB: string, userEnteredCode: string) =>
    {
      if (verifyOtp) {
        verifyOtp(MOB, userEnteredCode, token)
          .then((_) => onCodeVerified({ MOB }))
          .catch((_) =>
          {
            setUserEnteredCode({
              isChecked: true,
              showMissMatchError: true,
              value: '',
            })
          })
      }
    },
    [ onCodeVerified, token ],
  )

  useEffect(() =>
  {
    requestOtp()
  }, [ requestOtp ])

  useEffect(() =>
  {
    setTimeout(() => setRequestCodeWait(false), 5000)
  }, [])

  const manualVerificationMessage = t('component.fjordClub.manualVerificationMessage')
  const contactCustomerServiceMessage = t('component.fjordClub.contactCustomerService')

  if (!requestCodeState.isRequested && !requestCodeState.isRequestSuccessFull) {
    return (
      <MessageWrapper>
        <BackButton data-cy='back-btn' onClick={onBack} title={t('label.button.back')}>
          {t('label.button.back')}
        </BackButton>
        <AlertMessageWrapper>
          <Feedback type="error">{t('label.personalia.validateEmailFirst')}</Feedback>
        </AlertMessageWrapper>
      </MessageWrapper>
    )
  }

  return (
    <MessageWrapper>
      <BackButton onClick={onBack} title={t('label.button.back')}>
        {t('label.button.back')}
      </BackButton>

      <Paragraph>
        {isBannedNumber ? manualVerificationMessage : messageToEnterCode}{' '}
        {isBannedNumber ? (
          <>
            <a
              style={{
                marginTop: '1rem',
                fontWeight: 'bold',
                width: '100%',
                display: 'block',
                textDecoration: 'underline',
              }}
              target="_blank"
              rel="noreferrer noopener"
              href={`https://www.fjordline.com/${i18n.language}/p/kundeservice/hjelp-og-kontakt#send-oss-en-melding`}
            >
              {contactCustomerServiceMessage}
            </a>{' '}
          </>
        ) : null}
      </Paragraph>

      <FormElementWrapper>
        <Input
          value={userEnteredCode.value}
          onChange={handleInputChange}
          label={t('label.personalia.verificationCode')}
          data-cy='verification-code-input'
        />

        {userEnteredCode.isChecked && userEnteredCode.showMissMatchError ? (
          <AlertMessageWrapper>
            <Feedback type="error">{t('label.personalia.verifyErrorMessage')}</Feedback>
          </AlertMessageWrapper>
        ) : null}
        {!isBannedNumber && (
          <AlertMessageWrapper>
            <Feedback type="info">{verificationWillExpireMessage}</Feedback>
          </AlertMessageWrapper>
        )}
      </FormElementWrapper>
      <FormActionsWrapper>
        <Button
          dataCy='verify-otp-btn'
          disabled={userEnteredCode.value.length < 4}
          onClick={() =>
          {
            requestOtpVerification(mobile, userEnteredCode.value)
          }}
        >
          {t('label.personalia.verify', { value: t('label.personalia.code') })}
        </Button>
        <Button dataCy='cancel-verify-mobileNumber-btn' theme="ghost" onClick={onBack}>
          {t('label.button.cancel')}
        </Button>
      </FormActionsWrapper>

      {isBannedNumber ? null : requestCodeWait ? (
        <div>{t('label.personalia.sendingVerificationCode')} <Spinner /></div>
      ) : isBannedNumber ? null : (
        <>
          <p>{`${t('component.auth.noCode')} `}</p>
          <TextButton onClick={() => requestOtp()} type="button">
            {t('label.personalia.receiveNewVerificationCode')}
          </TextButton>
          <span>{`(${mobile})`}</span>
        </>
      )}

      {!isBannedNumber && requestCodeState.isRequested && !requestCodeState.isRequestSuccessFull && (
        <AlertMessageWrapper>
          <Feedback type="error">{t('label.personalia.tpoCannotSendCodeMessage')}</Feedback>
        </AlertMessageWrapper>
      )}
    </MessageWrapper>
  )
}

export default VerifyModalContent

const getOTPMobileCode = async (mobile: string, token: string) =>
{
  if (blackListedCountryCodes.includes(mobile.substring(0, 4))) return null
  return axios.post(
    `${OTP_URL}/send-mobile-totp`,
    {
      totpRecipient: mobile,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

const verifyOtp = async (MOB: string, code: string, token?: string) =>
{
  return axios.post(
    `${OTP_URL}/verify-mobile-totp`,
    {
      totpRecipient: MOB,
      totp: code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export type VerifyOtpParams = {
  totpRecipient: string
  totp: string
  token: string
}

export type SendOtpParams = {
  totpRecipient: string
  token: string
}

//TODO: Add country codes to blacklist
const blackListedCountryCodes = [ '' ]
