export enum journeyTypes {
  ONEWAY = 'ONEWAY',
  RETURN = 'RETURN',
  CRUISE = 'CRUISE',
  PACKAGE = 'PACKAGE',
}

export enum GTM_events {
  ADD_TO_CART = 'addToCart',
  CHECKOUT = 'checkout',
  REMOVE_FROM_CART = 'remove',
  PURCHASE = 'purchase',
}

export enum GTM_SESSION_STORAGE {
  MEALS = 'mealsAddedToCart',
  EXTRAS = 'extrasAddedToCart',
  CABINS = 'cabinsAddedToCart',
}

export enum GTM_pages {
  CABINS = 'Cabins',
  EXTRAS = 'Extras',
  MEALS = 'Meals',
  TICKETS = 'Tickets',
}

export enum GTM_categories {
  JOURNEY = 'Journey',
  MISC = 'Misc',
  VEHICLES = 'Vehicles',
}

export enum GTM {
  checkout = 'checkout',
  bookingPage = 'bookingPage',
}

export enum GTM_CATEGORY {
  ACCOMMODATION = 'Accommodation',
  COMPARTMENTS = 'Cabins and seats',
  EXTRAS = 'Extras',
  MEALS = 'Meals',
  PASSENGERS = 'Passengers',
  PERSONS = 'Persons',
  VEHICLES = 'Vehicles',
}

export enum GTM_VARIANT {
  PERSON = 'Person',
  VEHICLES = 'Vehicles',
}

export enum GTM_TravelType {
  travel = 'Travel',
  cruise = 'Cruise',
  package = 'Vacation package',
}

export enum GTM_tripType {
  oneWay = 'One way',
  roundTrip = 'Round Trip',
}

export type GTMProduct = {
  id: string
  name: string
  price: number
  quantity: number
  variant?: string
}

export type GTMBaseProduct = {
  brand: string
  dimension14: string
  dimension15: string
  dimension16: string
  dimension17: string
}

export type GTMData = {
  event: GTM_events
  pUrl: string
  page: GTM_pages
  pageType: string
  travelType: journeyTypes
  userId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ecommerce: any
}

export type HandleAddToCart = () => Promise<void>
