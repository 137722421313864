import { useLocation } from 'react-router-dom'
import { IPassengerDetails } from '@fjordline/booking-draft'

import UseGetBookingDetails from '../../../../../../graphql/customerHooks/UseGetBookingDetails'
import { extractBookingNumber } from '../../../../../../providers/WebsocketProvider'
import { passengerBirthDate } from '../../../../../fjordClub/editMemberShip/createBirthdayLists'

export function useCreateInfantBirthdayList(
  infantYearList: passengerBirthDate[],
  months,
  daysList: passengerBirthDate[],
  existingTraveler: IPassengerDetails | undefined,
) {
  //Age from date of departure
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode as string })
  const departureDate = flBooking?.outbound?.departureDate
  const currentYear = new Date(departureDate as string).getFullYear()
  const currentMonth = new Date(departureDate as string).getMonth()
  const currentDay = new Date(departureDate as string).getDate()
  const currentDate = new Date(currentYear, currentMonth, currentDay)
  const currentDateMinusOneYear = new Date(currentDate.setFullYear(currentYear - 1))
  let infantMonths = months
  let days = daysList

  function leapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
  }

  if (existingTraveler?.birthMonth === 2 && !leapYear(existingTraveler.birthYear)) {
    days = days.slice(0, 28)
  } else if (existingTraveler?.birthMonth === 2 && leapYear(existingTraveler.birthYear)) {
    days = days.slice(0, 29)
  } else if (
    existingTraveler?.birthMonth === 4 ||
    existingTraveler?.birthMonth === 6 ||
    existingTraveler?.birthMonth === 9 ||
    existingTraveler?.birthMonth === 11
  ) {
    days = days.slice(0, 30)
  }

  if (existingTraveler?.birthYear === infantYearList?.[infantYearList.length - 1]?.title) {
    const minimumDay = currentDateMinusOneYear.getDate()
    const minimumMonth = currentDateMinusOneYear.getMonth()
    infantMonths = months.slice(0, minimumMonth + 1)

    if (existingTraveler.birthMonth === infantMonths?.[infantMonths.length - 1]?.id) {
      days = days.slice(0, minimumDay)
    }
  }

  if (existingTraveler?.birthYear === infantYearList?.[0]?.title) {
    const minimumDay = currentDateMinusOneYear.getDate()
    const minimumMonth = currentDateMinusOneYear.getMonth()
    infantMonths = months.slice(minimumMonth)

    if (existingTraveler.birthMonth === infantMonths[0].id) {
      days = days.slice(minimumDay)
    }
  }

  return { infantDays: days, infantMonths }
}
