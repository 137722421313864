import { TFunction } from 'i18next'
import * as yup from 'yup'

import { isAlphabeticNameRegEx } from '../../../../fjordClub/validation/regExTemplates'
import { IEditPassenger } from '../edit.traveler.types'

export const editPassengerSchema: (t: TFunction<string>) => yup.Schema<IEditPassenger> = (t) => {
  const isAlphabeticValidationMessage = t('validation.isAlphabetic')
  const isRequiredValidationMessage = t('validation.isRequired')

  const schema: yup.Schema<IEditPassenger> = yup.object().shape({
    birthYear: yup.number().required(),
    birthMonth: yup.number().required(),
    birthDay: yup.number().required(),
    customerCode: yup.string().nullable(),
    disabledPerson: yup.boolean().required(),
    firstName: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    fareCode: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    gender: yup.string().oneOf(['FEMALE', 'MALE']).required(isRequiredValidationMessage),
    id: yup.object().shape({
      idNumber: yup.string().required(),
      idType: yup.string().required(isRequiredValidationMessage),
    }),
    lastName: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    nationality: yup.string().required('(nationality)' + isRequiredValidationMessage),
    rowNumber: yup.number().required(),
  })
  return schema
}
