export type TOption = {
  id: number | string
  title: string
  isEnabled: boolean
}

type Port = {
  portCode: string
  isEnabled: boolean
}

const ports: Port[] = [
  { portCode: 'B', isEnabled: true },
  { portCode: 'S', isEnabled: true },
  { portCode: 'HH', isEnabled: true },
  { portCode: 'K', isEnabled: true },
]

const PortOptions = (t): TOption[] => {
  return ports.map((port) => {
    return {
      id: port.portCode,
      title: t('ports.' + port.portCode) as string,
      isEnabled: port.isEnabled,
    }
  })
}
export default PortOptions
