import styled from 'styled-components'

export const TopMenuContainer = styled.div`
  padding: 1.25rem;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  /* 
  @media (min-width: 1200px) {
    width: 100%;
    margin: 0 auto;
  } */
`

const styledSidebarNavItem = `

  display: flex;
  align-content: center;
  width: 100%;
  color: #fff;
  text-transform: none;
  font-weight: 400;
  padding: 10px 0;
  font-size: 16px;
  line-height: 190%;
  height: 58px;

`

export const StyledSidebarNavDiv = styled.div`
  div > ul > li > a {
    background: none;
  }
  ${styledSidebarNavItem}
`

export const StyledSidebarNavAnchor = styled.a`
  ${styledSidebarNavItem} {
    cursor: pointer;
  }
`

export const CustomLink = styled.li`
  a {
    background: none;
    margin-left: -1rem;
    text-decoration: none !important;
  }
`
