import { useEffect, useState } from 'react'
import { fetchExtrasByCodeFromSanity } from '../fetchData'
import { imageUrlFor } from '../imageUrlBuilder'
import { useTranslation } from 'react-i18next'
import { ShipExtra } from './types'

function useFetchExtras(code?: string) {
  const { i18n } = useTranslation()
  //Single extra
  const [extraFromSanity, setExtraFromSanity] = useState<ShipExtra | undefined>(undefined)
  useEffect(() => {
    if (!code) return
    fetchExtrasByCodeFromSanity(code).then((extra: ShipExtra) => {
      setExtraFromSanity(extra[0])
    })
  }, [code])

  function findImageUrl() {
    const image = imageUrlFor(extraFromSanity?.extraImages?.[0]?.asset?._ref)?.url() ?? ''
    return image
  }

  function findExtrasName() {
    const name = extraFromSanity?.extraName?.[i18n.language]
    return name ?? extraFromSanity?.extraType?.[i18n.language]
  }

  function findExtrasFullDescription() {
    const fullDescription = extraFromSanity?.extraFullDesc?.[i18n.language]
    return fullDescription
  }

  const image = imageUrlFor(extraFromSanity?.extraImages?.[0]?.asset?._ref)?.url()

  return {
    extraFromSanity,
    findImageUrl,
    findExtrasName,
    findExtrasFullDescription,
    image,
  }
}

export default useFetchExtras
