/* eslint-disable @typescript-eslint/naming-convention */
import { PartialTranslation } from './nb'
import CarRes from '../CarRes/de'

export const translation: PartialTranslation = {
  language: {
    da: 'Dansk',
    de: 'Deutsch',
    en: 'English',
    nb: 'Norsk',
  },
  country: {
    da: 'Dänemark',
    de: 'Deutschland',
    en: 'England',
    nb: 'Norwegen',
    nl: 'Niederlanden',
  },
  number: {
    1: 'Eins',
    2: 'Zwei',
    3: 'Drei',
    4: 'Vier',
    5: 'Fünf',
    6: 'Sechs',
    7: 'Sieben',
    8: 'Acht',
    9: 'Neun',
    10: 'Zehn',
    11: 'Elf',
    12: 'Zwölf',
    13: 'Dreizehn',
    14: 'Vierzehn',
    15: 'Fünfzehn',
  },
  bookingListStatus: {
    journeys:  'Reisen',
    ACTIVE_AND_FUTURE: 'Aktiv und Zukunft $t(bookingListStatus.journeys)',
    CANCELLED: 'Abgesagt $t(bookingListStatus.journeys)',
    PASSED: 'Bestanden $t(bookingListStatus.journeys)',
    DEFAULT: '$t(bookingListStatus.ACTIVE_AND_FUTURE)',
  },
  component: {
    general: {
      loading: 'Laden',
      departure: 'Ausreise',
      return: 'Rückreise',
    },
    welcome: {
      oldMyPage: 'Haben Sie Probleme mit der neuen My Page?',
      goToOldMyPage: 'Gehe zurück zur alten Meine Seite',
      toMyPage: 'Willkommen auf Meiner Seite',
      generalInfo:
        'Hier finden Sie eine Übersicht über Ihre Reisen und / oder Ihre Fjord Club Mitgliedschaft. Wenn Sie noch nicht Mitglied im Fjord Club sind, können Sie sich hier anmelden.',
    },

    auth: {
      renew: {
        headline: 'Sie müssen Ihren Benutzer erneuern',
        paragraph1: 'Wir haben unsere Anmeldesysteme aktualisiert, daher müssen Sie Ihren Benutzer erneuern. Sie können dieselbe E-Mail und dasselbe Passwort wie zuvor verwenden.',
        paragraph2: 'Alle ursprünglichen Informationen und Buchungen bleiben weiterhin verfügbar.',
        renewBtn: 'Benutzer erneuern',
      },

      verifyAndProceed: 'Überprüfen Sie und fahren Sie fort',
      header: 'Meine Seite',
      login: 'Einloggen',
      logout: 'Ausloggen',
      noCode: 'Passwort nicht erhalten?',
      oneTimeCode: 'Einmaliges Passwort',
      email: 'Email',
      phone: 'Handynummer',
      resendCode: 'Senden Sie ein neues Einmalpasswort',
      codeInstructions:
        'Dieses Fenster darf nicht geschlossen werden. Öffnen Sie ein neues Fenster (Tab), um den Einmalcode abzurufen',
      loginError: 'Anmeldung fehlgeschlagen. Bitte versuche es erneut.',
      facebookEmailRequired:
        'Wir benötigen Zugriff auf Ihre E-Mail-Adresse, damit Meine Seite funktioniert. Bitte loggen Sie sich erneut ein und gewähren Sie Zugriff auf Ihre E-Mail-Adresse oder wählen Sie eine andere Login-Option.',
      auth0Error: {
        access_denied:
          'Falsches Einmalpasswort. Stellen Sie sicher, dass es richtig eingegeben wurde, und versuchen Sie es erneut.',
      },
      multipleProfiled: {
        header: 'Dein Benutzername',
        ingress1: 'Ihrem Benutzernamen ({{userName}}) ist mehr als ein Profil zugeordnet.',
        ingress2:
          'Wir haben einen Supportfall zu dem Problem registriert und werden Sie kontaktieren, sobald der Fall gelöst ist oder wir weitere Informationen von Ihnen benötigen',
        ingress3: 'Bitte kontaktieren Sie das Kundencenter, damit sie Ihnen helfen können',
        phone: 'Telefonnummer',
        email: 'Email',
        buttonText: 'Kontaktinformationen anzeigen',
      },
      emailNeedsVerification:
        'Bevor Sie sich zum ersten Mal anmelden können, müssen wir Ihre E-Mail-Adresse verifizieren. Geben Sie den einmaligen Code ein, den Sie per E-Mail erhalten haben.',
      emailVerified: 'Ihre E-Mail-Adresse wurde verifiziert und Sie können sich jetzt anmelden.',
      signUp: 'Anmelden',
    },
    cancelBooking: {
      cost: 'Stornierung dieser Reisekosten',
      disclaimer: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      dontCancel: 'Nicht stornieren',
      info: 'Die Stornierungsgebühren variieren je nach Ticketart und Zeitpunkt der Stornierung.',
      description: 'Eine eventuelle Rückerstattung wird gemäß den bei der Buchung angegebenen Bedingungen berechnet und ausgezahlt.',
      readMore: 'Weitere Informationen zu unseren Stornierungsbedingungen finden Sie hier.',
      prompt: 'Möchten Sie Ihre Buchung wirklich stornieren?',
      title: 'Buchung stornieren',
      notify: {
        loading: 'Stornierung läuft...',
        success: 'Stornierung erfolgt!',
        failure: 'Stornierung fehlgeschlagen!',
      },
    },
    requestBookingModification: {
      button: 'Änderung der Reise',
      header: 'Änderung der Reise',
      content:
        'Wählen Sie, ob Sie kontaktiert werden möchten, um die Reise umzubuchen, oder erhalten Sie einen Gutschein, der für zukünftige Reisen verwendet werden kann.',
      reArrange: 'Umbuchen',
      valueCheck: 'Gutschein',
      success: 'Nachricht gesendet, Sie werden in Kürze von uns hören.',
      error:
        'Nachricht aufgrund eines Fehlers nicht gesendet. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.',
    },
    extras: {
      button: 'Weitere Produkte anzeigen',
      noExtras: 'Sie haben noch keine weiteren Produkte bestellt',
      currencyInfo: 'Die angezeigte Währung ist die, mit der Sie Ihre Reise gebucht haben.',
      cabin: {
        title: 'Kabinen',
        title_seat: 'Sitzplätze',
        loungeError: 'Die Lounge ist für eine bestimmte Anzahl von Passagieren nicht verfügbar.',
        booked:
          'Reserviert: $t(component.extras.cabin.qty, {\'count\': {{count}}}) einschließlich $t(component.extras.cabin.specQty, {\'count\': {{specQty}}})',
        booked_plural:
          'Reserviert: $t(component.extras.cabin.qty, {\'count\': {{count}}}) einschließlich $t(component.extras.cabin.specQty, {\'count\': {{specQty}}})',
        booked_noseats: '$t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) ohne spezifizierten Sitzplatz',
        qty: '{{count}} Kabine',
        qty_plural: '{{count}} Kabinen',
        specQty: '{{count}} Passagier',
        specQty_plural: '{{count}} Passagiere',
        spec: CarRes.cabinSpec,
        changeCabin: 'Kabinen wechseln',
        chooseCabins: 'Kabinen auswählen',
        searchingForCabins: 'Suche nach verfügbaren Kabinen',
        passengersToPlace_plural: 'Sie müssen {{count}} Passagiere platzieren, bevor Sie fortfahren können.',
        passengersToPlace_singular: 'Sie müssen {{count}} Passagier platzieren, bevor Sie fortfahren können.',
        addCabin: 'Kabine ändern/hinzufügen',
        addSeat:'Sitzplatz hinzufügen',
        selectedCabins: 'Aktuelle Bestellung:',
        newCabins: 'Neue Bestellung:',
        removeCabin: 'Entfernen',
        passengersToPlaceModal: 'Sie müssen {{count}} Passagiere platzieren',
        totalPrice: 'Gesamtpreis',
        allPassengersPlaced: 'Alle Passagiere werden untergebracht!',
        lowerPriceWarning: 'Die von Ihnen gewählten Kabinen kosten weniger als Ihre vorherige Kabinenbestellung.',
        window: 'Fenster',
        persons: 'Personen',
        price: 'Preis',
        crib: 'Platz für ein Kinderbett: ',
        goToBasketToast: 'Alle Passagiere sind platziert. Zum Warenkorb gehen',
        cabinOutbound_basket: 'Ausgehende Kabine',
        cabinInbound_basket: 'Kabine eingehend',
        changeCabin_basket: 'Klicken Sie hier, um die Kabine zu wechseln.',
        addMissingCabin_basket: 'Fehlende Kabine hinzufügen.',
        allPassengersMustBePlaced_basket:
          'Alle Passagiere müssen platziert werden, bevor Sie die Bestellung abschließen können.',
        noAvailability: 'Auf dieser Reise sind keine Kabinen verfügbar.',
        oldCabins_basket: 'Kabinen, die entfernt werden:',
        upgradeCabin: 'Upgrade Kabine',
        quantityInCabin: 'Passagiere ',
        priceUpgrade: 'Preiserhöhung',
        seeAll: 'Alle Kabinen/Sitzplätze anzeigen',
          feedback: {
            toast: {
              minReq: 'Sie müssen mindestens 1 Person auswählen, um diese Kabine hinzuzufügen.',
              minOrd: 'Diese Kabine hat eine Mindestbestellung von {{minOrd}} Personen. Sie müssen noch {{passengersYetToPlace}} Personen platzieren.',
            },
            deleteBeforeAdd: 'Entschuldigung, alle Passagiere haben bereits einen Sitzplatz zugewiesen. Wenn Sie den Sitzplatz ändern möchten, müssen Sie zuerst einige Passagiere von der ausgewählten Kabine oder Sitzwahl entfernen.',
            missingPassengersPlaced: 'Sie haben {{passengersPlaced}} von {{passengersToPlace}} Passagieren platziert. Alle Passagiere müssen platziert werden, um zum Warenkorb zu gelangen.',
            newPriceIsLessThanExistingPrice: 'Die ausgewählten Kabinen kosten weniger als die bereits bestellten. Bitte wählen Sie andere oder mehr Kabinen aus, sodass die Kosten der aufgerüsteten Kabinen den Preis der bereits bestellten übersteigen.',
            allPassengersPlaced: 'Alle Passagiere sind platziert!',
            basket: {
              allPassengersPlacedError: 'Nicht alle Passagiere von {{errorType}} sind platziert. Bitte fügen Sie die fehlenden Kabinen/Sitze hinzu, um zur Zahlung fortzufahren, oder entfernen Sie sie aus dem Warenkorb.',
              goToCabins: 'Zu Kabinen gehen',
              removeCabins: 'Kabinen entfernen',
              missingCabins: 'Fehlende Kabinen/Sitze',
              existingRemoved:'Entfernt'
            }
          }
      },
      common: {
        arrival: 'Rückreise',
        count: 'Anzahl',
        departure: 'Abfahrt',
        readMore: 'Weiterlesen',
        deleteHeader: {
          remove: 'entfernen',
          itemGeneral: 'Product $t(component.extras.common.deleteHeader.remove)',
          cabins: 'Kabine $t(component.extras.common.deleteHeader.remove)',
          extrasAshore: 'Extra an Land $t(component.extras.common.deleteHeader.remove)',
          extrasOnboardInbound: 'Extra an Bord $t(component.extras.deleteHeader.common.remove)',
          extrasOnboardOutbound: 'Extra an Bord $t(component.extras.deleteHeader.common.remove)',
          meals: 'Mahlzeit $t(component.extras.common.deleteHeader.remove)',
          meetingRooms: 'Konferenzraum $t(component.extras.common.deleteHeader.remove)',
        },
        deleteItem: {
          itemGeneral: 'dieses Produkt',
          cabins: 'diese Kabine',
          extrasAshore: 'dieses zusätzliche Produkt',
          extrasOnboardInbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          extrasOnboardOutbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          meals: 'diese Mahlzeit',
          meetingRooms: 'diesen Besprechungsraum',
          deleteItemQuestion:
            'Möchten Sie $t(component.extras.common.deleteItem.{{extraCategory}}) aus dem Einkaufswagen löschen??',
        },
      },
      taxesAndFees: {
        headline: 'Steuern und Gebühren',
        envTax: 'EU-umweltsteuer',
      },
      meal: {
        add: 'Mahlzeiten hinzufügen',
        arrival: 'Rückreise',
        count: 'Anzahl',
        departure: 'Abfahrt',
        yourMeals: 'Ihre Mahlzeiten',
        chooseTime: 'Wählen Sie die Zeit',
        selectDay: 'Tag auswählen',
        existingOrder: 'Bestellt und bezahlt',
        title: 'Mahlzeiten',
        time: 'Zeitpunkt der Reservierung: {{Zeit}}',
        totalPrice: 'Gesamtbetrag: {{Betrag}}',
        spec: CarRes.mealSpec,
        spec_plural: CarRes.mealSpec,
        qty: 'Anzahl der Reservierungen: {{count}} Passagier',
        qty_plural: 'Anzahl der Reservierungen: {{count}} Passagiere',
        nomeals: 'Keine Reservierungen',
        meal: 'Mahlzeit',
        meals: 'Mahlzeiten',
        fromPrice: 'Von',
        buttonLabelCount: 'Menge wählen',
        noDetailsAvailable: 'Derzeit keine Details verfügbar available',
        labelTime: 'Uhrzeit auswählen',
        searchingForMeals: 'Suche nach verfügbaren Mahlzeiten',
        deleteToastMessage: '{{mealName}} aus dem Warenkorb entfernt',
        AddOneToastMessage: '{{specName}} hinzugefügt',
        RemoveOneToastMessage: '{{specName}} weggenomen',
        loadingMeals: 'Verfügbare Mahlzeiten werden geladen',
        inTotal: 'In Summe',
        noMealsAvailable: 'Keine Mahlzeiten verfügbar',
        adult: 'erwachsene',
        adults: 'erwachsene',
        child: 'kind(er)',
        baby: 'baby',
        infant: 'kleinkind',
        sixcour: 'sechs-Gang-Menü',
        fivecour: 'fünf-Gang-Menü',
        fourcour: 'vier-Gang-Menü',
        threecour: 'drei-Gang-Menü',
        discount: 'Jetzt bestellen und 20% Rabatt auf alle Mahlzeiten erhalten',
      },
      basket: {
        conditions: {
          ingress: 'Ich habe die Bedingungen',
          terms: 'gelesen und verstanden',
          force: 'Bitte akzeptieren Sie die Bedingungen',
        },
        added: 'Hinzugefügt',
        sumMeal: 'Gesamtmahlzeit',
        totalToPay: 'Gesamt zu zahlen',
        completeOrder: 'Bestellung abschließen',
        cancellingOrder: 'Der Warenkorb wird geleert...',
        availableSoon: 'kommt bald...',
        receipt: 'Erhalt',
        thankYou: 'Vielen Dank für Ihren Auftrag',
        payed: 'Bezahlt',
        remainsToPay: 'Bleibt zu zahlen',
        timeoutError: 'Etwas ist schief gelaufen. Bitte versuche es erneut',
        processingOrder: 'Ihre Bestellung wird bearbeitet. Warten Sie mal...',
        deleteOrder: 'Auftrag löschen und neu beginnen',
        retry: 'Wiederholung',
        orderCancelled: 'Einkaufswagen storniert',
        cancelOrder: 'Einkaufswagen leeren',
        paymentFailed: 'Die Zahlung konnte nicht abgeschlossen werden.',
        basketInfoErrorMessage: 'Entschuldigung, bei der Zahlung ist etwas schief gelaufen.',
        paymentErrorMessage:
          'Es ist wahrscheinlich, dass die Zahlung für diese Posten bereits erfolgt ist. Kehren Sie zu My Travels zurück und überprüfen Sie den Status der Reise.',
        goToPayment: 'Zur Zahlung gehen',
        orderIsReady: 'Ihre Bestellung ist fertig',
        showOrderAndPay: 'Warenkorb zeigen und bezahlen',
        empty: 'Ihr Warenkorb ist leer',
        alreadyReservedInCart_description: 'Sie müssen reservierte Artikel entfernen oder bezahlen, bevor Sie weitere Artikel hinzufügen können. Sie können wählen, zur Zahlung fortzufahren, den Warenkorb zu leeren oder Produkte im Warenkorb anzeigen.',
        emptyCart: 'Warenkorb leeren',
        alreadyReservedInCart: 'Sie haben bereits reservierte Artikel im Warenkorb',
        seeCart: 'Warenkorb anzeigen',

      },
      misc: {
        title: 'Sonstiges',
        other: 'Andere',
        qty: '{{count}} Absätze',
        tickets: 'Tickets ansehen',
        onShoreTickets: '{{count}} tickets',
        ashoreTitle: 'Unterkunft an Land',
        onboardTitle: 'Unterkunft an Bord',

        loadingExtraOnboard: 'Holt verfügbare Zusatzprodukte an Bord ab',
        loadingExtraAshore: 'Ruft verfügbare Zusatzprodukte an Land ab',
        noExtrasAvailable: 'Es sind keine zusätzlichen Produkte für Ihre Reise verfügbar',
        noExtrasAvailableOnboard: 'Es sind keine zusätzlichen Produkte an Bord für Ihre Reise verfügba',
        noExtrasAvailableAshore: 'Es sind keine zusätzlichen Produkte an Land für Ihre Reise verfügba',
      },
      ashore: {
        title: 'Unterkunft an Land',
        booked: 'Reserviert: $t(component.extras.ashore.qty, { \'count\': {{count}} })',
        qty: '{{count}} ',
        qty_plural: '{{count}} ',
        checkin: 'Einchecken',
        checkout: 'Auschecken',
        roomType: 'Räume',
      },
    },
    fjordClub: {
      header: 'Fjord Club',
      registered: 'Ihre Fjord Club Mitgliedschaft ist registriert',
      updated: 'Ihre Fjord Club Mitgliedschaft wurde aktualisiert',
      unsavedChanges: 'Sie haben ungespeicherte Änderungen. Möchten Sie Ihre Änderungen speichern?',
      terms: {
        link: 'https://www.fjordline.com/de/p/fjord-club/allgemeine-geschaftsbedingungen',
        view: 'Geschäftsbedingungen',
      },
      consent:
        'Ich stimme den Geschäftsbedingungen des Fjord Clubs zu, einschließlich des Empfangs von E-Mails von Fjord Line, und dass Fjord Line meine persönlichen Daten und Kaufinformationen speichert.',
      description:
        'Der Fjord Club ist unsere Art, diejenigen zu schätzen, die gerne mit der Fjord Line reisen. Melden Sie sich kostenlos an und Sie können auf Ihren Reisen Punkte sammeln und exklusive Angebote und Rabatte erhalten.',
      delay:
        'HINWEIS: Nachdem Sie das Fjord Club-Profil erstellt haben, kann es bis zu einem Tag dauern, bis das Profil beim Anmelden automatisch abgerufen wird. Das Profil kann jederzeit manuell abgerufen werden, indem die erforderlichen Felder ausgefüllt und das Formular erneut gesendet werden.',
      join: 'Registrieren Sie Ihre Fjord Club-Mitgliedschaft',
      join_description: 'Der Fjord Club ist unsere Art, Ihnen für das Reisen mit Fjord Line zu danken. Treten Sie kostenlos bei und sammeln Sie Punkte auf Ihren Reisen, und erhalten Sie exklusive Angebote und Rabatte.',
      minimumAge: 'Sie müssen 18 Jahre alt sein, um Mitglied im Fjord Club zu werden.',
      joinBannerMessage: 'Immer Vorteile mit Fjord Club - Kostenlos Mitglied werden!',
      fcMemberCreateFailed: 'Wir haben vorübergehende Probleme mit der Mitgliedererstellung. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice bei anhaltenden Problemen.',
      fcMemberUpdateFailed: 'Es scheint, dass wir derzeit Probleme beim Aktualisieren von Mitgliedschaften haben. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Kundenservice, wenn das Problem weiterhin besteht.',
      mustVerifyMobileFeedback: 'Sie müssen Ihre Handynummer verifizieren, um zu speichern.',
      manualVerificationMessage: 'Ihre Telefonnummer kann nicht automatisch einen Bestätigungscode erhalten. Wenden Sie sich an den Kundendienst, um Ihre Telefonnummer zu überprüfen. Der Kundendienst kann für Sie einen Code erstellen, den Sie in das Feld unten eingeben',
      contactCustomerService: 'Kontaktieren Sie den Kundendienst',  
    },
    boardingCards: {
      button: 'Offene Bordkarten',
      header: 'Bordkarten',
      messageNotAvailable:
        'Bordkarten sind {{hoursBeforeJourneyStartToShowBoardingCard}} Stunden vor Abflug erhältlich',
      messageNotAvailableTitle: 'HINWEIS!',
      departure: 'Abfahrt',
      arrival: 'Rückfahrt',
      switchTo: 'Zur {{journey}} wechseln',
    },
    footer: {
      contactUs: 'Kontaktiere uns',
      followUs: 'Folgen Sie uns',
    },
    editVehicles: {
      updated: 'Kennzeichen wird aktualisiert!',
      error: 'Etwas ist schief gelaufen. Die Aktualisierung konnte nicht durchgeführt werden. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundendienst, wenn das Problem weiterhin besteht.'
    },
    editPassengers: {
      updated: 'Die Passagierdaten wurden aktualisiert!'
    },
  },
  metadata: {
    title: {
      booking: 'Buchung {{bookingCode}}',
      bookings: 'Meine Buchungen',
      extras: 'Add-Ons {{bookingCode}}',
      login: 'Meine Seite Login',
      fjordClub: 'Fjord Club',
      accommodation: 'Unterkunft',
      default: 'Meine Seite - Fjord Line',
    },
    traveller: {
      ADL: 'Erwachsene 16+',
      CHD: 'Kinder 4-15 Jahre',
      INF: 'Kleinkinder',
      BABY: 'Baby 0-11 Monate',
    },
  },
  navbar: {
    bookings: '$t(metadata.title.bookings)',
    fjordClub: '$t(metadata.title.fjordClub)',
    login: '$t(component.auth.login)',
    logout: '$t(component.auth.logout)',
    menu: 'Menu',
  },
  addModal: {
    extrasOnboard: 'Extra an Bord',
    extrasAshore: 'Extra an Land',
  },
  error: {
    reset: 'Zurück zu Meine Seite',
    timeout: {
      title: 'Keine Antwort vom Server',
      message:
        'Es scheint, dass es zu lange dauert, eine Antwort vom Server zu erhalten. Dies kann entweder an einer schlechten Verbindung oder einem Fehler mit unseren Servern liegen. Bitte versuchen Sie die Seite zu einem späteren Zeitpunkt neu zu laden.',
    },
    authorization: 'Autorisierungsfehler',
    authTimeOut: 'Der Authentifizierungsvorgang hat zu lange gedauert',
    booking: {
      cancellationFailed: 'Buchung konnte nicht storniert werden',
      getFailed: 'Details für die Reise konnten nicht abgerufen werden. Bitte versuchen Sie die Seite neu zu laden.',
      updateFailed: 'Fehler beim Aktualisieren der Reise',
      cancelled: 'Die Reise wurde abgesagt',
      notFound: 'Konnte die Reise nicht finden',
      pdfFailed: 'Reisebestätigung konnte nicht erhalten werden',
      resendPdfFailed: 'Reisebestätigung konnte nicht gesendet werden',
      regnumFailed: 'Registrierungsnummer konnte nicht aktualisiert werden',
      travelerFailed: 'Passagierinformationen konnten nicht aktualisiert werden',
      invalid: 'Die Reise, die Sie abrufen möchten, ist ungültig.',
      connectionError: 'Bestellungen konnten nicht abgerufen werden. Bitte versuchen Sie es erneut',
    },
    customer: {
      notFound: 'Profil konnte nicht gefunden werden',
      getFailed: 'Profil konnte nicht abgerufen werden',
      createFailed: 'Profil konnte nicht erstellt werden',
      createAlreadyExists: 'Das Profil existiert bereits',
      updateFailed: 'Profil konnte nicht aktualisiert werden',
      travelFriends: {
        addFailed: 'Reisebegleiter konnte nicht hinzugefügt werden',
        updateFailed: 'Fehler beim Aktualisieren des Reisebegleiters',
        deleteFailed: 'Reisebegleiter konnte nicht gelöscht werden',
      },
    },
    notify: {
      loading: 'Änderung im Gange...',
      success: 'Änderung bestätigt!',
      failure: 'Änderung fehlgeschlagen!',
    },
    response:
      'Serverantwort kann nicht empfangen werden. \nBitte stellen Sie sicher, dass Sie über eine Netzwerkverbindung verfügen, und laden Sie die Seite neu, um es erneut zu versuchen.',
    generic: 'Ups! Irgendwas lief schief...',
    unchanged: 'Das Formular enthielt keine Änderungen',
    unknown: 'Unbekannter Fehler',
    gofish: 'Ohoi Seemann... keine Reisen hierher...',
    basket: {
      generic: 'Etwas ist schiefgelaufen... Die Zahlung konnte nicht abgeschlossen werden. Bitte versuchen Sie es später erneut',
      or: 'oder',
      contactCustomerService: 'Kontaktieren Sie den Kundenservice, wenn das Problem weiterhin besteht.',
      rollback: 'Der Einkaufswagen konnte nicht geleert werden. Bitte versuchen Sie es erneut oder kontaktieren Sie den Kundenservice, wenn das Problem anhält.',
      refused:
      'Die Zahlung wurde abgelehnt. Überprüfen Sie die Karteninformationen und versuchen Sie es erneut oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
      help: 'Brauchen Sie Hilfe? Wenden Sie sich hier an den Kundendienst',
      refused_headline: 'Zahlung abgelehnt',
    }

  },
  label: {
    allFieldsRequired: '* Alle Felder müssen ausgefüllt werden',
    booking: {
      header: 'Meine Buchungen',
      code: 'Buchungsnummer',
      resendPDF: 'Reiseunterlagen per E-Mail versenden',
      resendFailed: 'Senden fehlgeschlagen',
      noBookings: 'Sie haben keine bevorstehenden Reisen.',
      activeBooking_plural: 'Sie haben {{count}} aktiv Reisen',
      upcomingBooking_plural: 'Sie haben {{count}} bevorstehenden Reisen',
      noActiveBookings: 'Sie haben keine aktiven Reisen.',
      activeBooking: 'Sie haben eine aktive Reise',
      upcomingBooking: 'Sie haben eine bevorstehende Reise',
      makeBooking: 'Buchen Sie noch heute Ihre nächste Reise',
      makeNewBooking: 'Buchen Sie eine neue Reise',
      active: 'Aktiv',
      future: 'Kommend',
      cancelled: 'Abgebrochen',
      unavailable: 'Nicht verfügbar',
      pastBooking: 'Reise abgeschlossen',
      showCancelled: 'Abgebrochen anzeige',
      showPassed: 'Vergangene Reisen ansehen',
      hidePassed: 'Vergangene Reisen ausblenden',
      hideCancelled: 'Abgebrochen ausblenden',
      cancelValidTime: 'Sie können die Reise bis zu 24 Stunden vor Abflug stornieren',
      paymentDone: 'Bezahlvorgang abgeschlossen',
      paymentMessage: 'Danke für Ihren Einkauf! Die von Ihnen gekauften Produkte werden in Ihrer Buchungsübersicht angezeigt.',
      basketInfo: {
        yourBasket: 'Ihr Einkaufswagen',
        info: 'Gehen Sie zum Warenkorb, um die Änderungen an der Reise abzuschließen oder zu löschen',
        toPay: 'Bezahlen:',
      },
    },
    boolean: {
      false: 'Nein',
      true: 'Ja',
    },
    button: {
      add: 'Hinzufügen',
      addMore: 'Mehr hinzufügen',
      addMoreItems: 'Artikel zur Reise hinzufügen',
      noItems: 'Keine Artikel verfügbar auf dieser Reise',
      addMoreExtraOnboard: 'Extras an Bord hinzufügen',
      addMoreExtraAshore: 'Extras an Land hinzufügen',
      back: 'Zurück',
      cancel: 'Stornieren',
      close: 'Schließen',
      closeAlert: 'Warnung schließen',
      confirm: 'Bestätigen',
      delete: 'Löschen',
      deselectAll: 'Alles entfernen',
      edit: 'Veränderung',
      gotoBasket: 'Zum Einkaufskorb gehen',
      join: 'Kostenlos Mitglied werden!',
      loading: 'Wird geladen...',
      moreInfo: 'Reisedetails öffnen',
      resetChanges: 'Änderungen zurücksetzen',
      save: 'Speichern',
      saveChanges: 'Änderungen speichern',
      selectAll: 'Wählen Sie Alle',
      submit: 'Senden',
      show: 'Zeigen',
      ACTIVE_AND_FUTURE: 'Active and future',
      CANCELLED: 'Abgesagt ',
      PASSED: 'Bestanden ',
      ACTIVE: 'Aktiv',
      FUTURE: 'Bevorstehende',
      bookingOverview: 'Zur Buchungsübersicht gehen',
      downloadPDF: 'Reisebestätigung herunterladen (PDF)',
    },
    country: CarRes.country,
    currency: {
      NOK: '{{amount}} NOK',
      DKK: '{{amount}} DKK',
      EUR: '€{{amount}}',
    },
    date: {
      day: 'Tag',
      month: 'Monat',
      month_1: 'Januar',
      month_2: 'Februar',
      month_3: 'März',
      month_4: 'April',
      month_5: 'Mai',
      month_6: 'Juni',
      month_7: 'Juli',
      month_8: 'August',
      month_9: 'September',
      month_10: 'Oktober',
      month_11: 'November',
      month_12: 'Dezember',
      year: 'Jahr',
    },
    gender: {
      F: 'Weiblich',
      M: 'Männlich',
    },
    journey: {
      arrival: 'Ankunft',
      arrivalJourney: 'Hin-und Rückfahrt',
      arrivalPort: 'Ankunftshafen',
      arrivalTime: 'Ankunftszeit',
      departure: 'Abfahrt',
      departureJourney: 'Abfahrt',
      departurePort: 'Abfahrtshafen',
      departureTime: 'Abfahrtszeit',
      product: 'Ticketkategorie',
      stretch: 'Strecken',
    },
    customer: {
      allowContact: 'Erlauben Sie Fjord Line, mich zu kontaktieren',
      countryCode: 'Landesvorwahl',
      currency: 'Währung',
      customerCode: 'Kundennummer',
      customerGroup: 'Deine Interessen',
      language: 'Sprache',
      name: 'Name',
      travelFriend: 'Reisebegleiter',
      consents: {
        header: 'Zustimmung',
        pretext:
          'Ich stimme den Allgemeinen Geschäftsbedingungen des Fjord Clubs zu und stimme zu, dass Fjord Line meine Informationen für folgende Zwecke speichert:',
        posttext:
          'Fjord Line verwendet die Informationen nur, um Ihnen eine bessere Erfahrung zu bieten, und sie werden nicht an Dritte weitergegeben.',
        EMAIL: 'Senden Sie E-Mails, Benutzerprofile und Kaufinformationen',
        MAIL: 'Schicken Sie mir eine Mail',
        SMS: 'Schicken Sie mir eine SMS',
      },
    },
    membership: {
      header: 'Informationen zur Mitgliedschaft',
      memberNumber: 'Mitgliedsnummer',
      membershipLevel: 'Mitgliedschaftsstufe',
      memberSince: 'Mitglied seit',
      loyaltyPointsEarned: 'Insgesamt verdiente Punkte',
      loyaltyPointsBalance: 'Punkte',
      loyaltyPointsSoonExpiring: 'Punkte, die bald ablaufen',
    },
    signedUp:  {
      goToMyTravels: 'Gehe zu Meine Reisen',
      seeMembership : 'Siehe Mitgliedschaft',
    },
    personalia: {
      code: 'Code',
      dateOfBirth: 'Geburtsdatum',
      personalData: 'Persönliche Daten',
      email: 'E-mail-Adresse',
      firstName: 'Vorname',
      gender: 'Geschlecht',
      header: 'Persönliche Angaben',
      lastName: 'Nachname',
      mobile: 'Handynummer',
      mobileWord: 'Textnachricht',
      receiveNewVerificationCode: 'Erhalten Sie einen neuen Bestätigungscode',
      verificationCode: 'Bestätigungscode',
      verificationCompleted: '{{field}} wurde bestätigt',
      verificationModalMessage: 'Geben Sie den in {{field}} erhaltenen Bestätigungscode ein.',
      verificationRequired: 'Geänderte {{field}} muss bestätigt werden',
      verify: '{{value}} bestätigen',
      verifyErrorMessage: 'Eingegebener Code kann nicht verifiziert werden',
      tpoCannotSendCodeMessage: 'Einmaliger Code konnte nicht gesendet werden. Bitte versuche es erneut.',
    },
    address: {
      header: 'Adresse',
      address: 'Adresse',
      zip: 'Postleitzahl',
      city: 'Stadt',
      country: 'Land',
      nationality: 'Staatsangehörigkeit',
    },
    traveler: {
      edit: 'Reisenden bearbeiten',
      header: 'Reisende',
      disabledPerson: 'Behinderte / Hilfe benötigen',
      guestType: 'Ticketkategorie',
      passportNumber: 'ID-Nummer',
    },
    travelFriend: {
      tempDisabled: 'Reisebegleiter werden bald verfügbar sein.',
      header: 'Meine Reisebegleiter',
      add: 'Reisebegleiter hinzufügen',
      delete: 'Reisebegleiter löschen',
      edit: 'Reisebegleiter wechseln',
      find: 'Bestehendes Mitglied',
      fill: 'Bitte geben Sie die Informationen ein',
      new: 'Neuer Reisebegleiter',
      notEditable: 'Dieser Reisebegleiter ist Mitglied im Fjord Club und kann nicht geändert werden.',
      birthDate: 'Geburtsdatum',
      nationality: 'Staatsangehörigkeit',
    },
    unknown: 'Unbekannt',
    vehicle: {
      edit: 'Fahrzeug bearbeiten',
      header: 'Fahrzeug',
      category: 'Auto Typ',
      length: 'Länge',
      length_upto: 'Bis zu {{Länge}} m lang',
      regNum: 'Registrationsnummer',
        code: {
          vehicle195: 'Fahrzeug bis 1,95 m hoch',
          vehicle220: 'Fahrzeug bis 2,20 m hoch',
          vehicle235: 'Fahrzeug bis 2,35 m hoch',
          vehicle450: 'Fahrzeug bis 4,50 m hoch',
          bik: 'Fahrrad',
          mc: 'Motorrad',
          mcs: 'Motorrad mit Seitenwagen',
        }


    },
    preferences: {
      header: 'Reise',
      portOfDeparture: 'Bevorzugter Abfahrtshafen',
    },
    tickets: {
      header: 'Tickets',
      sendToEmail: 'An E-Mail senden',
      departureDate: 'Abreisedatum:',
      resentTickets: 'Die Tickets wurden an Ihre E-Mail gesendet',
      resendTicketsFailed: 'Entschuldigung, aber wir können Ihre Tickets im Moment nicht erneut senden. Bitte versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Kundenservice um Hilfe zu erhalten.',
      sendToEmailAgain: 'Erneut senden',
      resendTimeout: ' Kann in {{seconds}} Sekunden erneut gesendet werden',
      ticketError: 'Entschuldigung, aber wir können Ihre Tickets im Moment nicht abrufen. Bitte versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Kundenservice um Hilfe zu erhalten.',
      ticketError_delay: 'Bitte beachten Sie, dass es einige Minuten dauern kann, bis die Tickets auf dieser Seite verfügbar sind, wenn die Bestellung gerade erst durchgeführt wurde.',

      barcode: {
        alt: 'barcode',
      },
    },
  },
  placeholder: {
    personalia: {
      firstName: 'Ihr Vorname',
      lastName: 'Ihr Nachname',
      gender: 'Wähle Geschlecht',
      email: 'Ihre E-mail',
      mobile: 'Deine Telefonnummer',
      mobileNoCC: 'Deine Telefonnummer',
    },
    address: {
      address: 'Deine Adresse',
      zip: 'Deine Postleitzahl',
      city: 'Deine Heimatstadt',
      country: 'Land auswählen',
      nationality: 'Nationalität auswählen',
    },
    traveler: {
      passportNumber: 'Deine ID-Nummer',
    },
    vehicle: {
      regNum: 'Deine Registrationsnummer',
    },
    preferences: {
      portOfDeparture: 'Bevorzugter Abfahrtshafen',
    },
  },
  validation: {
    isRequired: 'Obligatorische Informationen',
    memberNumber: 'Die Mitgliedsnummer muss 7 oder 10 Ziffern haben',
    regNumber: 'Die Registrierungsnummer muss mindestens 2 sein',
    isAlphabetic: 'Dieses Feld sollte nur Buchstaben enthalten',
    isAlphanumeric: 'Dieses Feld sollte nur Buchstaben und Zahlen enthalten',
    isDate: 'Das ausgewählte Datum ist nicht gültig',
    isDateInFuture: 'Das gewählte Datum ist kein gültiges Geburtsdatum',
    isGuestType: 'Das Geburtsdatum entspricht nicht den Altersbeschränkungen des Tickets',
    isEmail: 'Dies ist keine gültige e-mail Adresse',
    isMobile: 'Dies ist keine gültige Telefonnummer',
    isCountryCode: 'Dies ist keine gültige Landesvorwahl',
    isOneTimeCode: 'Dies ist kein gültiger Einmalcode',
    isOwnCustomerCode: 'Sie können Ihre eigene Kunden-ID nicht verwenden',
    isNumeric: 'Dieses Feld sollte nur Zahlen enthalten',
    isPostalCode: 'Dies ist keine gültige Postleitzahl',
    hasDigits: 'Dieses Feld sollte keine Zahlen enthalten',
    hasLetters: 'Dieses Feld sollte keine Buchstaben enthalten',
    hasCountryCode: 'Ländercode ist erforderlich (+49)',
    cannotChangeUserNameMessage:
      'Das Feld kann nicht geändert werden, da dies Ihre Benutzer-ID ist, wenn Sie sich anmelden',
      missingRegNum: 'Das Kennzeichen fehlt für dieses Fahrzeug',
      isRegnumber: 'Dies ist keine gültige Fahrzeugnummer.',


  },
  ports: {
    LA: 'Langesund',
    B: 'Bergen',
    HH: 'Hirtshals',
    S: 'Stavanger',
    SS: 'Strømstad',
    K: 'Kristiansand',
    SA: 'Sandefjord',
    ST: 'Strömstad',
    ALB: 'Aalborg'
  },
  stepperInputWrapper: {
    selectValidValue: 'Muss zwischen liegen',
    validValuesFrom: 'Menge ab {{from}} zu {{to}}',
    validValuesTo: ' zu {{to}}',
  },
  payment: {
    partlyPaid: 'Teilweise bezahlt',
    partlyPaid_description: '',
    fullyPaid: 'Zahlung abgeschlossen',
    fullyPaid_description: '',
    declined: 'Zahlung abgelehnt',
  },

    icons: {
    seaView: 'Meerblick',
    shower: 'Dusche',
    sofaBed: 'Schlafsofa',
    roomForCrib: 'Platz für Kinderbett',
    doubleBed: 'Doppelbett',
  },

  payWithFjordClubPoints: {
    headline: 'Mit Fjord Club-Punkten bezahlen',
    ingress: 'Sie haben {{points}} Punkte. Mit diesen Punkten können Sie Ihre Reise bezahlen.',
    readMore: 'Lesen Sie mehr über diese Reise',
    bookNow: 'Jetzt buchen',
    navBar: 'Fjord Club Reisen',
    from: 'Von',
    EuTaxLabel: 'pro Person + EU-Steuer'
    },
    modificationDeadline: {
      seeWhatCloses: 'Siehe was abläuft',
      timerText: 'Die Änderungsfrist für diese Reise läuft in ',
      modalHeadline: 'Änderungsoptionen, die ablaufen',
      deadlineIsPastModal: 'Die Änderungsfrist für diese Reise ist abgelaufen.',
    }
}
