import { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'

import { FlBooking, useGetBookingQuery } from '../types'
import { trackError } from '../../providers/TelemetryProvider'

export type BookingDetails = {
  flBooking?: FlBooking
  flBookingLoading: boolean
  getFlBookingError?: ApolloError | undefined
  refetch?: () => void
}
// remark: https://stackoverflow.com/questions/48840223/apollo-duplicates-first-result-to-every-node-in-array-of-edges
const UseGetBookingDetails: (args: { bookingCode: string }) => BookingDetails = ({ bookingCode }) => {
  const [bookingDetails, setBookingDetails] = useState<BookingDetails>({ flBookingLoading: true })
  const { data, loading, error, refetch } = useGetBookingQuery({
    variables: { bookingCode: bookingCode ? bookingCode : '' },
    skip: bookingCode === undefined || bookingCode === '',
  })

  useEffect(() => {
    if (!loading && error !== undefined) {
      trackError(error, {
        'location': 'UseGetBookingDetails',
        'function': 'useGetBookingQuery',
        'bookingCode': bookingCode
      })
    }
  }, [loading, error, bookingCode])

  useEffect(() => {
    if (!loading && data) {
      setBookingDetails({
        flBooking: data.booking as FlBooking,
        flBookingLoading: loading,
        getFlBookingError: error,
        refetch,
      })
    }
    if (error) {
      setBookingDetails({ getFlBookingError: error, flBookingLoading: false, refetch })
    }
  }, [data, error, loading, refetch])

  return bookingDetails
}

export default UseGetBookingDetails
