import { paramKeys } from './types'

export default function getSubNavLink({
  subPath,
  backTarget,
  backTargetTitle,
}: {
  subPath?: string
  backTarget?: string
  backTargetTitle?: string
}): string {
  const url = `${subPath ? `/${subPath}` : ''}${backTargetTitle || backTarget ? '?' : ''}`
  const backTargetParam = `${backTarget ? `${paramKeys.BACK_TARGET}=${backTarget}` : ''}`
  const backTargetTitleParam = `${backTarget ? '&' : ''}${
    backTargetTitle ? `${paramKeys.BACK_TARGET_TITLE}=${backTargetTitle}` : ''
  }`
  return `${url}${backTargetParam}${backTargetTitleParam}`
}
