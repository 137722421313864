import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { BookingCard, DestinationHeader, FormElementWrapper, Link, TextButton } from '@fjordline/styles-v3'
import { FlBooking } from '../../graphql/types'
import { useTimeZonedDateFormatter } from '../../hooks/DateFormat'
import { BookingNavLink } from '../../routes/navLinkFunctions'
import { paramKeys } from '../../routes/navLinkfunctions/types'
import { useAvailabilityItems } from '../../providers/AvailabilityItemsProvider'
import { CartsByBookingCodes } from '../../providers/availabilityItemsProvider/types'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { BookingListWithKeys } from '../../types/MyPage'
import { Status } from '../../types/myPage/enums'
import AddExtrasModalButton from '../booking/AddExtrasModalButton'
import BoardingCardLinkOrMessage from '../booking/BoardingCardLinkOrMessage'
import BasketInfo from '../booking/boardingCards/BasketInfo'
import { BasketInfoWrapper } from '../booking/boardingCards/basketInfo/basketInfo.styles'

import { AllignContentBottom, BookingCardFill, BookingPreviewList, Centered } from './bookingCards/bookingCards.styles'
import getBookingNumberOrLabelCancelled from './bookingCards/getBookingNumberOrLabelCancelled'
import QrCode from './QrCode'
import useFetchImageContainer from '../../sanity/imageContainer/useFetchImageContainer'
import ModifyBookingDeadlineTimer from '../../components/ModifyBooingDeadlineTimer'
import { isPastDeadline } from '../../utils/isPastDeadline'

export default function BookingCards({
  bookings,
  status,
}: {
  bookings: BookingListWithKeys
  status: Status
}): JSX.Element | null {
  const { indexedBookings, sortedBookingCodes } = bookings
  if (sortedBookingCodes.length === 0) {
    return null
  }
  return (
    <BookingPreviewList>
      {sortedBookingCodes?.map((bookingCode) => {
        return (
          <SingleBookingCard
            key={bookingCode}
            bookingCode={bookingCode}
            indexedBookings={indexedBookings}
            status={status}
          />
        )
      })}
    </BookingPreviewList>
  )
}

type SingleBookingCardProps = {
  bookingCode: string
  status: Status
  indexedBookings: BookingListWithKeys['indexedBookings']
}
const SingleBookingCard: React.FC<SingleBookingCardProps> = ({
  bookingCode,
  status,
  indexedBookings,
}: SingleBookingCardProps) => {
  const { cartData } = useWebSocketContext()
  const addToCartState = useAvailabilityItems().addToCartState
  const isFullyPaid = (bookingCode: string) => {
    const cartWithBookingResult = cartData?.bookingCarts?.[bookingCode]?.bookingResult?.paidState === 'FULLY_PAID'

    return cartWithBookingResult
  }

  const { hasActivePayment } = useAvailabilityItems()

  const d = useTimeZonedDateFormatter()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const bookingResultPrice = cartData?.bookingCarts?.[bookingCode]?.bookingResult?.bookingBalance
  const booking: FlBooking = indexedBookings[bookingCode]
  const { outbound, inbound } = booking
  const isActiveOrFuture = status === Status.ACTIVE || status === Status.FUTURE
  const outboundDestinationPort: string = outbound ? t(`ports.${outbound.arrivalPortInfo?.carresPortCode}`) : ''
  const arrivalPort = booking.outbound?.arrivalPortInfo?.carresPortCode
  const { image } = useFetchImageContainer(arrivalPort || undefined)

  const modifyBookingDeadline = booking.modificationDeadline

  return (
    <FormElementWrapper key={bookingCode}>
      <BookingCardFill data-cy={`booking-item-${bookingCode}`}>
        <BookingCard
          bookingNumber={getBookingNumberOrLabelCancelled({ bookingCode })}
          bookingNumberTitle={`${t('label.booking.code')}`}
          qrCode={() => QrCode({ status, bookingCode })}
          outbound={
            outbound
              ? {
                  date: d(outbound.departureDate, 'PPPp'),
                  from: t(`ports.${outbound.departurePortInfo?.carresPortCode}`),
                  to: outboundDestinationPort,
                }
              : undefined
          }
          inbound={
            inbound
              ? {
                  date: d(inbound.departureDate, 'PPPp'),
                  from: t(`ports.${inbound.departurePortInfo?.carresPortCode}`),
                  to: t(`ports.${inbound.arrivalPortInfo?.carresPortCode}`),
                }
              : undefined
          }
          header={
            isActiveOrFuture ? (
              <DestinationHeader title={outboundDestinationPort} imageUrl={image || ''}>
                {findSumTotalBasket(bookingCode, addToCartState) > 0 &&
                !isFullyPaid(bookingCode) &&
                !isPastDeadline({ modifyBookingDeadline }) ? (
                  <BasketInfoWrapper>
                    <BasketInfo
                      data-cy={
                        `basket-info-${bookingCode}-sumTotal-${findSumTotalBasket(
                          bookingCode,
                          addToCartState,
                        )}` as string
                      }
                      sumTotal={
                        bookingResultPrice
                          ? parseInt(bookingResultPrice)
                          : findSumTotalBasket(bookingCode, addToCartState)
                      }
                      bookingCode={bookingCode || ''}
                    />
                  </BasketInfoWrapper>
                ) : null}
              </DestinationHeader>
            ) : null
          }
        >
          <AllignContentBottom>
            <div>
              {booking.showBoardingCards ? (
                <BoardingCardLinkOrMessage
                  bookingCode={bookingCode}
                  backTargetTitle={`${t('metadata.title.bookings')}`}
                  backTarget={pathname}
                  departureDate={outbound?.departureDate}
                  isActiveOrFuture={isActiveOrFuture}
                />
              ) : null}

              {(!isPastDeadline({ modifyBookingDeadline }) &&
                isActiveOrFuture &&
                booking.canBuyBookingItems &&
                hasActivePayment === null) ||
              (isActiveOrFuture &&
                booking.canBuyBookingItems &&
                !isPastDeadline({ modifyBookingDeadline }) &&
                hasActivePayment === null) ? (
                <>
                  <AddExtrasModalButton
                    backTargetTitle={t('metadata.title.bookings')}
                    bookingCode={bookingCode}
                    isActiveOrFuture={isActiveOrFuture}
                  />
                  <ModifyBookingDeadlineTimer modifyBookingDeadline={modifyBookingDeadline} />
                </>
              ) : null}

              {status !== Status.CANCELLED ? (
                <Centered>
                  <Link
                    onClick={() =>
                      navigate(
                        BookingNavLink({
                          backTargetTitle: `${t('metadata.title.bookings')}`,
                          bookingCode,
                          backTarget: `${pathname}?${paramKeys.BOOKING_LIST_VIEW}=${status}`,
                        }),
                      )
                    }
                  >
                    <TextButton>{t('label.button.moreInfo')}</TextButton>
                  </Link>
                </Centered>
              ) : null}
            </div>
          </AllignContentBottom>
        </BookingCard>
      </BookingCardFill>
    </FormElementWrapper>
  )
}

export function findSumTotalBasket(bookingCode: string, addToCartState: CartsByBookingCodes) {
  function findItemPrices(trip): number[] {
    const itemPrices: number[] = []

    const processCategory = (category) => {
      for (const key in category) {
        const items = category[key] && category[key].length > 0 ? category[key] : []

        for (const item of items) {
          if (item.quantity) {
            itemPrices.push(item.price.value * item.quantity)
          } else {
            itemPrices.push(item.price.value)
          }
        }
      }
    }

    if (trip.outbound) {
      processCategory(trip.outbound)
    }

    if (trip.inbound) {
      processCategory(trip.inbound)
    }

    return itemPrices
  }

  const basketTotal = addToCartState
  const findBasketTotalPrice = basketTotal?.[bookingCode] || {}
  const itemPrices = findItemPrices(findBasketTotalPrice || {})
  const sumTotal = itemPrices?.reduce((a, b) => a + b, 0)

  return sumTotal
}
