/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TFunction } from 'i18next'
import * as yup from 'yup'

import { Consent, Country, FlCustomer, Gender, MeansOfContactType } from '../../../graphql'

import {
  isAlphabeticNameRegEx,
  isAlphaNumeric,
  isCurrentlyValidPostalInfoRegEx,
  isMobile,
  isPostalCode,
} from './regExTemplates'

export const fjordClubYupSchema: (t: TFunction<string>) => yup.Schema<FlCustomer> = (t) => {
  const isAlphabeticValidationMessage = t('validation.isAlphabetic')
  const isAlphanumericValidationMessage = t('validation.isAlphanumeric')
  const isEmailMessage = t('validation.isEmail')
  const isMobileMessage = t('validation.isMobile')
  const isRequiredValidationMessage = t('validation.isRequired')
  const isPostalCodeMessage = t('validation.isPostalCode')

  const MeansOfContactSchema = yup.object().shape({
    type: yup.string().oneOf(Object.values(MeansOfContactType)).required(isMobileMessage),
    value: yup.string().required(isMobileMessage),
  })
  const nationalityCodeSchema = yup.string().oneOf(Object.values(Country)).required(isRequiredValidationMessage)

  const CustomerAddressSchema = yup.object().shape({
    countryCode: nationalityCodeSchema,
    postalAddress: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    postalCode: yup.string().required(isRequiredValidationMessage).matches(isPostalCode, isPostalCodeMessage),
    streetAddress: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphaNumeric, isAlphanumericValidationMessage),
  })

  const schema: yup.Schema<FlCustomer> = yup.object().shape({
    active: yup.boolean().required(),
    customerCode: yup.string(),
    firstName: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    lastName: yup
      .string()
      .required(isRequiredValidationMessage)
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    gender: yup.string().oneOf(Object.values(Gender)).required(isRequiredValidationMessage),
    birthYear: yup.number().required(isRequiredValidationMessage),
    birthMonth: yup.number().required(isRequiredValidationMessage),
    birthDay: yup.number().required(isRequiredValidationMessage),
    nationalityCode: nationalityCodeSchema,
    meansOfContacts: yup.array().of(MeansOfContactSchema).required(),
    address: CustomerAddressSchema.required(),
    defaultDeparturePortCode: yup.string().required(isRequiredValidationMessage),
    consents: yup.array<Consent[]>(),
    personId: yup.string(),
  })
  return schema
}
