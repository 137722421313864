const signalREvents = {
  initCart: 'InitCart',
  initCartFailed: 'InitCartFailed',
  //-----
  flCustomerUpdated: 'FlCustomerUpdated',
  flCustomerUpdateFailed: 'FlCustomerUpdateFailed',
  //-----
  flCustomerCreated: 'FlCustomerCreated',
  flCustomerCreateFailed: 'FlCustomerCreateFailed',
  //-----
  cartUpdated: 'CartUpdated',
  updateCartFailed: 'CartUpdateFailed',
  //-----
  bookingUpdated: 'BookingUpdated',
  bookingUpdateFailed: 'bookingUpdateFailed',
  //-----
  cartPaid: 'CartPaid',
  checkoutCartFailed: 'CheckoutCartFailed',

  //-----
  carresBookingEvent: 'CarresBookingEvent',
  updatedBooking: 'UpdatedBooking',
}

export default signalREvents
