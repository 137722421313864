import {
  CSP_DEFAULT_SRC,
  CSP_FONT_SRC,
  CSP_FRAME_SRC,
  CSP_IMG_SRC,
  CSP_OBJECT_SRC,
  CSP_SCRIPT_SRC,
  CSP_STYLE_SRC,
  CSP_WORKER_SRC,
} from '../config'

export const getCsp = (nonce: string) =>
  `${CSP_DEFAULT_SRC}; ${CSP_FONT_SRC}; ${CSP_FRAME_SRC}; ${CSP_IMG_SRC}; ${CSP_OBJECT_SRC}; ${CSP_SCRIPT_SRC}; ${CSP_STYLE_SRC}; ${CSP_WORKER_SRC} 'nonce-${nonce}'`
