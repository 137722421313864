/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { fetchAccommodationByCodeFromSanity } from '../fetchData'

function useFetchAccommodations(code: string) {
  const [accommodation, setAccommodation] = React.useState<any>(undefined)

  useEffect(() => {
    if (!code) return
    fetchAccommodationByCodeFromSanity(code).then((data: any) => {
      setAccommodation(data?.[0])
    })
  }, [code])

  return {
    accommodation,
  }
}

export default useFetchAccommodations
