import React from 'react'
import { useTranslation } from 'react-i18next'

import { Currency } from '../../../../graphql/types'
import useFetchCabins from '../../../../sanity/cabins/useFetchCabins'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { extractBookingNumber } from '../../../../providers/WebsocketProvider'
import { useLocation } from 'react-router-dom'

type CabinNameProps = {
  cabinCode: string
  quantity: number
  price: number
  currency: Currency
  isOutbound: boolean
}

function CabinName({ cabinCode, quantity, price, currency, isOutbound }: CabinNameProps) {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })

  const { i18n } = useTranslation()

  const vesselCode = isOutbound ? flBooking?.outbound?.vesselCode : flBooking?.inbound?.vesselCode
  const { cabinsFromSanity } = useFetchCabins(cabinCode, vesselCode || undefined)
  return (
    <span>
      {quantity} x {cabinsFromSanity?.cabinName?.[i18n.language] ?? cabinCode} ({currency} {price})
    </span>
  )
}

export default CabinName
