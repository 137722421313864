import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useAvailabilityItems } from '../../../../../providers/AvailabilityItemsProvider'
import { CartsByBookingCodes, cabinToAdd } from '../../../../../providers/availabilityItemsProvider/types'
import { useWebSocketContext } from '../../../../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { SuggestedCabin } from '../../../../../graphql'

function useAddLounge(savedSuggested: SuggestedCabin[], cabinsInState) {
  const { bookingCode } = useParams()
  const { cartData } = useWebSocketContext()
  const { showModal, addToCartState } = useAvailabilityItems()
  const currentCartForBookingCode =
    cartData?.bookingCarts?.[bookingCode || '']?.[showModal.isOutbound ? 'outbound' : 'inbound'] || {}

  const savedSuggestedMappedToCabinToAdd = savedSuggested.map((cabin) => {
    return {
      price: cabin.price,
      code: cabin.code,
      quantityInCabin: cabin.quantityInCabin,
      subCode: 'P',
      id: uuidv4(),
    } as cabinToAdd
  })

  function filterObject() {
    const filteredObj = { ...addToCartState }
    delete filteredObj[bookingCode || '']
    return filteredObj as CartsByBookingCodes
  }
  const carts = filterObject()

  const updatedCartWithLounge = {
    ...currentCartForBookingCode,
    cabins: [...cabinsInState, ...savedSuggestedMappedToCabinToAdd],
  }

  const allCartsUpdated = {
    ...carts,
    [bookingCode || '']: {
      ...carts[bookingCode || ''],
      [showModal.isOutbound ? 'outbound' : 'inbound']: {
        ...updatedCartWithLounge,
      },
    },
  }

  return {
    allCartsUpdated,
  }
}

export default useAddLounge
