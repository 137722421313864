import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Feedback, StepperInput, StepperInputPropType } from '@fjordline/styles-v3'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

export const StepperInputWrapper = styled.div`
  width: 100%;
  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  > div > div > div {
    padding: 0;
    @media only screen and (max-width: 568px) {
      margin-left: -1rem;
    }
    @media only screen and (max-width: 368px) {
      margin-left: 0;
    }

    > button {
      min-height: 2rem;
      width: 2rem;
    }
    > input {
      width: 55px;
      padding: 0.8rem;
    }
  }
`

export const StepperInputWrapperPassengers = styled.div`
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0.6rem;
  }
  > div > div > div {
    > button {
      min-height: 2rem;
      width: 2rem;
    }
    > input {
      width: 55px;
      padding: 0.8rem;
    }
  }
`
const StepperStateWrapper: React.FC<StepperInputPropType> = ({
  allowZero,
  children,
  feedback,
  inputAsText,
  label,
  max,
  min,
  postfix,
  prefix,
  price,
  setValue,
  state,
  value,
  id,
  debounceStepTimeMs = 0,
  disabled,
}: StepperInputPropType) => {
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState(value)
  const [lastValidValue, setLastValidValue] = useState(value)
  useEffect(() => {
    setCurrentValue(value)
    setLastValidValue(value)
  }, [value])

  const onSetValue = useCallback(
    (value: number) => {
      setCurrentValue(value)
      if (isQuantityWithinRange(value, min, max, allowZero)) {
        setLastValidValue(value)
        setValue(value)
      }
    },
    [setValue, min, max, allowZero],
  )
  const getFeedBack = useCallback(
    () =>
      lastValidValue !== currentValue ? (
        <Feedback type="error">{`${t('stepperInputWrapper.selectValidValue')} ${min} - ${max}. `}</Feedback>
      ) : undefined,

    [currentValue, lastValidValue, max, min, t],
  )

  const debounceSetValue = useMemo(
    () => debounce(debounceStepTimeMs || 0, (newValue: number) => onSetValue(newValue)),
    [debounceStepTimeMs, onSetValue],
  )

  const onDebounceSetValue = useCallback(
    (newValue: number) => {
      setCurrentValue(newValue)
      if (isQuantityWithinRange(newValue, min, max, allowZero)) {
        setLastValidValue(newValue)
      }
      debounceSetValue(newValue)
    },
    [debounceSetValue, min, max, allowZero],
  )

  return (
    <StepperInputWrapper>
      <StepperInput
        data-cy={`stepper-${id ? id : label}`}
        allowZero={allowZero}
        feedback={feedback || getFeedBack()}
        inputAsText={inputAsText}
        label={label}
        max={max}
        min={min}
        postfix={postfix}
        disabled={disabled}
        prefix={prefix}
        price={price}
        setValue={debounceStepTimeMs && debounceStepTimeMs > 0 ? onDebounceSetValue : onSetValue}
        state={state}
        value={currentValue}
      >
        {children}
      </StepperInput>
    </StepperInputWrapper>
  )
}

export default StepperStateWrapper

function isQuantityWithinRange(quantity: number, min?: number, max?: number, allowZero = false) {
  if (quantity === 0 && (allowZero || min === 0)) {
    return true
  }
  if (quantity === 0 && !allowZero) {
    return false
  }
  if (min && max) {
    return quantity >= min && quantity <= max
  }
  if (min) {
    return quantity >= min
  }
  if (max) {
    return quantity <= max
  }
  return true
}
