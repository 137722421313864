import { Colors } from '@fjordline/styles-v3'
import styled from 'styled-components'

export const FooterContainer = styled.div`
  footer a {
    color: ${Colors.PROFILE_RED};
  }

  footer > div {
    margin-left: 16rem;
  }

  footer > div > p {
    margin-left: 1rem;
  }

  @media only screen and (max-width: 1200px) {
    footer > div {
      margin-left: 13rem;
    }
    footer > div > p {
      margin-left: 1rem;
    }
  }

  @media only screen and (max-width: 991px) {
    footer > div {
      margin-left: 0rem;
    }
    footer > div > p {
      margin-left: 0rem;
    }
  }
`
