import React from 'react'
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom'
import { FormActionsWrapper, FormElementWrapper, Label, TextLink } from '@fjordline/styles-v3'

import { Status } from '../../types/myPage/enums'

import { JourneysCountAndMessage } from './journeysCountAndMessage'

export default function ActiveAndFutureLinksAndMessages({
  activeJourneysCountAndMessage,
  currentBookingStatusToView,
  futureJourneysCountAndMessage,
  setParams,
}: {
  activeJourneysCountAndMessage: JourneysCountAndMessage
  currentBookingStatusToView: Status
  futureJourneysCountAndMessage: JourneysCountAndMessage
  setParams: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => void
}): JSX.Element | null {
  return (
    <FormElementWrapper>
      <FormActionsWrapper>
        {currentBookingStatusToView !== Status.ACTIVE && activeJourneysCountAndMessage.journeyCount > 0 ? (
          <TextLink onClick={() => setParams(`?view=${Status.ACTIVE}`)}>
            <b>{activeJourneysCountAndMessage.journeysMessage}</b>
          </TextLink>
        ) : (
          <Label>{activeJourneysCountAndMessage.journeysMessage}</Label>
        )}
        {currentBookingStatusToView !== Status.FUTURE && futureJourneysCountAndMessage.journeyCount > 0 ? (
          <TextLink onClick={() => setParams(`?view=${Status.FUTURE}`)}>
            <b>{futureJourneysCountAndMessage.journeysMessage}</b>
          </TextLink>
        ) : (
          <Label>{futureJourneysCountAndMessage.journeysMessage}</Label>
        )}
      </FormActionsWrapper>
    </FormElementWrapper>
  )
}
