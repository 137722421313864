export function hasBasketItems({ addToCartState, bookingCode }: { addToCartState; bookingCode: string }) {
  const outboundBasket = addToCartState?.[bookingCode || '']?.outbound || []
  const inboundBasket = addToCartState?.[bookingCode || '']?.inbound || []
  const hasOutboundCabins = outboundBasket?.cabins && outboundBasket?.cabins.length > 0
  const hasOutboundExtrasAshore = outboundBasket?.extrasAshore && outboundBasket?.extrasAshore.length > 0
  const hasOutboundExtrasOnboard = outboundBasket?.extrasOnboard && outboundBasket?.extrasOnboard.length > 0
  const hasOutboundMeals = outboundBasket?.meals && outboundBasket?.meals.length > 0

  const hasInboundCabins = inboundBasket?.cabins && inboundBasket?.cabins.length > 0
  const hasInboundExtrasAshore = inboundBasket?.extrasAshore && inboundBasket?.extrasAshore.length > 0
  const hasInboundExtrasOnboard = inboundBasket?.extrasOnboard && inboundBasket?.extrasOnboard.length > 0
  const hasInboundMeals = inboundBasket?.meals && inboundBasket?.meals.length > 0

  const hasAnyOutbound = hasOutboundCabins || hasOutboundExtrasAshore || hasOutboundExtrasOnboard || hasOutboundMeals
  const hasAnyInbound = hasInboundCabins || hasInboundExtrasAshore || hasInboundExtrasOnboard || hasInboundMeals

  return { hasAnyOutbound, hasAnyInbound, hasOutboundCabins, hasInboundCabins }
}
