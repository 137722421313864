import { createContext, useContext } from 'react'

import { defaultMyPage, MyPage } from '../../types/MyPage'

import { MyPageOperations } from './myPageReducer/types'

export const myPageStateContext = createContext<MyPage>(defaultMyPage)
myPageStateContext.displayName = 'MyPage State'
export const useMyPage = () => useContext<MyPage>(myPageStateContext)

export const myPageStateOperationContext = createContext<MyPageOperations>({} as MyPageOperations)
myPageStateOperationContext.displayName = 'MyPageStateOperations'
export const useMyPageOperations = () => useContext<MyPageOperations>(myPageStateOperationContext)
