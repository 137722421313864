import React from 'react'
import { useLocation } from 'react-router-dom'

import { useAvailabilityItems } from '../../providers/AvailabilityItemsProvider'
import { extractBookingNumber } from '../../providers/WebsocketProvider'
import { IExtras } from '../../types/myPage/customerBookingDetails/types'
import { ExtraItem } from './ExtrasOnboard'

type Props = {
  isOutbound: boolean
}

const ExtrasAshore: React.FC<Props> = ({ isOutbound }: Props) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)

  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.extrasAshore

  if (itemsInState) {
    return transformExtraItems(itemsInState).map((item) => {
      return <ExtraItem key={item.code} isOutbound={isOutbound} item={item} isOnboard={false} />
    })
  } else {
    return null
  }
}

export default ExtrasAshore

function transformExtraItems(extras: IExtras[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items = [] as any

  for (const item of extras) {
    const { subCode, price } = item

    const specification = {
      specificationCode: subCode,
      specificationPrice: {
        value: price.value,
        currency: price.currency,
      },
    }

    const existingItem = items.find((i) => i.code === item.code)
    if (existingItem) {
      existingItem.spec.push(specification)
    } else {
      items.push({
        startTime: item.startTime,
        code: item.code,
        spec: [specification],
      })
    }
  }

  return items
}
