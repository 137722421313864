import React from 'react'
import { Feedback } from '@fjordline/styles-v3'
import { TFunction } from 'i18next'

export default function BoardingCardAvailabilityFeedback({
  hoursBeforeJourneyStartToShowBoardingCard,
  t,
}: {
  hoursBeforeJourneyStartToShowBoardingCard: number
  t: TFunction<string>
}): JSX.Element {
  return (
    <Feedback type="info" variant="notification" title={`${t('component.boardingCards.messageNotAvailableTitle')}`}>
      {t('component.boardingCards.messageNotAvailable', { hoursBeforeJourneyStartToShowBoardingCard })}
    </Feedback>
  )
}
