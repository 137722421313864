import { TFunction } from 'i18next'

import { FlBooking } from '../../graphql/types'
import { MayBe } from '../../types/MayBe'

import { BookingObjects, MealInfoGroupedByDateAndStartTime } from './getBookingObjects/getBookingObjects.types'
import getDeparturePorts from './getBookingObjects/getDeparturePorts'
import groupMealsByDateAndStartTime from './getBookingObjects/groupMealsByDateAndStartTime'

export default function getBookingObjects({
  bookingCode,
  flBooking,
  language,
  t,
}: {
  bookingCode?: string
  flBooking?: FlBooking
  t: TFunction<string>
  language: string
  updatedBooking?: FlBooking
}): BookingObjects {
  const inbound = MayBe.orElse(flBooking?.inbound ?? null, undefined)
  const extrasAshore = MayBe.orElse(inbound?.extrasAshore ?? null, [])
  const inboundCabins = MayBe.orElse(inbound?.cabins ?? null, [])
  const inboundExtrasAshore = MayBe.orElse(inbound?.extrasAshore ?? null, [])
  const inboundExtrasOnboard = MayBe.orElse(inbound?.extrasOnboard?.filter((e) => e.code !== 'ETAX') ?? null, [])
  const inboundMeals = MayBe.orElse(inbound?.meals ?? null, [])
  const inboundMealsGroupedByDateAndStartTime: MealInfoGroupedByDateAndStartTime | undefined =
    groupMealsByDateAndStartTime({
      meals: inboundMeals,
      t,
    })
  const isActiveOrFuture: boolean = (flBooking?.isOngoing || flBooking?.isInFuture) ?? false
  const isFuture: boolean = flBooking?.isInFuture || false
  const outbound = MayBe.orElse(flBooking?.outbound ?? null, undefined)
  const bookingLanguage = MayBe.orElse(flBooking?.language ?? null, language)
  const journeyPorts: string = getDeparturePorts({
    arrivalPortName: outbound?.arrivalPortInfo?.portName,
    departurePortName: outbound?.departurePortInfo?.portName,
  })
  const outboundCabins = MayBe.orElse(outbound?.cabins ?? null, [])
  const outboundExtrasAshore = MayBe.orElse(outbound?.extrasAshore ?? null, [])
  const outboundExtraOnboard = MayBe.orElse(outbound?.extrasOnboard?.filter((e) => e.code !== 'ETAX') ?? null, [])
  const outboundMeals = MayBe.orElse(outbound?.meals ?? null, [])
  const outboundMealsGroupedByDateAndStartTime: MealInfoGroupedByDateAndStartTime | undefined =
    groupMealsByDateAndStartTime({
      meals: outboundMeals,
      t,
    })
  const validatedBookingCode: string = MayBe.orElse(bookingCode ?? null, '')

  const outboundEtax = MayBe.orElse(outbound?.extrasOnboard?.find((e) => e.code === 'ETAX') ?? null, null)
  const inboundEtax = MayBe.orElse(inbound?.extrasOnboard?.find((e) => e.code === 'ETAX') ?? null, null)

  return {
    extrasAshore,
    inbound,
    inboundCabins,
    inboundExtrasAshore,
    inboundExtrasOnboard,
    inboundMeals,
    inboundMealsGroupedByDateAndStartTime,
    isActiveOrFuture,
    isFuture,
    journeyPorts,
    bookingLanguage,
    outbound,
    outboundCabins,
    outboundExtrasAshore,
    outboundExtraOnboard,
    outboundMeals,
    outboundMealsGroupedByDateAndStartTime,
    validatedBookingCode,
    outboundEtax,
    inboundEtax,
  }
}
