import { BookedAccommodation } from '../../../graphql'

export function mergeRoomsByCode(data: BookedAccommodation[]) {
  const mergedData = [] as BookedAccommodation[]
  data.forEach((item) => {
    const code = item.code
    if (!mergedData[code]) {
      mergedData[code] = {
        rooms: [],
        price: 0, // Initialize the price to 0
        checkinDate: item.checkinDate,
        checkoutDate: item.checkoutDate,
        code: code,
      }
    }

    // Merge rooms with the same code
    mergedData[code].rooms.push(...item.rooms)

    // Update the total price
    mergedData[code].price += item.price
  })

  // Convert the merged data object into an array
  const mergedArray = Object.values(mergedData)

  return mergedArray
}
