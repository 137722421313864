import { Journey } from '@fjordline/booking-draft'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { FlBooking } from '../../graphql'

import { GTM_CATEGORY, GTM_VARIANT, GTMBaseProduct, GTMProduct } from './types'

export function baseProductData(
  session: FlBooking,
  journey: Journey,
  isOutbound: boolean,
  outboundVessel: string,
  inboundVessel: string,
) {
  const timeZone = 'Europe/Oslo'
  const yearMonthDay = 'yyyy-MM-dd'
  const hoursAndMinutes = 'HH:mm'
  const depDate =
    journey?.departureDate === undefined || journey?.departureDate.toString().length === 0
      ? undefined
      : format(utcToZonedTime(journey?.departureDate ? journey?.departureDate : new Date(), timeZone), yearMonthDay)
  const depTime =
    journey?.departureDate === undefined || journey?.departureDate.toString().length === 0
      ? undefined
      : format(utcToZonedTime(journey?.departureDate ? journey?.departureDate : new Date(), timeZone), hoursAndMinutes)

  return {
    brand: isOutbound ? outboundVessel : inboundVessel,
    dimension14: isOutbound
      ? `${session?.outbound?.departurePortInfo?.portCode} - ${session?.outbound?.arrivalPortInfo?.portCode}`
      : `${session?.outbound?.arrivalPortInfo?.portCode} - ${session?.outbound?.departurePortInfo?.portCode}`, // stretch
    dimension15: isOutbound ? 'Trip' : 'Return', // Trip (outbound) or Return
    dimension16: depDate, // departure date
    dimension17: depTime, // departure time of day
  } as GTMBaseProduct
}

export function getTravelTypeForGTM(session: FlBooking) {
  let travelType = ''
  // if (session?.accommodations && session?.accommodations.length > 0) {
  //   travelType = 'Vacation package'
  // } else
  if (
    session?.outbound &&
    session?.outbound.departurePortInfo?.carresPortCode === session?.outbound.arrivalPortInfo?.carresPortCode
  ) {
    travelType = 'Cruise'
  } else {
    travelType = 'Travel'
  }

  return travelType
}

export function getRequestForVirtualURLForGTM(session: FlBooking) {
  let request = ''
  if (
    session?.outbound &&
    session?.outbound.departurePortInfo?.carresPortCode === session?.outbound.arrivalPortInfo?.carresPortCode
  ) {
    request = 'CRUISE'
    // } else if (session?.accommodations && session?.accommodations?.length > 0) {
    //   request = 'PACKAGE'
  } else {
    request = 'RETURN-ONEWAY'
  }
  return request
}

export function ticketData(session: FlBooking, productName, outboundVessel, inboundVessel) {
  const outbound = session?.outbound as unknown as Journey
  const inbound = session?.inbound as unknown as Journey
  const outboundTicket = {
    ...baseProductData(session, outbound, true, outboundVessel as string, inboundVessel as string),
    id: outbound?.ticket?.ticketCode,
    name: productName,
    price: session?.totalPrice,
    quantity: 1,
  } as GTMProduct

  const inboundTicket = inbound
    ? ({
        ...baseProductData(session, inbound, false, outboundVessel as string, inboundVessel as string),
        id: inbound?.ticket?.ticketCode,
        name: productName,
        price: 0,
        quantity: 1,
      } as GTMProduct)
    : undefined

  const tickets = session?.outbound && session?.inbound ? [outboundTicket, inboundTicket] : [outboundTicket]
  return tickets
}

export function compartmentsData(session, availabilityItemsInCart, outboundVessel: string, inboundVessel: string) {
  const outboundCompartments = availabilityItemsInCart?.outbound?.cabins
    ? availabilityItemsInCart?.outbound?.cabins?.map((cabin) => {
        return {
          ...baseProductData(
            session,
            availabilityItemsInCart.outbound,
            true,
            outboundVessel as string,
            inboundVessel as string,
          ),
          name: cabin.code,
          id: cabin.code,
          variant: GTM_VARIANT.PERSON,
          category: GTM_CATEGORY.COMPARTMENTS,
          quantity: 1,
          price: cabin.price?.value,
          currency: session?.currency,
        } as GTMProduct
      })
    : []
  const inboundCompartments = availabilityItemsInCart?.inbound?.cabins
    ? availabilityItemsInCart?.inbound?.cabins?.map((cabin) => {
        return {
          ...baseProductData(
            session,
            availabilityItemsInCart?.inbound,
            false,
            outboundVessel as string,
            inboundVessel as string,
          ),
          name: cabin.code,
          id: cabin.code,
          variant: GTM_VARIANT.PERSON,
          category: GTM_CATEGORY.COMPARTMENTS,
          quantity: 1,
          price: cabin.price?.value,
          currency: session?.currency,
        } as GTMProduct
      })
    : []

  const cabins =
    session?.outbound && session?.inbound
      ? [...((outboundCompartments as GTMProduct[]) ?? []), ...((inboundCompartments as GTMProduct[]) ?? [])]
      : outboundCompartments
  return cabins
}

export function mealsData(session: FlBooking, availabilityItemsInCart, outboundVessel: string, inboundVessel: string) {
  const outboundMeals = availabilityItemsInCart?.outbound?.meals?.map((meal) => {
    return {
      ...baseProductData(
        session,
        availabilityItemsInCart?.outbound,
        true,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: meal.code,
      id: meal.code,
      variant: meal.subCode,
      category: GTM_CATEGORY.MEALS,
      quantity: meal.quantity,
      price: meal.price?.value,
      currency: session?.currency,
    } as GTMProduct
  })
  const inboundMeals = availabilityItemsInCart?.inbound?.meals?.map((meal) => {
    return {
      ...baseProductData(
        session,
        availabilityItemsInCart?.inbound,
        false,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: meal.code,
      id: meal.code,
      variant: meal.subCode,
      quantity: meal.quantity,
      price: meal.price?.value,
      currency: session?.currency,
      category: GTM_CATEGORY.MEALS,
    } as GTMProduct
  })

  const meals =
    session?.outbound &&
    session?.inbound !== null &&
    ((outboundMeals && outboundMeals.length > 0) || (inboundMeals && inboundMeals.length > 0))
      ? [...((outboundMeals as GTMProduct[]) ?? []), ...((inboundMeals as GTMProduct[]) ?? [])]
      : outboundMeals
  return meals
}

export function extrasData(session: FlBooking, availabilityItemsInCart, outboundVessel: string, inboundVessel: string) {
  const outboundExtrasAshore = availabilityItemsInCart?.outbound?.extrasAshore?.map((extra) => {
    return {
      ...baseProductData(
        session,
        availabilityItemsInCart?.outbound,
        true,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: extra.code,
      category: GTM_CATEGORY.EXTRAS,
      id: extra.code,
      variant: extra.subCode,
      quantity: extra.quantity,
      price: extra.price?.value,
      currency: session?.currency,
    } as GTMProduct
  })
  const inboundExtrasAshore = availabilityItemsInCart?.inbound?.extrasAshore?.map((extra) => {
    return {
      ...baseProductData(
        session,
        availabilityItemsInCart?.inbound,
        false,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: extra.code,
      category: GTM_CATEGORY.EXTRAS,
      id: extra.code,
      variant: extra.subCode,
      quantity: extra.quantity,
      price: extra.price?.value,
      currency: session?.currency,
    } as GTMProduct
  })

  const outboundExtrasOnboard = availabilityItemsInCart?.outbound?.extrasOnboard?.map((extra) => {
    return {
      ...baseProductData(
        session,
        availabilityItemsInCart?.outbound,
        true,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: extra.code,
      id: extra.code,
      category: GTM_CATEGORY.EXTRAS,
      variant: extra.subCode,
      quantity: extra.quantity,
      price: extra.price?.value,
      currency: session?.currency,
    } as GTMProduct
  })
  const inboundExtrasOnboard = availabilityItemsInCart?.inbound?.extrasOnboard?.map((extra) => {
    return {
      ...baseProductData(
        session,
        availabilityItemsInCart?.inbound,
        false,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: extra.code,
      id: extra.code,
      category: GTM_CATEGORY.EXTRAS,
      variant: extra.subCode,
      quantity: extra.quantity,
      price: extra.price?.value,
      currency: session?.currency,
    } as GTMProduct
  })

  const extrasAshore =
    session?.outbound &&
    session?.inbound !== null &&
    ((outboundExtrasAshore && outboundExtrasAshore.length > 0) ||
      (inboundExtrasAshore && inboundExtrasAshore.length > 0))
      ? [...((outboundExtrasAshore as GTMProduct[]) ?? []), ...((inboundExtrasAshore as GTMProduct[]) ?? [])]
      : outboundExtrasAshore

  const extrasOnboard =
    session?.outbound &&
    session?.inbound !== null &&
    ((outboundExtrasOnboard && outboundExtrasOnboard.length > 0) ||
      (inboundExtrasOnboard && inboundExtrasOnboard.length > 0))
      ? [...((outboundExtrasOnboard as GTMProduct[]) ?? []), ...((inboundExtrasOnboard as GTMProduct[]) ?? [])]
      : outboundExtrasOnboard

  return [...((extrasAshore as GTMProduct[]) ?? []), ...((extrasOnboard as GTMProduct[]) ?? [])]
}

export function passengersData(session: FlBooking, outboundVessel: string, inboundVessel: string) {
  const outboundPassengers = session?.outbound?.passengers?.map((passenger) => {
    const passengerPrice = session?.outbound?.passengerPrices?.find((p) => p.code === passenger.fareCode)
      ?.price as number
    return {
      ...baseProductData(
        session,
        session?.outbound as unknown as Journey,
        true,
        outboundVessel as string,
        inboundVessel as string,
      ),
      name: passenger.fareCode,
      id: passenger.fareCode,
      category: GTM_CATEGORY.PASSENGERS,
      variant: undefined,
      quantity: session?.outbound?.passengers?.filter((e) => e.fareCode === passenger.fareCode).length || 0,

      price: passengerPrice || 0,
      currency: session?.currency,
    } as GTMProduct
  })
  const inboundPassengers =
    session?.outbound && session?.inbound !== null
      ? session?.inbound?.passengers?.map((passenger) => {
          const passengerPrice = session?.inbound?.passengerPrices?.find((p) => p.code === passenger.fareCode)?.price
          return {
            ...baseProductData(
              session,
              session?.inbound as unknown as Journey,
              false,
              outboundVessel as string,
              inboundVessel as string,
            ),
            name: passenger.fareCode,
            id: passenger.fareCode,
            category: GTM_CATEGORY.PASSENGERS,
            variant: undefined,
            quantity: session?.inbound?.passengers?.filter((e) => e.fareCode === passenger.fareCode).length || 0,
            price: passengerPrice || 0,
            currency: session?.currency,
          } as GTMProduct
        })
      : []
  const passengers =
    session?.outbound && session?.inbound !== null ? [outboundPassengers, inboundPassengers] : outboundPassengers

  return passengers
}
