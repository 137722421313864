import { useEffect, useState } from 'react'
import { ShipCabin } from './types'
import { fetchCabinsByCodeFromSanity } from '../fetchData'
import cabincodeForSanity from './cabinHelpLounge'

function useFetchCabins(code?: string, vesselCode?: string) {
  const [cabinsFromSanity, setCabinFromSanity] = useState<ShipCabin | undefined>(undefined)

  useEffect(() => {
    if (!code || !vesselCode) return
    fetchCabinsByCodeFromSanity(cabincodeForSanity(code, vesselCode)).then((cabins) => {
      setCabinFromSanity(cabins?.[0])
    })
  }, [code, vesselCode])

  return {
    cabinsFromSanity,
  }
}

export default useFetchCabins
