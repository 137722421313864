import { useEffect, useState } from 'react'

export const nordicCountryCodes = ['no', 'dk', 'se', 'fl', 'is', 'ax', 'fo', 'gl']
const schengenCountryCodes = [
  'at',
  'be',
  'bg',
  'cy',
  'cz',
  'de',
  'dk',
  'ee',
  'el',
  'es',
  'fi',
  'fr',
  'hr',
  'hu',
  'ie',
  'it',
  'lt',
  'lu',
  'lv',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'se',
  'si',
  'sk',
]

const isCountryNordicOrInSchengen = (countryCode: string) =>
  nordicCountryCodes.includes(countryCode.toLowerCase()) || schengenCountryCodes.includes(countryCode.toLowerCase())

const isCountryNordic = (countryCode: string) => nordicCountryCodes.includes(countryCode.toLowerCase())

const isCountryInSchengen = (countryCode: string) => schengenCountryCodes.includes(countryCode.toLowerCase())

function useCountryMember(countryCode: string) {
  const [countryMember, setCountryMember] = useState({
    isCountryNordicOrInSchengen: false,
    isCountryNordic: false,
    isCountryInSchengen: false,
  })

  useEffect(() => {
    const countryCodeLowerCase = countryCode.toLowerCase()
    setCountryMember({
      isCountryNordicOrInSchengen: isCountryNordicOrInSchengen(countryCodeLowerCase),
      isCountryNordic: isCountryNordic(countryCodeLowerCase),
      isCountryInSchengen: isCountryInSchengen(countryCodeLowerCase),
    })
  }, [countryCode])

  return countryMember
}

export default useCountryMember
