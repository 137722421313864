import { Cabin, Extra, Journey, Meal } from '../../../graphql/types'

export enum MealSpec {
  ADL = 'ADL',
  CHD = 'CHD',
  BABY = 'BABY',
  INF = 'INF',
}

export enum MealSpecGrieg {
  SIXCOUR = '6COUR',
  FIVECOUR = '5COUR',
  FOURCOUR = '4COUR',
  THREECOUR = '3COUR',
}

type MealInfo = {
  quantity: number
  rowNumber: number
  translatedInfo: string
}

export type Participants = { [subCode: string]: MealInfo }

export type MealInfoGroupedByDateAndStartTime = {
  [date: string]: {
    [startTime: string]: {
      mealNameFromCode?: string
      totalQuantity: number
      participants: Participants
    }
  }
}

export type BookingObjects = {
  extrasAshore: Extra[]
  inbound: Journey | undefined
  inboundCabins: Cabin[]
  inboundExtrasAshore: Extra[]
  inboundExtrasOnboard: Extra[]
  inboundMeals: Meal[]
  inboundMealsGroupedByDateAndStartTime: MealInfoGroupedByDateAndStartTime | undefined
  isActiveOrFuture: boolean
  isFuture: boolean
  journeyPorts: string
  bookingLanguage: string
  outbound: Journey | undefined
  outboundCabins: Cabin[]
  outboundExtrasAshore: Extra[]
  outboundExtraOnboard: Extra[]
  outboundMeals: Meal[]
  outboundMealsGroupedByDateAndStartTime: MealInfoGroupedByDateAndStartTime | undefined
  validatedBookingCode: string
  outboundEtax: Extra | null
  inboundEtax: Extra | null
}
